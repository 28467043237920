/**
 * react-images-uploading
 * @see https://github.com/vutoan266/react-images-uploading/blob/master/README.md
 * @see https://www.npmjs.com/package/react-images-uploading
 */

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CompanyActions } from '../_redux/action';
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import ImageUploading from 'react-images-uploading';
import { DeleteConfirm } from '_metronic/_partials/dialogs';
import { Icon } from '_metronic/_icons';
import { BASE_URL } from 'constants/apiUrl';



export function Logo({ loading }) {

  const dispatch = useDispatch();

  const { companyBaseData, logo } = useSelector(state => ({
    companyBaseData: state.company.companyBaseData,
    logo: state.company.logo,
  }));

  const [show, setShow] = useState(false);
  const [index, setIndex] = useState();

  const uploadImage = async (imageList, addUpdateIndex) => {

    if (imageList.length === 0 && addUpdateIndex === undefined) {
      dispatch(CompanyActions.deleteFile(companyBaseData.id, 'company_logo', companyBaseData.logo?.id));
    }
    dispatch(CompanyActions.logoOnChangeHandler(imageList[0]));
  };

  const pictureSpecs = {
    width: 1200,
    height: 1200,
    size: 3145728, // 3MB
    format: ['jpeg', 'jpg', 'gif', 'png'],
    maxNumber: 1,
  };

  return (
    <Card>

      <CardSubHeader
        icon={<Icon.Image />}
        title={<FormattedMessage id="GENERAL.LOGO" />}
      />

      <CardBody>
        <ImageUploading multiple={false} value={companyBaseData.logo.url?.length > 0 ? [companyBaseData.logo] : logo.url?.length > 0 ? [logo] : []} onChange={uploadImage} dataURLKey="url" maxNumber={pictureSpecs.maxNumber} acceptType={pictureSpecs.format} maxFileSize={pictureSpecs.size}>
          {({ imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps, errors }) => (
            <>
              {!imageList.length > 0 && <div
                style={{
                  borderColor: errors ? `#F9D7DA` : `#cbd5e1`,
                  background: isDragging ? '#fff' : errors ? `#FFFAFB` : '#f8fafc',
                }}
                className='images-container'
                onClick={onImageUpload}
                disabled={loading}
                id="images_upload"
                {...dragProps}
              >
                <div className="d-flex align-items-center flex-column mt-12 mb-5" style={{ pointerEvents: 'none' }}>
                  <div className="svg-icon svg-icon-6x opacity-25 mb-8">
                    <Icon.ImagePlus />
                  </div>

                  <b className="text-muted mb-8">
                    <FormattedMessage id="PRODUCT.PICTURE_HERE" />
                  </b>

                  <small className="text-center">
                    <div>
                      <FormattedMessage id="PRODUCT.PICTURE_STANDARD" />
                    </div>

                    <div>
                      <FormattedMessage id="PRODUCT.PICTURE_FORMAT" values={{ size: pictureSpecs.size / 1024 / 1024, format: pictureSpecs.format.join(', ').toLocaleUpperCase() }} />
                    </div>
                  </small>
                </div>

                <div className="font-weight-bold text-danger text-center mb-5" style={{ height: '20px' }}>
                  {errors &&
                    <>
                      <Icon.ExclamationTriangle className="mr-1" />
                      {errors?.maxNumber && <FormattedMessage id="GENERAL.PICTURE.MAX_NUMBER" values={{ pictureMaxNumber: pictureSpecs.maxNumber }} />}
                      {errors?.acceptType && <FormattedMessage id="GENERAL.PICTURE.FORMAT" values={{ pictureFormat: pictureSpecs.format.join(', ').toLocaleUpperCase() }} />}
                      {errors?.maxFileSize && <FormattedMessage id="GENERAL.PICTURE.SIZE" values={{ pictureSize: pictureSpecs.size / 1024 / 1024 }} />}
                    </>
                  }
                </div>
              </div>}

              {/* {imageList.length > 0 && <div className="separator separator-solid my-10" />} */}

              {imageList?.map((image, i) => (
                <div className="d-flex align-items-start" key={i}>
                  <img src={image.file ? `${image.url}` : `${BASE_URL}/${image.url}`} alt="" style={{ maxHeight: '10rem' }} />
                  <OverlayTrigger overlay={<Tooltip id="company-logo-tooltip"><FormattedMessage id="GENERAL.DELETE" /></Tooltip>}>
                    <Button variant="outline-secondary" size="sm" type="button" className="btn-hover-danger px-3 py-2 ml-3" onClick={() => { setShow(true); setIndex(i); }} disabled={loading} id="btn_logo_delete">
                      <Icon.Close />
                    </Button>
                  </OverlayTrigger>
                </div>
              ))}

              <DeleteConfirm show={show} onHide={() => setShow(false)} toDelete={() => { onImageRemove(index); setShow(false); }} />
            </>
          )}
        </ImageUploading>
      </CardBody>
    </Card>
  );
}
