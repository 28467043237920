import { call, put, takeEvery, select, delay } from 'redux-saga/effects';
import { getNotification } from '_metronic/layout/components/header/_redux/saga';
import { AuthActions } from 'app/pages/auth/_redux/actions';
import { connectionsMiddleware } from 'middlewares';
import { ConnectionsActions } from './actions';
import ActionTypes from './actionTypes';




// Get Connections
function* getConnections() {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const token = yield call(connectionsMiddleware.getConnections, accessToken);
    yield put(ConnectionsActions.getConnectionsSuccess(token.data));

  } catch (err) {
    if (err.response && err.response.status === 401) {
      return yield put(AuthActions.logout());
    }
    yield put(ConnectionsActions.getConnectionsFail(err));
  }
}


// Create Settings
function* createSettings({ payload: { settings } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const response = yield call(connectionsMiddleware.createSettings, accessToken, settings)
    yield put(ConnectionsActions.createSettingsSuccess(response.data))

    yield delay(10000); // BE siparişlerin alınması için 10 saniye bekleniyor
    yield call(getNotification);

  } catch (err) {
    yield put(ConnectionsActions.createSettingsFail(err));
  }
}


// Update Settings
function* updateSettings({ payload: { settings } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const response = yield call(connectionsMiddleware.updateSettings, accessToken, settings);
    yield put(ConnectionsActions.updateSettingsSuccess(response.data))

  } catch (err) {
    yield put(ConnectionsActions.updateSettingsFail(err?.response?.data?.error?.message));
  }
}


// Connection check
function* connectionCheck({ payload: { tokenId } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const response = yield call(connectionsMiddleware.connectionCheck, accessToken, tokenId);
    yield put(ConnectionsActions.connectionCheckSuccess(response.data));

    //Error handling bakilmali catch e girmedigi icin buradan faile gönderildi
    if (response?.data?.error && response?.data?.check === 'failed') {
      yield put(ConnectionsActions.connectionCheckFail(response.data.error));
    }

  } catch (err) {
    //Error handling bakilmali buraya hic girmiyor
    const errMsg = err?.response?.data?.error?.message || 'Connection failed';
    yield put(ConnectionsActions.connectionCheckFail(errMsg));
  }
}


// connection fetch orders
function* connectionFetchOrders({ payload: { market_id } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const response = yield call(connectionsMiddleware.connectionFetchOrders, accessToken, market_id);
    yield put(ConnectionsActions.connectionFetchOrdersSuccess(response.data));

    // Siparislerin alinmasi basarili ise bildirimlerin güncellenmesi icin
    yield call(getNotification);

  } catch (err) {
    const errMsg = err?.response?.data?.error?.message || 'Fetch Product failed';
    yield put(ConnectionsActions.connectionFetchOrdersFail(errMsg));
  }
}


// Get Settings By Id
function* getConnectionById({ payload: { settingsId } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const response = yield call(connectionsMiddleware.getSettingById, accessToken, settingsId);
    yield put(ConnectionsActions.getConnectionByIdSuccess(response.data));

  } catch (err) {
    if (err.response && err.response.status === 401) {
      return yield put(AuthActions.logout());
    }
    yield put(ConnectionsActions.getConnectionByIdFail(err));
  }
}


// Delete Settings
function* deleteSettings({ payload: { settingsId, settingsType } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(connectionsMiddleware.deleteSettings, accessToken, settingsType, settingsId);
    yield put(ConnectionsActions.deleteSettingsSuccess(settingsId));

  } catch (err) {
    if (err.response && err.response.status === 401) {
      return yield put(AuthActions.logout());
    }
    yield put(ConnectionsActions.deleteSettingsFail());
  }
}


//* --------------------- Deutsche Post --------------------- *//

// Get Deutsche Post Configs
function* getDeutschePostConfigs() {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const response = yield call(connectionsMiddleware.getDeutschePostConfigs, accessToken);
    yield put(ConnectionsActions.getDeutschePostConfigsSuccess(response.data));

  } catch (err) {
    yield put(ConnectionsActions.getDeutschePostConfigsFail(err));
  }
}


//* -------------------------- Ebay -------------------------- *//

// Set eBay Auth Token
function* setEbayAuthToken({ payload: { code } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const response = yield call(connectionsMiddleware.saveEbayToken, accessToken, code);
    yield put(ConnectionsActions.setEbayAuthTokenSuccess(response.data));

  } catch (err) {
    yield put(ConnectionsActions.setEbayAuthTokenFail());
  }
}


// Get eBay Auth Token
function* getEbayAuthToken() {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const response = yield call(connectionsMiddleware.getEbayToken, accessToken);
    yield put(ConnectionsActions.getEbayAuthTokenSuccess(response.data));

  } catch (err) {
    if (err.response && err.response.status === 401) {
      return yield put(AuthActions.logout());
    }
    yield put(ConnectionsActions.getEbayAuthTokenFail());
  }
}


//* --------------------- Amazon --------------------- *//

function* getAmazonAuthToken() {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const response = yield call(connectionsMiddleware.getAmazonToken, accessToken);
    yield put(ConnectionsActions.getAmazonAuthTokenSuccess(response.data));

  } catch (err) {
    yield put(ConnectionsActions.getAmazonAuthTokenFail());
  }
}


function* getAmazonConnection() {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const response = yield call(connectionsMiddleware.getSettingsByTypeParams, accessToken, "Amazon");
    yield put(ConnectionsActions.getAmazonConnectionSuccess(response.data));

  } catch (err) {
    yield put(ConnectionsActions.getAmazonConnectionFail());
  }
}


//* --------------------- Etsy --------------------- *//

// Get Etsy Auth Token
function* getEtsyAuthToken({ payload: { settings } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const response = yield call(connectionsMiddleware.getEtsyToken, accessToken, settings);
    yield put(ConnectionsActions.getEtsyAuthTokenSuccess(response.data))

  } catch (err) {
    if (err.response && err.response.status === 401) {
      return yield put(AuthActions.logout());
    }
    yield put(ConnectionsActions.getEtsyAuthTokenFail());
  }
}


// Set Etsy Auth Token
function* setEtsyAuthToken({ payload: { settings } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const response = yield call(connectionsMiddleware.setEtsyAuth, accessToken, settings)
    yield put(ConnectionsActions.setEtsyAuthTokenSuccess(response.data));

  } catch (err) {
    yield put(ConnectionsActions.setEtsyAuthTokenFail());
  }
}



export function* connectionsSaga() {
  yield takeEvery(ActionTypes.GET_CONNECTIONS, getConnections);
  yield takeEvery(ActionTypes.GET_CONNECTION_BY_ID, getConnectionById);
  yield takeEvery(ActionTypes.CREATE_SETTINGS, createSettings);
  yield takeEvery(ActionTypes.UPDATE_SETTINGS, updateSettings);
  yield takeEvery(ActionTypes.DELETE_SETTINGS, deleteSettings);

  yield takeEvery(ActionTypes.CONNECTION_CHECK, connectionCheck);
  yield takeEvery(ActionTypes.CONNECTION_FETCH_ORDERS, connectionFetchOrders);

  yield takeEvery(ActionTypes.GET_DEUTSCHE_POST_CONFIGS, getDeutschePostConfigs);

  yield takeEvery(ActionTypes.GET_EBAY_AUTH_TOKEN, getEbayAuthToken);
  yield takeEvery(ActionTypes.SET_EBAY_AUTH_TOKEN, setEbayAuthToken);

  yield takeEvery(ActionTypes.GET_AMAZON_AUTH_TOKEN, getAmazonAuthToken);
  yield takeEvery(ActionTypes.GET_AMAZON_CONNECTION, getAmazonConnection);

  yield takeEvery(ActionTypes.GET_ETSY_AUTH_TOKEN, getEtsyAuthToken);
  yield takeEvery(ActionTypes.SET_ETSY_AUTH_TOKEN, setEtsyAuthToken);
}
