import React, { useEffect, useState } from 'react';
import { SearchControl } from '_metronic/_partials/controls';
import { ProductsGrouping } from './ProductsGrouping';
import { Col, Row } from 'react-bootstrap';
import { useQuery } from 'app/hooks';
import { Dropdown } from 'react-bootstrap';
import { theme } from '_metronic/_helpers';
import { Icon } from '_metronic/_icons';
import { FormattedMessage } from 'react-intl';

export function ProductsFilter({ queryParams, setSearchQuery, setFilterQuery }) {


  const query = useQuery();
  const [search, setSearchBase] = useState(query.get('search') ?? '');

  /* Filter */
  const [filterValue, setFilterValue] = useState({
    published: queryParams?.filter?.inventories?.elemMatch ? true : false,
    unpublished: queryParams?.filter?.inventories?.$not ? true : false,
    low_stock: queryParams?.filter?.quantity?.lt ? true : false,
  });

  const handleFilterChange = (e) => {

    const { name, checked } = e.target;

    setFilterValue((prev) => {

      const updatedFilterValue = {
        ...prev,
        [name]: checked,
      };

      if (name === "published" && checked) {
        updatedFilterValue.unpublished = false;
      } else if (name === "unpublished" && checked) {
        updatedFilterValue.published = false;
      }

      const filterQueries ={
        published: {
          col_name: "inventories",
          query_key: "inventories",
          query_value: { elemMatch : { status: "PUBLISHED", inventory: { $exists: true } } },
        },
        unpublished: {
          col_name: "inventories",
          query_key: "inventories",
          query_value: { $not: { $elemMatch: { status: "PUBLISHED" } } },
        },
        low_stock: {
          col_name: "quantity",
          query_key: "quantity",
          query_value: { lt: 10 },
        }
      }

      updatedFilterValue[name] === true ? setFilterQuery(filterQueries[name].query_key, filterQueries[name].query_value) : setFilterQuery(filterQueries[name].query_key, null);
  
      return updatedFilterValue;
    });

  };

  const resetFilters = () => {
    setFilterValue({
      published: false,
      unpublished: false,
      low_stock: false,
    });
  
    // Tüm filtreleri kaldır
    setFilterQuery("DELETE_PARAMS", ["inventories", "quantity"]);
  };
  
  useEffect(() => {
    setSearchBase(query.get('search') ?? '');
    setFilterValue({
      published: queryParams?.filter?.inventories?.elemMatch ? true : false,
      unpublished: queryParams?.filter?.inventories?.$not ? true : false,
      low_stock: queryParams?.filter?.quantity?.lt ? true : false,
    });
  }, [query, queryParams]);

  const handleSearch = (value) => {
    setSearchQuery(value);
    setSearchBase(value);
  };


  return (
    <Row className='mb-3'>

      <Col sm="12" md="6" lg="4" className="mb-lg-0 mb-2">
        <SearchControl search={search} handleSearch={handleSearch} />
      </Col>

      <Col className='d-flex align-items-start justify-content-start px-md-0'>

        <Dropdown className="dropdown-inline symbol mr-3" drop="down">
          <Dropdown.Toggle variant='outline-secondary' className="svg-icon svg-icon-sm font-weight-bold" style={{ height: theme.units.input.height['sm'] }} id="dropdown-basic">
            <Icon.Sliders className="mr-3" />
            <FormattedMessage id='DOCUMENT.FILTER.CONTENT' />
          </Dropdown.Toggle>

          {Object.keys(filterValue)?.some(key => filterValue[key] === true) && <i className="symbol-badge bg-danger"></i>}
          <Dropdown.Menu className="dropdown-menu-right dropdown-menu-anim-down" style={{ minWidth: '180px' }} id="dropdown_document_filter">
            <ul className="navi navi-hover font-weight-bold">
             
              <li className="navi-item">
                <label className="navi-link checkbox checkbox-md checkbox-outline checkbox-primary">
                  <input type="checkbox" name="published" checked={filterValue.published} onChange={handleFilterChange} />
                  <span />
                  <div className="text-nowrap">
                    <FormattedMessage id="PRODUCT.FILTERS.PUBLISHED" />
                  </div>
                </label>
              </li>

              <li className="navi-item">
                <label className="navi-link checkbox checkbox-md checkbox-outline checkbox-primary">
                  <input type="checkbox" name="unpublished" checked={filterValue.unpublished} onChange={handleFilterChange} />
                  <span />
                  <div className="text-nowrap">
                    <FormattedMessage id="PRODUCT.FILTERS.UNPUBLISHED" />
                  </div>
                </label>
              </li>

              <li className="navi-item">
                <label className="navi-link checkbox checkbox-md checkbox-outline checkbox-primary">
                  <input type="checkbox" name="low_stock" checked={filterValue.low_stock} onChange={handleFilterChange} />
                  <span />
                  <div className="text-nowrap">
                    <FormattedMessage id="PRODUCT.FILTERS.LOW_STOCK" />
                  </div>
                </label>
              </li>

              <Dropdown.Divider />

              <li className="navi-item">
                <Dropdown.Item className='navi-link font-weight-bolder text-danger justify-content-center bg-white py-1' id='clear'>
                  <span onClick={resetFilters}>
                    <FormattedMessage id='GENERAL.CLEAR' />
                  </span>
                </Dropdown.Item>
              </li>
            </ul>
          </Dropdown.Menu>
        </Dropdown>

      </Col>

      <Col className='d-flex align-items-start justify-content-end'>
        <ProductsGrouping />
      </Col>
      
    </Row>
  );
}
