import React, { useMemo, useEffect } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import { FormattedMessage, useIntl, } from "react-intl";
import { Card, CardBody, CardSubHeader, SVReactSelectInput } from "_metronic/_partials/controls";
import { getVariantSpecs } from '../../../../products/product-edit/variants/core/helpers';
import { useProductListingsUIContext } from "../../../_context/ProductListingsUIContext";
import { useSelector, shallowEqual } from "react-redux";
import { getAspectRows } from '../../EbayListingsUIHelper';
import * as columnFormatters from '../ColumnFormatters';
import { Col, Row } from "react-bootstrap";
import { Spinner } from "_metronic/_partials";
import { utils } from '_metronic/_helpers';
import { Field } from "formik";



export default function EbayCategory({ method, market_id }) {

  const { formatMessage: intl } = useIntl();

  const UIContext = useProductListingsUIContext();
  const UIProps = useMemo(() => ({
    categoryInputValue: UIContext.categoryInputValue,
    setCategoryInputValue: UIContext.setCategoryInputValue,
    selectedCategoryAspects: UIContext.selectedCategoryAspects,
    setCategoryAspects: UIContext.setCategoryAspects,
  }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [UIContext.categoryInputValue, UIContext.selectedCategoryAspects]
  );

  const { productForEdit, productVariants, categorySuggestions, isLoaded } = useSelector(state => ({
    productForEdit: state.listings.productForEdit,
    categorySuggestions: state.listings.categorySuggestions,
    isLoaded: state.listings.isLoaded,
    productVariants: state.products.variants,
  }), shallowEqual);


  useEffect(() => {

    if (categorySuggestions?.length > 0 && productForEdit) {
      if (method === "new") {
        const firstCategory = categorySuggestions[0];

        categoryOnChangeHandler({ value: firstCategory.categoryId, label: firstCategory.categoryPath });
      } else if (method === "edit") {
        const listingCategory = productForEdit.ebayInventory?.category?.id;
        const categoryFind = categorySuggestions.find(item => item.categoryId === listingCategory);
        const category = categoryFind ? categoryFind : categorySuggestions[0];
        categoryOnChangeHandler({ value: category.categoryId, label: category.categoryPath });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, categorySuggestions, productForEdit, method, market_id]);

  const categoryOnChangeHandler = cat => {

    UIProps.setCategoryInputValue(cat);
    UIProps.setCategoryAspects([]);

    const findCategory = categorySuggestions.find(cs => cs.categoryId === cat.value);
    if (findCategory) {
      const specifications = getVariantSpecs(productVariants);
      const _selectedAspects = [];

      for (const aspect of findCategory.aspects) {
        const { localizedAspectName, aspectConstraint, aspectValues } = aspect;
        const mode = aspectConstraint?.aspectMode;
        const findMatchedAspect = productForEdit?.features?.find(f => f.name.toLowerCase() === localizedAspectName?.toLowerCase());
        const isAspectNameInVariantOptions = specifications.find(spec => spec.name === localizedAspectName);
        const aspectValue = findMatchedAspect?.value;

        let value;
        if (findMatchedAspect && aspectValue) {
          Array.isArray(aspectValue) ? value = aspectValue[0] : value = aspectValue
        }

        if (isAspectNameInVariantOptions) {
          continue;
        }

        const newRow = getAspectRows({
          id: utils.uuidv4(),
          isRequired: aspectConstraint?.aspectRequired,
          name: localizedAspectName,
          options: mode === 'SELECTION_ONLY' && aspectValues?.length > 0 && aspectValues.map(val => val.localizedValue),
          value: value ? value : localizedAspectName === 'Marke' ? productForEdit?.brand : '',
          mode: mode,
          dataType: aspectConstraint.aspectDataType.toLowerCase(),
        });
        _selectedAspects.push(newRow);
      }

      /* for (let i = 0; i < productForEdit?.features.length; i++) {
        for (let j = 0; j < _selectedAspects.length; j++) {
          if (productForEdit?.features[i].name.toLowerCase() === _selectedAspects[j].name.toLowerCase()) {
            const aspectValue = productForEdit?.features[i].value;
            _selectedAspects[j].value = Array.isArray(findMatchedAspect.value) ? aspectValue[0] : aspectValue;
          }
        }
      } */

      const requiredOrMatchedAspects = _selectedAspects.filter(aspect => aspect.isRequired);
      const optionalAspects = _selectedAspects.filter(aspect => !aspect.isRequired);
      const concatenatedAspects = [...requiredOrMatchedAspects, ...optionalAspects];
      UIProps.setCategoryAspects(concatenatedAspects);
    }
  };

  const aspectColumns = [
    {
      dataField: 'position',
      isDummyField: true,
      text: intl({ id: 'GENERAL.NO' }),
      headerStyle: { width: '5%', textAlign: 'center' },
      classes: 'text-center',
      formatter: (cell, row, rowIndex, formatExtraData) => { return rowIndex + 1; },
    },
    {
      dataField: 'isRequired',
      text: intl({ id: 'GENERAL.STATUS' }),
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <label className={`label label-inline label-sm label-${cell ? 'success' : 'light'}`}>
          <FormattedMessage id={cell ? 'GENERAL.REQUIRED' : 'GENERAL.OPTIONAL'} />
        </label>
      ),
      headerStyle: { width: '15%', textAlign: 'center' },
      classes: 'text-center',
    },
    {
      dataField: 'name',
      text: intl({ id: 'GENERAL.NAME' }),
      headerStyle: { width: '15%', textAlign: 'center' },
      classes: 'text-center',
    },
    {
      dataField: 'value',
      text: intl({ id: 'PRODUCT.EBAY.VALUE' }),
      formatter: (cell, row, rowIndex, formatExtraData) => (
        row.mode === 'SELECTION_ONLY'
          ? <select className="form-control form-control-sm"
            onChange={e => UIProps.setCategoryAspects(prev => {
              prev[rowIndex].value = e.target.value; return prev;
            })}
          >
            <option value="" style={{ display: 'none' }}>{intl({ id: 'PRODUCT_LISTINGS.EBAY.SELECT_VALUE' })}</option>
            {row.options != null && row.options.length > 0 && row.options.map(val => (
              <option key={val} value={val}>{val}</option>
            ))}
          </select>
          : <input
            className="form-control form-control-sm"
            defaultValue={row.value}
            onBlur={e => UIProps.setCategoryAspects(prev => {
              prev[rowIndex].value = e.target.value; return prev;
            })}
          />
      ),
      headerStyle: { width: '45%', textAlign: 'center' },
    },
    {
      dataField: 'action',
      text: intl({ id: 'GENERAL.ACTIONS' }),
      headerClasses: 'text-center',
      formatter: columnFormatters.ActionFormatterCategory,
      headerStyle: { width: '10%', textAlign: 'center' },
      classes: 'text-center',
      formatExtraData: { deletePositionAction: id => UIProps.setCategoryAspects(prev => prev.filter(aspect => aspect.id !== id)) },
    },
  ];


  return (
    <Card>

      <CardSubHeader title={<FormattedMessage id="PRODUCT.CATEGORIES" />} />

      <CardBody>

        <Row>
          <Col sm="12">
            <div className="font-weight-bold mb-2">
              <FormattedMessage id="PRODUCT.EBAY.CATEGORY_TITLE" /><span className="text-danger">*</span>
            </div>
            <Field component={SVReactSelectInput}
              {...{
                name: 'SVReactSelectInput',
                label: intl({ id: 'PRODUCT.EBAY.CATEGORY_TITLE' }),
                value: (isLoaded || method === "edit") && UIProps.categoryInputValue,
                onChange: value => categoryOnChangeHandler(value),
                options: categorySuggestions.map(category => ({
                  value: category.categoryId,
                  label: category.categoryPath,
                })),
                withFeedbackLabel: true,
                isLoading: categorySuggestions?.length > 0 ? false : true,
                required: true,
              }}
            />
          </Col>

          <Col sm="12" className="mt-5">
            <div className="font-weight-bold mb-2">
              <FormattedMessage id="PRODUCT_LISTINGS.EBAY.FEATURE" />
            </div>
            <BootstrapTable
              keyField="id"
              data={(isLoaded || method === "edit") ? UIProps.selectedCategoryAspects : []}
              columns={aspectColumns}
              wrapperClasses="table-responsive"
              headerClasses="bg-info-o-10 border-0"
              classes="table table-head-custom table-vertical-center overflow-hidden my-0"
              bootstrap4
              bordered={false}
              condensed={false}
            />
          </Col>

          {!isLoaded && method === 'new' && (
            <Col sm="12" className="py-20 text-center">
              <Spinner loading={!isLoaded && method === 'new'} color="primary" className="spinner-lg" />
            </Col>
          )}
        </Row>

      </CardBody>
    </Card>
  );
};
