/**
 * react-images-uploading
 * @see https://github.com/vutoan266/react-images-uploading/blob/master/README.md
 * @see https://www.npmjs.com/package/react-images-uploading
 */

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { DeleteConfirm } from '_metronic/_partials/dialogs';
import { SupportActions } from '../_redux/actions';
import ImageUploading from 'react-images-uploading';
import { UserInfoDropdown } from '_metronic/_partials';
import { BASE_URL } from 'constants/apiUrl';
import { Icon } from '_metronic/_icons';



const pictureSpecs = {
  width: 1200,
  height: 1200,
  size: 3145728, // 3MB
  format: ['jpeg', 'jpg', 'gif', 'png'],
  maxNumber: 4,
};



export function ImageUpload({ ticketImages, existingImages, sessionId, ticketId }) {

  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [index, setIndex] = useState();
  const [errors, setErrors] = useState();
  const [deleteImageId, setDeleteImageId] = useState(null);

  const uploadImage = async (imageList, addUpdateIndex) => {
    setErrors();
    // if (imageList.length === 0 && addUpdateIndex === undefined) {
    //   dispatch(SupportActions.imageDelete(user.id));
    // }
    const imageNames = imageList.map(image => image?.file?.name);
    const isDuplicate = imageNames.some((name, idx) => imageNames.indexOf(name) !== idx);
    if (isDuplicate) {
      setErrors({ duplicate: true });
      return;
    }
    dispatch(SupportActions.picturesHandler(imageList));
  };

  return (
    <>

      <h6 className='d-flex text-dark-75 mb-3 mt-6'>
        <FormattedMessage id="SUPPORT.NEW_TICKET.IMAGE_UPLOAD" />
        <UserInfoDropdown size="w-75"
          title={<FormattedMessage id="PRODUCT.PICTURE_STANDARD" />}
          description={<FormattedMessage id="SUPPORT.NEW_TICKET.PICTURE_FORMAT" values={{ size: pictureSpecs.size / 1024 / 1024, format: pictureSpecs.format.join(', ').toLocaleUpperCase() }} />}
        />
      </h6>

      <div className="separator separator-solid mb-6" />

      <ImageUploading
        multiple={true}
        value={ticketImages}
        onChange={uploadImage}
        dataURLKey="thumbnail"
        maxNumber={pictureSpecs.maxNumber}
        acceptType={pictureSpecs.format}
        maxFileSize={pictureSpecs.size}
        onError={(errors, files) => setErrors(errors)}
      >
        {({ imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
          <>
            <div className="d-flex flex-wrap">

            {existingImages?.length > 0 &&
                existingImages?.map((image, index) => (

                  <div key={index} className="overlay mr-5">

                    <div
                      className="overlay-wrapper img-thumbnail"
                      style={{ height: '9rem', width: '9rem', overflow: 'hidden' }}
                      title={image?.name}>

                      <img
                        src={`${BASE_URL}/${image.thumbnail}`}
                        width="100%" height="100%"
                        style={{ pointerEvents: 'none', objectFit: 'cover' }}
                        className='rounded'
                        alt={`support ${index}`}
                      />

                    </div>

                    <div type="button" className="overlay-layer rounded flex-center m-3">
                      <span className="btn btn-icon btn-sm btn-danger svg-icon" onClick={() => { setShow(true); setIndex(index); setDeleteImageId(image.id);}} id='btn_close'>
                        <Icon.Trash />
                      </span>
                    </div>

                  </div>
                ))
              }

              {imageList?.length > 0 &&
                imageList?.map((image, index) => (

                  <div key={index} className="overlay mr-5">

                    <div
                      className="overlay-wrapper img-thumbnail"
                      style={{ height: '9rem', width: '9rem', overflow: 'hidden' }}
                      title={image?.name}>

                      <img
                        src={image.file ? `${image.thumbnail}` : `${BASE_URL}/${image.thumbnail}`}
                        width="100%" height="100%"
                        style={{ pointerEvents: 'none', objectFit: 'cover' }}
                        className='rounded'
                        alt={`support ${index}`}
                      />

                    </div>

                    <div type="button" className="overlay-layer rounded flex-center m-3">
                      <span className="btn btn-icon btn-sm btn-danger svg-icon" onClick={() => { setShow(true); setIndex(index); setDeleteImageId(null);}} id='btn_close'>
                        <Icon.Trash />
                      </span>
                    </div>

                  </div>
                ))
              }

              {existingImages?.length + imageList.length < 4 &&
                <div
                  style={{
                    borderColor: errors ? `#F9D7DA` : `#cbd5e1`,
                    background: isDragging ? '#fff' : errors ? `#FFFAFB` : '#f8fafc',
                    height: '9rem',
                    width: '9rem',
                  }}
                  className='images-container'
                  onClick={onImageUpload}
                  id="images_upload"
                  {...dragProps}
                >
                  <div className="d-flex align-items-center flex-column h-100" style={{ pointerEvents: 'none' }}>
                    <div className="svg-icon svg-icon-3x opacity-25 my-auto">
                      <Icon.ImagePlus />
                    </div>
                  </div>

                  <div className="font-weight-bold text-danger text-center mt-3" style={{ height: '20px' }}>
                    {errors &&
                      <>
                        <Icon.ExclamationTriangle className="mr-1" />
                        {errors?.maxNumber && <FormattedMessage id="GENERAL.PICTURE.MAX_NUMBER" values={{ pictureMaxNumber: pictureSpecs.maxNumber }} />}
                        {errors?.acceptType && <FormattedMessage id="GENERAL.PICTURE.FORMAT" values={{ pictureFormat: pictureSpecs.format.join(', ').toLocaleUpperCase() }} />}
                        {errors?.maxFileSize && <FormattedMessage id="GENERAL.PICTURE.SIZE" values={{ pictureSize: pictureSpecs.size / 1024 / 1024 }} />}
                        {errors?.duplicate && <FormattedMessage id='GENERAL.PICTURE.DUPLICATE' />}
                      </>
                    }
                  </div>
                </div>}

              <DeleteConfirm show={show} onHide={() => { setIndex(null); setShow(false) }} toDelete={() => {deleteImageId && dispatch(SupportActions.deleteImage(deleteImageId, sessionId, ticketId)); onImageRemove(index); setShow(false); }} />
            </div>

          </>
        )}
      </ImageUploading>

    </>
  );
}
