import ActionTypes from './actionTypes';



const INITIAL_STATE = {
  loading: false,
  entities: [],
  count: 0,
  totalCount: 0,
};


export function reportReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {

    // Get Order List
    case ActionTypes.GET_ORDER_LIST:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.GET_ORDER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        entities: payload.data
      };

    case ActionTypes.GET_ORDER_LIST_FAIL:
      return {
        ...state,
        loading: false,
      };

    // Get Email Logs
    case ActionTypes.GET_EMAIL_LOGS:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.GET_EMAIL_LOGS_SUCCESS:      
      return {
        ...state,
        loading: false,
        entities: payload.data,
        count: payload.count.count,
        totalCount: payload.count.total,
      };

    case ActionTypes.GET_EMAIL_LOGS_FAIL:
      return {
        ...state,
        loading: false,
      };
      

    default:
      return state;
  }
}
