import React from "react";
import { Field } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Col, Row } from "react-bootstrap";
import { Card, CardBody, CardHeaderToolbar, CardSubHeader, SVFormControl, SVReactSelect } from "_metronic/_partials/controls";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { ListingActions } from "../../../_redux/actions";
import { Icon } from '_metronic/_icons';



export default function EbayPolicies({ market_id }) {

  const { formatMessage: intl } = useIntl();
  const dispatch = useDispatch();

  const { shop } = useSelector(state => ({
    shop: state.connections.entities
  }), shallowEqual);

  const { policies } = shop?.find(item => item.id === market_id) ?? {};

  const INPUTS = [
    {
      name: 'listingPolicies.fulfillmentPolicyId',
      label: 'PRODUCT.EBAY.POLICY_FULFILLMENT',
      component: SVReactSelect,
      options: policies?.fulfillmentPolicyId && policies.fulfillmentPolicyId.map(p => ({ value: p.id, label: p.name })),
      required: true,
    },
    {
      name: 'listingPolicies.paymentPolicyId',
      label: 'PRODUCT.EBAY.POLICY_PAYMENT',
      component: SVReactSelect,
      options: policies?.paymentPolicyId && policies.paymentPolicyId.map(p => ({ value: p.id, label: p.name })),
      required: true,
    },
    {
      name: 'listingPolicies.returnPolicyId',
      label: 'PRODUCT.EBAY.POLICY_RETURN',
      component: SVReactSelect,
      options: policies?.returnPolicyId && policies.returnPolicyId.map(p => ({ value: p.id, label: p.name })),
      required: true,
    },
    {
      name: 'merchantLocationKey',
      label: 'PRODUCT.EBAY.LOCATION',
      component: SVReactSelect,
      options:
        policies?.locations?.length ?
          policies.locations.map(item => {
            const { merchantLocationKey, location: { address }, } = item;
            return { value: merchantLocationKey, label: merchantLocationKey + ' - ' + Object.values(address).join(', ') };
          }) : [],
      required: true,
    },
  ];

  const getPolicies = () => {
    dispatch(ListingActions.updateEbayPolicies(market_id));
  };


  return (
    <Card className="gutter-b">

      <CardSubHeader title={<FormattedMessage id="PRODUCT_LISTINGS.EBAY.POLICIES" />}>
        <CardHeaderToolbar>
          <Button size="sm" variant='outline-primary' className="svg-icon svg-icon-sm" onClick={getPolicies} id='btn_policies'>
            <Icon.ArrowRepeat />
            <span className="d-none d-sm-inline font-weight-bold ml-2">
              <FormattedMessage id="PRODUCT_LISTINGS.EBAY.POLICIES" />
            </span>
          </Button>
        </CardHeaderToolbar>
      </CardSubHeader>

      <CardBody>
        <Row>
          {INPUTS.map((item, i) => (
            <Col sm="12" lg="6" key={i}>
              <Field
                component={item.component || SVFormControl}
                {...{
                  type: item.type,
                  name: item.name,
                  label: intl({ id: item.label }),
                  onFocus: item.onFocus,
                  withFeedbackLabel: true,
                  options: item.options,
                  feedbackLabel: true,
                  required: item.required,
                }}
              />
            </Col>
          ))}
        </Row>
      </CardBody>

    </Card>
  );
};





