import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { SupportActions } from 'app/modules/support/_redux/actions';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';



export function TicketDropdown() {

  const dispatch = useDispatch();

  const { unreadTickets, role } = useSelector(state => ({
    unreadTickets: state.supports.unreadTickets,
    role: state.auth.user.role,
  }));

  useEffect(() => {
    dispatch(SupportActions.countTickets());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <OverlayTrigger placement="left" overlay={<Tooltip id="quick-actions-tooltip"><FormattedMessage id="SUPPORT.UNREAD_TICKET" values={{ count: unreadTickets }} /></Tooltip>}>
      <div className="topbar-item">
        <Link to={`${ModuleRoutes.SUPPORT_PAGE_ALL}${unreadTickets > 0 ? `&filter.${role === "644c41e257d397eb064fe0f9" ? "seen" : "read"}=unread` : ""}`} className="btn btn-icon btn-clean btn-lg text-hover-primary" id="btn_support">
          <div className="symbol">
            <span className="svg-icon svg-icon-sm">
              <Icon.Support />
            </span>
            {unreadTickets > 0 && <i className="symbol-badge bg-danger"></i>}
          </div>
        </Link>
      </div>
    </OverlayTrigger>
  );
}
