import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Portal } from 'react-portal';
import { useFormikContext } from 'formik';
import { DocumentActions } from '../_redux/actions';
import { Icon } from '_metronic/_icons';



export function TaxCategoryDialog({ show, onHide, taxCategory }) {

  const dispatch = useDispatch();
  const { setFieldValue } = useFormikContext();

  const taxInfo = (taxCategory) => {
    const { existing, newCategory, country, isVatId } = taxCategory;
    return {
      category: existing?.label,
      newCategory: newCategory?.label,
      country,
      tax: (newCategory?.value === 'domestic' || (newCategory?.value === 'eu_region' && isVatId)) ? "Standard" : "0",
      b: chunk => <span className='text-danger'>{chunk}</span>
    }
  }

  const changeTaxCategory = (value) => {
    dispatch(DocumentActions.updatePositionTaxesToFrei(value, taxCategory.isVatId));
    setFieldValue('tax.category', value);
    onHide()
  };

  return (
    <Portal node={document && document.getElementById('modal-portal')}>
      <Modal show={show} onHide={onHide} size="md" backdrop="static" aria-labelledby="example-modal-sizes-title-md" centered>

        <div className="d-flex justify-content-center icon-4x pt-10">
          <Icon.InfoCircle className="text-info" />
        </div>

        <Modal.Body className="text-center line-height-lg h6 py-10 mb-0">
          <FormattedMessage id='DOCUMENT.EDIT.PAYMENT.TAX_CATEGORY' values={taxInfo(taxCategory)} />
        </Modal.Body>

        <Modal.Footer className="flex-nowrap overflow-hidden p-0">

          <Button variant="ios" className="border-right" onClick={onHide} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button variant="ios" className="text-danger" onClick={() => changeTaxCategory(taxCategory.newCategory.value)} id="btn_delete_modal">
            <FormattedMessage id="GENERAL.CONTINUE" />
          </Button>

        </Modal.Footer>

      </Modal>
    </Portal>
  );
}