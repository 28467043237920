import ActionTypes from './actionTypes';

const INITIAL_COMPANY = {
  company_name: "",
  owner: "",
  email: "",
  web: "",
  phone: "",
  mobile: "",
  fax: "",
  logo: {
    id: '',
    url: '',
    name: '',
    thumbnail: '',
  },
  vat_id: "",
  tax_number: "",
  bank: {
    name: "",
    iban: "",
    bic: ""
  },
  address: {
    address_line1: "",
    post_code: "",
    city: "",
    country_code: "DE",
  },
};

const INITIAL_STATE = {
  loading: false,
  request: {
    loading: false,
    result: '',
  },
  companyBaseData: INITIAL_COMPANY,
  logo: {
    url: '',
    file: null
  },
  isLogoChanged: false,
  error: null,
};



export const companyReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {


    // Complete user register
    case ActionTypes.CREATE_COMPANY:
      return {
        ...state,
        request: {
          loading: true,
          where: 'complete-register',
          error: null,
        },
      };

    case ActionTypes.CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        companyBaseData: {
          ...state.companyBaseData,
          ...payload.updatedUser
        },
        request: {
          loading: false,
          status: 'fulfilled',
          where: 'complete-register',
          error: null,
        },
      };

    case ActionTypes.CREATE_COMPANY_FAIL:
      return {
        ...state,
        request: {
          loading: false,
          status: 'failed',
          where: 'complete-register',
          error: payload?.error || 'Something went wrong',
        },
      };

    case ActionTypes.COMPLETE_USER_REGISTER:
      return {
        ...state,
        request: {
          loading: true,
          where: 'complete-register',
          error: null,
        },
      };



    // Update Company Base Data
    case ActionTypes.UPDATE_COMPANY_BASE_DATA:
      return {
        ...state,
        loading: true,
        request: {
          loading: true,
          result: 'request_started',
        },
      };

    case ActionTypes.UPDATE_COMPANY_BASE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        request: {
          loading: false,
          result: 'request_fulfilled',
        },
        error: null,
      };

    case ActionTypes.UPDATE_COMPANY_BASE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        request: {
          loading: false,
          result: 'request_failed',
        },
        error: JSON.stringify(payload.error),
      };



    // Get Company Base Data
    case ActionTypes.GET_COMPANY_BASE_DATA:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.GET_COMPANY_BASE_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        companyBaseData: {
          ...state.companyBaseData,
          ...payload.company
        },
        logo: {
          url: '',
          file: null
        },
      };
    }

    case ActionTypes.GET_COMPANY_BASE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        companyBaseData: INITIAL_COMPANY,
        error: JSON.stringify(payload.error),
      };



    // Logo handler
    case ActionTypes.LOGO_ONCHANGE_HANDLER:
      return {
        ...state,
        logo: {
          ...payload.logo
        },
        isLogoChanged: true,
      };



    //  Delete Image
    case ActionTypes.DELETE_FILE:
      return {
        ...state,
        loading: true,
        imageDeleted: false,
      };

    case ActionTypes.DELETE_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        companyBaseData: {
          ...state.companyBaseData,
          logo: INITIAL_COMPANY.logo,
        },
        logo: INITIAL_COMPANY.logo,
      };

    case ActionTypes.DELETE_FILE_FAIL:
      return {
        ...state,
        loading: false,
        imageDeleted: false,
      };



    // Clean handler
    case ActionTypes.CLEAN_ERROR_HANDLER:
      return {
        ...state,
        error: null,
        loading: false,
        request: {
          loading: false,
          result: '',
        },
      };

    case ActionTypes.CLEAR_LOADING_EFFECTS:
      return {
        ...state,
        loading: false,
        request: {
          loading: false,
          result: '',
        },
      };



    default:
      return state;
  }
};
