import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { DropdownTopbarItemToggler } from '_metronic/_partials/dropdowns';
import { NotificationActions } from './_redux/actions';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';



export function Notifications({ connections }) {

  const dispatch = useDispatch();

  const { fetch_order_count, total_order_count, role } = useSelector(state => ({
    fetch_order_count: state.notification.fetch_order_count,
    total_order_count: state.notification.total_order_count,
    role: state.auth.user.role,
  }));

  useEffect(() => {
    if (role !== "644c41e257d397eb064fe0f9") {
      // İlk açılışta çalış
      dispatch(NotificationActions.getNotification());

      let intervalId = setInterval(() => {
        dispatch(NotificationActions.getNotification());
      }, 20 * 60 * 1000); // 20 dakikada bir yenile

      return () => {
        clearInterval(intervalId);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <Dropdown drop="down" alignRight>

      <Dropdown.Toggle as={DropdownTopbarItemToggler} id="kt_quick_notifications_toggle">

        <OverlayTrigger placement="left" overlay={<Tooltip id="quick-actions-tooltip"><FormattedMessage id='HEADER.NOTIFICATIONS' /></Tooltip>}>
          <div className="topbar-item">
            <div className="btn btn-icon btn-clean btn-lg" id="btn_notification">
              <div className="symbol">
                <span className="svg-icon">
                  <Icon.Bell />
                </span>
                {(total_order_count?.created > 0) && <i className="symbol-badge bg-danger"></i>}
              </div>
            </div>
          </div>
        </OverlayTrigger>

      </Dropdown.Toggle>


      <Dropdown.Menu className="dropdown-menu-right dropdown-menu-anim dropdown-menu-lg p-0" id='dropdown_notification'>

        <div
          className="d-flex align-items-center justify-content-between flex-wrap px-8 py-8 bgi-size-cover bgi-no-repeat rounded-top mb-4"
          style={{ backgroundImage: "url(/media/bg/bg-1.jpg)" }}
        >

          <span className="svg-icon svg-icon-3x text-light mr-4">
            <Icon.Bell />
          </span>

          <div className="text-white m-0 flex-grow-1 mr-3 font-size-h3">
            <FormattedMessage id='HEADER.NOTIFICATIONS' />
          </div>

        </div>

        {/* TODO: Tamamlanmadı */}
        {connections?.length > 0
          ? <div className="px-8 mb-4">

            <div className="d-flex align-items-center justify-content-between border-bottom py-2">
              <span className='font-weight-bold'>
                <Icon.Shop className="mr-2" />
                Created order
              </span>
              <span className="label label-lg label-light-success label-inline">
                {total_order_count?.created}
              </span>
            </div>

            <div className="d-flex align-items-center justify-content-between border-bottom py-2">
              <span className='font-weight-bold'>
                <Icon.Shop className="mr-2" />
                Updated order
              </span>
              <span className="label label-lg label-light-success label-inline">
                {total_order_count?.updated}
              </span>
            </div>

            {fetch_order_count?.map((item, index) => (item.status !== "success" &&
              <div className="d-flex align-items-center justify-content-between border-bottom py-2" key={index}>
                <span className='font-weight-bold text-capitalize'>
                  <Icon.Shop className="mr-2" />
                  {item.name}
                </span>
                <span className="label label-light-danger label-inline d-inline-block text-truncate" style={{ maxWidth: "200px" }} title={item.error}>
                  {item.error}
                </span>
              </div>
            ))}

          </div>
          : <div className='py-20 text-center svg-icon svg-icon-6x' style={{ opacity: "0.05" }}>
            <Icon.BellOff />
          </div>
        }

      </Dropdown.Menu>
    </Dropdown>
  )
}
