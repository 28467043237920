/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import ApexCharts from "apexcharts";
import { theme } from "_metronic/_helpers";
import { FormattedMessage, useIntl } from "react-intl";
import { BlankMessage } from "_metronic/_partials/components";
import { Card, CardBody } from "_metronic/_partials/controls";
import ShowHideButton from "../components/ShowHideButton";
import { UserInfoDropdown } from "_metronic/_partials";
import { useToggleDisplayCard } from "app/hooks";
import { Icon } from "_metronic/_icons";
import moment from "moment";



export function LineChartDailySales({ data, total_invoice_count }) {

  const { formatMessage: intl } = useIntl();
  const [toggleProperty, dispatchProperty] = useToggleDisplayCard("sales_volume")


  useEffect(() => {
    if (toggleProperty) {

      const element = document.getElementById("kt_stats_widget_7_chart");
      if (!element) {
        return;
      }

      if (data?.yearly_order.length === 0) {
        return;
      }

      const options = getChartOption(data?.yearly_order, intl);
      const chart = new ApexCharts(element, options);
      chart.render();

      return function cleanUp() {
        chart.destroy();
      };
    }
  }, [toggleProperty, data.yearly_order, intl]);


  return (
    <Card className='card-stretch gutter-b'>

      <div className="d-flex justify-content-between p-8">
        <div className="d-flex align-items-start flex-column">
          <h4 className="d-flex align-items-center font-weight-bolder text-dark">
            <FormattedMessage id="DASHBOARD.SALES_VOLUME" />
            <UserInfoDropdown description={<FormattedMessage id='USER_GUIDE.DASHBOARD.SALES_VOLUME' />} />
          </h4>
          <span className="text-muted font-weight-bold font-size-sm">
            {intl({ id: "DOCUMENT.INVOICE_PLURAL" })} : {data?.count}
          </span>
        </div>
        <div className="align-items-start">
          <ShowHideButton toggleProperty={toggleProperty} dispatchProperty={dispatchProperty} id={"cardSalesVolumeShow"} />
        </div>
      </div>

      <CardBody className="pt-0">
        {!(!toggleProperty || !data?.yearly_order.length > 0)
          ? <div className="card-rounded-bottom" id="kt_stats_widget_7_chart" />
          : <BlankMessage
            icon={<Icon.GraphUpNew />}
            message={toggleProperty && <FormattedMessage id={(total_invoice_count === 0) ? 'DASHBOARD.BLANK_MESSAGE' : 'DASHBOARD.BLANK_MESSAGE.NO_FILTER'} />}
          />
        }
      </CardBody>

    </Card>
  );
}



function getChartOption(data, intl) {
  const options = {
    series: [
      {
        name: intl({ id: "DASHBOARD.ORDER_VALUE" }),
        data: data ? data.map(daily => daily?.total?.toFixed(2)) : [],
      },
      {
        name: intl({ id: "DASHBOARD.PREVIOUS_PERIOD" }),
        data: data ? data.map(daily => daily?.total_alt?.toFixed(2)) : [],
      },
    ],
    chart: {
      type: "area",
      height: 200,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    stroke: {
      curve: 'smooth'
    },
    colors: [
      theme.palette.warning['500'],
      theme.palette.info['500']
    ],
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'right',
      offsetX: -20,
    },
    xaxis: {
      categories: data ? data.map(daily => moment(daily.date).format('LL') + " vs " + moment(daily.date_alt).format('LL')) : [],
      axisBorder: {
        show: true,
      },
    },
    fill: {
      type: 'gradient',

    },
  };
  return options;
}