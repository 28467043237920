import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button, Modal } from 'react-bootstrap';
import { Formik, Form } from "formik";
import { ConnectionsActions } from "../../_redux/actions";



export default function EbayForm({ children, connectionId, initialValue, loading, onHide }) {

  const dispatch = useDispatch();

  const { ebayForEdit, success } = useSelector(state => ({
    ebayForEdit: state.connections.ebayForEdit,
    success: state.connections.success
  }), shallowEqual);

  const handleSubmit = () => {
    dispatch(ConnectionsActions.getEbayAuthToken())
  };

  useEffect(() => {
    return () => {
      dispatch(ConnectionsActions.clearLoadingEffects())
    }
  }, [dispatch])

  return (
    <Formik enableReinitialize={true} initialValues={ebayForEdit} onSubmit={handleSubmit}>
      <Form autoComplete='off'>

        <Modal.Body style={{ minHeight: "80px" }} />

        <Modal.Footer>

          {children}

          <Button variant="light" onClick={onHide} disabled={loading} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button variant='primary' type="submit" className='ml-2' disabled={loading || success} id='btn_get_token'>
            <FormattedMessage id="GENERAL.GET_TOKEN" />
          </Button>

        </Modal.Footer>


      </Form>
    </Formik>
  )
}
