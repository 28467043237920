
import ActionTypes from './actionTypes';



const INITIAL_STATE = {
  loading: false,
  users: [],
  reports: [],
  contact: [],
  temp_users: [],
  company: {},
  order_report: [],
  usage_report: {},
  user: {
    id: '',
    status: '',
    name: '',
    surname: '',
    email: '',
    language: '',
    license: {},
    fail_log: {},
    success_login: {},
    note: '',
    newsletter: false,
  },
  count: 0,
  totalCount: 0,
  queryParams: {},
  actionId: null, // enum of actions: 'get-users', 'update-user-servers'
  actionResults: [],
  error: null,
  deleteUserId: null,
};


export const adminReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {


    // Get all users
    case ActionTypes.GET_USERS: {
      return {
        ...state,
        loading: true,
        actionId: 'get-users',
      };
    }

    case ActionTypes.GET_USERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        users: payload.users,
        totalCount: payload.count.total,
        count: payload.count.count,
      };
    }

    case ActionTypes.GET_USERS_FAIL: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }


    // Get user
    case ActionTypes.GET_USER: {
      return {
        ...state,
        loading: true,
      };
    }

    case ActionTypes.GET_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        user: payload.user,
      };
    }

    case ActionTypes.GET_USER_FAIL: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }


    // Update User
    case ActionTypes.UPDATE_USER:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.UPDATE_USER_SUCCESS:
      const { userId, updatedUser } = payload;
      const { license_type, ...rest } = updatedUser
      return {
        ...state,
        loading: false,
        user: {
          ...state.user,
          ...rest,
          license: license_type 
            ? { ...state.user.license, type: license_type } 
            : state.user.license,
        },
        users: state.users.map((user) => user.id === userId
          ? { ...user, note: updatedUser?.note }
          : user
        ),
      };

    case ActionTypes.UPDATE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };


    // Delete user
    case ActionTypes.DELETE_USER: {
      return {
        ...state,
        loading: true,
      };
    }

    case ActionTypes.DELETE_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        users: state.users.filter(user => user.id !== payload.user.id),
      };
    }

    case ActionTypes.DELETE_USER_FAIL: {
      return {
        ...state,
        loading: false,
        error: payload.error.message,
      };
    }



    // Get all temp users
    case ActionTypes.GET_TEMP_USERS: {
      return {
        ...state,
        loading: true,
        actionId: 'get-temp-users',
      };
    }

    case ActionTypes.GET_TEMP_USERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        temp_users: payload.tempUsers,
        totalCount: payload.count.total,
        count: payload.count.count,
      };
    }

    case ActionTypes.GET_TEMP_USERS_FAIL: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }


    // Update Temp user
    case ActionTypes.UPDATE_TEMP_USER:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.UPDATE_TEMP_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case ActionTypes.UPDATE_TEMP_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };


    // Delete temp users
    case ActionTypes.DELETE_TEMP_USERS:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.DELETE_TEMP_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        temp_users: state.temp_users.filter((user) => user.id !== payload.id),
        count: state.count - 1,
        totalCount: state.totalCount - 1,
      };

    case ActionTypes.DELETE_TEMP_USERS_FAIL:
      return {
        ...state,
        loading: false,
        error: "GENERAL.ERROR_MESSAGE_GENERAL",
      };

    // Get all users reports
    case ActionTypes.GET_REPORTS_USERS: {
      return {
        ...state,
        loading: true,
      };
    }

    case ActionTypes.GET_REPORTS_USERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        reports: payload.users,
        count: payload.count.count,
        totalCount: payload.count.total,
      };
    }

    case ActionTypes.GET_REPORTS_USERS_FAIL: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }

    // Get all users contact
    case ActionTypes.GET_USERS_CONTACT: {
      return {
        ...state,
        loading: true,
      };
    }

    case ActionTypes.GET_USERS_CONTACT_SUCCESS: {
      return {
        ...state,
        loading: false,
        contact: payload.users,
        totalCount: payload.count.total,
        count: payload.count.count,
      };
    }

    case ActionTypes.GET_USERS_CONTACT_FAIL: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }

    // Get user company
    case ActionTypes.GET_USER_COMPANY:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.GET_USER_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        company: payload.company,
        order_report: payload.order_report,
        usage_report: payload.usage_report,
      };

    case ActionTypes.GET_USER_COMPANY_FAIL:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    // Clean up the user module
    case ActionTypes.CLEAN_UP_USER_MODULE: {
      return {
        ...INITIAL_STATE,
      };
    }

    case ActionTypes.CLEAN_UP_USER_MODULE_PARAMS: {
      return {
        ...state,
        ...payload.params,
      };
    }


    default:
      return state;
  }
};
