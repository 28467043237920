import { cloneDeep, findIndex } from 'lodash';
import * as uiHelpers from '../_context/DocumentsUIHelpers';
import { utils } from '_metronic/_helpers';
import ActionTypes from './actionTypes';



const INITIAL_STATE = {
  request: uiHelpers.initialStatesForReducer.request,
  actionController: uiHelpers.initialStatesForReducer.actionController,
  reminderLoading: false,
  error: '',
  requestedDocument: {},
  actionLoading: false,
  isLoading: false,
  //DHLActionLoading: false,
  printLoading: false, // loading effect for print or label actions
  emailSuccess: false,
  fetchLoading: {
    loading: false,
    where: '',
  },
  loading: false,
  positionActionLoading: false,
  getDocumentById: {
    ...uiHelpers.initialDocument,
    /*  positions: [{ id: utils.uuidv4(), ...uiHelpers.initialPosition }],
     positionTotals: { id: utils.uuidv4(), ...uiHelpers.initialPositionTotals }, */
  },
  documentForEdit: uiHelpers.initialDocument,
  positions: [],
  // positions: [{ id: utils.uuidv4(), ...uiHelpers.initialPosition }],
  positionTotals: { id: utils.uuidv4(), ...uiHelpers.initialPositionTotals },
  documents: [],
  labels: [],
  //getDocumentByIdForPrint: [],
  customer: {},
  customers: [],
  countDocuments: 0,
  totalDocuments: 0,
  //emailTemplate: [],
  //dhlLabelUrl: {},
  documentPDF: {},
  //getDocumentForControl: {},
  userTokens: [],
  tax: 19,
  getDocumentCustomerDetails: {
    customer_details: {
      id: '',
      type: '',
      name: '',
      email: '',
      phone: '',
      company: '',
      invoice_address: {},
      delivery_address: {},
    },
  },
  fetchCustomerLoading: false,
  userSettings: {
    default_subtitle: {
      header: '',
      footer: '',
    },
    default_payment: {
      method: '',
    },
    default_shipping: [],
    default_number_range: {
      prefix: '',
      date: '',
      min_length: 0,
      next_value: '',
    },
    default_product: null,
    stock_control: null
  },
  companyBaseData: {},
  convertDetails: [],
  shippingGrouping: [],
  //Dhl service options
  serviceOptions: {
    goGreen: false,
  },
  success: false,
};



export function documentsReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {


    //* Setup Requested Document
    case ActionTypes.SETUP_REQUESTED_DOCUMENT:
      return {
        ...state,
        requestedDocument: payload.requestedDocument,
      };


    //* print document
    case ActionTypes.PRINT_DOCUMENT:
      return {
        ...state,
        printLoading: true,
        emailSuccess: false,
      };

    case ActionTypes.PRINT_DOCUMENT_SUCCESS:
      return {
        ...state,
        documentPDF: payload.response,
        printLoading: false,
        emailSuccess: true,
      };

    case ActionTypes.PRINT_DOCUMENT_FAIL:
      return {
        ...state,
        printLoading: false,
        emailSuccess: false,
        error: payload.err,
      };


    // -------------------------------- CREATE LABELS START --------------------------------
    case ActionTypes.CREATE_LABELS:
      return {
        ...state,
        printLoading: true,
      };

    case ActionTypes.CREATE_LABELS_SUCCESS:
      const labels = payload?.labels || [];
      const updateShipping = labels.map(({ documentId, totalPrice, label, ...rest }) => ({ ...rest }));
      //error varsa kaldır
      const currentLabels = state.documentForEdit.shipping.filter(item => !item.error);
      // length 1 den fazla ise documentForEdit shipping update edilemez
      const shipping = labels.length === 1 ? [...currentLabels, ...updateShipping] : currentLabels;
      // documents shipping update
      const updateDocuments = state.documents.map(item => {
        const matchingLabel = labels.find(i => i.documentId === item.id);
        if (matchingLabel) {
          const { documentId, label, price, weight, totalPrice, ...rest } = matchingLabel;
          item.pricing_summary.shipping_price = totalPrice;
          item.payment_status = uiHelpers.PaymentStatus(item.position, item);
          item.shipping = [...item.shipping.filter(label => !label.error), rest];
        }
        return item;
      });

      // pricing_summary ve payment_status >> state.documentForEdit.price_summary.shipping_price için gerekli
      if (labels.length === 1) {
        state.documentForEdit.pricing_summary.shipping_price = labels[0].totalPrice;
        state.getDocumentById.pricing_summary.shipping_price = labels[0].totalPrice; // getDocumentById kaldırılacak
      }

      return {
        ...state,
        labels: labels,
        printLoading: false,
        documents: updateDocuments,
        documentForEdit: {
          ...state.documentForEdit,
          payment_status: uiHelpers.PaymentStatus(state.positions, state.documentForEdit),
          shipping,
        },
        getDocumentById: {
          ...state.getDocumentById,
          payment_status: uiHelpers.PaymentStatus(state.positions, state.documentForEdit),
          shipping,
        },
        ...(state.documentForEdit.marketplace === "" && labels.length === 1 && {
          positionTotals: {
            ...state.positionTotals,
            shipping_price: labels[0]?.totalPrice ?? 0
          }
        })
      };

    case ActionTypes.CREATE_LABELS_FAIL:
      return {
        ...state,
        printLoading: false,
        error: 'GENERAL.ERROR_MESSAGE',
        labels: [],
      };
    // -------------------------------- CREATE LABELS END ----------------------------------


    // -------------------------------- GET LABELS START -----------------------------------
    case ActionTypes.GET_LABEL:
      return {
        ...state,
        printLoading: true,
      };

    case ActionTypes.GET_LABEL_SUCCESS:
      return {
        ...state,
        labels: payload?.labels,
        printLoading: false,
      };

    case ActionTypes.GET_LABEL_FAIL:
      return {
        ...state,
        printLoading: false,
        error: 'GENERAL.ERROR_MESSAGE',
        labels: [],
      };
    // -------------------------------- GET LABELS END -------------------------------------


    // -------------------------------- DELETE LABELS START --------------------------------
    case ActionTypes.DELETE_LABEL:
      return {
        ...state,
        loading: true
      };

    case ActionTypes.DELETE_LABEL_SUCCESS:

      const deleteShipping = state.documentForEdit.shipping.filter(label => label.id !== payload.labelId) || [];

      const updatedDocuments = state.documents.map(item => {
        if (item.id === payload.documentId) {
          item.pricing_summary.shipping_price = payload?.totalPrice;
          item.payment_status = uiHelpers.PaymentStatus(item.position, item);
          item.shipping = item.shipping.filter(label => label.id !== payload.labelId);
        }
        return item;
      });

      // pricing_summary ve payment_status >> state.documentForEdit.price_summary.shipping_price için gerekli
      state.documentForEdit.pricing_summary.shipping_price = payload?.totalPrice ?? 0;
      state.getDocumentById.pricing_summary.shipping_price = payload?.totalPrice ?? 0; // getDocumentById kaldırılacak

      return {
        ...state,
        loading: false,
        labels: [],
        documents: updatedDocuments,
        documentForEdit: {
          ...state.documentForEdit,
          payment_status: uiHelpers.PaymentStatus(state.positions, state.documentForEdit),
          shipping: deleteShipping,
        },
        getDocumentById: {
          ...state.getDocumentById,
          payment_status: uiHelpers.PaymentStatus(state.positions, state.getDocumentById),
          shipping: deleteShipping,
        },
        ...(state.documentForEdit.marketplace === "" && {
          positionTotals: {
            ...state.positionTotals,
            shipping_price: payload?.totalPrice
          }
        })
      };

    case ActionTypes.DELETE_LABEL_FAIL:
      return {
        ...state,
        loading: false
      };

    //  -------------------------------- DELETE LABELS END ----------------------------------





    //* create document
    case ActionTypes.CREATE_DOCUMENT:
      return {
        ...state,
        success: false,
        request: {
          loading: true,
          name: 'create_document',
          specify: null,
          error: null,
          status: 'start',
        },
      };

    case ActionTypes.CREATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        success: true,
        request: {
          loading: false,
          name: 'create_document',
          specify: null,
          error: null,
          status: 'fulfilled',
          documentId: payload.documentId,
        },
        countDocuments: state.countDocuments + 1,
        totalDocuments: state.totalDocuments + 1,
      };

    case ActionTypes.CREATE_DOCUMENT_FAIL:
      return {
        ...state,
        success: false,
        request: {
          loading: false,
          name: 'create_document',
          specify: null,
          error: 'GENERAL.ERROR_MESSAGE',
          status: 'failed',
          message: payload.error.message
        },
        error: 'GENERAL.ERROR_MESSAGE',
      };



    //* update document
    case ActionTypes.UPDATE_DOCUMENT:
      return {
        ...state,
        success: false,
        request: {
          loading: true,
          name: 'update_document',
          specify: null,
          error: null,
          status: 'start',
        },
      };

    case ActionTypes.UPDATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        success: true,
        request: {
          loading: false,
          name: 'update_document',
          specify: null,
          error: null,
          status: 'fulfilled',
        },
      };

    case ActionTypes.UPDATE_DOCUMENT_FAIL:
      return {
        ...state,
        success: false,
        request: {
          loading: false,
          name: 'update_document',
          specify: null,
          error: '--ERROR--',
          status: 'failed',
        },
        error: 'GENERAL.ERROR_MESSAGE',
      };



    //* delete document
    case ActionTypes.DELETE_DOCUMENT:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        documents: state.documents.filter(document => !payload.ids.includes(document.id)),
        countDocuments: state.countDocuments - payload.ids.length,
        totalDocuments: state.totalDocuments - payload.ids.length,
      };

    case ActionTypes.DELETE_DOCUMENT_MODULE_DOCUMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: "GENERAL.ERROR_MESSAGE",
      };



    //* UPDATE_DOCUMENT_BY_FIELD
    case ActionTypes.UPDATE_DOCUMENT_BY_FIELD:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.UPDATE_DOCUMENT_BY_FIELD_SUCCESS:
      const result = payload?.updatedDocument
      const copyDocuments = [...state.documents];
      const findUpdatedDocumentIndex = findIndex(copyDocuments, {
        id: result?.id,
      });
      if (findUpdatedDocumentIndex !== -1) {
        copyDocuments[findUpdatedDocumentIndex] = result;
      };

      return {
        ...state,
        loading: false,
        documents: copyDocuments,
        documentForEdit: result,
        positionTotals: {
          ...state.positionTotals,
          shipping_price: result?.pricing_summary?.shipping_price || 0,
        }
      };


    case ActionTypes.UPDATE_DOCUMENT_BY_FIELD_FAIL:
      return {
        ...state,
        loading: false,
        error: 'GENERAL.ERROR_MESSAGE',
      };



    case ActionTypes.GET_DOCUMENTS:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.GET_DOCUMENTS_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        isLoading: false,
        loading: false,
        //DHLActionLoading: false,
        documents: payload.documents,
        countDocuments: payload.count.count,
        totalDocuments: payload.count.total,
      };

    case ActionTypes.GET_DOCUMENTS_FAIL:
      return {
        ...state,
        actionLoading: false,
        //DHLActionLoading: false,
        loading: false,
        isLoading: false,
        error: 'GENERAL.ERROR_MESSAGE',
      };



    //* get document by id for just control data
    case ActionTypes.GET_DOCUMENT_CUSTOMER_DETAILS:
      return {
        ...state,
        fetchCustomerLoading: true,
      };

    case ActionTypes.GET_DOCUMENT_CUSTOMER_DETAILS_SUCCESS:
      return {
        ...state,
        fetchCustomerLoading: false,
        getDocumentCustomerDetails: payload.document,
      };

    case ActionTypes.GET_DOCUMENT_CUSTOMER_DETAILS_FAIL:
      return {
        ...state,
        fetchCustomerLoading: false,
        error: 'GENERAL.ERROR_MESSAGE',
      };

    //* get document by id
    case ActionTypes.GET_DOCUMENT_BY_ID:
      return {
        ...state,
        actionLoading: true,
      };

    case ActionTypes.GET_DOCUMENT_BY_ID_SUCCESS:

      let documentForEdit = payload.document;

      if (payload.isCopy) {
        documentForEdit = {
          ...uiHelpers.initialDocument,
          customer_details: documentForEdit.customer_details,
          position: documentForEdit.position,
          pricing_summary: {
            ...documentForEdit.pricing_summary,
            shipping_price: 0,
          },
        }
      } else if (payload.isConvert) {
        documentForEdit = {
          ...documentForEdit,
          shipping: documentForEdit.shipping.filter(label => !label.error),
        }
      }

      return {
        ...state,
        error: '',
        actionLoading: false,

        getDocumentById: documentForEdit,
        documentForEdit: documentForEdit,

        positions: documentForEdit?.position.map(position => ({
          ...position,
          ...(!position.id && { id: utils.uuidv4() }),
          /* original_tax_rate: position.tax_rate, */
        })),

        positionTotals: {
          ...state.positionTotals,
          ...uiHelpers.calculatePositionPrices(documentForEdit?.position),
          shipping_price: documentForEdit.pricing_summary.shipping_price,
          currency: documentForEdit.pricing_summary.currency || 'EUR',
          discount: documentForEdit.pricing_summary.discount ?? 0,
          total_price_discount: documentForEdit.pricing_summary.total_price_discount ?? 0,
        },
      };


    case ActionTypes.GET_DOCUMENT_BY_ID_FAIL:
      return {
        ...state,
        actionLoading: false,
        error: 'GENERAL.ERROR_MESSAGE',
      };



    //* enter payment
    case ActionTypes.ENTER_PAYMENT:
      return {
        ...state,
        actionController: {
          ...state.actionController,
          loading: true,
          id: payload.documentId,
        },
      };

    case ActionTypes.ENTER_PAYMENT_SUCCESS:
      const paymentValues = payload.paymentValues;
      const paymentStatus = payload.paymentStatus;

      const updatedDocumentPayment = state.documents.map(document => {
        if (document.id === payload.documentId) {
          return {
            ...document,
            payment_status: paymentStatus,
            payments: [...document.entered_payment, paymentValues],
            entered_payment: [...document.entered_payment, paymentValues],
          };
        } else {
          return document;
        }
      });

      return {
        ...state,
        actionController: {
          ...state.actionController,
          loading: false,
          error: null,
          result: 'success',
        },
        documents: updatedDocumentPayment,
        getDocumentById: {
          ...state.getDocumentById,
          payment_status: paymentStatus,
          entered_payment: [...state.getDocumentById.entered_payment, paymentValues],
        },
        documentForEdit: {
          ...state.documentForEdit,
          payment_status: paymentStatus,
          entered_payment: [...state.documentForEdit.entered_payment, paymentValues],
        },
      };

    case ActionTypes.ENTER_PAYMENT_FAIL: {
      const error = payload?.error;
      return {
        ...state,
        actionController: {
          ...state.actionController,
          loading: false,
          error: typeof error === 'string' ? error : JSON.stringify(error.error.message ?? error),
          result: 'error',
        },
      };
    }



    //* delete entered payment
    case ActionTypes.DELETE_ENTERED_PAYMENT:
      return {
        ...state,
      };

    case ActionTypes.DELETE_ENTERED_PAYMENT_SUCCESS:

      const deletePaymentDocId = payload.documentId;
      const deletePaymentId = payload.paymentId;

      const updatedDocument = state.documents.map(document => {
        if (document.id === deletePaymentDocId) {
          return {
            ...document,
            payment_status: uiHelpers.PaymentStatus(document.position, { ...document, entered_payment: document.entered_payment.filter(payment => payment.id !== deletePaymentId) }),
            entered_payment: document.entered_payment.filter(payment => payment.id !== deletePaymentId)
          };
        } else {
          return document;
        }
      });
      const deleteEnteredPayment = state.documentForEdit.entered_payment.filter(payment => payment.id !== deletePaymentId)

      return {
        ...state,
        documents: updatedDocument,
        getDocumentById: {
          ...state.getDocumentById,
          payment_status: uiHelpers.PaymentStatus(state.positions, { ...state.getDocumentById, entered_payment: deleteEnteredPayment }),
          entered_payment: deleteEnteredPayment,
        },
        documentForEdit: {
          ...state.documentForEdit,
          payment_status: uiHelpers.PaymentStatus(state.positions, { ...state.documentForEdit, entered_payment: deleteEnteredPayment }),
          entered_payment: deleteEnteredPayment,
        },
      };

    case ActionTypes.DELETE_ENTERED_PAYMENT_FAIL:
      return {
        ...state,
        error: 'GENERAL.ERROR_MESSAGE',
      };



    //* get customers
    case ActionTypes.GET_CUSTOMERS:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        loading: false,
        customers: payload.customers,
        error: '',
      };

    case ActionTypes.GET_CUSTOMERS_FAIL:
      return {
        ...state,
        loading: false,
        error: 'GENERAL.ERROR_MESSAGE',
        customer: {},
      };



    //* get customer
    case ActionTypes.GET_CUSTOMER:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.GET_CUSTOMER_SUCCESS:

      const { address, ...rest } = payload.customer;
      let findInvoiceAddress = address.find(add => add.type === 'address_invoice');
      let findShippingAddress = address.find(add => add.type === 'address_delivery');
      if (!findInvoiceAddress) {
        findInvoiceAddress = findShippingAddress;
      }
      if (!findShippingAddress) {
        findShippingAddress = findInvoiceAddress;
      }

      return {
        ...state,
        loading: false,
        customer: payload.customer,
        documentForEdit: {
          ...state.documentForEdit,
          customer_details: {
            ...rest,
            delivery_address: findShippingAddress,
            invoice_address: findInvoiceAddress,
          },
        },
        error: '',
      };

    case ActionTypes.GET_CUSTOMER_FAIL:
      return {
        ...state,
        loading: false,
        error: 'GENERAL.ERROR_MESSAGE',
        customer: {},
      };



    //* update customer
    case ActionTypes.UPDATE_CUSTOMER:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };

    case ActionTypes.UPDATE_CUSTOMER_FAIL:
      return {
        ...state,
        loading: false,
        error: 'GENERAL.ERROR_MESSAGE',
      };



    //* update customer
    case ActionTypes.GET_SETTINGS_BY_TYPE: {
      return {
        ...state,
        loading: true,
      };
    }

    case ActionTypes.GET_SETTINGS_BY_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        userSettings: payload.settings,
        tax: 19,
        error: '',
      };
    }

    case ActionTypes.GET_SETTINGS_BY_TYPE_FAIL: {
      return {
        ...state,
        loading: false,
        error: 'GENERAL.ERROR_MESSAGE',
      };
    }



    //* send invoice with email
    case ActionTypes.GET_COMPANY_BASE_DATA:
      return {
        ...state,
      };

    case ActionTypes.GET_COMPANY_BASE_DATA_SUCCESS:
      return {
        ...state,
        companyBaseData: payload.companyBaseData,
      };

    case ActionTypes.GET_COMPANY_BASE_DATA_FAIL:
      return {
        ...state,
      };



    //* add position
    case ActionTypes.ADD_POSITION:
    case ActionTypes.ADD_MULTIPLE_POSITION_ROW:
      return {
        ...state,
        positionActionLoading: true,
      };

    case ActionTypes.ADD_POSITION_SUCCESS: {
      const positions = cloneDeep(state.positions);
      const { tax, customer_details } = state.getDocumentById;
      const isTax = tax.category === "domestic" || (tax.category === "eu_region" && customer_details.vat_id);
      const { product, variant } = payload;
      const productTaxRate = isTax ? +product.pricing?.tax : 0;
      const variantText = variant.specs.map((spec) => spec.value).join(', ');
      const newPosition = {
        id: utils.uuidv4(),
        productId: product.id,
        sku: variant ? variant.sku : product.sku,
        title: `${product.name} [${variantText}]`.trim(),
        quantity: 1,
        tax_rate: productTaxRate,
        original_tax_rate: +product.pricing?.tax,
        net_price: variant
          ? +variant.pricing?.gross_price / (1 + +productTaxRate / 100)
          : +product.pricing?.gross_price / (1 + +productTaxRate / 100),
        total_net_price: variant
          ? +variant.pricing?.gross_price / (1 + +productTaxRate / 100)
          : +product.pricing?.gross_price / (1 + +productTaxRate / 100),
        gross_price: variant
          ? +variant.pricing?.gross_price
          : +product.pricing?.gross_price,
        total_gross_price: variant
          ? +variant.pricing?.gross_price
          : +product.pricing?.gross_price,
        category: '',
        product_url: '',
        unit: product.unit || '',
        currency: product.pricing.currency || '',
        weight: product?.dimensions?.weight || 0,
      };
      const matchPosition = positions.filter(item => item.sku === variant.sku)
      if (matchPosition.length === 0/*  && variant.quantity > 0 */) {
        positions.push(newPosition);
      }

      return {
        ...state,
        positionActionLoading: false,
        positions: positions,
        documentForEdit: {
          ...state.documentForEdit,
          payment_status: uiHelpers.PaymentStatus(positions, state.documentForEdit),
        },
        positionTotals: {
          ...state.positionTotals,
          ...uiHelpers.calculatePositionPrices(positions),
        },
      };
    }

    case ActionTypes.ADD_MULTIPLE_POSITION_ROW_SUCCESS: {
      const positions = cloneDeep(state.positions);
      const { tax, customer_details } = state.getDocumentById;
      const isTax = tax.category === "domestic" || (tax.category === "eu_region" && customer_details.vat_id);
      const products = payload.products;
      const newPositions = products.map(product => {
        const productTaxRate = isTax ? +product.pricing?.tax : 0;
        const existingPosition = positions.find(p => p.sku === product.sku);

        if (!existingPosition/*  && product.quantity > 0 */) {
          return {
            id: utils.uuidv4(),
            productId: product.id,
            sku: product.sku,
            title: product.name,
            quantity: 1,
            tax_rate: productTaxRate,
            original_tax_rate: +product.pricing?.tax,
            net_price: +product.pricing?.gross_price / (1 + +productTaxRate / 100),
            total_net_price: +product.pricing?.gross_price / (1 + +productTaxRate / 100),
            gross_price: +product.pricing?.gross_price,
            total_gross_price: +product.pricing?.gross_price,
            category: '',
            product_url: '',
            unit: product.unit || '',
            currency: product.pricing.currency || '',
            weight: product?.dimensions?.weight || 0,
          };
        } else {
          return null;
        }
      }).filter(position => position !== null);

      const concatPositions = [...positions, ...newPositions];

      return {
        ...state,
        positionActionLoading: false,
        positions: concatPositions,
        documentForEdit: {
          ...state.documentForEdit,
          payment_status: uiHelpers.PaymentStatus(concatPositions, state.documentForEdit),
        },
        positionTotals: {
          ...state.positionTotals,
          ...uiHelpers.calculatePositionPrices(concatPositions),
        }
      };
    }

    case ActionTypes.ADD_POSITION_FAIL:
      return {
        ...state,
        positionActionLoading: false,
      };



    //* add new position Row
    case ActionTypes.ADD_NEW_POSITION_ROW: {
      const positions = cloneDeep(state.positions);
      const defaultProductTax = state?.userSettings?.default_product?.tax;
      const { tax, customer_details } = state.getDocumentById;
      const isTax = tax.category === "domestic" || (tax.category === "eu_region" && customer_details.vat_id);
      positions.push({
        ...uiHelpers.initialPosition,
        id: utils.uuidv4(),
        productId: utils.uuidv4(),
        tax_rate: isTax ? (defaultProductTax || state.tax) : 0,
        original_tax_rate: state.tax,
        gross_price: 0,
        currency: state.positionTotals.currency ?? state.userSettings?.default_product?.currency,
      });

      return {
        ...state,
        positions: positions,
        positionTotals: {
          ...state.positionTotals,
          ...uiHelpers.calculatePositionPrices(positions),
        },
      };
    }



    //* update position
    case ActionTypes.UPDATE_POSITION: {

      const positions = cloneDeep(state.positions);
      const updatedPositionIndex = positions.findIndex(p => p.id === payload.updatedRow.id);
      const { net_price, gross_price, quantity, tax_rate, sku, title } = payload.updatedRow;

      if (!state.documentForEdit.marketplace && updatedPositionIndex !== -1) {
        if (payload.column?.dataField === 'gross_price') {
          const newGrossPrice = typeof gross_price === 'string' ? +gross_price.replace(',', '.') : gross_price;
          positions[updatedPositionIndex] = {
            ...payload.updatedRow,
            sku: sku?.trim(),
            title: title?.trim(),
            net_price: +newGrossPrice / (1 + tax_rate / 100),
            total_net_price: +quantity * (newGrossPrice / (1 + tax_rate / 100)),
            gross_price: +newGrossPrice,
            total_gross_price: +quantity * +newGrossPrice,
            //...(column.dataField === 'quantity' && { decrementInStock: +quantity }),
          }
        } else {
          const newNetPrice = typeof net_price === 'string' ? +net_price.replace(',', '.') : net_price;
          positions[updatedPositionIndex] = {
            ...payload.updatedRow,
            sku: sku?.trim(),
            title: title?.trim(),
            net_price: newNetPrice,
            total_net_price: +quantity * +newNetPrice,
            gross_price: +newNetPrice * (1 + +tax_rate / 100),
            total_gross_price: +quantity * +newNetPrice * (1 + +tax_rate / 100),
            //...(column.dataField === 'quantity' && { decrementInStock: +quantity }),
          }
        }

      } else if (updatedPositionIndex !== -1) {
        if (payload.column?.dataField === 'gross_price') {
          const newGrossPrice = typeof gross_price === 'string' ? +gross_price.replace(',', '.') : gross_price;
          positions[updatedPositionIndex] = {
            ...payload.updatedRow,
            sku: sku?.trim(),
            title: title?.trim(),
            net_price: +newGrossPrice / (1 + tax_rate / 100),
            total_net_price: +quantity * (newGrossPrice / (1 + tax_rate / 100)),
            gross_price: +newGrossPrice,
            total_gross_price: +quantity * +newGrossPrice,
            //...(column.dataField === 'quantity' && { decrementInStock: +quantity }),
          }
        } else {
          //const newNetPrice = typeof net_price === 'string' ? +net_price.replace(',', '.') : net_price;
          const newNetPrice = typeof gross_price === 'string' ? +gross_price.replace(',', '.') : gross_price;
          positions[updatedPositionIndex] = {
            ...payload.updatedRow,
            sku: sku?.trim(),
            title: title?.trim(),
            // net_price: newNetPrice - newNetPrice * (+tax_rate / 100),
            // TODO: Hem net_price hem de gross_price Değiştiği için bu kısımda net_price sabit bırakıldı
            net_price: net_price,
            total_net_price: +quantity * (newNetPrice - +newNetPrice * (+tax_rate / 100)),
            gross_price: +newNetPrice,
            total_gross_price: +quantity * +newNetPrice,
            //...(column.dataField === 'quantity' && { decrementInStock: +quantity }),
          }
        }
      }

      return {
        ...state,
        positions: positions,
        documentForEdit: {
          ...state.documentForEdit,
          payment_status: uiHelpers.PaymentStatus(positions, state.documentForEdit),
        },
        positionTotals: {
          ...state.positionTotals,
          ...uiHelpers.calculatePositionPrices(positions),
        }
      };
    }

    //* update discount
    case ActionTypes.UPDATE_DISCOUNT: {
      const pricingSummary = {
        ...state.documentForEdit.pricing_summary,
        discount: payload.discount
      }
      return {
        ...state,
        documentForEdit: {
          ...state.documentForEdit,
          payment_status: uiHelpers.PaymentStatus(state.positions, { ...state.documentForEdit, pricing_summary: pricingSummary }),
          pricing_summary: pricingSummary
        },
        positionTotals: {
          ...state.positionTotals,
          discount: payload.discount
        },
      };
    }


    //* update position tax to frei
    case ActionTypes.UPDATE_POSITION_TAXES_TO_FREI:
      const positions = cloneDeep(state.positions);
      let positions2 = [];

      const { taxCategory, isVatId } = payload
      const isTax = taxCategory === "domestic" || (taxCategory === "eu_region" && isVatId);

      if (state.documentForEdit.marketplace) {
        if (isTax) {
          positions2 = positions.map(pos => {
            const newTax = pos.original_tax_rate != null ? pos.original_tax_rate : pos.tax_rate;
            return {
              ...pos,
              tax_rate: newTax,
              net_price: pos.gross_price / (1 + +newTax / 100),
              total_net_price: +pos.quantity * (pos.gross_price / (1 + +newTax / 100)),
              gross_price: +pos.gross_price,
              total_gross_price: +pos.gross_price * +pos.quantity,
            };
          });
        } else {
          positions2 = positions.map(pos => {
            return {
              ...pos,
              tax_rate: 0,
              // original_tax_rate: pos.tax_rate,
              net_price: pos.gross_price,
              total_net_price: +pos.quantity * +pos.gross_price,
              gross_price: pos.gross_price,
              total_gross_price: +pos.gross_price * +pos.quantity,
            };
          });
        }
      } else {
        if (isTax) {
          positions2 = positions.map(pos => {
            const newTax = pos.original_tax_rate != null ? pos.original_tax_rate : pos.tax_rate;
            return {
              ...pos,
              tax_rate: newTax,
              total_net_price: +pos.quantity * +pos.net_price,
              gross_price: +pos.net_price * (1 + +newTax / 100),
              total_gross_price: +pos.net_price * (1 + +newTax / 100) * +pos.quantity,
            };
          });
        } else {
          positions2 = positions.map(pos => {
            return {
              ...pos,
              tax_rate: 0,
              // original_tax_rate: pos.tax_rate,
              total_net_price: +pos.quantity * +pos.net_price,
              gross_price: pos.net_price,
              total_gross_price: +pos.net_price * +pos.quantity,
            };
          })
        }
      }

      return {
        ...state,
        positions: positions2,
        positionTotals: {
          ...state.positionTotals,
          ...uiHelpers.calculatePositionPrices(positions2),
        },
      };



    //* delete position
    case ActionTypes.DELETE_POSITION: {
      const positions = cloneDeep(state.positions);
      const deletePosition = positions.filter(pos => pos.id !== payload.id);
      // if (deletePosition.length === 0) {
      //   return {
      //     ...state,
      //     positions: [{ id: utils.uuidv4(), ...uiHelpers.initialPosition }],
      //     positionTotals: {
      //       id: utils.uuidv4(),
      //       ...uiHelpers.initialPositionTotals,
      //     },
      //   };
      // }


      return {
        ...state,
        positions: deletePosition,
        documentForEdit: {
          ...state.documentForEdit,
          payment_status: uiHelpers.PaymentStatus(deletePosition, state.documentForEdit),
        },
        positionTotals: {
          ...state.positionTotals,
          ...uiHelpers.calculatePositionPrices(deletePosition),
        }
      };
    }



    //* select customer
    case ActionTypes.SELECT_CUSTOMER:
      return {
        ...state,
        customer: payload.customer,
      };



    //* edit select customer
    case ActionTypes.EDIT_SELECTED_CUSTOMER:
      const currentCustomer = cloneDeep(state.customer);
      return {
        ...state,
        customer: {
          ...currentCustomer,
          ...payload.update,
        },
      };

    case ActionTypes.SELECT_CUSTOMER_ADDRESS: {
      const currentDocumentForEdit = cloneDeep(state.documentForEdit);
      return {
        ...state,
        documentForEdit: {
          ...currentDocumentForEdit,
          customer_details: {
            ...currentDocumentForEdit.customer_details,
            ...payload.address,
          },
        },
      };
    }



    //* clean up store
    case ActionTypes.CLEAN_UP_STORE:
      return {
        ...INITIAL_STATE,
        requestedDocument: state.requestedDocument,
      };

    case ActionTypes.CLEAN_UP_DHL_STATES:
      return {
        ...state,
        dhlLabelUrl: {},
        //getDocumentForControl: {},
      };



    //* EBAY REDUCERS
    //* get Ebay orders
    case ActionTypes.GET_USER_TOKENS:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.GET_USER_TOKENS_SUCCESS:
      return {
        ...state,
        loading: false,
        userTokens: payload.tokens,
      };

    case ActionTypes.GET_USER_TOKENS_FAIL:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };



    //* get Requested Shop orders
    case ActionTypes.GET_REQUESTED_SHOP_ORDERS:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.GET_REQUESTED_SHOP_ORDERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.GET_REQUESTED_SHOP_ORDERS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };



    //* get Requested Shop orders
    case ActionTypes.GET_AMAZON_PRIME_ORDERS:
      return {
        ...state,
        // isLoading: true,
      };

    case ActionTypes.GET_AMAZON_PRIME_ORDERS_SUCCESS:
      return {
        ...state,
        // isLoading: false,
      };

    case ActionTypes.GET_AMAZON_PRIME_ORDERS_FAIL:
      return {
        ...state,
        // isLoading: false,
        error: payload.error,
      };



    //* create fulfillment
    case ActionTypes.CREATE_FULFILLMENT_METHODS:
      return {
        ...state,
        actionLoading: true
      };

    case ActionTypes.CREATE_FULFILLMENT_METHODS_SUCCESS:
      const currentConvertDetails = state.convertDetails.map(item => {
        const newConvertDetails = payload?.data.find(i => i.id === item.id);
        return { ...item, ...newConvertDetails };
      });

      return {
        ...state,
        actionLoading: false,
        convertDetails: currentConvertDetails
      };

    case ActionTypes.CREATE_FULFILLMENT_METHODS_FAIL:
      return {
        ...state,
        actionLoading: false
      };


    case ActionTypes.SET_STATE_IN_REDUCER:
      const params = payload.param;
      return {
        ...state,
        ...(params && { ...params }),
      };


    // documentIds reducer
    case ActionTypes.GET_DOCUMENT_BY_IDS:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.GET_DOCUMENT_BY_IDS_SUCCESS:
      const documents = payload.document;
      const convertDetails = documents.map(item => ({
        id: item.id,
        marketplace: item.marketplace,
        marketplace_url: item.marketplace_url,
        shipping: item.shipping,
        payment_status: item.payment_status,
        orderId: item.order_number ? item.order_number : item.order_id,
      }));

      return {
        ...state,
        isLoading: false,
        convertDetails,
      };

    case ActionTypes.GET_DOCUMENT_BY_IDS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: 'GENERAL.ERROR_MESSAGE',
      };


    // create all shippings 
    case ActionTypes.CREATE_ALL_SHIPPINGS:
      return {
        ...state,
        isLoading: true,
        serviceOptions: {
          ...state.serviceOptions,
          goGreen: payload.goGreen,
        }
      };

    case ActionTypes.CREATE_ALL_SHIPPINGS_SUCCESS:
      const document = payload.document;
      const default_shipping = state.userSettings?.default_shipping ?? [];
      const shippingGrouping = document.map(item => ({
        id: utils.uuidv4(),
        documentId: item?.id,
        dp_product_id: default_shipping.find(item => item.service === payload.type)?.dp_product_id ? default_shipping.find(item => item.service === payload.type)?.dp_product_id : null,
        ...(item.billing_number && { billing_number: item.billing_number }),
        marketplace: item?.marketplace,
        order_id: item?.order_id ?? '',
        order_number: item.order_number ?? '',
        email: item?.customer_details?.email,
        number: item?.customer_details?.number,
        delivery_address: item?.customer_details?.delivery_address,
        position: item?.position,
        price: item?.marketplace ? item?.pricing_summary?.shipping_price : default_shipping?.find(item => item.service === payload.type)?.price ?? 0,
        currency: item?.pricing_summary?.currency,
        weight: default_shipping.find(item => item.service === payload.type)?.weight ? default_shipping.find(item => item.service === payload.type)?.weight : (payload.type === "dhl_warenpost" || payload.type === "dhl_new_warenpost") ? 0.5 : 2,
      }));

      return {
        ...state,
        isLoading: false,
        shippingGrouping
      }

    case ActionTypes.CREATE_ALL_SHIPPINGS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: 'GENERAL.ERROR_MESSAGE',
      };


    //* update shipping grouping
    case ActionTypes.UPDATE_SHIPPING_GROUPING:
      return {
        ...state,
        shippingGrouping: state.shippingGrouping.map(item => item.id === payload.row?.id ? payload.row : item),
      };


    // -------------------------------- CREATE RETURN LABELS START --------------------------------
    /*     case ActionTypes.RETURN_LABELS:
          return {
            ...state,
            printLoading: true,
          };
    
        case ActionTypes.RETURN_LABELS_SUCCESS:
    
          const returnLabels = payload?.labels || [];
    
          // documentForEdit shipping update
          const updateReturnShipping = returnLabels.map(({ documentId, label, ...rest }) => ({ ...rest }));
    
          //error varsa kaldır
          const currentReturnLabels = state.documentForEdit.shipping.filter(item => !item.error);
    
          // documents shipping update
          const updateReturnLabels = state.documents.map(item => {
            const matchingLabel = returnLabels.find(i => i.documentId === item.id);
            if (matchingLabel) {
              const { documentId, label, ...rest } = matchingLabel;
              item.shipping.push(rest);
            }
            return item;
          });
    
          return {
            ...state,
            labels: returnLabels,
            printLoading: false,
            documents: updateReturnLabels,
            documentForEdit: {
              ...state.documentForEdit,
              shipping: [...currentReturnLabels, ...updateReturnShipping]
            },
            getDocumentById: {
              ...state.getDocumentById,
              shipping: [...currentReturnLabels, ...updateReturnShipping]
            },
          };
    
        case ActionTypes.RETURN_LABELS_FAIL:
          return {
            ...state,
            isLoading: false,
          }; */
    // -------------------------------- CREATE RETURN LABELS END ----------------------------------


    //* return labels Email
    case ActionTypes.RETURN_LABEL_EMAIL:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.RETURN_LABEL_EMAIL_SUCCESS:

      return {
        ...state,
        loading: false,
      };

    case ActionTypes.RETURN_LABEL_EMAIL_FAIL:
      return {
        ...state,
        loading: false,
      };


    //  -------------------------------- UPDATE LABELS START --------------------------------------
    case ActionTypes.UPDATE_LABEL:
      return {
        ...state,
      };

    case ActionTypes.UPDATE_LABEL_SUCCESS:

      const { totalPrice: totalShippingPrice, ...restUpdateShipping } = payload?.shipping

      // pricing_summary ve payment_status >> state.documentForEdit.price_summary.shipping_price için gerekli
      state.documentForEdit.pricing_summary.shipping_price = totalShippingPrice ?? 0;
      state.getDocumentById.pricing_summary.shipping_price = totalShippingPrice ?? 0; // getDocumentById kaldırılacak

      return {
        ...state,
        documentForEdit: {
          ...state.documentForEdit,
          payment_status: uiHelpers.PaymentStatus(state.positions, state.documentForEdit),
          shipping: state.documentForEdit.shipping.map(item => item.id === payload?.shipping?.id ? restUpdateShipping : item),
        },
        getDocumentById: {
          ...state.getDocumentById,
          payment_status: uiHelpers.PaymentStatus(state.positions, state.getDocumentById),
          shipping: state.getDocumentById.shipping.map(item => item.id === payload?.shipping?.id ? restUpdateShipping : item),
        },
        ...(state.documentForEdit?.marketplace === "" && {
          positionTotals: {
            ...state.positionTotals,
            shipping_price: totalShippingPrice
          }
        })
      };

    case ActionTypes.UPDATE_LABEL_FAIL:
      return {
        ...state,
      };
    // -------------------------------- UPDATE LABELS END -----------------------------------------



    // -------------------------------- CREATE UN LABELS START -------------------------------------
    case ActionTypes.CREATE_UNLABELED:
      return {
        ...state,
      };

    case ActionTypes.CREATE_UNLABELED_SUCCESS:

      const { documentId, totalPrice, ...restUnlabeled } = payload?.shipping || {};
      const currentUnlabeled = state.documentForEdit.shipping.filter(item => !item.error);

      // documents shipping update
      const updateDocumentsUnlabeled = state.documents.map(item => {
        if (documentId === item.id) {
          item.pricing_summary.shipping_price = totalPrice;
          item.payment_status = uiHelpers.PaymentStatus(item.position, item);
          item.shipping = [...item.shipping.filter(label => !label.error), restUnlabeled];
        }
        return item;
      });

      // pricing_summary ve payment_status >> state.documentForEdit.price_summary.shipping_price için gerekli
      state.documentForEdit.pricing_summary.shipping_price = totalPrice ?? 0;
      state.getDocumentById.pricing_summary.shipping_price = totalPrice ?? 0; // getDocumentById kaldırılacak

      return {
        ...state,
        printLoading: false,
        documents: updateDocumentsUnlabeled,
        documentForEdit: {
          ...state.documentForEdit,
          payment_status: uiHelpers.PaymentStatus(state.positions, state.documentForEdit),
          shipping: [...currentUnlabeled, restUnlabeled]
        },
        getDocumentById: {
          ...state.getDocumentById,
          payment_status: uiHelpers.PaymentStatus(state.positions, state.getDocumentById),
          shipping: [...currentUnlabeled, restUnlabeled]
        },
        ...(state.documentForEdit.marketplace === "" && {
          positionTotals: {
            ...state.positionTotals,
            shipping_price: totalPrice ?? 0
          }
        })
      };

    case ActionTypes.CREATE_UNLABELED_FAIL:
      return {
        ...state,
      };
    //  -------------------------------- CREATE UN LABELS END --------------------------------------


    case ActionTypes.DELETE_PAYMENT_WARNING:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.DELETE_PAYMENT_WARNING_SUCCESS:

      const filteredDocuments = state?.documents.map(doc => {
        if (doc.id === payload?.documentId) {
          const filterPaymentWarnings = doc.payment_warnings.filter(warning => warning.id !== payload?.id);
          const paymentStatus = uiHelpers.PaymentStatus(doc.position, { ...doc, payment_warnings: filterPaymentWarnings })
          return { ...doc, payment_status: paymentStatus, payment_warnings: filterPaymentWarnings };
        }
        return doc;
      });

      return {
        ...state,
        loading: false,
        documents: filteredDocuments,
      };

    case ActionTypes.DELETE_PAYMENT_WARNING_FAIL:
      return {
        ...state,
        loading: false,
      };



    case ActionTypes.DELETE_COMPLATE_LIST:
      return {
        ...state,
        convertDetails: state.convertDetails.filter(item => item.id !== payload.id)
      };


    case ActionTypes.DELETE_SHIPPINGS_LIST:
      return {
        ...state,
        shippingGrouping: state.shippingGrouping.filter(item => item.id !== payload.id)
      };


    case ActionTypes.CLEAR_LOADING_EFFECTS:
      return {
        ...state,
        actionLoading: false,
        isLoading: false,
        //DHLActionLoading: false,
        loading: false,
        positionActionLoading: false,
        fetchLoading: {
          loading: false,
          where: '',
        },
        emailSuccess: false,
        error: '',
      };

    case ActionTypes.CLEAN_STATES_IN_STORE:
      return {
        ...state,
        ...payload.cleanedStates,
        success: false,
      };


    default:
      return state;
  }
}
