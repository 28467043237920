import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Portal } from 'react-portal';
import { importExportActions } from 'app/modules/import-export/_redux/actions';
import { useLang } from '_metronic/i18n';
import { useHistory } from 'react-router-dom';
import { useCustomersUIContext } from '../_context/CustomersUIContext';



export function CustomerExportDialog() {

  const dispatch = useDispatch();
  const locale = useLang();
  const history = useHistory();

  const UIContext = useCustomersUIContext();
  const UIProps = useMemo(() => ({
    setIds: UIContext.setIds,
    ids: UIContext.ids,
    show: UIContext.showExportCustomerDialog,
    onHide: UIContext.closeExportCustomerDialog,
    customerId: UIContext.customerId,
  
  }), [UIContext]);

  const exportDocumentAction = () => {

    const customerIds = UIProps.customerId
      ? UIProps.customerId
      : UIProps.ids;

    if (customerIds.length) {
      dispatch(importExportActions.getExport({
        export_select: 'customers',
        date_range: { title: "none", start_date: "", end_date: "" },
        language: locale,
        ids: customerIds,
      }));
    }

    UIProps.onHide();
    UIProps.setIds([]);
    history.push('/export');
  };

  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={UIProps.show} onHide={UIProps.onHide} size="sm" backdrop="static" aria-labelledby="example-modal-sizes-title-sm" centered>

        <Modal.Body className="text-center line-height-md h6 py-10 mb-0">
          {UIProps.customerId || UIProps.ids.length === 1
            ? <FormattedMessage id="DOCUMENT.EXPORT_DIALOG" />
            : <>
              <FormattedMessage id="DOCUMENT.EXPORT_DIALOG_BODY_1" />
              <span className="label label-xl label-inline label-light-danger mx-1">{UIProps.ids.length}</span>
              <FormattedMessage id="DOCUMENT.EXPORT_DIALOG_BODY_2" />
            </>
          }
        </Modal.Body>

        <Modal.Footer className='flex-nowrap overflow-hidden p-0'>

          <Button variant='ios' className="border-right" onClick={UIProps.onHide} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button variant='ios' className="text-danger" onClick={exportDocumentAction} id="btn_export_modal">
            <FormattedMessage id="IMPORT_EXPORT.EXPORT" />
          </Button>

        </Modal.Footer>

      </Modal>
    </Portal>
  );
}
