/**
 * react-images-uploading
 * @see https://github.com/vutoan266/react-images-uploading/blob/master/README.md
 * @see https://www.npmjs.com/package/react-images-uploading
 */

import React, { useState } from 'react';
import ImageUploading from 'react-images-uploading';
import { ProductActions } from '../../_redux/actions';
import { FormattedMessage } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls';
import { pictureSpecs } from '../../_context/ProductsUIHelpers';
import { DeleteConfirm } from '_metronic/_partials/dialogs';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BASE_URL } from 'constants/apiUrl';
import { Icon } from '_metronic/_icons';
import PicturesModal from './PicturesModal';



export function Pictures({ productId, productImages, loading }) {

  const dispatch = useDispatch();

  const { imageDeleted } = useSelector(state => ({
    imageDeleted: state.products.imageDeleted,
  }),
    shallowEqual
  );

  const [imageIndex, setImageIndex] = useState(null);

  // Delete Confirm Modal
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState();

  // Big Picture Modal
  const [showModal, setShowModal] = useState(false);

  // Images Drag & Drop state
  const [dragItemIndex, setDragItemIndex] = useState(null);
  const [dragOverItemIndex, setDragOverItemIndex] = useState(null);

  const handleImages = (imageList, addUpdateIndex) => {
    setErrors();

    if (productId && addUpdateIndex === undefined) {
      const deletedImage = productImages.find(img => img.name && !imageList.includes(img));
      deletedImage && dispatch(ProductActions.deleteFile(productId, "product_images", deletedImage?.id));
    }

    const imageNames = imageList.map(image => image?.file?.name || image.name);
    const isDuplicate = imageNames.some((name, idx) => imageNames.indexOf(name) !== idx);

    if (isDuplicate) {
      setErrors({ duplicate: true });
      return;
    }

    dispatch(ProductActions.picturesHandler(imageList));
  };


  return (
    <Card className="gutter-b">
      <CardSubHeader title={<FormattedMessage id="PRODUCT.PICTURES" />} icon={<Icon.Image />} id="pictures" />
      <CardBody>

        <ImageUploading
          multiple={true}
          value={productImages}
          onChange={handleImages}
          maxNumber={pictureSpecs.maxNumber}
          dataURLKey="thumbnail"
          acceptType={pictureSpecs.format}
          maxFileSize={pictureSpecs.size}
          onError={(errors, files) => setErrors(errors)}
        >
          {({ imageList, onImageUpload, onImageRemoveAll, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
            <>
              <Button
                variant='clean'
                style={{
                  borderColor: errors ? `#F9D7DA` : `#cbd5e1`,
                  background: isDragging ? '#fff' : errors ? `#FFFAFB` : '#f8fafc',
                }}
                className='images-container w-100'
                onClick={onImageUpload}
                disabled={loading}
                {...dragProps}
              >
                <div className="d-flex align-items-center flex-column text-dark-75 mt-12 mb-5" style={{ pointerEvents: 'none' }}>
                  <div className="svg-icon svg-icon-6x opacity-25 mb-8">
                    <Icon.ImagePlus />
                  </div>
                  <b className="text-muted mb-8">
                    <FormattedMessage id="PRODUCT.PICTURE_HERE" />
                  </b>
                  <small className="text-center">
                    <div>
                      <FormattedMessage id="PRODUCT.PICTURE_STANDARD" />
                    </div>
                    <div>
                      <FormattedMessage id='PRODUCT.PICTURE_FORMAT' values={{ size: pictureSpecs.size / 1024 / 1024, format: pictureSpecs.format.join(', ').toLocaleUpperCase() }} />
                    </div>
                  </small>
                </div>

                <div className="font-weight-bold text-danger text-center mb-5" style={{ height: '20px' }}>
                  {errors && (
                    <>
                      <Icon.ExclamationTriangle className="mr-1" />
                      {errors?.maxNumber && <FormattedMessage id='GENERAL.PICTURE.MAX_NUMBER' values={{ pictureMaxNumber: pictureSpecs.maxNumber }} />}
                      {errors?.acceptType && <FormattedMessage id='GENERAL.PICTURE.FORMAT' values={{ pictureFormat: pictureSpecs.format.join(', ').toLocaleUpperCase() }} />}
                      {errors?.maxFileSize && <FormattedMessage id='GENERAL.PICTURE.SIZE' values={{ pictureSize: pictureSpecs.size / 1024 / 1024 }} />}
                      {errors?.duplicate && <FormattedMessage id='GENERAL.PICTURE.DUPLICATE' />}
                    </>
                  )}
                </div>
              </Button>

              {imageList?.length > 0 && <div className="separator separator-solid my-10" />}

              {imageList?.length > 0 &&
                <div className="d-flex flex-wrap">
                  {imageList?.map((image, index) => (

                    <Button key={index}
                      variant='link'
                      className={`d-flex flex-column img-thumbnail img-thumbnail-border h-100 mr-5 mb-5 ${(dragItemIndex && (dragOverItemIndex === index)) ? "next-position" : ""}`}
                      title={image?.name}
                      onDragStart={() => setDragItemIndex(index)}
                      onDragEnter={() => setDragOverItemIndex(index)}
                      onDragLeave={() => setDragOverItemIndex(index)}
                      onDragEnd={() => { setDragItemIndex(null); setDragOverItemIndex(null); }}
                      onDragOver={(e) => e.preventDefault()}
                      onDrop={() => imageList.splice(dragOverItemIndex, 0, ...imageList.splice(dragItemIndex, 1))}
                      draggable={!loading}
                      disabled={loading}
                    >

                      {index === 0 &&
                        <div className="ribbon ribbon-top">
                          <div className="ribbon-target bg-primary text-nowrap" style={{ top: "-4px", left: "6px" }}>
                            <FormattedMessage id="PRODUCT.MAIN_PICTURE" />
                          </div>
                        </div>
                      }

                      <OverlayTrigger overlay={<Tooltip id="product-image-tooltip"><FormattedMessage id="GENERAL.DELETE" /></Tooltip>}>
                        <span type="button" className="btn btn-icon btn-sm text-dark-50 btn-hover-danger svg-icon mb-1 mr-0 ml-auto invisible" onClick={() => { setShow(true); setImageIndex(index); }} id='btn_close'>
                          <Icon.Trash />
                        </span>
                      </OverlayTrigger>

                      <div onClick={() => { setShowModal(true); setImageIndex(index); }}>
                        <img src={image.file ? `${image.thumbnail}` : `${BASE_URL}/${image.thumbnail}`} alt={`product ${index}`} width="100%" height="auto" className="h-100 thumbnail-200" style={{ pointerEvents: 'none' }} />
                      </div>
                    </Button>
                  ))}

                </div>
              }

              <PicturesModal
                show={showModal}
                onHide={() => { setImageIndex(null); setShowModal(false); }}
                toDelete={(index) => { onImageRemove(index); }}
                imageIndex={imageIndex}
                images={imageList}
                isDeleted={imageDeleted}
                loading={loading}
              />

              <DeleteConfirm
                show={show}
                onHide={() => { setImageIndex(null); setShow(false) }}
                toDelete={() => { setShow(false); onImageRemove(imageIndex); }}
                isDeleted={imageDeleted}
                loading={loading}
              />

            </>
          )}
        </ImageUploading>

      </CardBody>
    </Card>
  );
}
