import { ModuleRoutes } from 'constants/moduleRoutes';
import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';



export function CustomerNotAvailableDialog({ customerForEdit }) {

  const history = useHistory();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (customerForEdit && customerForEdit.active === false) {
      setShowModal(true);
    }
  }, [customerForEdit]);

  return (
    <Modal show={showModal} backdrop="static" aria-labelledby="example-modal-sizes-title-sm" size="sm" centered>

      <Modal.Body className="text-center line-height-md h6 py-10 mb-0">
        <FormattedHTMLMessage id="CUSTOMER.NOT_AVAILABLE_MESSAGE" values={{ customerId: customerForEdit.number, what: 'react-intl', a: chunks => <a href='/'>{chunks}</a>, b: chunks => <strong>{chunks}</strong>, span: chunks => <span class="">{chunks}</span> }} />
      </Modal.Body>

      <Modal.Footer className="flex-nowrap p-0 overflow-hidden">

        <Button variant='ios' className="border-right" onClick={() => { history.push(ModuleRoutes.DOCUMENT_ORDER); setShowModal(false) }} id="btn_back_modal">
          <FormattedMessage id="GENERAL.BACK" />
        </Button>

        <Button variant='ios' className="border-right" onClick={() => history.push(ModuleRoutes.CUSTOMER)} id="btn_customers_modal">
          <FormattedMessage id="GENERAL.CLOSE" />
        </Button>

      </Modal.Footer>

    </Modal>
  )
}


