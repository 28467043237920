/**
 * High level router.
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/pages/auth`, `src/app/BasePage`).
 */

import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { BaseRoutes, AuthRoutes, RELATIVE_PATH } from 'constants/moduleRoutes';
import { ToastifyContainer } from '_metronic/_partials/controls';
import { Logout, ErrorsPage } from '../pages';
import { FirstLoginPage } from 'app/modules';
import BasePage from './BasePage';
import AuthPage from './AuthPage';
import AdminPage from './AdminPage';



export function Routes() {

  const { user, license, isLogin } = useSelector(state => ({
    user: state.auth.user,
    license: state.auth.license,
    isLogin: state.auth.isLogin
  }), shallowEqual);


  return (
    <>
      <ToastifyContainer />

      <Switch>

        {!isLogin
          /*Render auth page when user at `/auth` and not authorized.*/
          ? <AuthPage />
          /*Otherwise redirect to root page (`/`)*/
          : <Redirect from={AuthRoutes.path} to={RELATIVE_PATH} />
        }

        <Route path={BaseRoutes.ERROR} component={ErrorsPage} />
        <Route path={AuthRoutes.LOGOUT} component={Logout} />

        {!isLogin
          /*Redirect to `/auth` when user is not authorized*/
          ? <Redirect to={AuthRoutes.LOGIN} />
          : user?.status === 'first_login'
            ? <FirstLoginPage />
            : user?.role === "644c41e257d397eb064fe0f9"
              ? <AdminPage />
              : <BasePage license={license} />
        }

      </Switch>
    </>
  );
}