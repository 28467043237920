import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useLoadingHandler } from 'app/hooks';
import { useDocumentsUIContext } from '../_context/DocumentsUIContext';
import { useDispatch, useSelector } from 'react-redux';
import { DocumentActions } from '../_redux/actions';
import * as uiHelpers from '../_context/DocumentsUIHelpers';
import { Portal } from 'react-portal';
import { CloseButton } from '_metronic/_partials';
import { PaymentReminderAddForm } from './PaymentReminderAddForm';
import { useDocumentLocalUIContext } from '../_context/DocumentLocalUIContext';
import moment from 'moment';



export function PaymentReminderDialog({ id, onHide, show, type }) {

  const dispatch = useDispatch();
  const [error, setError] = useState(false)
  const [showForm, setShowForm] = useState(false)

  const contextLocal = useDocumentLocalUIContext();
  const UIContext = useDocumentsUIContext();
  const UIProps = useMemo(() => ({
    requestedDocument: UIContext.requestedDocument,
    queryParams: contextLocal.queryParams,
    openPrintDocumentDialog: UIContext.openPrintDocumentDialog,
  }), [
    UIContext.openPrintDocumentDialog,
    UIContext.requestedDocument,
    contextLocal.queryParams
  ]);


  const { actionLoading, documents } = useSelector(state => ({
    documents: state.documents.documents,
    actionLoading: state.documents.reminderLoading
  }));


  const onHideCallBack = () => {
    onHide();
    dispatch(DocumentActions.cleanStatesInStore({ /* getDocumentForControl: {}, */ request: uiHelpers.initialStatesForReducer.request }));
    setError(false);
    setShowForm(false);
  };


  const handleSubmit = (values) => {

    const data = {
      ids: [id],
      type: UIProps.requestedDocument.type,
      isEmail: true,
      reminder: values,
    };
    
    onHideCallBack();
    dispatch(DocumentActions.printDocument(data, UIProps.queryParams));
    UIProps.openPrintDocumentDialog();
  };


  const submitByRef = useRef();
  const submitByRefClick = () => {
    submitByRef.current && submitByRef.current.click();
  };

  const useLoading = useLoadingHandler(actionLoading, DocumentActions.cleanStatesInStore({ actionLoading: false }));
  const handleWarningError = (messageId) => setError(<FormattedMessage id={messageId} />);
  const handleDuplicateError = (messageId, date) => setError(<FormattedMessage id={messageId} values={{ date: moment(date).format('YYYY-MM-DD') }} />);
  const paymentWarnings = documents?.find(doc => doc.id === id)?.payment_warnings

  useEffect(() => {

    const paymentWarningsTypes = ['reminder', 'warning_1', 'warning_2']

    if (paymentWarningsTypes.includes(type)) {
      setError(false);
      const warnings = {};
      paymentWarningsTypes.forEach(type => warnings[type] = paymentWarnings?.find(item => item.type === type))
      const { reminder, warning_1, warning_2 } = warnings;

      if (type === 'warning_1') {
        if (!reminder) return handleWarningError('DOCUMENT.PAYMENT.REMINDER1_WARNING_1');
        if (warning_1) return handleDuplicateError('DOCUMENT.PAYMENT.REMINDER1_WARNING_2', warning_1?.created_at);
      } else if (type === 'warning_2') {
        if (!warning_1?.created_at) return handleWarningError('DOCUMENT.PAYMENT.REMINDER2_WARNING_1');
        if (warning_2) return handleDuplicateError('DOCUMENT.PAYMENT.REMINDER2_WARNING_2', warning_2?.created_at);
      }

      setShowForm(true);
    }
  }, [paymentWarnings, type]);


  return (
    <Portal node={document && document.getElementById('modal-portal')}>
      <Modal show={show} size="lg" onHide={onHide} keyboard={false} aria-labelledby="contained-modal-title-centered" centered>

        <Modal.Header>

          <Modal.Title>
            {uiHelpers.paymentWarnings.find(item => item.value === type)?.label}
          </Modal.Title>

          <CloseButton onClick={onHideCallBack} />

        </Modal.Header>
        <Modal.Body>
          <PaymentReminderAddForm handleSubmit={handleSubmit} btnRef={submitByRef} type={type} error={error} showForm={showForm} />
        </Modal.Body>

        <Modal.Footer>

          <Button variant='light' onClick={onHideCallBack} id='btn_close_modal'>
            <FormattedMessage id="GENERAL.CLOSE" />
          </Button>

          {!error &&
            <Button variant='primary' className="ml-2" disabled={useLoading || error} onClick={submitByRefClick} id='btn_send_preview_modal'>
              <FormattedMessage id="DOCUMENT.PAYMENT.REMINDER.SEND_AND_PREVIEW" />
            </Button>}

        </Modal.Footer>

      </Modal>
    </Portal>
  );
}