import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { ReportActions } from "../_redux/actions";
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "_metronic/_partials/controls";
import { FormattedHTMLMessage, FormattedMessage, useIntl } from "react-intl";
import { documentContents } from "app/modules/documents/GenerateDocument";
import { OrderListTable } from "./order-list-table/OrderListTable";
import { OrderListFilter } from "./order-list-table/OrderListFilter";
import { UserGuideHelper } from "app/user-guides/UserGuideHelper";
import { BlankMessage } from "_metronic/_partials/components";
import { ModuleRoutes } from "constants/moduleRoutes";
import { Icon } from "_metronic/_icons";
// import OrderListSelect from "./OrderListSelect";



export default function OrderListCard() {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  useEffect(() => {
    dispatch(ReportActions.getOrderList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const { loading, entities } = useSelector(state => ({
    loading: state.reports.loading,
    entities: state.reports.entities,
  }), shallowEqual);

  const btnRef = useRef();
  const handleSubmit = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const [search, handleSearch] = useState('');

  let filtered = entities.filter(item => [item.title, item.sku].some(field => field?.toLowerCase().includes(search.toLowerCase())));

  const orderModel = documentContents.find(item => item.type === 'orders');

  const instructions = intl({ id: 'REPORT.BLANK_MESSAGE' }, { ordersUrl: ModuleRoutes.DOCUMENT_NEW_FN(orderModel?.type) })


  return (
    <Card className="card-height">

      <CardHeader
        icon={<Icon.Order />}
        title={<FormattedMessage id='REPORT.ORDER_LIST.CARD_TITLE' />}
        info={<UserGuideHelper />}
        className="display-5"
        id='title_order_list'
      >
        <CardHeaderToolbar>
          {/* <OrderListSelect /> */}
          {entities?.length !== 0 && !loading &&
            <Button variant='primary' className="font-weight-bold svg-icon svg-icon-sm  ml-auto" onClick={handleSubmit}>
              <Icon.CloudDownload />
              <span className="d-none d-sm-inline ml-2">
                <FormattedMessage id='REPORT.EXPORT_TO_CSV' />
              </span>
            </Button>}
        </CardHeaderToolbar>

      </CardHeader>

      <CardBody>
        {entities?.length === 0 && !loading
          ? <BlankMessage
            icon={<Icon.Order />}
            title={<FormattedMessage id='DOCUMENT.BLANK_MESSAGE_TITLE' values={{ value: intl({ id: orderModel?.plural }) }} />}
            message={instructions.split("#").map((item, i) =>
              item.includes("href=")
                ? <span key={i}><FormattedHTMLMessage id={item} values={{ a: chunks => <a href='/'>{chunks}</a> }} /></span>
                : <span key={i}>{item}</span>
            )}
            className='min-50-height'
            loading={loading}
          />
          : <>
            <OrderListFilter search={search} handleSearch={handleSearch} />
            <OrderListTable entities={filtered} loading={loading} btnRef={btnRef} />
          </>
        }
      </CardBody>

    </Card>
  )
}
