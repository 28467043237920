import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Field, useFormikContext } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { Accordion, Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { SVFormControl, SVReactSelect } from '_metronic/_partials/controls';
import { Icon } from '_metronic/_icons';
import moment from 'moment';



const getFullYear = new Date().getFullYear();

const preview = {
  invoice_number: `${getFullYear}0001`,
  offer_number: `${getFullYear}0001`,
  waybill_number: `${getFullYear}0001`,
  credit_number: `${getFullYear}0001`,
}

const documentNumberDateOptions = [
  {
    label: 'None',
    value: '',
  },
  {
    label: 'YYYY',
    value: 'YYYY',
  },
  {
    label: 'YYYYMM',
    value: 'YYYYMM',
  },
  {
    label: 'YY',
    value: 'YY',
  },
  {
    label: 'YYMM',
    value: 'YYMM',
  },
  {
    label: 'YYYYMMDD',
    value: 'YYYYMMDD',
  },
  {
    label: 'YYMMDD',
    value: 'YYMMDD',
  },
];

export default function DocumentSettings({ loading, saveSettingsClick, setDocumentTyp, settingType }) {

  const { formatMessage: intl } = useIntl();

  const [minLengthOptions, setMinLengthOptions] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [generatedExampleNumbers, setGeneratedExampleNumbers] = useState({
    invoices: [],
    offers: [],
    waybills: [],
    credits: [],
  });

  const { setFieldValue } = useFormikContext();
  const { values: { default_number_range: { prefix, date, next_value, min_length } } } = useFormikContext();

  const { document_count } = useSelector(state => ({
    document_count: state.settings.document_count,
  }));

  useEffect(() => {

    const totalLength = (prefix ?? '').length + (date ?? '').length;
    const nextValueLength = ((next_value ?? '').length > 4) ? ((next_value ?? '').length - 4) : 0;
    const newMinLengthOptions = Array.from({ length: 5 }, (_, i) => ({
      label: (totalLength + nextValueLength + i + 4).toString(),
      value: totalLength + nextValueLength + i + 4,
    }));

    setMinLengthOptions(newMinLengthOptions);

    const initialMinLength = newMinLengthOptions[0].value;
    setFieldValue('default_number_range.min_length', initialMinLength);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefix, date, next_value]);

  const generateDocumentNumber = (item) => {
    const docNextWert = next_value ? next_value : '1';
    const docPref = prefix ? prefix : date ? '' : docNextWert.length >= +min_length ? '' : '1';
    const docDatePref = date ? date : '';
    const calculateZero = +min_length - docNextWert.length - docDatePref.length - docPref.length;
    const nullErsatzes = calculateZero > 0 ? '0'.repeat(calculateZero) : '';
    return `${docPref}${date ? moment().format(date) : ''}${nullErsatzes}${next_value ? next_value : 1}`;
  };

  const handleSaveSettingsClick = (item) => {
    saveSettingsClick();
    setDocumentTyp(item.settings_type);
    const newNumber = generateDocumentNumber(item);
    setGeneratedExampleNumbers((prevGeneratedNumbers) => {
      const newGeneratedExampleNumbers = { ...prevGeneratedNumbers };
      const index = newGeneratedExampleNumbers[item.settings_type].indexOf(item.documentNumber);
      index !== -1
        ? newGeneratedExampleNumbers[item.settings_type][index] = newNumber
        : newGeneratedExampleNumbers[item.settings_type] = [newNumber]

      return { ...newGeneratedExampleNumbers };
    });
  };


  const documentIds = [
    {
      documentNumber: generatedExampleNumbers.invoices.length > 0 ? generatedExampleNumbers.invoices : preview.invoice_number,
      name: intl({ id: 'GENERAL.INVOICE_NUMBER' }),
      settings_type: 'invoices',
    },
    {
      documentNumber: generatedExampleNumbers.offers.length > 0 ? generatedExampleNumbers.offers : preview.offer_number,
      name: intl({ id: 'GENERAL.OFFER_NUMBER' }),
      settings_type: 'offers',
    },
    {
      documentNumber: generatedExampleNumbers.waybills.length > 0 ? generatedExampleNumbers.waybills : preview.waybill_number,
      name: intl({ id: 'GENERAL.WAYBILL_NUMBER' }),
      settings_type: 'waybills',
    },
    {
      documentNumber: generatedExampleNumbers.credits.length > 0 ? generatedExampleNumbers.credits : preview.credit_number,
      name: intl({ id: 'GENERAL.CREDIT_NUMBER' }),
      settings_type: 'credits',
    },
  ]


  const INPUT = [
    {
      name: "default_number_range.prefix",
      component: SVFormControl,
      label: "SETTINGS.DOCUMENT_ABBREVIATION",
      withFeedbackLabel: false,
    },
    {
      name: "default_number_range.date",
      component: SVReactSelect,
      options: documentNumberDateOptions,
      label: "SETTINGS.DOCUMENT_DATE",
    },
    {
      name: "default_number_range.next_value",
      component: SVFormControl,
      label: "SETTINGS.DOCUMENT_NEXT",
      withFeedbackLabel: false,
    },
    {
      name: "default_number_range.min_length",
      component: SVReactSelect,
      options: minLengthOptions,
      label: "SETTINGS.DOCUMENT_MIN",
      isClearable: false,
      isSearchable: false,
    }
  ]


  return (
    <>
      <div className="alert alert-custom alert-notice alert-light-warning py-4">
        <div className='font-weight-bold'>
          <FormattedMessage id="SETTINGS.FIRST_LOGIN.DOCUMENT.INFO" />
        </div>
      </div>

      {documentIds.map((item, i) =>

        <Accordion key={i} defaultActiveKey="" className='accordion-toggle-arrow'>
          <Card className='mb-4'>

            <Accordion.Toggle as={Card.Header} className='d-flex justify-content-between align-items-center bg-light-info py-3 px-4' eventKey="0"
              onClick={() => { editIndex === i ? setEditIndex(null) : setEditIndex(i) }}>

              <div className='font-weight-bold font-size-h5'>
                <Icon.Settings className="mr-3" />
                <FormattedMessage id={item.name} /> : {item.documentNumber}
              </div>

              <span type="button" className="text-hover-primary icon-lg" onClick={() => { editIndex === i ? setEditIndex(null) : setEditIndex(i) }}>
                {editIndex === i ? <Icon.ChevronUp /> : <Icon.PencilSquare />}
              </span>

            </Accordion.Toggle>

            <Accordion.Collapse eventKey="0">
              <Card.Body>
                {editIndex === i && (

                  <div className='d-flex align-items-end flex-nowrap'>
                    {INPUT.map((item, i) => (
                      <Field component={item.component} key={i}
                        {...{
                          name: item.name,
                          label: intl({ id: item.label }),
                          options: item.options,
                          withFeedbackLabel: item.withFeedbackLabel,
                          feedbackLabel: true,
                          isClearable: item.isClearable,
                          isSearchable: item.isSearchable,
                          disabled: document_count > 0 || loading,
                          className: 'mb-0 mr-4 w-25',
                        }}
                      />
                    ))}

                    <OverlayTrigger placement="top" overlay={<Tooltip id="quick-actions-tooltip"><FormattedMessage id='GENERAL.SAVE' /></Tooltip>}>
                      <Button variant="outline-primary" className="font-weight-bold svg-icon px-3" onClick={() => handleSaveSettingsClick(item)}>
                        <Icon.Check />
                      </Button>
                    </OverlayTrigger>

                  </div>

                )}

              </Card.Body>
            </Accordion.Collapse>

          </Card>
        </Accordion>
      )}

    </>
  )
}