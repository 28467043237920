import React, { useState } from 'react';
import { ProductKeysGrouping } from './ProductKeysGrouping';
import { SearchControl } from '_metronic/_partials/controls';
import { Col, Row } from 'react-bootstrap';



export function ProductKeysFilter({ handleSearch }) {

  const [search, setSearch] = useState('');

  const handleSearchKeys = (value) => {
    setSearch(value);
    handleSearch(value);
  }


  return (
    <Row className='mb-3'>

      <Col md="4" className="mb-lg-0 mb-2">
        <SearchControl search={search} handleSearch={handleSearchKeys} />
      </Col>

      <Col md="8">
        <ProductKeysGrouping />
      </Col>

    </Row>
  );
}
