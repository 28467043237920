import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { FeatureEditForm } from "./FeatureEditForm";
import { useProductEditUIContext } from "../../../_context/ProductEditUIContext";
import { FormattedMessage } from "react-intl";
import { utils } from "_metronic/_helpers";
import { Icon } from "_metronic/_icons";



export function FeatureEditDialog() {

  const UIContext = useProductEditUIContext();
  const UIProps = useMemo(() => ({
    id: UIContext.selectedFeatureId,
    show: UIContext.showEditFeatureDialog,
    onHide: UIContext.closeEditFeatureDialog,
    featuresForEdit: UIContext.featuresForEdit,
    getFeatureHandler: UIContext.getFeatureHandler,
    updateFeatureHandler: UIContext.updateFeatureHandler,
    addFeatureHandler: UIContext.addFeatureHandler,
  }), [UIContext]);


  useEffect(() => {
    UIProps.id && UIProps.getFeatureHandler(UIProps.id)
  }, [UIProps.id, UIProps]);


  const saveFeature = (feature) => {

    UIProps.id
      ? UIProps.updateFeatureHandler(UIProps.id, { id: UIProps.id, ...feature })
      : UIProps.addFeatureHandler({ id: utils.uuidv4(), ...feature })

    UIProps.onHide();
  };


  return (
    <Modal show={UIProps.show} onHide={UIProps.onHide} aria-labelledby="example-modal-sizes-title-lg" centered backdrop="static" keyboard={false}>

      <Modal.Header>
        <Modal.Title>
          <Icon.Tags className="mr-3" />
          <FormattedMessage id={(UIProps.featuresForEdit && UIProps.id) ? "PRODUCT.FEATURES.UPDATE" : "PRODUCT.FEATURES.ADD"} />
        </Modal.Title>
      </Modal.Header>

      <FeatureEditForm saveFeature={saveFeature} initialValues={UIProps.id ? UIProps.featuresForEdit : { name: "", value: "" }} onHide={UIProps.onHide} />

    </Modal>
  );
}


