import React, { createContext, useContext, useState } from 'react';


const UserSettingsUIContext = createContext({});
export const useUserSettingsUIContext = () => useContext(UserSettingsUIContext);



export function UserSettingsUIProvider({ children }) {

  const [ids, setIds] = useState([]);

  const [showEmailEditDialog, setShowEmailEditDialog] = useState(false);
  const openEmailEditDialog = () => setShowEmailEditDialog(true);
  const closeEmailEditDialog = () => setShowEmailEditDialog(false);

  const [showPasswordEditDialog, setShowPasswordEditDialog] = useState(false);
  const openPasswordEditDialog = () => setShowPasswordEditDialog(true);
  const closePasswordEditDialog = () => setShowPasswordEditDialog(false);

  const [showDeleteAccountDialog, setShowDeleteAccountDialog] = useState(false);
  const openAccountDeleteDialog = () => setShowDeleteAccountDialog(true);
  const closeAccountDeleteDialog = () => setShowDeleteAccountDialog(false);

  // Shipping settings
  // Label Settings_Dialog
  const [shippingId, setShippingId] = useState();

  const [showShippingSettingsDialog, setShowShippingSettingsDialog] = useState(false);
  const openShippingSettingDialog = (id) =>{
    setShippingId(id);
     setShowShippingSettingsDialog(true)
     };
  const closeShippingSettingsDialog = () => setShowShippingSettingsDialog(false);


    // Shipping delete modal
    const [showDeleteShippingDialog, setShowDeleteShippingDialog] = useState(false);
    const openShippingDeleteDialog = (id) => {
      setShippingId(id);
      setShowDeleteShippingDialog(true);
    };
    const closeDeleteShippingDialog = () => {
      setShowDeleteShippingDialog(false);
    };


  const value = {
    ids,
    setIds,
    showEmailEditDialog,
    openEmailEditDialog,
    closeEmailEditDialog,

    showPasswordEditDialog,
    openPasswordEditDialog,
    closePasswordEditDialog,

    showDeleteAccountDialog,
    openAccountDeleteDialog,
    closeAccountDeleteDialog,

    showShippingSettingsDialog,
    openShippingSettingDialog,
    closeShippingSettingsDialog,

    showDeleteShippingDialog,
    openShippingDeleteDialog,
    closeDeleteShippingDialog,
    shippingId,
  };

  return (
    <UserSettingsUIContext.Provider value={value}>
      {children}
    </UserSettingsUIContext.Provider>
  );
}