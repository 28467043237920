import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import { getHandlerTableChange, sortCaret, headerSortingClasses, NoRecordsFoundMessage, getSelectRow } from '_metronic/_helpers';
import { useSupplierUIContext } from '../_context/SupplierUIContext';
import { RemotePagination } from '_metronic/_partials/controls';
import { ActionsColumnFormatter } from './ActionsColumnFormatter';
import * as UIHelpers from '../_context/SuppliersUIHelpers';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { Icon } from '_metronic/_icons';
import { useIntl } from 'react-intl';



export function SuppliersTable({ entities, countSupplier, loading, pagination, setPaginationQuery, setOrderQuery, setSearchQuery }) {

  const { formatMessage: intl } = useIntl();

  // Suppliers UI Context
  const UIContext = useSupplierUIContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    setIds: UIContext.setIds,
  }), [UIContext]);


  const COLUMNS = [
    {
      dataField: 'number',
      text: intl({ id: 'GENERAL.NO' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: "60px", width: '5%' },
      headerClasses: 'pl-4',
      classes: 'font-weight-bold text-muted pl-3',
    },
    {
      dataField: 'name',
      text: intl({ id: 'GENERAL.NAME' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap',
      classes: 'font-weight-bold',
      headerStyle: { minWidth: "140px", width: "20%" },
      formatter: (cell, row) => (
        <Link to={ModuleRoutes.EDIT_SUPPLIER_FN(row.id)} type="button" className="d-flex align-items-center text-decoration-none text-dark text-hover-primary text-nowrap preview" id='btn_customer_preview'>
          <span className='font-weight-bold'>{cell}</span>
          <span className='d-flex invisible text-dark-25 svg-icon svg-icon-sm pl-2'>
            <Icon.Pencil />
          </span>
        </Link>
      ),
    },
    {
      dataField: 'company',
      text: intl({ id: 'GENERAL.COMPANY' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap',
      headerStyle: { minWidth: "140px", width: "20%" },
    },
    {
      dataField: 'email',
      text: intl({ id: 'GENERAL.EMAIL' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerStyle: { width: "15%" },
      formatter: (cell, row) => (<a href={`mailto:${cell}`} className="text-decoration-none text-dark text-hover-primary">{cell}</a>)
    },
    {
      dataField: 'phone',
      text: intl({ id: 'GENERAL.PHONE' }),
      headerStyle: { width: "15%" },
    },
    {
      dataField: 'mobile',
      text: intl({ id: 'GENERAL.MOBILE' }),
      headerStyle: { width: "15%" },
    },
    {
      dataField: 'action',
      text: intl({ id: 'GENERAL.ACTIONS' }),
      classes: 'text-right pr-0',
      headerClasses: 'text-right pr-3',
      headerStyle: { minWidth: '100px', width: '5%' },
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditSupplierDialog: (id) => ModuleRoutes.EDIT_SUPPLIER_FN(id),
        openDeleteSupplierDialog: (id) => ModuleRoutes.DELETE_SUPPLIER_FN(id),
      },
    },
  ];


  return (
    <>
      <BootstrapTable
        keyField="id"
        key={UIProps.ids}
        data={entities || []}
        columns={COLUMNS}
        wrapperClasses="table-responsive"
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        striped
        bordered={false}
        condensed
        defaultSorted={UIHelpers.defaultSorted}
        noDataIndication={<NoRecordsFoundMessage loading={loading} onClick={() => setSearchQuery('')} />}
        onTableChange={getHandlerTableChange(UIProps.setQueryParams)}
        selectRow={getSelectRow({
          ids: UIProps.ids,
          setIds: UIProps.setIds,
          entities: entities,
        })}
      />
      <RemotePagination
        queryParams={pagination}
        setQueryParams={setPaginationQuery}
        totalCount={countSupplier}
        entitiesCount={entities?.length}
        loading={loading}
      />
    </>
  );
}
