import React, { useEffect, useState, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { FormattedMessage, useIntl } from "react-intl";
import DocumentNumberSettings from './DocumentNumberSettings';
import { LanguageSelectorDropdown, Logout } from '_metronic/layout';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { maxValidation } from '_metronic/_helpers';
import { SettingsActions } from '../../_redux/actions';
import { CompanyActions } from '../_redux/action';
import Introduction from './Introduction';
import CompanyInfo from './CompanyInfo';
import { Icon } from '_metronic/_icons'
import { Form, Formik } from 'formik';
import { Portal } from 'react-portal';
import * as Yup from "yup";



export default function FirstLoginPage() {

  const history = useHistory();
  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  const [currentStep, setCurrentStep] = useState(0);
  const [documentTyp, setDocumentTyp] = useState('');

  const { documentSettingsForEdit, loading } = useSelector(state => ({
    documentSettingsForEdit: state.settings.documentSettingsForEdit,
    loading: state.settings.loading
  }));

  const settingType = documentSettingsForEdit.settings_type;

  const handleNextPage = () => {
    if (currentStep < steps.length - 1) { setCurrentStep(currentStep + 1) }
  };

  const handlePreviousPage = () => {
    if (currentStep > 0) { setCurrentStep(currentStep - 1) }
  };

  const steps = [
    {
      title: intl({ id: "SETTINGS.FIRST_LOGIN.INTRODUCTION.TITLE" }),
      description: intl({ id: "SETTINGS.FIRST_LOGIN.INTRODUCTION.DESCRIPTION" }),
      state: "done",
      icon: <Icon.ChevronRight className='mx-7' />
    },
    {
      title: intl({ id: "COMPANY.TITLE" }),
      description: intl({ id: "SETTINGS.FIRST_LOGIN.COMPANY.DESCRIPTION" }),
      state: "current",
      icon: <Icon.ChevronRight className='mx-7' />
    },
    {
      title: intl({ id: "SETTINGS.TITLE" }),
      description: intl({ id: "SETTINGS.FIRST_LOGIN.SETTINGS.DESCRIPTION" }),
      state: "pending"
    }
  ]

  const btnRef = useRef();
  const saveSettingsClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };
  useEffect(() => {
    return () => {
      dispatch(SettingsActions.clearState())
    };
  }, [documentTyp, dispatch]);

  const handleSubmit = (values) => {

    const { default_number_range, default_payment, default_shipping, default_subtitle } = values;
    const newValues = {
      settings_type: documentTyp,
      default_number_range,
      default_payment,
      default_shipping,
      default_subtitle
    };

    documentSettingsForEdit.id
      ? dispatch(SettingsActions.updateUserSettings(documentSettingsForEdit.id, newValues))
      : dispatch(SettingsActions.createUserSettings(newValues))
  };

  const completeRegister = () => {
    const userUpdate = { status: 'active' };
    // history.push(ModuleRoutes.DASHBOARD);
    dispatch(CompanyActions.completeUserRegister(userUpdate));
  }

  const documentValidationSchema = Yup.object().shape({
    default_number_range: Yup.object().shape({
      prefix: Yup.string()
        .max(10, maxValidation("10")),
      next_value: Yup.string()
        .max(10, maxValidation("10"))
        .test('is-number-format', intl({ id: 'SETTINGS.FIRST_LOGIN.SETTINGS.NEXT_VALUE_INVALID' }), (value) => !value || /^[0-9]+$/.test(value)),
    }),
  });


  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={true} onHide={() => history.push(ModuleRoutes.DASHBOARD)} backdrop="static" keyboard={false} size="lg" centered>

        <Modal.Header>
          <div className="wizard wizard-6 d-flex flex-column flex-lg-row flex-column-fluid" id="kt_wizard" data-wizard-state="between">
            <div className="wizard-content d-flex flex-column mx-auto">
              <div className="d-flex flex-column-auto flex-column">
                <div className="wizard-nav d-flex flex-column align-items-center align-items-md-start">
                  <div className="wizard-steps d-flex flex-column flex-md-row">

                    {steps.map((step, i) => (
                      <div className="wizard-step flex-grow-1 flex-basis-0 mt-3" data-wizard-type="step" data-wizard-state={`${step.state}`} key={i}>
                        <div className="d-flex align-items-center">
                          <div className={`symbol symbol-50 symbol-light${currentStep > i ? '-success' : currentStep === i ? '-primary' : ''} mr-5`}>
                            <div className="symbol-label">
                              <span className="svg-icon svg-icon-lg">
                                {currentStep > i
                                  ? <Icon.Check />
                                  : <span className="wizard-number font-size-h5">{i + 1}</span>
                                }
                              </span>
                            </div>
                          </div>

                          <div className={`wizard-title ${currentStep > i ? 'text-muted' : ''}`}>
                            <h4 className="wizard-title">
                              {step.title}
                            </h4>
                            <div className={`wizard-desc ${currentStep > i ? 'text-muted' : ''}`}>
                              {step.description}
                            </div>
                          </div>

                          <span className="svg-icon">
                            {step.icon}
                          </span>
                        </div>
                      </div>
                    ))}

                  </div>
                </div>
              </div>
            </div>

          </div>
        </Modal.Header>

        <Modal.Body className="px-15 pt-15 pb-5">
          <PerfectScrollbar className="scroll pr-5 mr-n5" style={{ maxHeight: '600px' }}>

            {currentStep === 0 && <Introduction />}
            {currentStep === 1 && <CompanyInfo btnRef={btnRef} currentStep={currentStep} setCurrentStep={setCurrentStep} />}
            {currentStep === 2 &&
              <Formik enableReinitialize={true} initialValues={documentSettingsForEdit} validationSchema={documentValidationSchema} onSubmit={handleSubmit}>
                <Form>
                  <DocumentNumberSettings loading={loading} saveSettingsClick={saveSettingsClick} setDocumentTyp={setDocumentTyp} settingType={settingType} />
                  <button type="submit" className='d-none' ref={btnRef} />
                </Form>
              </Formik>
            }
          </PerfectScrollbar>

        </Modal.Body>

        <Modal.Footer className="py-3 px-5">

          <div className='d-flex mr-auto'>
            <LanguageSelectorDropdown />
            <Logout />
          </div>

          {currentStep > 0 &&
            <Button variant="link" className="font-weight-bold mr-3" onClick={handlePreviousPage} id='btn_settings_previous'>
              <Icon.ChevronLeft className="mr-2" />
              <FormattedMessage id="GENERAL.PREVIOUS" />
            </Button>
          }

          {currentStep < steps.length - 1
            ? <Button variant="primary" type='button' className="font-weight-bold" onClick={currentStep === 1 ? saveSettingsClick : handleNextPage} id='btn_settings_next'>
              <FormattedMessage id="GENERAL.NEXT" />
              <Icon.ChevronRight className="ml-2" />
            </Button>
            : <Button variant="primary" className="font-weight-bold" onClick={completeRegister}>
              <FormattedMessage id="GENERAL.FINISH" />
            </Button>
          }

        </Modal.Footer>

      </Modal>
    </Portal>
  );
};
