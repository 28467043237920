import React from 'react';
import { FormattedMessage } from 'react-intl';
import ActionTypes from './actionTypes';
import { toast } from 'react-toastify';



export class DocumentActions {

  /**
   * get url access data from store
   * @param {Object} access
   * @returns
   */
  static getUrlAccessDataFromStore = (access) => ({
    type: ActionTypes.GET_URL_ACCESS_DATA_FROM_STORE,
    payload: {
      access
    },
  });



  /**
   * print document
   * @param {object} data
   */
  static printDocument = (data, queryParams) => {
    return {
      type: ActionTypes.PRINT_DOCUMENT,
      payload: {
        data,
        queryParams,
      },
    };
  };

  static printDocumentSuccess = (response) => {
    response?.error && toast.error(response.error);
    return {
      type: ActionTypes.PRINT_DOCUMENT_SUCCESS,
      payload: {
        response,
      },
    };
  };

  static printDocumentFail = (err) => {
    return {
      type: ActionTypes.PRINT_DOCUMENT_FAIL,
      payload: {
        err
      },
    };
  };



  /**
   * Label actions
   * @param {object} data
   */
  static createLabels = (shipper, data) => {
    return {
      type: ActionTypes.CREATE_LABELS,
      payload: {
        shipper,
        data
      },
    };
  };

  static createLabelsSuccess = (labels) => {
    return {
      type: ActionTypes.CREATE_LABELS_SUCCESS,
      payload: {
        labels,
      },
    };
  };

  static createLabelsFail = () => {
    return {
      type: ActionTypes.CREATE_LABELS_FAIL,
    };
  };


  // get Labels
  static getLabel = (labelId, type) => {
    return {
      type: ActionTypes.GET_LABEL,
      payload: {
        labelId,
        type
      },
    };
  };

  static getLabelSuccess = labels => {
    return {
      type: ActionTypes.GET_LABEL_SUCCESS,
      payload: {
        labels,
      },
    };
  };

  static getLabelFail = () => {
    return {
      type: ActionTypes.GET_LABEL_FAIL,
    };
  };

  // delete label
  static deleteLabel = (labelId, documentId) => {
    return {
      type: ActionTypes.DELETE_LABEL,
      payload: {
        labelId,
        documentId
      },
    };
  };

  static deleteLabelSuccess = (labelId, documentId, totalPrice) => {
    toast.success(<FormattedMessage id='DOCUMENT.REDUX.LABEL.DELETE_SUCCESS' />);
    return {
      type: ActionTypes.DELETE_LABEL_SUCCESS,
      payload: {
        labelId,
        documentId,
        totalPrice
      },
    };
  };

  static deleteLabelFail = (error) => {
    toast.error(error.message ?? <FormattedMessage id='DOCUMENT.REDUX.LABEL.DELETE_FAIL' />);
    return {
      type: ActionTypes.DELETE_LABEL_FAIL,
    };
  };



  /**
   * create document actions
   * @param {object} document
   */
  static createDocument = (document, convertParams) => {
    return {
      type: ActionTypes.CREATE_DOCUMENT,
      payload: {
        document,
        convertParams,
      },
    };
  };

  static createDocumentSuccess = (documentId) => {
    toast.success(<FormattedMessage id='DOCUMENT.REDUX.CREATE_DOCUMENT.SUCCESS' />);
    return {
      type: ActionTypes.CREATE_DOCUMENT_SUCCESS,
      payload: {
        documentId,
      },
    };
  };

  static createDocumentFail = (error) => {
    toast.error(error.message);
    return {
      type: ActionTypes.CREATE_DOCUMENT_FAIL,
      payload: {
        error
      },
    };
  };



  /**
   * update document bz field
   * @param {object} document updated document object
   * @param {String} id
   */
  static updateDocumentByField = (documentId, key, value) => {
    return {
      type: ActionTypes.UPDATE_DOCUMENT_BY_FIELD,
      payload: {
        documentId,
        key,
        value,
      },
    };
  };

  static updateDocumentByFieldSuccess = (updatedDocument) => {
    return {
      type: ActionTypes.UPDATE_DOCUMENT_BY_FIELD_SUCCESS,
      payload: { updatedDocument },
    };
  };

  static updateDocumentByFieldFail = (err) => {
    toast.error(<FormattedMessage id='GENERAL.FAIL' />);
    return {
      type: ActionTypes.UPDATE_DOCUMENT_BY_FIELD_FAIL,
      payload: {
        err
      },
    };
  };



  /**
   * update document actions
   * @param {object} document updated document object
   * @param {String} id
   */
  static updateDocument = (document) => {
    return {
      type: ActionTypes.UPDATE_DOCUMENT,
      payload: {
        document
      },
    };
  };

  static updateDocumentSuccess = () => {
    toast.success(<FormattedMessage id='DOCUMENT.REDUX.UPDATE_DOCUMENT.SUCCESS' />);
    return {
      type: ActionTypes.UPDATE_DOCUMENT_SUCCESS,
    };
  };

  static updateDocumentFail = (error) => {
    toast.error(<FormattedMessage id='DOCUMENT.REDUX.UPDATE_DOCUMENT.FAIL' />);
    return {
      type: ActionTypes.UPDATE_DOCUMENT_FAIL,
      payload: {
        error
      },
    };
  };



  /**
   * delete document actions
   * @param {String} documentId
   */
  static deleteDocument = (ids) => {
    return {
      type: ActionTypes.DELETE_DOCUMENT,
      payload: {
        ids,
      },
    };
  };

  static deleteDocumentSuccess = (ids) => {
    toast.success(<FormattedMessage id='DOCUMENT.REDUX.SEND_ARCHIVE.SUCCESS' />);
    return {
      type: ActionTypes.DELETE_DOCUMENT_SUCCESS,
      payload: {
        ids,
      },
    };
  };

  static deleteDocumentFail = () => {
    return {
      type: ActionTypes.DELETE_DOCUMENT_FAIL,
    };
  };



  /**
   * get all documents actions
   */
  static getDocuments = (documentQueryParams) => {
    return {
      type: ActionTypes.GET_DOCUMENTS,
      payload: {
        documentQueryParams,
      },
    };
  };

  /**
   * @param {array} documents return all documents related by the user
   */
  static getDocumentsSuccess = (documents, count) => {
    return {
      type: ActionTypes.GET_DOCUMENTS_SUCCESS,
      payload: {
        documents: documents,
        count,
      },
    };
  };

  static getDocumentsFail = () => {
    return {
      type: ActionTypes.GET_DOCUMENTS_FAIL,
    };
  };



  /**
   * get document by id
   * @param {String} documentId
   *
   */
  static getDocumentById = (documentId, isCopy, isConvert) => {
    return {
      type: ActionTypes.GET_DOCUMENT_BY_ID,
      payload: {
        documentId,
        isCopy,
        isConvert,
      },
    };
  };

  static getDocumentByIdSuccess = (document, isCopy, isConvert) => {
    return {
      type: ActionTypes.GET_DOCUMENT_BY_ID_SUCCESS,
      payload: {
        document,
        isCopy,
        isConvert
      },
    };
  };

  static getDocumentByIdFail = (error) => {
    return {
      type: ActionTypes.GET_DOCUMENT_BY_ID_FAIL,
      payload: {
        error
      },
    };
  };



  /**
   * get document customer details
   * @param {String} documentId
   *
   */
  static getDocumentCustomerDetails = (documentId) => {
    return {
      type: ActionTypes.GET_DOCUMENT_CUSTOMER_DETAILS,
      payload: {
        documentId
      },
    };
  };

  static getDocumentCustomerDetailsSuccess = (document) => {
    return {
      type: ActionTypes.GET_DOCUMENT_CUSTOMER_DETAILS_SUCCESS,
      payload: {
        document,
      },
    };
  };

  static getDocumentCustomerDetailsFail = (error) => {
    return {
      type: ActionTypes.GET_DOCUMENT_CUSTOMER_DETAILS_FAIL,
      payload: {
        error
      },
    };
  };



  /**
   * Enter Payment
   * @param {*} id
   * @param {*} document
   * @returns
   */
  static enterPayment = (paymentValues, paymentStatus, documentId) => {
    return {
      type: ActionTypes.ENTER_PAYMENT,
      payload: {
        paymentValues,
        paymentStatus,
        documentId
      },
    };
  };

  static enterPaymentSuccess = (paymentValues, paymentStatus, documentId) => {
    //FIXME:BE den mesaj geliyor mesaja yansitilabilir
    toast.success(<FormattedMessage id='DOCUMENT.REDUX.ENTER_PAYMENT.SUCCESS' />);
    return {
      type: ActionTypes.ENTER_PAYMENT_SUCCESS,
      payload: {
        paymentValues,
        paymentStatus,
        documentId
      },
    };
  };

  static enterPaymentFail = (error) => {
    return {
      type: ActionTypes.ENTER_PAYMENT_FAIL,
      payload: {
        error
      },
    };
  };



  /**
   * Update Enter Payment
   * @param {*} id
   * @param {*} document
   * @returns
   */
  static updateEnterPayment = (documentId, paymentId, updatedPayment, isPaid) => {
    return {
      type: ActionTypes.UPDATE_ENTER_PAYMENT,
      payload: {
        updatedPayment,
        documentId,
        paymentId,
        isPaid,
      },
    };
  };

  static updateEnterPaymentSuccess = (enteredData) => {
    return {
      type: ActionTypes.UPDATE_ENTER_PAYMENT_SUCCESS,
    };
  };

  static updateEnterPaymentFail = () => {
    return {
      type: ActionTypes.UPDATE_ENTER_PAYMENT_FAIL,
    };
  };



  /**
   * Delete Enter Payment
   */
  static deleteEnteredPayment = (paymentId, documentId) => {
    return {
      type: ActionTypes.DELETE_ENTERED_PAYMENT,
      payload: {
        paymentId,
        documentId
      },
    };
  };

  static deleteEnteredPaymentSuccess = (paymentId, documentId) => {
    return {
      type: ActionTypes.DELETE_ENTERED_PAYMENT_SUCCESS,
      payload: {
        paymentId,
        documentId
      },
    };
  };

  static deleteEnteredPaymentFail = (error) => {
    return {
      type: ActionTypes.DELETE_ENTERED_PAYMENT_FAIL,
      payload: {
        error
      },
    };
  };



  /**
   * Add Position
   * @param {object} product
   */
  static addPosition = (productId, variant) => {
    return {
      type: ActionTypes.ADD_POSITION,
      payload: {
        productId,
        variant,
      },
    };
  };

  static addPositionSuccess = (product, variant) => {
    return {
      type: ActionTypes.ADD_POSITION_SUCCESS,
      payload: {
        product,
        variant,
      },
    };
  };

  static addPositionFail = () => {
    return {
      type: ActionTypes.ADD_POSITION_FAIL,
    };
  };

  static addMultiplePositions = (ids) => {
    return {
      type: ActionTypes.ADD_MULTIPLE_POSITION_ROW,
      payload: {
        ids
      },
    };
  };

  static addMultiplePositionsSuccess = (products) => {
    return {
      type: ActionTypes.ADD_MULTIPLE_POSITION_ROW_SUCCESS,
      payload: {
        products
      },
    };
  };


  /**
   * Add New Position Row
   */
  static addNewPositionRow = () => {
    return {
      type: ActionTypes.ADD_NEW_POSITION_ROW,
    };
  };


  /**
   * Update Position
   * @param {object} product
   */
  static updatePosition = (updatedRow, column) => {
    return {
      type: ActionTypes.UPDATE_POSITION,
      payload: {
        updatedRow,
        column,
      },
    };
  };


  /**
   * Update Discount
   * @param {object} product
   */
  static updateDiscount = (discount) => {
    return {
      type: ActionTypes.UPDATE_DISCOUNT,
      payload: {
        discount
      },
    };
  };


  /**
   * Update Position taxes to frei
   * @param {object} product
   */
  static updatePositionTaxesToFrei = (taxCategory, isVatId) => {
    return {
      type: ActionTypes.UPDATE_POSITION_TAXES_TO_FREI,
      payload: {
        taxCategory,
        isVatId
      },
    };
  };



  /**
   * Delete Position
   * @param {*} id
   * @returns
   */
  static deletePosition = (id) => {
    return {
      type: ActionTypes.DELETE_POSITION,
      payload: {
        id,
      },
    };
  };



  /**
   * Get Customers
   * @param {String} customerId
   */
  static getCustomers = (event, page, limit) => {
    return {
      type: ActionTypes.GET_CUSTOMERS,
      payload: {
        event,
        page,
        limit,
      },
    };
  };

  static getCustomersSuccess = (customers) => {
    return {
      type: ActionTypes.GET_CUSTOMERS_SUCCESS,
      payload: {
        customers,
      },
    };
  };

  static getCustomersFail = () => {
    return {
      type: ActionTypes.GET_CUSTOMERS_FAIL,
    };
  };



  /**
   * Get Customer
   * @param {String} customerId
   */
  static getCustomer = (customerId) => {
    return {
      type: ActionTypes.GET_CUSTOMER,
      payload: {
        customerId,
      },
    };
  };

  static getCustomerSuccess = (customer) => {
    return {
      type: ActionTypes.GET_CUSTOMER_SUCCESS,
      payload: {
        customer,
      },
    };
  };

  static getCustomerFail = () => {
    return {
      type: ActionTypes.GET_CUSTOMER_FAIL,
    };
  };



  /**
   * Update Customer
   * @param {object} updatedCustomer
   */
  static updateCustomer = (updatedCustomer) => {
    return {
      type: ActionTypes.UPDATE_CUSTOMER,
      payload: {
        updatedCustomer,
      },
    };
  };

  static updateCustomerSuccess = () => {
    return {
      type: ActionTypes.UPDATE_CUSTOMER_SUCCESS,
    };
  };

  static updateCustomerFail = () => {
    return {
      type: ActionTypes.UPDATE_CUSTOMER_FAIL,
    };
  };



  /**
   * Get Settings by Type
   * @param {String} settingsType
   */
  static getSettingsByType = (settingsType) => {
    return {
      type: ActionTypes.GET_SETTINGS_BY_TYPE,
      payload: {
        settingsType,
      },
    };
  };

  static getSettingsByTypeSuccess = (settings) => {
    return {
      type: ActionTypes.GET_SETTINGS_BY_TYPE_SUCCESS,
      payload: {
        settings
      },
    };
  };

  static getSettingsByTypeFail = () => {
    return {
      type: ActionTypes.GET_SETTINGS_BY_TYPE_FAIL,
    };
  };



  /**
   * Select Customer
   * @param {object} customer customer
   */
  static selectCustomer = (customer) => {
    return {
      type: ActionTypes.SELECT_CUSTOMER,
      payload: {
        customer: customer,
      },
    };
  };



  /**
   * Edit Selected Customer
   * @param {}
   */
  static editSelectedCustomer = (update) => {
    return {
      type: ActionTypes.EDIT_SELECTED_CUSTOMER,
      payload: {
        update: update,
      },
    };
  };

  static selectCustomerInvoiceAddress = (invoice) => {
    return {
      type: ActionTypes.SELECT_CUSTOMER_INVOICE_ADDRESS,
      payload: {
        invoice,
      },
    };
  };

  static editSelectedCustomerInvoiceAddress = (invoiceInput) => {
    return {
      type: ActionTypes.EDIT_SELECTED_CUSTOMER_INVOICE_ADDRESS,
      payload: {
        invoiceInput,
      },
    };
  };

  static selectCustomerShippingAddress = (address) => {
    return {
      type: ActionTypes.SELECT_CUSTOMER_ADDRESS,
      payload: {
        address,
      },
    };
  };

  static editSelectedCustomerShippingAddress = (shippingInput) => {
    return {
      type: ActionTypes.EDIT_SELECTED_CUSTOMER_SHIPPING_ADDRESS,
      payload: {
        shippingInput,
      },
    };
  };



  /**
   * Get Company base data
   * @param {object} invoice
   */
  static getCompanyBaseData = () => {
    return {
      type: ActionTypes.GET_COMPANY_BASE_DATA,
    };
  };

  static getCompanyBaseDataSuccess = (companyBaseData) => {
    return {
      type: ActionTypes.GET_COMPANY_BASE_DATA_SUCCESS,
      payload: { companyBaseData },
    };
  };

  static getCompanyBaseDataFail = () => {
    return {
      type: ActionTypes.GET_COMPANY_BASE_DATA_FAIL,
    };
  };



  static cleanUpDHLStates = () => {
    return {
      type: ActionTypes.CLEAN_UP_DHL_STATES,
    };
  };



  /**
   * *...
   */
  static setupRequestedDocument = requestedDocument => {
    return {
      type: ActionTypes.SETUP_REQUESTED_DOCUMENT,
      payload: {
        requestedDocument,
      },
    };
  };




  /**
   * Get Orders
   * @param {object} data
   */
  static getAmazonPrimeOrders = () => {
    return {
      type: ActionTypes.GET_AMAZON_PRIME_ORDERS,
      payload: {},
    };
  };

  static getAmazonPrimeOrdersSuccess = (orders) => {

    const filteredErrors = orders.filter(ord => ord.errorText || ord.error);

    if (filteredErrors.length > 0) {
      const errorTexts = filteredErrors.map(ordErr => ordErr.errorText).join(`\n`);
      toast.error(<FormattedMessage id='DOCUMENT.REDUX.GET_AMAZON_PRIME_ORDERS.SUCCESS_FAIL' values={{ error: errorTexts }} />);
    }

    if (orders.length - filteredErrors.length > 0) {
      toast.success(<FormattedMessage id='DOCUMENT.REDUX.GET_AMAZON_PRIME_ORDERS.SUCCESS' values={{ quantity: orders.length - filteredErrors.length }} />);
    }

    return {
      type: ActionTypes.GET_AMAZON_PRIME_ORDERS_SUCCESS,
      payload: {
        orders,
      },
    };
  };

  static getAmazonPrimeOrdersFail = (error) => {
    toast.error(<FormattedMessage id='DOCUMENT.REDUX.GET_AMAZON_PRIME_ORDERS.FAIL' />);
    return {
      type: ActionTypes.GET_AMAZON_PRIME_ORDERS_FAIL,
      payload: {
        error,
      },
    };
  };



  /**
   * Create Fulfillment Methods
   * @param {array} ids order ids
   */
  static createFulfillments = (data, queryParams) => {
    return {
      type: ActionTypes.CREATE_FULFILLMENT_METHODS,
      payload: {
        data,
        queryParams
      },
    };
  };

  static createFulfillmentsSuccess = (data) => {

    const counts = data.reduce((acc, item) => {
      if (item.invoice) { acc.invoiceLength++; }
      if (item.fulfillment) { acc.fulfillmentLength++; }
      return acc;
    }, { invoice: 0, fulfillment: 0 });

    counts.fulfillment > 0 && toast.success(<FormattedMessage id={counts.fulfillment > 1 ? 'DOCUMENT.REDUX.CREATE_FULFILLMENT_METHODS.SUCCESS_PLURAL' : 'DOCUMENT.REDUX.CREATE_FULFILLMENT_METHODS.SUCCESS'} values={{ length: counts.fulfillment }} />);
    counts.invoice > 0 && toast.success(<FormattedMessage id={counts.invoice > 1 ? 'DOCUMENT.REDUX.CONVERT_DOCUMENTS.SUCCESS_PLURAL' : 'DOCUMENT.REDUX.CONVERT_DOCUMENTS.SUCCESS'} values={{ length: counts.invoice }} />);

    return {
      type: ActionTypes.CREATE_FULFILLMENT_METHODS_SUCCESS,
      payload: {
        data
      },
    };
  };

  static createFulfillmentsFail = (error) => {
    toast.error(<FormattedMessage id='DOCUMENT.REDUX.CREATE_FULFILLMENT_METHODS.FAIL' />);
    return {
      type: ActionTypes.CREATE_FULFILLMENT_METHODS_FAIL,
      payload: {
        error,
      },
    };
  };



  /**
   * Create Shipping Fulfillment
   * @param {object} data
   */
  static getUserTokens = () => {
    return {
      type: ActionTypes.GET_USER_TOKENS,
    };
  };

  static getUserTokensSuccess = (tokens) => {
    return {
      type: ActionTypes.GET_USER_TOKENS_SUCCESS,
      payload: {
        tokens,
      },
    };
  };

  static getUserTokensFail = (error) => {
    return {
      type: ActionTypes.GET_USER_TOKENS_FAIL,
      payload: {
        error,
      },
    };
  };

  static setStateInReducer = (param) => {
    return {
      type: ActionTypes.SET_STATE_IN_REDUCER,
      payload: {
        param,
      },
    };
  };


  /**
   * get all documentIds actions
   */
  static getDocumentByIds = (ids, type) => {
    return {
      type: ActionTypes.GET_DOCUMENT_BY_IDS,
      payload: {
        ids,
        type
      },
    };
  };


  /**
   * @param {array} documents
   */
  static getDocumentByIdsSuccess = (document, type) => {
    return {
      type: ActionTypes.GET_DOCUMENT_BY_IDS_SUCCESS,
      payload: {
        document,
        type
      },
    };
  };

  static getDocumentByIdsFail = () => {
    return {
      type: ActionTypes.GET_DOCUMENT_BY_IDS_FAIL,
    };
  };


  static createAllDocuments = (ids, service, dhlServiceOptions) => {
    return {
      type: ActionTypes.CREATE_ALL_SHIPPINGS,
      payload: {
        ids,
        service,
        dhlServiceOptions
      },
    };
  };

  static createAllDocumentsSuccess = (document, type) => {
    return {
      type: ActionTypes.CREATE_ALL_SHIPPINGS_SUCCESS,
      payload: {
        document,
        type
      },
    };
  }

  static createAllDocumentsFail = () => {
    return {
      type: ActionTypes.CREATE_ALL_SHIPPINGS_FAIL,
    };
  }

  static updateShippingGrouping = (row) => {
    return {
      type: ActionTypes.UPDATE_SHIPPING_GROUPING,
      payload: {
        row
      },
    };
  };



  /*   static returnLabels = (shipper, data) => {
      return {
        type: ActionTypes.RETURN_LABELS,
        payload: {
          shipper,
          data
        },
      };
    };
  
    static returnLabelsSuccess = (labels) => {
      return {
        type: ActionTypes.RETURN_LABELS_SUCCESS,
        payload: {
          labels,
        },
      };
    };
  
    static returnLabelsFail = () => {
      return {
        type: ActionTypes.RETURN_LABELS_FAIL,
      };
    }; */



  static returnLabelEmail = (documentType, documentId, shipperId) => {
    return {
      type: ActionTypes.RETURN_LABEL_EMAIL,
      payload: {
        documentType,
        documentId,
        shipperId
      },
    };
  };

  static returnLabelEmailSuccess = (labels) => {
    toast.success(<FormattedMessage id='DOCUMENT.REDUX.RETURN_LABEL.EMAIL_SUCCESS' />);
    return {
      type: ActionTypes.RETURN_LABEL_EMAIL_SUCCESS,
      payload: {
        labels,
      },
    };
  };

  static returnLabelEmailFail = () => {
    toast.error(<FormattedMessage id='DOCUMENT.REDUX.RETURN_LABEL.EMAIL_FAIL' />);
    return {
      type: ActionTypes.RETURN_LABEL_EMAIL_FAIL,
    };
  };


  /**
   * Label update actions
   * @param {object} data
   */
  static updateLabel = (data) => {
    return {
      type: ActionTypes.UPDATE_LABEL,
      payload: {
        data
      },
    };
  };

  static updateLabelSuccess = (shipping) => {
    return {
      type: ActionTypes.UPDATE_LABEL_SUCCESS,
      payload: {
        shipping,
      },
    };
  };

  static updateLabelFail = () => {
    return {
      type: ActionTypes.UPDATE_LABEL_FAIL,
    };
  };

  /**
   * Label update actions
   * @param {object} data
   */
  static createUnlabeled = (data) => {
    return {
      type: ActionTypes.CREATE_UNLABELED,
      payload: {
        data
      },
    };
  };

  static createUnlabeledSuccess = (shipping) => {
    return {
      type: ActionTypes.CREATE_UNLABELED_SUCCESS,
      payload: {
        shipping
      },
    };
  };

  static createUnlabeledFail = () => {
    return {
      type: ActionTypes.CREATE_UNLABELED_FAIL,
    };
  };


  // delete payment warning
  static deletePaymentWarning = (documentId, id) => {
    return {
      type: ActionTypes.DELETE_PAYMENT_WARNING,
      payload: {
        documentId,
        id
      },
    };
  };

  static deletePaymentWarningSuccess = (documentId, id) => {
    toast.success(<FormattedMessage id='DOCUMENT.PAYMENT.WARNINGS.DELETE_SUCCESS' />);
    return {
      type: ActionTypes.DELETE_PAYMENT_WARNING_SUCCESS,
      payload: {
        documentId,
        id
      },
    };
  };

  static deletePaymentWarningFail = () => {
    toast.error(<FormattedMessage id='DOCUMENT.PAYMENT.WARNINGS.DELETE_FAIL' />);
    return {
      type: ActionTypes.DELETE_PAYMENT_WARNING_FAIL,
    };
  };


  static deleteCompleteList = (id) => {
    return {
      type: ActionTypes.DELETE_COMPLATE_LIST,
      payload: {
        id
      },
    };
  };

  static deleteShippingsList = (id) => {
    return {
      type: ActionTypes.DELETE_SHIPPINGS_LIST,
      payload: {
        id
      },
    };
  };



  /**
   * Clear Loading Handler
   * @returns
   */
  static clearLoadingEffects = () => {
    return {
      type: ActionTypes.CLEAR_LOADING_EFFECTS,
    };
  };



  /**
   * Clear Loading Handler
   * @returns
   */
  static cleanStatesInStore = (cleanedStates) => {
    return {
      type: ActionTypes.CLEAN_STATES_IN_STORE,
      payload: {
        cleanedStates
      },
    };
  };



  /**
   * Clean up reducer
   */
  static cleanUpDocumentStore = () => {
    return {
      type: ActionTypes.CLEAN_UP_STORE,
    };
  };
}
