import React from 'react';
import { Card, CardBody, CardSubHeader, SVFormTextarea } from '_metronic/_partials/controls';
import { FormattedMessage, useIntl } from 'react-intl';
import { UserInfoDropdown } from '_metronic/_partials';
import { Col, Row } from 'react-bootstrap';
import { Icon } from '_metronic/_icons';
import { Field, useFormikContext } from 'formik';



export function TextCard({ documentId, loading }) {

  const { formatMessage: intl } = useIntl();
  const { values: { notes } } = useFormikContext();

  const INPUTS = [
    {
      name: "subtitle.header",
      label: "GENERAL.HEADER",
      size: 6,
    },
    {
      name: "subtitle.footer",
      label: "GENERAL.FOOTER",
      size: 6,
    },
    {
      name: "notes",
      label: "DOCUMENT.HEADER_COURSE",
      feedbackLabel: notes?.length
        ? <span className={`${notes && notes?.length <= (1000 * 80 / 100) ? 'text-success' : notes && notes?.length <= 1000 ? 'text-warning' : 'text-danger'}`}>
          <FormattedMessage id="SETTINGS.DOCUMENT.REMAINING_CHARACTER" values={{ character: 1000 - notes?.length }} />
        </span>
        : true,
      // maxLength: 1000,
    },
  ];
  
  return (
    <Card>

      <CardSubHeader
        icon={<Icon.TextIndentLeft />}
        title={<FormattedMessage id="GENERAL.HEADER&FOOTER" />}
        info={<UserInfoDropdown
          title={<FormattedMessage id={'DOCUMENT.HEADER.INFO_TITLE'} />}
          description={<FormattedMessage id={'DOCUMENT.HEADER.INFO_DESCRIPTION'} />} />}
      />

      <CardBody>
        <Row>

          {INPUTS.map((item, i) => (!(item.name === "notes" && !documentId) &&
            <Col lg={item.size || 12} key={i}>
              <Field component={SVFormTextarea}
                {...{
                  name: item.name,
                  label: intl({ id: item.label }),
                  placeholder: item.placeholder,
                  rows: 6,
                  maxLength: item.maxLength,
                  withFeedbackLabel: true,
                  feedbackLabel: item.feedbackLabel || true,
                  disabled: loading,
                }}
              />
            </Col>
          ))}
        </Row>

      </CardBody>
    </Card>
  );
}
