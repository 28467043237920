import React, { useMemo } from "react";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { SelectCounter } from "_metronic/_partials";
import { Icon } from '_metronic/_icons';
import { useDigitalProductsUIContext } from "../_context/DigitalProductsUIContext";



export function DigitalProductsGrouping() {

  const UIContext = useDigitalProductsUIContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    openSendMailKeyDialog: UIContext.openSendMailKeyDialog,
    openReserveDialog: UIContext.openReserveDialog
  }),
    [UIContext]
  );

  return (
    <div className="d-flex flex-wrap justify-content-end mb-2">

      <SelectCounter count={UIProps.ids.length} />

      <Button variant="light-primary" className="svg-icon svg-icon-sm font-weight-bold ml-2 mb-2"  onClick={() => UIProps.openSendMailKeyDialog(UIProps.ids)} disabled={UIProps.ids.length <= 0} id="btn_multiple_email" >
        <Icon.EnvelopeAt />
        <span className="d-none d-sm-inline ml-2">
          <FormattedMessage id="DIGITAL_PRODUCT.SEND_EMAIL" />
        </span>
      </Button>

{/*       <Button variant="light-primary" className="svg-icon svg-icon-sm font-weight-bold ml-2 mb-2" onClick={() => UIProps.openReserveDialog(UIProps.ids)} disabled={UIProps.ids.length <= 0} id="btn_multiple_delete">
        <Icon.Reply />
        <span className="d-none d-sm-inline ml-2">
          <FormattedMessage id="PRODUCT.KEYS.RESERVE_DELETE" />
        </span>
      </Button> */}
    </div>
  );
}