export const supplierFieldParams = {
  fields: [
    "id",
    "name",
    "company",
    "number"
  ],
};

export const searchKeys = [
  'name',
  'sku',
  "category.path",
  'variants.sku',
  'variants.ean',
  'variants.asin',
  'identities.ean',
  'identities.asin',
  'pricing.purchase_price',
  'identities.barcode',
  'identities.gtin',
  'identities.isbn',
];