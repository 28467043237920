import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useProductsUIContext } from "../_context/ProductsUIContext";
import { ModuleRoutes } from "constants/moduleRoutes";
import { SelectCounter } from "_metronic/_partials";
import { Icon } from "_metronic/_icons";



export function ProductsGrouping() {

  const history = useHistory();

  const productsUIContext = useProductsUIContext();

  const productsUIProps = useMemo(() => ({
    ids: productsUIContext.ids,
    setIds: productsUIContext.setIds,
    openExportProductDialog: productsUIContext.openExportProductDialog,
  }), [productsUIContext]);

  const deleteAllSelectedProducts = () => {
    const ids = productsUIProps.ids.join("&");
    history.push(ModuleRoutes.DELETE_PRODUCT_LISTS_FN(ids))
  };


  return (
    <div className="d-flex justify-content-between">

      <SelectCounter count={productsUIProps.ids.length} />

      <Button variant="light-primary" className="svg-icon svg-icon-sm font-weight-bold ml-auto mr-2"
        onClick={() => productsUIProps.openExportProductDialog()}
        disabled={!productsUIProps.ids || productsUIProps.ids.length <= 0}
        style={{ cursor: !productsUIProps.ids || (productsUIProps.ids.length <= 0 && 'not-allowed') }}
        id='btn_export'
      >
        <Icon.CloudDownload />
        <span className="d-none d-sm-inline ml-2">
          <FormattedMessage id="IMPORT_EXPORT.EXPORT" />
        </span>

      </Button>

      <Button variant="light-primary" className="svg-icon svg-icon-sm font-weight-bold" onClick={deleteAllSelectedProducts} disabled={productsUIProps.ids.length <= 0} id="btn_multiple_delete">
        <Icon.Trash />
        <span className="d-none d-sm-inline ml-2">
          <FormattedMessage id="GENERAL.DELETE" />
        </span>
      </Button>

    </div>
  );
}
