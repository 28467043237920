
import React, { useState, useEffect } from 'react';
import { SearchControl } from '_metronic/_partials/controls';
import { Col, Row } from 'react-bootstrap';
import { useQuery } from 'app/hooks';
import { TempUserGrouping } from './TempUserGrouping';



export function TempUserFilter({ UIProps }) {

  const query = useQuery();
  const [search, setSearch] = useState(query.get('search') ?? '');

  useEffect(() => {
    setSearch(query.get('search') ?? '');
  }, [query]);

  const handleSearch = (values) => {
    setSearch(values);
    UIProps.setSearchQuery(values);
  }


  return (
    <Row className="mb-3">

      <Col md="4">
        <SearchControl search={search} handleSearch={handleSearch} />
      </Col>

      <Col md="8">
        <TempUserGrouping />
      </Col>
      
    </Row>
  );
}


