import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { maxValidation } from '_metronic/_helpers';
import { SVFormControl } from '_metronic/_partials/controls';
import { FormattedMessage, useIntl } from 'react-intl';



export const AddVariantForm = ({ onSubmit, submitBtnRef, currentSpecs }) => {

  const { formatMessage: intl } = useIntl();
  const [selectedVariants, setSelectedVariants] = useState([]);

  const initialValues = { gross_price: 0, quantity: 0, selectedVariants: selectedVariants }

  const validateSchema = Yup.object().shape({
    quantity: Yup.number()
      .moreThan(-1, intl({ id: "PRODUCT.GENERAL.PRICE_THAN" }))
      .max(999999, maxValidation("999.999")),
    gross_price: Yup.number()
      .min(0, intl({ id: "PRODUCT.GENERAL.PRICE_THAN" }))
      .test('Is positive?', intl({ id: "PRODUCT.GENERAL.PRICE_THAN" }), value => value >= 0)
      .max(10000000, intl({ id: "PRODUCT.GENERAL.PRICE_MAX" })),
    selectedVariants: Yup.array()
      .test('selectedVariants', intl({ id: "PRODUCT.ADD_VARIANT.SELECT_VALIDATION" }), value => value.length === currentSpecs.length),
  })


  const isSelected = (aspect, option, selectedVariants) => {
    const findOption = selectedVariants.find(item => item.name === option);
    if (findOption) {
      return findOption.values.includes(aspect);
    }
    return false;
  };


  const handleCheckboxChange = (event, aspect, option) => {
    const isChecked = event.target.checked;

    setSelectedVariants(prevVariants => {
      const existingVariant = prevVariants.find(variant => variant.name === option);

      if (existingVariant) {
        let updatedValues;

        if (isChecked) {
          updatedValues = [aspect];
        } else {
          updatedValues = existingVariant.values.filter(value => value !== aspect);
        }

        const updatedVariant = { name: option, values: updatedValues };

        return prevVariants.map(variant =>
          variant.name === option ? updatedVariant : variant
        );
      } else {
        const newVariant = { name: option, values: [aspect] };
        return [...prevVariants, newVariant];
      }
    });
  };


  return (
    <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={validateSchema} onSubmit={onSubmit}>
      {({ handleSubmit, values, errors }) => (
        <Form className="row">

          <Col sm="12">

            {currentSpecs.map((option, index) => (
              <Row className='mb-2' key={index}>

                <Col sm="3" className={`text-capitalize font-weight-bold ${(!values.selectedVariants?.some(item => item.name === option.name) && errors.selectedVariants) ? "text-danger" : ""}`}>
                  {option.name}<span className='text-danger'>*</span>
                </Col>

                <Col sm="9">

                  <div className='radio-inline'>
                    {option.aspects?.map((aspect, i) => (
                      <label className="radio text-capitalize mb-0 mr-5" key={i}>
                        <input type="radio" name={option.name} value={aspect}
                          checked={isSelected(aspect, option.name, selectedVariants)}
                          onChange={event => handleCheckboxChange(event, aspect, option.name)}
                        />
                        <span />
                        {aspect}
                      </label>
                    ))}
                  </div>

                  <span className={`text-danger ${(!values.selectedVariants?.some(item => item.name === option.name) && errors.selectedVariants) ? "visible" : "invisible "}`}>
                    <FormattedMessage id="PRODUCT.VARIANT.CHOOSE_LAST" />
                  </span>

                </Col>

              </Row>
            ))}
          </Col>

          <Col sm="6">
            <Field component={SVFormControl}
              {...{
                name: "quantity",
                type: "number",
                label: intl({ id: 'PRODUCT.GENERAL.QUANTITY' }),
                min: 0,
                feedbackLabel: true,
                required: true,
              }}
            />
          </Col>

          <Col sm="6">
            <Field component={SVFormControl}
              {...{
                name: "gross_price",
                type: "number",
                label: intl({ id: 'PRODUCT.GENERAL.SALE_PRICE_GROSS' }),
                min: 0,
                feedbackLabel: true,
                required: true,
              }}
            />
          </Col>

          <button type="submit" className='d-none' ref={submitBtnRef} onClick={handleSubmit} />
        </Form>
      )}
    </Formik>
  );
};