import React from "react";
import ActionTypes from "./actionTypes";
import { toast } from "react-toastify";
import { FormattedMessage } from "react-intl";




export class CustomerActions {

  // Create Customer
  static createCustomer = (customer) => {
    return {
      type: ActionTypes.CREATE_CUSTOMER,
      payload: {
        customer
      },
    };
  };

  static createCustomerSuccess = (customer) => {
    // toast.success(<FormattedMessage id='CUSTOMER.REDUX.CREATE_CUSTOMER.SUCCESS' values={{ customer: customer.name }} />);
    toast.success(<FormattedMessage id='CUSTOMER.REDUX.CREATE_CUSTOMER.SUCCESS' />);
    return {
      type: ActionTypes.CREATE_CUSTOMER_SUCCESS,
      payload: {
        customer
      },
    };
  };

  static createCustomerFail = () => {
    return {
      type: ActionTypes.CREATE_CUSTOMER_FAIL,
    };
  };



  // Find Customer By Property
  static findCustomerByProperty = (key, value, customerId) => {
    return {
      type: ActionTypes.FIND_CUSTOMER_BY_PROPERTY,
      payload: {
        key,
        value,
        customerId,
      },
    };
  };

  static findCustomerByPropertySuccess = (customer) => {
    return {
      type: ActionTypes.FIND_CUSTOMER_BY_PROPERTY_SUCCESS,
      payload: {
        customer
      },
    };
  };

  static findCustomerByPropertyFail = () => {
    return {
      type: ActionTypes.FIND_CUSTOMER_BY_PROPERTY_FAIL,
    };
  };



  // Update Customer
  static updateCustomer = (customer) => {
    return {
      type: ActionTypes.UPDATE_CUSTOMER,
      payload: {
        customer
      },
    };
  };

  static updateCustomerSuccess = () => {
    toast.success(<FormattedMessage id="CUSTOMER.REDUX.UPDATE_CUSTOMER.SUCCESS" />);
    return {
      type: ActionTypes.UPDATE_CUSTOMER_SUCCESS,
    };
  };

  static updateCustomerFail = () => {
    toast.error(<FormattedMessage id="CUSTOMER.REDUX.UPDATE_CUSTOMER.FAIL" />);
    return {
      type: ActionTypes.UPDATE_CUSTOMER_FAIL,
    };
  };



  // Get Customers
  static getCustomers = (queryParams) => {
    return {
      type: ActionTypes.GET_CUSTOMERS,
      payload: {
        queryParams,
      },
    };
  };

  static getCustomersSuccess = (customers, count) => {
    return {
      type: ActionTypes.GET_CUSTOMERS_SUCCESS,
      payload: {
        customers,
        count,
      },
    };
  };

  static getCustomersFail = () => {
    return {
      type: ActionTypes.GET_CUSTOMERS_FAIL,
    };
  };



  // Get Customer by id
  static getCustomerById = (id) => {
    return {
      type: ActionTypes.GET_CUSTOMER_BY_ID,
      payload: {
        id
      },
    };
  };

  static getCustomerByIdSuccess = (customer) => {
    return {
      type: ActionTypes.GET_CUSTOMER_BY_ID_SUCCESS,
      payload: {
        customer
      },
    };
  };

  static getCustomerByIdFail = () => {
    return {
      type: ActionTypes.GET_CUSTOMER_BY_ID_FAIL,
    };
  };



  // Delete Customer
  static deleteCustomer = (ids, queryParams) => {
    return {
      type: ActionTypes.DELETE_CUSTOMER,
      payload: {
        ids,
        queryParams
      },
    };
  };

  static deleteCustomerSuccess = (ids) => {
    toast.success(<FormattedMessage id="CUSTOMER.REDUX.DELETE_CUSTOMER.SUCCESS" />);
    return {
      type: ActionTypes.DELETE_CUSTOMER_SUCCESS,
      payload: {
        ids
      },
    };
  };

  static deleteCustomerFail = () => {
    toast.error(<FormattedMessage id="CUSTOMER.REDUX.DELETE_CUSTOMER.FAIL" />);
    return {
      type: ActionTypes.DELETE_CUSTOMER_FAIL,
    };
  };



  // Get Documents by Customer Id
  // static getDocumentsByCustomerId = (queryParams, customerId, filteredDocument) => {
  //   return {
  //     type: ActionTypes.GET_DOCUMENTS_BY_CUSTOMER_ID,
  //     payload: {
  //       queryParams,
  //       customerId,
  //       filteredDocument,
  //     },
  //   };
  // };

  // static getDocumentsByCustomerIdSuccess = (documents, count) => {
  //   return {
  //     type: ActionTypes.GET_DOCUMENTS_BY_CUSTOMER_ID_SUCCESS,
  //     payload: {
  //       documents,
  //       count,
  //     },
  //   };
  // };

  // static getDocumentsByCustomerIdFail = () => {
  //   return {
  //     type: ActionTypes.GET_DOCUMENTS_BY_CUSTOMER_ID_FAIL,
  //   };
  // };

  // Get Customers
  static getCustomerDocumentCounts = (customerId) => {
    return {
      type: ActionTypes.GET_CUSTOMER_DOCUMENT_COUNTS,
      payload: {
        customerId,
      },
    };
  };

  static getCustomerDocumentCountsSuccess = (counts) => {
    return {
      type: ActionTypes.GET_CUSTOMER_DOCUMENT_COUNTS_SUCCESS,
      payload: {
        counts
      },
    };
  };

  static getCustomerDocumentCountsFail = () => {
    return {
      type: ActionTypes.GET_CUSTOMER_DOCUMENT_COUNTS_FAIL,
    };
  };


  // Clean Error Handler
  static cleanErrorHandler = () => {
    return {
      type: ActionTypes.CLEAN_ERROR_HANDLER,
    };
  };


  // Reset Form Handler
  static resetFormHandler = () => {
    return {
      type: ActionTypes.RESET_CUSTOMER_FORM,
    };
  };

  // Clean Handler
  static cleanCustomer = () => {
    return {
      type: ActionTypes.CLEAN_CUSTOMER,
    };
  };

  // Clear Loading Handler
  static clearLoadingEffects = () => {
    return {
      type: ActionTypes.CLEAR_LOADING_EFFECTS,
    };
  };

  static cleanStatesInStore = (cleanedStates) => {
    return {
      type: ActionTypes.CLEAN_STATES_IN_STORE,
      payload: {
        cleanedStates
      },
    };
  };
}