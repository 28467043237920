import React, { useEffect, useRef } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { LABEL_OPTIONS, getShipperOptions } from 'app/modules/documents/_context/DocumentsUIHelpers';
import { DHL_ACCOUNT_OPTIONS } from 'app/modules/connections/_context/SettingsUIHelpers';
import { UserInfoDropdown } from '_metronic/_partials';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { ShippingAddForm } from './ShippingAddForm';
import { useFormikContext } from 'formik';
import { Icon } from '_metronic/_icons';
import { theme } from '_metronic/_helpers';



export function ShippingAdd({ UIProps }) {

  const submitByRef = useRef();
  const submitByRefClick = () => {
    submitByRef.current && submitByRef.current.click();
  };
  //delivery address değişirse güncel addresi gönder
  const { default_shipping, connections } = useSelector(state => ({
    default_shipping: state.documents.userSettings.default_shipping,
    connections: state.connections.entities,
  }), shallowEqual);

  const { values: { type, profile_name, labeled }, setFieldValue } = useFormikContext();
  const isLabeled = labeled === 'labeled'

  useEffect(() => {
    const foundProfileName = default_shipping?.find(item => item?.profile_name === profile_name);

    if (foundProfileName?.unlabeled) {
      setFieldValue("labeled", 'unlabeled');
    } else if (foundProfileName) {
      setFieldValue("labeled", 'labeled');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile_name]);

  //DHL
  const myShipper = connections?.filter((item) => item?.token_type === 'shipper') || [];
  const dhlSubscriptions = connections.find((item) => item?.name === 'dhl_new')?.dhl?.subscriptions || [];
  const dhlAccountOptions = dhlSubscriptions.map(item => {
    const baseOption = DHL_ACCOUNT_OPTIONS?.find((opt) => opt.accountNumber === item.accountNumber);
    return baseOption;
  })

  const newOptions = myShipper?.map((item) => {
    const baseOption = LABEL_OPTIONS?.find((opt) => opt.value === item.name);
    if (item.name === 'dhl') {
      const baseOptionWarenpost = LABEL_OPTIONS?.find((opt) => opt.value === "dhl_warenpost");
      return [baseOption, baseOptionWarenpost].filter(option => option); // Boş olmayanları filtrele
    }
    if (item.name === 'dhl_new') {
      const baseOptionWarenpost = LABEL_OPTIONS?.find((opt) => opt.value === "dhl_new_warenpost" && dhlAccountOptions.some(item => item?.type === 'dhl_new_warenpost'));
      return [baseOption, baseOptionWarenpost].filter(option => option); // Boş olmayanları filtrele
    }
    return baseOption;
  }).flat().filter(i => type === "return" ? i.return : i) || [];

  //Profile Name Options
  const labeledDefaultNameOptions = default_shipping?.filter(item => !item.unlabeled).map(item => {
    const baseOption = newOptions?.find((opt) => opt.value === item.service);
    if (baseOption) {
      return {
        ...baseOption,
        value: item.profile_name,
      };
    }
    return null;
  }).filter(item => item !== null) || [];

  const unlabeledDefaultNameOptions = default_shipping?.filter(item => item.unlabeled).map(item => {
    const baseOption = getShipperOptions()?.find((opt) => opt.value === item.service);
    if (baseOption) {
      return {
        ...baseOption,
        value: item.profile_name,
        icon: <Icon.SupplierFIll />,
      };
    }
    return null;
  }).filter(item => item !== null) || [];

  const combinedProfileOptions = [...labeledDefaultNameOptions, ...unlabeledDefaultNameOptions];

  const currentProfileName = combinedProfileOptions?.find(i => i.value === profile_name)


  return (
    <>
      <Modal.Header>

        <Modal.Title>
          <FormattedMessage id='GENERAL.SHIPPING' />
          <UserInfoDropdown description={<FormattedMessage id='DOCUMENT.SHIPPING.CREATE_LABEL.INFO' />} />
        </Modal.Title>

        <Dropdown className="dropdown-inline" drop="down">

          <Dropdown.Toggle variant="outline-secondary" className="d-flex justify-content-between align-items-center text-left font-weight-bold" style={{ height: theme.units.input.height['md'] }} id="dropdown-service">
            {!profile_name || !currentProfileName
              ? <span className='text-muted'>
                <FormattedMessage id="DOCUMENT.SHIPPING_PROFILE_NAME.PLACEHOLDER" />
              </span>
              : <span className='d-flex align-items-center pr-5'>
                <span className="svg-icon svg-icon-xl mr-2">{currentProfileName?.icon}</span>
                <span className="navi-text text-nowrap">{currentProfileName?.value}</span>
              </span>
            }
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu-md" id="dropdown_service_options">
            <ul className="navi navi-hover">
              {combinedProfileOptions.length
                ? <>
                  {combinedProfileOptions?.map((opt, index) => (
                    <li className="navi-item" key={index}>
                      <Dropdown.Item className="navi-link py-2" onClick={() => setFieldValue("profile_name", opt.value)} id="dropdown_item_profile_name">
                        <span className="svg-icon svg-icon-xl mr-1">{opt.icon}</span>
                        <span className="navi-text text-nowrap font-weight-bold">{opt.value}</span>
                      </Dropdown.Item>
                    </li>
                  ))}
                  <Dropdown.Divider />
                  <li className="navi-item">
                    <Dropdown.Item
                      className='navi-link font-weight-bolder text-danger justify-content-center bg-white py-1'
                      onClick={() => { setFieldValue("profile_name", ''); setFieldValue("type", 'shipment'); setFieldValue("service", ''); setFieldValue("labeled", 'labeled'); setFieldValue("price", ''); setFieldValue("weight", '') }}
                      id='clear-date'
                    >
                      <FormattedMessage id='GENERAL.CLEAR' />
                    </Dropdown.Item>
                  </li>
                </>
                : <li className="navi-item px-3">
                  <span className="svg-icon svg-icon-sm text-info text-hover-primary mr-2">
                    <Icon.InfoCircle />
                  </span>
                  <span className="navi-text">
                    <FormattedHTMLMessage id="DOCUMENT.SHIPPING.NO_PROFILE_NAME" values={{ url: ModuleRoutes.SETTINGS_SHIPPING, a: chunks => <a href='/' id="btn_add_profile_name">{chunks}</a> }} />
                  </span>
                </li>
              }
            </ul>
          </Dropdown.Menu>
        </Dropdown>

      </Modal.Header>

      <Modal.Body>
        <ShippingAddForm isLabeled={isLabeled} newOptions={newOptions} dhlAccountOptions={dhlAccountOptions} documentId={UIProps.documentId} />
        <button type="submit" className='d-none' ref={submitByRef} id="btn_submit"/>
      </Modal.Body>

      <Modal.Footer>

        <Button variant='light' onClick={UIProps.onHide} id="btn_cancel_modal">
          <FormattedMessage id="GENERAL.CANCEL" />
        </Button>

        <Button variant='primary' className="ml-2" onClick={submitByRefClick} id="btn_save_modal">
          <FormattedMessage id={isLabeled ? "DOCUMENT.SHIPPING.CREATE_LABEL" : "GENERAL.SAVE"} />
        </Button>

      </Modal.Footer>
    </>
  );
}
