import React from 'react'
import { EmailLogsUIProvider } from '../_context/EmailLogsUIContext'
import EmailLogsCard from './EmailLogsCard'
import PrintDocumentDialog from './email-log-dialogs/PrintDocumentDialog'
import { SendEmailsDialog } from './email-log-dialogs/SendEmailsDialog'
import { PrintLabelsDialog } from './email-log-dialogs/PrintLabelsDialog'



export default function EmailLogsPage() {
  return (
    <EmailLogsUIProvider>
      <PrintDocumentDialog />
      <PrintLabelsDialog />
      <SendEmailsDialog />
      <EmailLogsCard />
    </EmailLogsUIProvider>
  )
}
