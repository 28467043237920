
export const validator = {

  isValidPrice: (cell, intl) => {
    if (cell.toString().includes(',')) {
      cell = +cell.replace(',', '.');
    }
    if (isNaN(cell)) {
      return {
        valid: false,
        message: intl({ id: "DOCUMENT.POSITION.IS_VALID.PRICE" })
      };
    }
    if (+cell <= 0) {
      return {
        valid: false,
        message: intl({ id: "PRODUCT.GENERAL.PRICE_THAN" })
      };
    }

    if (+cell > 999999) {
      return {
        valid: false,
        message: intl({ id: "DOCUMENT.SHIPPING.MAX_PRICE" })
      };
    }
  },

  isValidWeight: (cell, service, intl) => {

    if (cell.toString().includes(',')) {
      cell = +cell.replace(',', '.');
    }

    const weightRegex = /^\d+(\.\d{1,3})?$/;

    if (isNaN(cell) || !weightRegex.test(cell.toString())) {
      return {
        valid: false,
        message: intl({ id: 'DOCUMENT.SHIPPING.VALID_WEIGHT' }),
      };
    }

    if (cell < 0.001) {
      return {
        valid: false,
        message: intl({ id: 'DOCUMENT.SHIPPING.VALID_WEIGHT_MIN' }),
      };
    }

    const weightString = cell.toString();
    const decimalPlaces = (weightString.split('.')[1] || '').length;

    if (decimalPlaces > 3) {
      return {
        valid: false,
        message: intl({ id: 'DOCUMENT.SHIPPING.VALID_WEIGHT_DECIMAL' }),
      };
    }

    if ((service === 'dhl_warenpost' || service === 'dhl_new_warenpost') && cell > 1) {
      return {
        valid: false,
        message: intl({ id: 'DOCUMENT.SHIPPING.VALID_WEIGHT_MAX' }),
      };
    }

  },

  isValidDescription : (cell, row, intl) => {
    if (cell && cell.length > 50) {
      return {
        valid: false,
        message: intl({ id: "DOCUMENT.SHIPPING.CUSTOMS.IS_VALID.DESCRIPTION" })
      };
    }
  },
};
