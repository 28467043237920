import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader } from "_metronic/_partials/controls";
import { ArchiveFilter } from "./archive-filter/ArchiveFilter";
import { ArchiveTable } from "./archive-table/ArchiveTable";
import { UserGuideHelper } from "../../user-guides/UserGuideHelper";
import { BlankMessage } from "_metronic/_partials/components";
import { useArchiveUIContext } from "./_context/ArchiveUIContext";
import { useSearchQueryContext } from "./_context/SearchQueryProvider";
import { LoadingDialog } from "_metronic/_partials/loading";
import { ArchiveActions } from './_redux/actions';
import { FormattedMessage } from "react-intl";
import { Icon } from '_metronic/_icons';



export function ArchiveCard() {

  const dispatch = useDispatch();
  const queryContext = useSearchQueryContext();

  const { queryParams, setOrderQuery, setPaginationQuery, setSearchQuery, setFilterQuery } = useMemo(() => ({
    queryParams: queryContext.queryParams,
    setOrderQuery: queryContext.setOrderQuery,
    setPaginationQuery: queryContext.setPaginationQuery,
    setSearchQuery: queryContext.setSearchQuery,
    setFilterQuery: queryContext.setFilterQuery,
  }), [queryContext]);

  const UIContext = useArchiveUIContext();
  const UIProps = useMemo(() => ({
    requestedArchive: UIContext.requestedArchive,
    setRequestedArchive: UIContext.setRequestedArchive,
    dateRange: UIContext.dateRange,
  }),
    [UIContext]
  );

  useEffect(() => {
    let delayDebounceFn;
    if (queryParams.search) {
      delayDebounceFn = setTimeout(() => {
        dispatch(ArchiveActions.getArchive(
          queryParams?.filter.doc_type
            ? queryParams?.filter.doc_type
            : UIProps.requestedArchive,
          queryParams, UIProps.dateRange
        ));
      }, 350);
    } else {
      dispatch(ArchiveActions.getArchive(
        queryParams?.filter.doc_type
          ? queryParams?.filter.doc_type
          : UIProps.requestedArchive,
        queryParams, UIProps.dateRange
      ));
    }
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [queryParams, UIProps.requestedArchive, UIProps.dateRange, dispatch]);

  // Getting current state of customers list from store (Redux)
  const { documents, loading, countDocuments, totalDocuments } = useSelector(state => ({
    documents: state.archive.documents,
    loading: state.archive.loading,
    countDocuments: state.archive.countDocuments,
    totalDocuments: state.archive.totalDocuments,
  }),
    shallowEqual
  );


  return (
    <Card className="card-height">

      <CardHeader
        icon={<Icon.Archive />}
        title={<FormattedMessage id="ARCHIVE.TITLE" />}
        info={<UserGuideHelper />}
        className="display-5"
        id='title_archive'
      >
      </CardHeader>

      <CardBody>
        <ArchiveFilter setSearchQuery={setSearchQuery} setFilterQuery={setFilterQuery} UIProps={UIProps} queryParams={queryParams} totalDocuments={totalDocuments}/>
        {totalDocuments === 0
          ? <BlankMessage
            icon={<Icon.Archive />}
            title={<FormattedMessage id='ARCHIVE.BLANK_MESSAGE_TITLE' />}
            message={<FormattedMessage id='ARCHIVE.BLANK_MESSAGE' />}
            className='min-50-height'
            loading={loading}
          />
          : <ArchiveTable
            queryParams={queryParams}
            setPaginationQuery={setPaginationQuery}
            setOrderQuery={setOrderQuery}
            documents={documents}
            loading={loading}
            countDocuments={countDocuments}
            setSearchQuery={setSearchQuery}
          />
        }
      </CardBody>

      <LoadingDialog isLoading={loading} />

    </Card>
  );
}
