import React from 'react'
import { AddressFilter } from './AddressFilter'
import { AddressTable } from './AddressTable'
import { AddressEditDialog } from './edit/AddressEditDialog'



export function AddressForm() {
  return (
    <>
      <AddressFilter />
      <div className='mb-5' />
      <AddressTable />
      <AddressEditDialog />
    </>
  )
}
