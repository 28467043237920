import ActionTypes from "./actionTypes";



const initialDocumentSettingsForEdit = {
  settings_type: '',
  default_subtitle: {
    header: "Vielen Dank, dass Sie sich bei uns entschieden haben.",
    footer: "Bei Fragen oder Problemen zu Ihrer Rechnung helfen wir Ihnen gerne weiter.\nWir wünschen Ihnen einen schönen Tag."
  },
  default_payment: {
    method: "",
  },
  default_shipping: [],
  default_number_range: {
    prefix: "",
    date: "YYYY",
    min_length: null,
    next_value: "",
  },
  default_product: {
    tax: "",
    category: "",
    unit: "",
  },
  stock_control: {
    kaufland: false,
    amazon: false,
    ebay: false,
  },
  print_details: {
    payment_method: true,
  },
  variant_options: [],
  digital_product: {
    mail_template: "Bitte teilen Sie Ihren Lizenzschlüssel mit niemandem und bewahren Sie ihn sicher auf. Ein Lizenzschlüssel ist wie ein digitaler Schlüssel zu Ihrem Produkt und sollte vertraulich behandelt werden, um unbefugte Nutzung zu verhindern.",
    status: false,
    trigger: "is_order",
    contact_email: "",
  },
  // Default Dashboard Card View
  default_card_view: {
    orders: true,
    sales: true,
    open_invoices: true,
    sales_by_channel: true,
    sales_by_country: true,
    sales_volume: true,
    top_products: true,
    top_customers: true,
  },
};

const INITIAL_STATE = {
  loading: false,
  error: "",
  entities: [],
  entitiesForEdit: {},
  documentSettingsForEdit: initialDocumentSettingsForEdit,
  initial: {},
  document_count: 0,
};



export function settingsReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {


    // Create Document Settings
    case ActionTypes.CREATE:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        documentSettingsForEdit: payload.userSettings
      };

    case ActionTypes.CREATE_FAIL:
      return {
        ...state,
        loading: false,
        access: {},
        error: "GENERAL.ERROR_MESSAGE_GENERAL",
      };

    // Get Document Settings by Type
    case ActionTypes.GET_BY_TYPE:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.GET_BY_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        documentSettingsForEdit: {
          ...state.documentSettingsForEdit,
          ...payload.userSettings
        },
      };

    case ActionTypes.GET_BY_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: "GENERAL.ERROR_MESSAGE_GENERAL",
      };



    // Delete Document Settings
    case ActionTypes.DOCUMENT_COUNT:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.DOCUMENT_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        document_count: payload.count
      };

    case ActionTypes.DOCUMENT_COUNT_FAIL:
      return {
        ...state,
        loading: false,
        error: "GENERAL.ERROR_MESSAGE_GENERAL",
      };



    // Update Document Settings
    case ActionTypes.UPDATE:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case ActionTypes.UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        error: "GENERAL.ERROR_MESSAGE_GENERAL",
      };


    // Patch User Settings
    case ActionTypes.PATCH_USER_SETTINGS:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.PATCH_USER_SETTINGS_SUCCESS:

      return {
        ...state,
        loading: false,
        documentSettingsForEdit: {
          ...state.documentSettingsForEdit,
          variant_options: payload.settings?.variant_options,
          default_shipping: payload.settings?.default_shipping ?? [],
          default_card_view: payload.settings?.default_card_view ?? state.documentSettingsForEdit.default_card_view,
        }
      };

    case ActionTypes.PATCH_USER_SETTINGS_FAIL:
      return {
        ...state,
        loading: false,
        documentSettingsForEdit: {
          ...state.documentSettingsForEdit,
          default_shipping: [],
        },
        error: "GENERAL.ERROR_MESSAGE_GENERAL",
      };



    //Variant Options
    /*     case ActionTypes.VARIANT_OPTIONS_HANDLER:
          return {
            ...state,
            documentSettingsForEdit: {
              ...state.documentSettingsForEdit,
               variant_options: payload.variantOptions,
            },
          }; */


    // Clear Document Settings
    case ActionTypes.CLEAR_LOADING_EFFECTS:
      return {
        ...state,
        loading: false,
      };

    case ActionTypes.CLEAR_STATE:
      return {
        ...state,
        documentSettingsForEdit: {
          ...initialDocumentSettingsForEdit,
          // Keep the status of digital product
          digital_product: {
            ...initialDocumentSettingsForEdit.digital_product,
            status: state.documentSettingsForEdit.digital_product.status,
          }
        },
      };



    default:
      return state;
  }
}
