export const INITIAL_VALUES = {

  amazon: {
    name: 'amazon',
    token_type: 'marketplace',
    amazon: {
      MarketplaceID: '',
      MerchantToken: '',
      AuthToken: '',
      market_name: '',
      prime: false,
      invoice_upload: false,
      credit_upload: false,
    },
    check: '',
    error: '',
  },

  check24: {
    name: 'check24',
    token_type: 'marketplace',
    check24: {
      FTPHost: '',
      FTPPort: '',
      Benutzername: '',
      Passwort: '',
    },
    check: '',
    error: '',
  },

  ebay: {
    name: 'ebay',
    token_type: 'marketplace',
    ebay: {
      market_name: '',
      token_expires: '',
      accessToken: '',
      website: '',
      account_type: '',
    },
    location: [],
    policies: {},
    check: '',
    error: '',
  },

  etsy: {
    name: 'etsy',
    token_type: 'marketplace',
    etsy: {
      website: '',
      market_name: '',
      client_id: '',
      shared_secret: ''
    },
    check: '',
    error: '',
  },

  etsyConnection: {
    website: '',
    client_id: '',
    shared_secret: '',
    market_name: '',
    verifier: '',
    connect_url: '',
  },

  hood: {
    name: 'hood',
    token_type: 'marketplace',
    hood: {
      user_name: '',
      password: '',
    },
    check: '',
    error: '',
  },

  kaufland: {
    name: 'kaufland',
    token_type: 'marketplace',
    kaufland: {
      ClientKey: '',
      SecretKey: '',
      market_name: '',
      token_expires: '',
      website: '',
      invoice_upload: false,
    },
    check: '',
    error: '',
  },

  manomano: {
    name: 'manomano',
    token_type: 'marketplace',
    manomano: {
      benutzerName: '',
      passwort: '',
    },
    check: '',
    error: '',
  },

  otto: {
    name: 'otto',
    token_type: 'marketplace',
    otto: {
      username: '',
      password: '',
    },
    check: '',
    error: '',
  },


  prestashop: {
    name: 'prestashop',
    token_type: 'shop',
    prestashop: {
      website: '',
      token: '',
    },
    check: '',
    error: '',
  },

  shopify: {
    name: 'shopify',
    token_type: 'shop',
    shopify: {
      website: '',
      market_name: '',
      token_expires: '',
      access_token: '',
    },
    check: '',
    error: '',
  },

  shopware5: {
    name: 'shopware5',
    token_type: 'shop',
    shopware5: {
      website: '',
      username: '',
      token: '',
      market_name: '',
      token_expires: '',
    },
    check: '',
    error: '',
  },

  shopware6: {
    name: 'shopware6',
    token_type: 'shop',
    shopware6: {
      website: '',
      client_id: '',
      client_secret: '',
    },
    check: '',
    error: '',
  },

  woocommerce: {
    name: 'woocommerce',
    token_type: 'shop',
    woocommerce: {
      website: '',
      consumer_key: '',
      consumer_secret: '',
    },
    check: '',
    error: '',
  },

  deutsche_post: {
    name: 'deutsche_post',
    token_type: 'shipper',
    deutsche_post: {
      Benutzername: '',
      Kennwort: '',
      Layout: '',
      product_list: [],
    },
    check: '',
    error: '',
  },

  dhl: {
    name: 'dhl',
    token_type: 'shipper',
    dhl: {
      customerNumber: '',
      username: '',
      password: '',
      shipperReference: '',
      subscriptions: [],
    },
    check: '',
    error: '',
  },

  dhl_new: {
    name: 'dhl_new',
    token_type: 'shipper',
    dhl: {
      customerNumber: '',
      username: '',
      password: '',
      shipperReference: '',
      subscriptions: [],
    },
    check: '',
    error: '',
  },

  dpd: {
    name: 'dpd',
    token_type: 'shipper',
    dpd: {
      cloud_user_id: '',
      token: '',
    },
    check: '',
    error: '',
  },

  gls: {
    name: 'gls',
    token_type: 'shipper',
    gls: {
      contact_id: '',
      user_name: '',
      password: '',
      customer_number: '',
    },
    check: '',
    error: '',
  },
};



export const setConnectionByName = (connection) => {
  switch (connection.name) {
    case 'amazon':
      return { amazonForEdit: connection };
    case 'check24':
      return { check24ForEdit: connection };
    case 'ebay':
      return { ebayForEdit: connection };
    case 'etsy':
      return { etsyForEdit: connection };
    case 'hood':
      return { hoodForEdit: connection };
    case 'kaufland':
      return { kauflandForEdit: connection };
    case 'manomano':
      return { manomanoForEdit: connection };
    case 'otto':
      return { ottoForEdit: connection };

    case 'prestashop':
      return { prestashopForEdit: connection };
    case 'shopify':
      return { shopifyForEdit: connection };
    case 'shopware5':
      return { shopware5ForEdit: connection };
    case 'shopware6':
      return { shopware6ForEdit: connection };
    case 'woocommerce':
      return { woocommerceForEdit: connection };

    case 'deutsche_post':
      return { deutschePostForEdit: connection };
    case 'dpd':
      return { dpdForEdit: connection };
    case 'dhl':
      return { dhlForEdit: connection };
    case 'dhl_new':
      return { dhlNewForEdit: connection };
    case 'gls':
      return { glsForEdit: connection };

    default:
      return {};
  }
};


export const connectionForEdit = (connectionName) => {
  switch (connectionName) {
    case 'amazon':
      return "amazonForEdit";
    case 'ebay':
      return "ebayForEdit";
    case 'etsy':
      return "etsyForEdit";
    case 'shopify':
      return "shopifyForEdit";
    case 'woocommerce':
      return "woocommerceForEdit";
    case 'dpd':
      return "dpdForEdit";
    case 'gls':
      return "glsForEdit";
    case 'dhl':
      return "dhlForEdit";
    case 'dhl_new':
      return "dhlNewForEdit";
    case 'deutsche_post':
      return "deutschePostForEdit";
    case 'check24':
      return "check24ForEdit";
    case 'hood':
      return "hoodForEdit";
    case 'kaufland':
      return "kauflandForEdit";
    case 'manomano':
      return "manomanoForEdit";
    case 'otto':
      return "ottoForEdit";
    case 'prestashop':
      return "prestashopForEdit";
    case 'shopware5':
      return "shopware5ForEdit";
    case 'shopware6':
      return "shopware6ForEdit";
    default:
      return
  }
}

export const DHL_LIST = [
  {
    id: "0101-TEST",
    name: "DHL Paket (ohne Service)",
    product: 'V01PAK',
    accountNumber: `0101`,
    location: 'domestic'
  },
  {
    id: "0102-TEST",
    name: "DHL Paket (mit Service)",
    product: 'V01PAK',
    accountNumber: `0102`,
    location: 'domestic'
  },
  {
    id: "0103-TEST",
    name: "DHL Paket (mit GoGreen)",
    product: 'V01PAK',
    accountNumber: `0103`,
    location: 'domestic'
  },
  {
    id: "5301-TEST",
    name: "DHL Paket International",
    product: 'V53WPAK',
    accountNumber: `5301`,
    location: 'abroad'
  },
  {
    id: "5302-TEST",
    name: "DHL Paket International (mit GoGreen)",
    product: 'V53WPAK',
    accountNumber: `5302`,
    location: 'abroad'
  },
  {
    id: "5401-TEST",
    name: "DHL Europaket",
    product: 'V54EPAK',
    accountNumber: `5401`,
    location: 'abroad'
  },
  {
    id: "5402-TEST",
    name: "DHL Europaket (mit GoGreen)",
    product: 'V54EPAK',
    accountNumber: `5402`,
    location: 'abroad'
  },
  {
    id: "6201-TEST",
    name: "Warenpost",
    product: 'V62WP',
    accountNumber: `6201`,
    location: 'domestic'
  },
  {
    id: "6202-TEST",
    name: "Warenpost  (mit GoGreen)",
    product: 'V62WP',
    accountNumber: `6202`,
    location: 'domestic'
  },
  {
    id: "6601-TEST",
    name: "Warenpost International",
    product: 'V66WPI',
    accountNumber: `6601`,
    location: 'abroad'
  },
  {
    id: "6602-TEST",
    name: "Warenpost International",
    product: 'V66WPI',
    accountNumber: `6602`,
    location: 'abroad'
  },
  {
    id: "6603-TEST",
    name: "Warenpost International",
    product: 'V66WPI',
    accountNumber: `6603`,
    location: 'abroad'
  },
  {
    id: "6604-TEST",
    name: "Warenpost International  (mit GoGreen)",
    product: 'V66WPI',
    accountNumber: `6604`,
    location: 'abroad'
  },
  /*    {
      id: "0701",
      name: "DHL Retoure für DHL Paket und Warenpost",
      product: 'V07PAK',
      accountNumber: `0701`,
      location: 'domestic'
    },
    {
      id: "0702",
      name: "DHL Retoure (mit GoGreen)",
      product: 'V07PAK',
      accountNumber: `0702`,
      location: 'domestic'
    } */
]


export const DHL_ACCOUNT_OPTIONS = [
  {
    id: "0101-TEST",
    type: 'dhl_new',
    name: "CONNECTIONS.DHL_PARCEL.WITHOUT_SERVICE",
    name_short: "CONNECTIONS.DHL_PARCEL.WITHOUT_SERVICE_SHORT",
    product: 'V01PAK',
    accountNumber: `0101`,
    location: 'domestic',
    additional_insurance: true
  },
  {
    id: "0102-TEST",
    type: 'dhl_new',
    name: "CONNECTIONS.DHL_PARCEL.WITH_SERVICE",
    name_short: "CONNECTIONS.DHL_PARCEL.WITH_SERVICE_SHORT",
    product: 'V01PAK',
    accountNumber: `0102`,
    location: 'domestic',
    additional_insurance: true
  },
  {
    id: "0103-TEST",
    type: 'dhl_new',
    name: "CONNECTIONS.DHL_PARCEL.WITH_GOGREEN",
    name_short: "CONNECTIONS.DHL_PARCEL.WITH_GOGREEN_SHORT",
    product: 'V01PAK',
    accountNumber: `0103`,
    location: 'domestic',
    goGreen: true,
    additional_insurance: true
  },
  {
    id: "5301-TEST",
    type: 'dhl_new',
    name: "CONNECTIONS.DHL_PARCEL.INTERNATIONAL",
    name_short: "CONNECTIONS.DHL_PARCEL.INTERNATIONAL_SHORT",
    product: 'V53WPAK',
    accountNumber: `5301`,
    location: 'abroad',
    additional_insurance: true,
    premium: true,
    endorsement: true //'RETURN'
  },
  {
    id: "5302-TEST",
    type: 'dhl_new',
    name: "CONNECTIONS.DHL_PARCEL.INTERNATIONAL.WITH_GOGREEN",
    name_short: "CONNECTIONS.DHL_PARCEL.INTERNATIONAL.WITH_GOGREEN_SHORT",
    product: 'V53WPAK',
    accountNumber: `5302`,
    location: 'abroad',
    goGreen: true,
    additional_insurance: true,
    premium: true,
    endorsement: true //'RETURN'
  },
  {
    id: "5401-TEST",
    type: 'dhl_new',
    name: "CONNECTIONS.DHL_PARCEL.EUROPE",
    name_short: "CONNECTIONS.DHL_PARCEL.EUROPE_SHORT",
    product: 'V54EPAK',
    accountNumber: `5401`,
    location: 'eu',
    additional_insurance: true,
  },
  {
    id: "5402-TEST",
    type: 'dhl_new',
    name: "CONNECTIONS.DHL_PARCEL.EUROPE.WITH_GOGREEN",
    name_short: "CONNECTIONS.DHL_PARCEL.EUROPE.WITH_GOGREEN_SHORT",
    product: 'V54EPAK',
    accountNumber: `5402`,
    location: 'eu',
    goGreen: true,
    additional_insurance: true,
  },
  {
    id: "6201-TEST",
    type: 'dhl_new_warenpost',
    name: "CONNECTIONS.DHL_PARCEL.WARENPOST",
    name_short: "CONNECTIONS.DHL_PARCEL.WARENPOST_SHORT",
    product: 'V62WP',
    accountNumber: `6201`,
    location: 'domestic',
  },
  {
    id: "6202-TEST",
    type: 'dhl_new_warenpost',
    name: "CONNECTIONS.DHL_PARCEL.WARENPOST.WITH_GOGREEN",
    name_short: "CONNECTIONS.DHL_PARCEL.WARENPOST.WITH_GOGREEN_SHORT",
    product: 'V62WP',
    accountNumber: `6202`,
    location: 'domestic',
    goGreen: true,
  },
  {
    id: "6601-TEST",
    type: 'dhl_new_warenpost',
    name: "CONNECTIONS.DHL_PARCEL.WARENPOST.INTERNATIONAL",
    name_short: "CONNECTIONS.DHL_PARCEL.WARENPOST.INTERNATIONAL_SHORT",
    product: 'V66WPI',
    accountNumber: `6601`,
    location: 'abroad',
    premium: true,
  },
  {
    id: "6602-TEST",
    type: 'dhl_new_warenpost',
    name: "CONNECTIONS.DHL_PARCEL.WARENPOST.INTERNATIONAL",
    name_short: "CONNECTIONS.DHL_PARCEL.WARENPOST.INTERNATIONAL_SHORT",
    product: 'V66WPI',
    accountNumber: `6602`,
    location: 'abroad',
    premium: true,
  },
  {
    id: "6603-TEST",
    type: 'dhl_new_warenpost',
    name: "CONNECTIONS.DHL_PARCEL.WARENPOST.INTERNATIONAL",
    name_short: "CONNECTIONS.DHL_PARCEL.WARENPOST.INTERNATIONAL_SHORT",
    product: 'V66WPI',
    accountNumber: `6603`,
    location: 'abroad',
  },
  {
    id: "6604-TEST",
    type: 'dhl_new_warenpost',
    name: "CONNECTIONS.DHL_PARCEL.WARENPOST.INTERNATIONAL.WITH_GOGREEN",
    name_short: "CONNECTIONS.DHL_PARCEL.WARENPOST.INTERNATIONAL.WITH_GOGREEN_SHORT",
    product: 'V66WPI',
    accountNumber: `6604`,
    location: 'abroad',
    goGreen: true,
    premium: true,
  },
  /*   {
      id: "0701",
      name: "DHL Retoure für DHL Paket und Warenpost",
      product: 'V07PAK',
      accountNumber: `0701`,
      location: 'domestic'
    },
    {
      id: "0702",
      name: "DHL Retoure (mit GoGreen)",
      product: 'V07PAK',
      accountNumber: `0702`,
      location: 'domestic'
    } */
]