export const INITIAL_DIGITAL_PRODUCT = {
  token: '',
  order_id: '',
  order_number: '',
  marketplace: '',
  marketplace_url: '',
  sku: '',
  key: '',
  key_id: '',
  product: '',
  name: '',
  email: '',
  email_sended_date: null,
  order_date: null,
};

export const defaultSorted = [{ dataField: 'id', order: 'desc' }];