import React from 'react';
import { Route } from 'react-router-dom';
import { DocumentDeleteDialog } from './document-dialogs/DocumentDeleteDialog';
import { SendEmailsDialog } from './send-emails-dialog/SendEmailsDialog';
import { PaymentReminderDialog } from './payment-reminder-dialog/PaymentReminderDialog';
import { EnterPaymentDialog } from './enter-payment-dialog/EnterPaymentDialog';
import { ConvertingDocumentsDialog } from './document-convert/ConvertingDocumentsDialog';
import { CustomerDetailsDialog } from './document-dialogs/CustomerDetailsDialog';
import { DocumentDetailsDialog } from './document-dialogs/DocumentDetailsDialog';
import { PrintLabelsDialog } from './label-setting-dialog/PrintLabelsDialog';
import { ConvertDocumentAlertDialog } from './document-convert/ConvertDocumentAlertDialog';
import { DocumentLocalUIContextProvider } from './_context/DocumentLocalUIContext';
import { ShippingAddFormGroping } from './document-edit/shipping/shipping-add/ShippingAddFormGruping';
import { ShippingDeleteDialog } from './document-edit/shipping/shipping-dialogs/ShippingDeleteDialog';
import { SendEmailDialog } from './document-edit/shipping/shipping-dialogs/SendEmailDialog';
import { PaymentWarningsTable } from './payment-reminder-dialog/PaymentWarningsTable';
import { PaymentWarningsDeleteDialog } from './payment-reminder-dialog/PaymentWarningsDeleteDialog';
import { ConvertDocumentInfoDialog } from './document-convert/ConvertDocumentInfoDialog';
import { ShippingAddDialog } from './document-edit/shipping/shipping-add/ShippingAddDialog';
import { ShippingTableDialog } from './document-edit/shipping/ShippingTableDialog';
import PrintDocument from './document-template/PrintDocument';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { getLastSearch } from '_metronic/_helpers';
import { DocumentsCard } from './DocumentsCard';
import { DocumentExportDialog } from './document-dialogs/DocumentExportDialog';



export function DocumentsPage({ moduleName }) {
  const lastSearch = getLastSearch()
  return (
    <DocumentLocalUIContextProvider>
      <SendEmailsDialog />
      <CustomerDetailsDialog />
      <DocumentDetailsDialog />
      <ConvertDocumentAlertDialog />
      <ConvertDocumentInfoDialog />
      <PrintLabelsDialog />
      <ShippingAddDialog />
      <ShippingAddFormGroping />
      <ShippingTableDialog />
      <ShippingDeleteDialog />
      <SendEmailDialog />
      <PaymentWarningsTable />
      <PaymentWarningsDeleteDialog />
      <ConvertingDocumentsDialog />
      <DocumentDeleteDialog />
      <PrintDocument />
      <DocumentExportDialog />

      <Route path={ModuleRoutes.DOCUMENT_PAYMENT_REMINDER_FN(moduleName)}>
        {({ history, match }) => (
          <PaymentReminderDialog
            show={match != null}
            id={match && match.params.id}
            type={match && match.params.type}
            onHide={() => history.push(ModuleRoutes.DOCUMENT_NAME_FN(moduleName + lastSearch))}
          />
        )}
      </Route>

      <Route path={ModuleRoutes.DOCUMENT_ENTER_PAYMENT_FN(moduleName)}>
        {({ history, match }) => (
          <EnterPaymentDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => history.push(ModuleRoutes.DOCUMENT_NAME_FN(moduleName + lastSearch))}
          />
        )}
      </Route>
      
      <DocumentsCard />
    </DocumentLocalUIContextProvider>
  );
}
