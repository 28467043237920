import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap'
import { SettingsActions } from '../_redux/actions';
import { useUserSettingsUIContext } from '../_context/UserSettingsUIContext';
import { ShippingSettingsDialog } from './settings-dialog/ShippingSettingsDialog';
import { ShippingSettingsCard } from './ShippingSettingsCard';
import { useSearchQuery } from 'app/hooks';



export function ShippingSettings() {

  const dispatch = useDispatch();

  const [queryParams, setQueryBase] = useState({
    // pagination: {
    //   page: 1,
    //   limit: 10,
    // },
    // order: 'id DESC',
    search: '',
    filter: null,
  });

  const setQueryParams = useCallback((params) => {
    setQueryBase(params)
  }, [])

  const { setSearchQuery, setOrderQuery, setPaginationQuery } = useSearchQuery(setQueryParams);

  const UIContext = useUserSettingsUIContext();
  const UIProps = useMemo(() => ({
    openShippingSettingDialog: UIContext.openShippingSettingDialog,

  }),
    [UIContext]
  );

  useEffect(() => {
    dispatch(SettingsActions.getUserSettingsByType('shipping'));
  }, [dispatch]);

  return (
    <>
      <ShippingSettingsDialog />

      <Row>
        <Col lg={12}>
          <ShippingSettingsCard UIProps={UIProps} queryParams={queryParams} setPaginationQuery={setPaginationQuery} setOrderQuery={setOrderQuery} setSearchQuery={setSearchQuery} />
        </Col>
      </Row>
    </>
  )
}
