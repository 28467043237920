import { API } from 'constants/apiUrl';

export const productListingsMiddleware = {
  // ---------------------------- eBay ---------------------------- //

  getEbayInventoryListingsPreparation: async function (accessToken, { market_id, categorySearch }) {
    return await API.get(`/ebay/${market_id}/inventory-listings?category_search=${categorySearch}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  },

  publishEbayInventory: async function (accessToken, payload) {
    return await API.post(
      `/ebay/publish-listing`,
      { ...payload },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
  },

  withdrawEbayInventory: async function (accessToken, payload) {
    return await API.post(
      `/ebay/withdraw-offer`,
      { ...payload },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
  },

  updateEbayPolicies: async function (accessToken, market_id) {
    return await API.get(`/ebay/policies/${market_id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  },

  getEbayProducts: async function (accessToken, market_id) {
    return await API.get(`/ebay/product/${market_id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  },

  getEbayMigrationsProducts: async function (accessToken, market_id) {
    return await API.get(`/ebay/product-migrations/${market_id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  },

  // ---------------------------- Kaufland ---------------------------- //

  getKauflandInventoryListingsPreparation: async function (accessToken, { id, market_id }) {
    return await API.get(`/kaufland/${id}/${market_id}/prepare-product`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  },

  kauflandCreateOrReplaceInventory: async function (accessToken, payload) {
    return await API.post(`/kaufland/create-inventory`, payload, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  },

  kauflandDeleteInventory: async function (accessToken, payload) {
    return await API.post(`/kaufland/delete-product`, payload, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  },

  getKauflandProducts: async function (accessToken, market_id) {
    return await API.get(`/kaufland/product/${market_id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  }
};
