/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { QuantityStyle, convertPriceCurrency } from '_metronic/_helpers';
import { getConnection } from "constants/connectionSettings";



export function Ek1PriceFormatter(cell, row, rowIndex) {
  const currency = row.pricing?.currency;
  if (row.variants && row.variants.length > 0) {

    const prices = row.variants.map(variant => variant.pricing?.purchase_price);
    const startingPrice = Math.min(...prices);
    return (
      <>
        <span className="label label-lg label-inline label-light-info font-weight-bold mr-1">* ab</span>
        <span style={{ paddingRight: "0.75rem" }}>{convertPriceCurrency(startingPrice, currency)}</span>
      </>
    )
  };
  const inventory = Array.isArray(row?.inventories) && row.inventories.length > 0 && row.inventories.some(ei => ei.status === "PUBLISHED");
  return (
    <div type={!inventory ? "button" : ""} className={!inventory ? "edit-table" : ""}>
      {convertPriceCurrency(row.pricing?.purchase_price, currency)}
    </div>
  );
}



export function SPGrossFormatter(cell, row, rowIndex) {
  const currency = row.pricing?.currency;
  if (row.variants && row.variants.length > 0) {
    const prices = row.variants.map(variant => variant.pricing?.gross_price);
    const startingPrice = Math.min(...prices);
    return (
      <>
        <span className="label label-lg label-inline label-light-info font-weight-bold mr-1">* ab</span>
        <span style={{ paddingRight: "0.75rem" }}>{convertPriceCurrency(startingPrice, currency)}</span>
      </>
    )
  };
  const inventory = Array.isArray(row?.inventories) && row.inventories.length > 0 && row.inventories.some(ei => ei.status === "PUBLISHED");
  return (
    <div type={!inventory ? "button" : ""} className={!inventory ? "edit-table" : ""}>
      {convertPriceCurrency(row.pricing?.gross_price, currency)}
    </div>
  );
}



export function SPNetFormatter(cell, row, rowIndex) {
  const currency = row.pricing?.currency;
  if (row.variants && row.variants.length > 0) {
    const prices = row.variants.map(variant => variant.pricing?.gross_price);
    const startingPrice = Math.min(...prices);
    const netPrice = (+startingPrice / (1 + +row.pricing?.tax / 100));
    return (
      <>
        <span className="label label-lg label-inline label-light-info font-weight-bold mr-1">* ab</span>
        <span>{convertPriceCurrency(netPrice, currency)}</span>
      </>
    )
  } else {
    const netPrice = (+row.pricing?.gross_price / (1 + +row.pricing?.tax / 100));
    return <span className="ml-1">{convertPriceCurrency(netPrice, currency)}</span>;
  };
}



export function Ek1PriceVariantFormatter(cell, row, pricing) {
  if (row.variants && row.variants.length > 0) {

    const prices = row.variants.map(variant => variant.pricing?.purchase_price);
    const startingPrice = Math.min(...prices);
    return (
      <div type="button" className="edit-table">
        <span className="label label-lg label-inline label-light-info font-weight-bold mr-1">* ab</span>
        <span>{convertPriceCurrency(startingPrice, pricing?.currency)}</span>
      </div>
    )
  };
  return (
    <div type="button" className="edit-table">
      {convertPriceCurrency(row.pricing?.purchase_price, pricing?.currency)}
    </div>
  );
};



export function Vk1PriceNetVariantFormatter(cell, row, pricing) {
  const netPrice = (+row.pricing?.gross_price / (1 + +pricing.tax / 100));
  return <span className="ml-1">{convertPriceCurrency(+netPrice, pricing.currency)}</span>;
};



export function Vk1PriceBruttoVariantFormatter(cell, row, pricing) {

  if (row.variants && row.variants.length > 0) {
    const prices = row.variants.map(variant => variant.pricing?.gross_price);
    const startingPrice = Math.min(...prices);
    return (
      <div type="button" className="edit-table">
        <span className="label label-lg label-inline label-light-info font-weight-bold mr-1">* ab</span>
        <span>{convertPriceCurrency(startingPrice, pricing?.currency)}</span>
      </div>
    )
  };
  return (
    <div type="button" className="edit-table">
      {convertPriceCurrency(row.pricing?.gross_price, pricing?.currency)}
    </div>
  );
}



export function QuantityFormatter(cell, row) {
  let quantity = 0;

  if (row.variants && row.variants.length > 0) {
    quantity = row.variants.reduce((acc, variant) => acc + +variant.quantity, 0);
  } else {
    quantity = row.quantity;
  }
  const inventory = Array.isArray(row?.inventories) && row.inventories.length > 0 && row.inventories.some(ei => ei.status === "PUBLISHED");

  return (QuantityStyle(quantity, inventory || row.variants?.length > 0 || row.category.key === "digital_product"));
}



export function TaxFormatter(cell, row) {
  const inventory = Array.isArray(row?.inventories) && row.inventories.length > 0 && row.inventories.some(ei => ei.status === "PUBLISHED");
  return <span type={!inventory ? "button" : ""} className={`ml-1 ${!inventory ? "edit-table" : ""}`}>{row.pricing?.tax + ' %'} </span>
}



export function editableFormatter(cell, row) {
  const inventory =
    Array.isArray(row?.inventories) &&
    row.inventories.length > 0 &&
    row.inventories.some(ei => ei.status === "PUBLISHED");

  return (
    ((row.variants && row.variants.length > 0) || inventory) ? false : true
  );
}

export function editableQuantity(cell, row) {
  const inventory =
    Array.isArray(row?.inventories) &&
    row.inventories.length > 0 &&
    row.inventories.some(ei => ei.status === "PUBLISHED");

  return (
    ((row.variants && row.variants.length > 0) || inventory || row.category.key === "digital_product") ? false : true
  );
}



export function editableFormatter2(cell, row) {

  const inventory =
    Array.isArray(row?.inventories) &&
    row.inventories.length > 0 &&
    row.inventories.some(ei => ei.status === "PUBLISHED");

  return (
    inventory ? false : true
  );
}

export function StatusColumnFormatter(cell, row, rowIndex) {
  return (
    <div className="symbol-group justify-content-center">
      {cell?.map((inventory) => {  
        const market = getConnection((inventory.market).toLowerCase());
        return (
          <OverlayTrigger key={(inventory.market).toLowerCase()} overlay={<Tooltip id="marketplace-tooltip">{market.label}</Tooltip>}>
            <span className="symbol d-flex mr-4 bg-white svg-icon svg-icon-lg">
              {market.icon}
            </span>
          </OverlayTrigger>
        );
      })}
    </div>
  );

}