import React, { useEffect, useMemo } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ModalProgressBar } from '_metronic/_partials/loading';
import { useProductsUIContext } from '../_context/ProductsUIContext';
import { ProductActions } from '../_redux/actions';
import { FormattedMessage } from 'react-intl';
import { Portal } from 'react-portal';



export function ProductsDeleteDialog({ show, onHide, ids, queryParams }) {

  // Products UI Context
  const UIContext = useProductsUIContext();
  const UIProps = useMemo(() => ({
    setIds: UIContext.setIds,
    ids: UIContext.ids,
  }),
    [UIContext]
  );

  // Products Redux state
  const dispatch = useDispatch();

  const { entities, loading } = useSelector(state => ({
    entities: state.products.entities,
    loading: state.products.loading,
  }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    !UIProps.ids.length && onHide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UIProps.ids.length]);

  const newIds = UIProps.ids.length > 0 ? UIProps.ids : [ids];
  const publishedInventoryIds = [];

  entities.forEach(product => {
    if (product?.inventories && product.inventories?.some(inventory => inventory.status === 'PUBLISHED')) {
      publishedInventoryIds.push({ id: product.id, sku: product.sku });
    }
  });
  const idsToDelete = newIds.filter(id => !publishedInventoryIds.map(item => item.id).includes(id));

  const deleteAllSelectedProducts = () => {
    dispatch(ProductActions.deleteAllSelectedProducts(idsToDelete, queryParams));
    UIProps.setIds([]);
    onHide();
  };

  const matchedSku = publishedInventoryIds.filter(item => newIds.includes(item.id)).map(item => item.sku);
  const isDisabled = matchedSku.length > 0 && idsToDelete.length === 0 ? true : false;

  const isDigitalProduct = entities
    .filter(entity => newIds.includes(entity.id))
    .some(entity => entity.category.key === "digital_product" && entity.digital_product?.keys?.length > 0);

  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={show} onHide={onHide} size="sm" backdrop="static" aria-labelledby="example-modal-sizes-title-sm" centered>
        {loading && <ModalProgressBar variant="query" />}


        <Modal.Body className="text-center line-height-md h6 py-10 mb-0">
          {idsToDelete.length > 0 && (
            <>
              <FormattedMessage id="PRODUCT.DELETE_BODY1" />
              <span className="label label-xl label-inline label-light-danger mx-1">{idsToDelete.length}</span>
              <FormattedMessage id="PRODUCT.DELETE_BODY2" />

              {isDigitalProduct &&
                <div className='mt-2 text-danger'>
                  <FormattedMessage id="PRODUCT.MULTIPLE_DELETE.DIGITAL_PRODUCT_WARNING" />
                </div>
              }
            </>
          )}

          {/*  FIXME: */}
          {matchedSku.length > 0 && (
            <div className="mt-3">
              <p className="mb-0 text-danger"> <FormattedMessage id='PRODUCT.PRODUCT_DELETE.LIVE_PRODUCT' /></p>
              <ul className="pl-0 list-unstyled">
                {matchedSku.map((sku, index) => (
                  <li key={index}>
                    <FormattedMessage id='GENERAL.SKU' /> : <span className="text-danger">{sku}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer className="flex-nowrap p-0 overflow-hidden">

          <Button variant='ios' className="border-right" onClick={onHide} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button
            variant='ios'
            className="text-danger"
            onClick={deleteAllSelectedProducts}
            disabled={isDisabled}
            style={{ pointerEvents: isDisabled ? 'none' : 'auto' }}
            id="btn_delete_modal"
          >
            <FormattedMessage id="GENERAL.DELETE" />
          </Button>
        </Modal.Footer>

      </Modal>
    </Portal>
  );
}
