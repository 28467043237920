import React from 'react';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import ActionTypes from './actionTypes';



export class ConnectionsActions {

  // Get Settings By Id
  static getConnections = () => {
    return {
      type: ActionTypes.GET_CONNECTIONS,
    };
  };

  static getConnectionsSuccess = (connections) => {
    return {
      type: ActionTypes.GET_CONNECTIONS_SUCCESS,
      payload: {
        connections,
      },
    };
  };

  static getConnectionsFail = () => {
    return {
      type: ActionTypes.GET_CONNECTIONS_FAIL,
    };
  };



  // Get Settings By Id
  static getConnectionById = (settingsId) => {
    return {
      type: ActionTypes.GET_CONNECTION_BY_ID,
      payload: {
        settingsId,
      },
    };
  };

  static getConnectionByIdSuccess = (connection) => {
    return {
      type: ActionTypes.GET_CONNECTION_BY_ID_SUCCESS,
      payload: {
        connection,
      },
    };
  };

  static getConnectionByIdFail = () => {
    return {
      type: ActionTypes.GET_CONNECTION_BY_ID_FAIL,
    };
  };



  // Create Settings
  static createSettings = (settings) => {
    return {
      type: ActionTypes.CREATE_SETTINGS,
      payload: {
        settings,
      },
    };
  };

  static createSettingsSuccess = (settings) => {
    toast.success(<FormattedMessage id='CONNECTIONS.SHOP_SAVE.SUCCESS' values={{ shopName: settings.name.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase()) }} />);
    return {
      type: ActionTypes.CREATE_SETTINGS_SUCCESS,
      payload: {
        settings,
      },
    };
  };

  static createSettingsFail = (error) => {
    toast.error(<FormattedMessage id='PRODUCT_LISTINGS.KAUFLAND_CREATE.FAIL' />);
    return {
      type: ActionTypes.CREATE_SETTINGS_FAIL,
      payload: {
        error
      }
    };
  };



  // Update Settings
  static updateSettings = (settings) => {
    return {
      type: ActionTypes.UPDATE_SETTINGS,
      payload: {
        settings,
      },
    };
  };

  static updateSettingsSuccess = (settings) => {
    toast.success(<FormattedMessage id='CONNECTIONS.SHOP_UPDATE.SUCCESS' values={{ shopName: settings.name.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase()) }} />);
    return {
      type: ActionTypes.UPDATE_SETTINGS_SUCCESS,
      payload: {
        settings,
      },
    };
  };

  static updateSettingsFail = (error) => {
    toast.error(<FormattedMessage id='PRODUCT_LISTINGS.KAUFLAND_CREATE.FAIL' />);
    return {
      type: ActionTypes.UPDATE_SETTINGS_FAIL,
      payload: {
        error
      }
    };
  };



  // Delete Settings
  static deleteSettings = (settingsId, settingsType) => {
    return {
      type: ActionTypes.DELETE_SETTINGS,
      payload: {
        settingsId,
        settingsType,
      },
    };
  };

  static deleteSettingsSuccess = (settingsId) => {
    toast.success(<FormattedMessage id='CONNECTIONS.DELETE.SUCCESS' />);
    return {
      type: ActionTypes.DELETE_SETTINGS_SUCCESS,
      payload: {
        settingsId,
      },
    };
  };

  static deleteSettingsFail = () => {
    toast.error(<FormattedMessage id='GENERAL.FAIL' />);
    return {
      type: ActionTypes.DELETE_SETTINGS_FAIL,
    };
  };



  // connection check
  static connectionCheck = (tokenId) => {
    return {
      type: ActionTypes.CONNECTION_CHECK,
      payload: {
        tokenId
      },
    };
  };


  static connectionCheckSuccess = (response) => {
    toast.success(response?.check === 'success' && <FormattedMessage id='CONNECTIONS.CHECK.SUCCESS' />);
    return {
      type: ActionTypes.CONNECTION_CHECK_SUCCESS,
      payload: {
        response
      },
    };
  };

  static connectionCheckFail = (error) => {
    toast.error(error ? error : <FormattedMessage id='CONNECTIONS.CHECK.FAIL' />);
    return {
      type: ActionTypes.CONNECTION_CHECK_FAIL,
      payload: {
        error
      },
    };
  };


  // connection fetch orders
  static connectionFetchOrders = (market_id) => {
    return {
      type: ActionTypes.CONNECTION_FETCH_ORDERS,
      payload: {
        market_id
      },
    };
  };

  static connectionFetchOrdersSuccess = (orders) => {
    return {
      type: ActionTypes.CONNECTION_FETCH_ORDERS_SUCCESS,
      payload: {
        orders
      },
    };
  };

  static connectionFetchOrdersFail = (error) => {
    toast.error(<FormattedMessage id='CONNECTIONS.SHOP_FETCH_ORDERS.ERROR' />)
    return {
      type: ActionTypes.CONNECTION_FETCH_ORDERS_FAIL,
      payload: {
        error
      },
    };
  };


  //* --------------------- Deutsche Post --------------------- *//

  // Get Deutsche Post Configurations
  static getDeutschePostConfigs = () => {
    return {
      type: ActionTypes.GET_DEUTSCHE_POST_CONFIGS,
    };
  };

  static getDeutschePostConfigsSuccess = (configs) => {
    return {
      type: ActionTypes.GET_DEUTSCHE_POST_CONFIGS_SUCCESS,
      payload: {
        configs
      },
    };
  };

  static getDeutschePostConfigsFail = (error) => {
    return {
      type: ActionTypes.GET_DEUTSCHE_POST_CONFIGS_FAIL,
      payload: {
        error
      },
    };
  };


  //* --------------------- DHL --------------------- *//

  /*   static deleteDhlSubscription = (connectionId, subscriptionId) => {
      return {
        type: ActionTypes.DELETE_DHL_SUBSCRIPTION,
        payload: {
          connectionId,
          subscriptionId
        },
      };
    }; */


  //* --------------------- Ebay --------------------- *//

  // Ebay Get token
  static getEbayAuthToken = () => {
    return {
      type: ActionTypes.GET_EBAY_AUTH_TOKEN,
    };
  };

  static getEbayAuthTokenSuccess = (response) => {
    window.location.href = response;
    return {
      type: ActionTypes.GET_EBAY_AUTH_TOKEN_SUCCESS,
      payload: {
        response
      },
    };
  };

  static getEbayAuthTokenFail = (error) => {
    return {
      type: ActionTypes.GET_EBAY_AUTH_TOKEN_FAIL,
      payload: {
        error
      },
    };
  };



  // Ebay Set token
  static setEbayAuthToken = (code) => {
    return {
      type: ActionTypes.SET_EBAY_AUTH_TOKEN,
      payload: {
        code
      },
    };
  };

  static setEbayAuthTokenSuccess = (settings) => {
    toast.success(<FormattedMessage id='CONNECTIONS.SHOP_SAVE.SUCCESS' values={{ shopName: settings.name.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase()) }} />);
    return {
      type: ActionTypes.SET_EBAY_AUTH_TOKEN_SUCCESS,
      payload: {
        settings
      },
    };
  };

  static setEbayAuthTokenFail = (error) => {
    toast.error("Token Connection Error");
    return {
      type: ActionTypes.SET_EBAY_AUTH_TOKEN_FAIL,
      payload: {
        error
      },
    };
  };


  //* --------------------- Amazon --------------------- *//

  // amazon
  static getAmazonAuthToken = () => {
    return {
      type: ActionTypes.GET_AMAZON_AUTH_TOKEN,
    };
  };

  static getAmazonAuthTokenSuccess = (response) => {
    return {
      type: ActionTypes.GET_AMAZON_AUTH_TOKEN_SUCCESS,
      payload: {
        response
      },
    };
  };

  static getAmazonAuthTokenFail = (error) => {
    return {
      type: ActionTypes.GET_AMAZON_AUTH_TOKEN_FAIL,
      payload: {
        error
      },
    };
  };

  static getAmazonConnection = () => {
    return {
      type: ActionTypes.GET_AMAZON_CONNECTION,
    };
  };

  static getAmazonConnectionSuccess = (response) => {
    return {
      type: ActionTypes.GET_AMAZON_CONNECTION_SUCCESS,
      payload: {
        response
      },
    };
  };

  static getAmazonConnectionFail = (error) => {
    return {
      type: ActionTypes.GET_AMAZON_CONNECTION_FAIL,
      payload: {
        error
      },
    };
  };



  //* -------------------------- Etsy -------------------------- *//

  // Etsy Get token
  static getEtsyAuthToken = (settings) => {
    return {
      type: ActionTypes.GET_ETSY_AUTH_TOKEN,
      payload: {
        settings
      },
    };
  };

  static getEtsyAuthTokenSuccess = (settings) => {
    return {
      type: ActionTypes.GET_ETSY_AUTH_TOKEN_SUCCESS,
      payload: {
        settings
      },
    };
  };

  static getEtsyAuthTokenFail = (error) => {
    return {
      type: ActionTypes.GET_ETSY_AUTH_TOKEN_FAIL,
      payload: {
        error
      },
    };
  };



  // Etsy Set token
  static setEtsyAuthToken = (settings) => {
    return {
      type: ActionTypes.SET_ETSY_AUTH_TOKEN,
      payload: {
        settings
      },
    };
  };

  static setEtsyAuthTokenSuccess = (settings) => {
    window.location.href = settings.connect_url;
    return {
      type: ActionTypes.SET_ETSY_AUTH_TOKEN_SUCCESS,
      payload: {
        settings
      },
    };
  };

  static setEtsyAuthTokenFail = (error) => {
    return {
      type: ActionTypes.SET_ETSY_AUTH_TOKEN_FAIL,
      payload: {
        error
      },
    };
  }

  // Clear Loading Handler
  static amazonConnection = (settings) => {
    return {
      type: ActionTypes.AMAZON_SETTINGS,
      payload: {
        settings,
      },
    };
  };


  // Clear Loading Handler
  static clearLoadingEffects = () => {
    return {
      type: ActionTypes.CLEAR_LOADING_EFFECTS,
    };
  };

  /**
  * Clear Loading Handler
  * @returns
  */
  static cleanStatesInStore = (cleanedStates) => {
    return {
      type: ActionTypes.CLEAR_STATES_IN_STORE,
      payload: {
        cleanedStates
      },
    };
  }
}