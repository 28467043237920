import React, { useMemo, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEmailLogsUIContext } from '../../_context/EmailLogsUIContext';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';
import { Portal } from 'react-portal';
import { DocumentActions } from 'app/modules/documents/_redux/actions';



export function SendEmailsDialog() {

  const UIContext = useEmailLogsUIContext();
  const UIProps = useMemo(() => ({
    show: UIContext.showSendEmailDialog,
    onHide: UIContext.handleCloseSendEmailDialog,
    reminder: UIContext.reminder,
  }),
    [UIContext]
  );


  const { printLoading, error, emailSuccess, loading } = useSelector(state => ({
    printLoading: state.documents.printLoading,
    emailSuccess: state.documents.emailSuccess,
    error: state.documents.error,
    loading: state.documents.loading,
  }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const sendEmail = () => {
    const { reminder, document_id, document_type, type, shipper_id } = UIProps.reminder;
    if (shipper_id) {
      dispatch(DocumentActions.returnLabelEmail(document_type, document_id, shipper_id))

    } else {
      const data = {
        ids: [document_id],
        type: document_type,
        isEmail: true,
        ...(reminder?.id && {
          reminder: {
            id: reminder?.id,
            after_text: reminder?.after_text,
            before_text: reminder?.before_text,
            type: type,
          }
        }),
      };

      dispatch(DocumentActions.printDocument(data));
    }

  };

  useEffect(() => {
    if (emailSuccess || loading) {
      setTimeout(() => {
        UIProps.onHide()
        dispatch(DocumentActions.clearLoadingEffects());
        // UIProps.setIds([]);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailSuccess, UIProps.ids, loading]);


  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={UIProps.show} onHide={UIProps.onHide} aria-labelledby="example-modal-sizes-title-standard" centered>

        <Modal.Body className='p-12'>

          <div className="d-flex flex-column align-items-center">
            {emailSuccess || loading
              ? <Icon.CheckCircle className="text-success icon-6x my-5" />
              : <Icon.EnvelopeAt className="text-dark-75 icon-5x my-6" />
            }
          </div>

          <h5 className="text-center font-weight-bold">
            {emailSuccess || loading ?
              <FormattedMessage id='DOCUMENT.REDUX.SEND_SELECTED_DOCUMENT_WITH_EMAIL.SUCCESS' />
              : error
                ? <span className="text-danger">
                  {error?.message ? error.message : <FormattedMessage id='GENERAL.ERROR_MESSAGE' />}
                </span>
                : <FormattedMessage id="DOCUMENT.EMAIL_SEND.CONTENT" />
            }
          </h5>
        </Modal.Body>

        <Modal.Footer>

          <Button variant='light' onClick={UIProps.onHide} disabled={printLoading || loading} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CLOSE" />
          </Button>

          {(!emailSuccess || !loading )&&
            <Button variant='primary' className='ml-2' onClick={sendEmail} disabled={printLoading || loading} id="btn_send_email">
              <FormattedMessage id="AUTH.LOGIN.RESEND_EMAIL" />
            </Button>}

        </Modal.Footer>

      </Modal>
    </Portal>
  );
}
