import React, { useEffect, useState, useRef, useMemo } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CreationAndUpdateInfo, BackButton, ResetButton, SaveButton } from '_metronic/_partials';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '_metronic/_partials/controls';
import { SettingsActions } from '../../../../app/modules/settings/_redux/actions';
import { ServerRespondDialog } from '../product-dialogs/ServerRespondDialog';
import { useProductEditUIContext } from '../_context/ProductEditUIContext';
import { TAX_OPTIONS, UNIT_OPTIONS } from '../_context/ProductsUIHelpers';
import { useSkuValidationControl, useLoadingHandler } from 'app/hooks';
import { UserGuideHelper } from '../../../user-guides/UserGuideHelper';
import { capitalizeFirstLetter, utils } from '_metronic/_helpers';
import { FormattedMessage, useIntl } from 'react-intl';
import { LayoutSplashScreen } from '_metronic/layout';
import { ProductActions } from '../_redux/actions';
import { FormikStepper } from './FormikHandler';
import { toast } from 'react-toastify';
import { Variant } from './variants/Variant';
import { General } from './general/General';
import { Pictures } from './pictures/Pictures';
import { Features } from './features/Features';
import { Descriptions } from './descriptions/Descriptions';
import { MoreFeatures } from './more-features/MoreFeatures';
import { ProductKeys } from './product-keys/ProductKeys';



export function ProductEditChildren({ onHide, copyId }) {

  const dispatch = useDispatch();
  const { productId } = useParams();
  const { formatMessage: intl } = useIntl();

  useEffect(() => {
    dispatch(SettingsActions.getUserSettingsByType('products'));
    dispatch(ProductActions.getSupplier());

    return () => {
      dispatch(SettingsActions.clearState());
    };
  }, [dispatch]);

  const UIContext = useProductEditUIContext();
  const UIProps = useMemo(() => ({
    showServerRespondDialog: UIContext.showServerRespondDialog,
    handleOpenServerRespondDialog: UIContext.handleOpenServerRespondDialog,
    prices: UIContext.prices,
    features: UIContext.features,
    selectedCategoriesRoute: UIContext.selectedCategoriesRoute,
    shippings: UIContext.shippings,
    variantOptions: UIContext.variantOptions,
    isProductContainsVariant: UIContext.isProductContainsVariant,
    selectedKategorie: UIContext.selectedKategorie,
    categoryKey: UIContext.selectedKategorie?.key ?? '',
    setSelectedKategorie: UIContext.setSelectedKategorie,
    validateForm: UIContext.validateForm,
    // selectedCategory: UIContext.selectedCategory,
    // setSelectedCategory: UIContext.setSelectedCategory,
  }),
    [UIContext]
  );
  // const UIVariantProps = useMemo(() => UIContext.newVariantsReducer, [UIContext.newVariantsReducer]);
  // const dispatchNewVariants = useMemo(() => UIContext.dispatchNewVariants, [UIContext.dispatchNewVariants]);

  const { productForEdit, productImages, description, /* shortDescription, */ existedProductImages, request, variants, loading, digitalProduct } = useSelector(state => ({
    request: state.products.request,
    loading: state.products.loading,
    productImages: state.products.productImages,
    //shortDescription: state.products.shortDescription,
    description: state.products.description,
    digitalProduct: state.products.digitalProductKeys,
    existedProductImages: state.products.existedProductImages,
    variants: state.products.variants,
    productForEdit: productId
      ? state.products.productForEdit
      : state.settings.documentSettingsForEdit.default_product
        ? {
          ...state.products.productForEdit,
          unit: state.settings.documentSettingsForEdit.default_product.unit
            ? state.settings.documentSettingsForEdit.default_product.unit
            : UNIT_OPTIONS.at(0).value,
          pricing: {
            ...state.products.productForEdit.pricing,
            tax: state.settings.documentSettingsForEdit.default_product.tax >= 0
              ? +state.settings.documentSettingsForEdit.default_product.tax
              : TAX_OPTIONS.at(2).value,
          }
        }
        : state.products.productForEdit,
  }),
    shallowEqual
  );

  const { checkIsSkuValid } = useSkuValidationControl();
  const isLoading = useLoadingHandler(loading, ProductActions.clearLoadingEffects());

  useEffect(() => {
    productId && dispatch(ProductActions.getProductById(productId, false));
    copyId && dispatch(ProductActions.getProductById(copyId, true));

    return () => {
      dispatch(ProductActions.resetFormHandler());
      dispatch(ProductActions.setVariants([]));
    };
  }, [dispatch, productId, copyId]);

  const [requestId, setRequestId] = useState(null);

  useEffect(() => {
    if (request.id === requestId && request.status === 'error') {
      toast.error(request.error);
      dispatch(ProductActions.clearRequest());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, request.error, request.id, request.status, requestId]);

  const saveProduct = (values) => {

    const { dimensions, pricing, identities } = values;

    if (checkIsSkuValid(values.sku)) {
      toast.warning(intl({ id: 'PRODUCT.GENERAL.SKU_WARNING' }));
      return;
    }
    if (UIProps.categoryKey === "digital_product") {
      const hasMissingKey = digitalProduct?.keys?.some(item => !item.key || item.key.trim() === "");
      if (hasMissingKey) {
        return toast.warning(intl({ id: 'PRODUCT.KEYS_WARNING' }));
      }
    }
    const newVariants = variants.map(item => {
      const { net_price, ...pricingWithoutNetPrice } = item.pricing;
      return {
        ...item,
        pricing: pricingWithoutNetPrice,
      };
    });



    const withFile = [];
    const withoutFile = [];

    if (digitalProduct?.guides?.length) {
      digitalProduct.guides.forEach(guide => {
        if (guide.file) {
          withFile.push(guide);
        } else {
          withoutFile.push(guide);
        }
      });
    }

    const product = {
      name: capitalizeFirstLetter(values?.name ?? ''),
      sku: values.sku,
      condition: values.condition,
      brand: values.brand ?? '',
      quantity: variants.length > 0 ? variants.reduce((acc, variant) => acc + parseFloat(variant.quantity), 0) : values.quantity < 0 ? 0 : values.quantity,
      supplier_id: values.supplier_id ?? '',
      unit: values.unit,
      description: description ?? '',
      //short_description: shortDescription ?? '',
      features: UIProps.features ?? [],
      identities: {
        gtin: identities?.gtin ?? '',
        isbn: identities?.isbn ?? '',
        ean: identities?.ean ?? '',
        asin: identities?.asin ?? '',
        upc: identities?.upc ?? '',
        barcode: identities?.barcode ?? '',
        mpn: identities?.mpn ?? '',
      },
      pricing: {
        purchase_price: pricing?.purchase_price ?? 0,
        /* net_price: pricing?.net_price ?? 0, */
        gross_price: pricing?.gross_price ?? 0,
        tax: pricing.tax,
        currency: pricing.currency ?? 'EUR',
      },
      dimensions: {
        length: dimensions.length ?? 0,
        width: dimensions.width ?? 0,
        height: dimensions.height ?? 0,
        weight: dimensions.weight ?? 0,
      },
      category: {
        id: UIProps.selectedKategorie?.id ?? '',
        name: UIProps.selectedKategorie?.name ?? '',
        path: UIProps.selectedKategorie?.path ?? '',
        key: UIProps.selectedKategorie?.key ?? '',
      },
      ...(UIProps.categoryKey === "digital_product" && { digital_product: { ...digitalProduct, guides: withoutFile } ?? {} }),
      created_at: values.created_at,

      //variants
      // variants: variants,
      variants: UIProps.categoryKey === "digital_product" ? [] : newVariants,
      images: values.images ?? [],
    };

    const requestId = productId ? productId : utils.uuidv4();

    setRequestId(requestId);


    const form_data = new FormData();
    let productIsExisted = false;

    if (digitalProduct?.guides?.length) {
      productIsExisted = true;
      withFile.forEach(guide => form_data.append('product_guides', guide.file));
    }

    if (productId) {

      if (productImages.length) {
        productIsExisted = true;
        productImages.forEach(image => {
          const isExisted = existedProductImages.find(pic => pic === image.name);
          !isExisted && form_data.append('product_images', image.file);
        });
      };

      const upload = { is: productIsExisted, file: form_data };

      dispatch(ProductActions.updateProduct(productId, product, upload, requestId));
    } else {

      if (productImages.length) {
        productIsExisted = true;
        productImages.forEach(images => form_data.append('product_images', images.file));
      };

      const upload = { is: productIsExisted, file: form_data };

      dispatch(ProductActions.createProduct(product, upload, requestId));
    }
  };

  const btnRef = useRef();
  const saveProductClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const backToProductsList = () => {
    UIProps.setSelectedKategorie({});
    onHide();
  };

  const resetBtnRef = useRef();
  const resetFormHandler = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(intl({ id: 'GENERAL.CONFIRM' }))) {
      if (resetBtnRef && resetBtnRef.current) {
        resetBtnRef.current.click();
      }
      UIProps.setSelectedKategorie({});
      dispatch(ProductActions.resetFormHandler());
    }
  };


  return (
    <>
      {UIProps.showServerRespondDialog && <ServerRespondDialog />}
      {isLoading && <LayoutSplashScreen />}
      <Card className="card-box">

        <CardHeader
          back={<BackButton onClick={backToProductsList} />}
          title={<FormattedMessage id={productId ? 'PRODUCT.PRODUCT_EDIT' : 'PRODUCT.PRODUCT_NEW'} />}
          info={<UserGuideHelper />}
          id={productId ? 'title_product_edit' : 'title_product_new'}
          sticky
        >
          <CardHeaderToolbar>
            {!productId && <ResetButton onClick={resetFormHandler} />}
            <SaveButton onClick={saveProductClick} disabled={isLoading} />
          </CardHeaderToolbar>

        </CardHeader>

        <CardBody>
          <FormikStepper productId={productId} product={productForEdit} btnRef={btnRef} saveProduct={saveProduct} resetBtnRef={resetBtnRef} success={request.status === 'success'} onClose={() => { onHide(); dispatch(ProductActions.clearRequest()) }}>
            <General productId={productId} loading={isLoading} />
            <Pictures productId={productId} productImages={productImages} loading={isLoading} />
            <Descriptions loading={isLoading} />
            <Features productId={productId} loading={isLoading} />

            {(UIProps.categoryKey === "digital_product")
              ? <ProductKeys productId={productId} loading={isLoading} />
              : <Variant loading={isLoading} />
            }

          </FormikStepper>

          <MoreFeatures loading={isLoading} />
        </CardBody>

        <CreationAndUpdateInfo data={productForEdit} />

      </Card>
    </>
  );
}
