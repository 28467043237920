import React, { useMemo } from 'react';
import { useCustomersUIContext } from '../../_context/CustomersUIContext';
import { FormattedMessage } from 'react-intl';
import { Button } from 'react-bootstrap';
import { Icon } from '_metronic/_icons';



export function AddressFilter() {

  const UIContext = useCustomersUIContext();
  const UIProps = useMemo(() => ({
    openEditAddressDialogHandler: UIContext.openEditAddressDialogHandler,
    address: UIContext.address,
  }), [UIContext]);

  return (
    <div className="d-flex">
      {UIProps.address.length < 5 && (
        <Button variant='outline-primary' size='sm' className='svg-icon svg-icon-sm ml-auto font-weight-bold' onClick={() => UIProps.openEditAddressDialogHandler()} id="btn_new_address">
          <Icon.Plus />
          <span className="d-none d-sm-inline ml-2">
            <FormattedMessage id="ADDRESS.TITLE_NEW" />
          </span>
        </Button>
      )}
    </div>
  );
}
