import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { ContentRoute } from '_metronic/layout';
import { ModuleRoutes, RELATIVE_PATH } from 'constants/moduleRoutes';
import { SupportUIProvider } from './_context/SupportUIContext';
import SupportEditPage from './support-edit/SupportEditPage';
import { SupportPage } from './SupportPage';



export default function Support() {
  return (
    <SupportUIProvider>

      <Switch>
        <Redirect exact={true} from={RELATIVE_PATH} to={ModuleRoutes.SUPPORT} />
        <ContentRoute path={ModuleRoutes.VIEW_TICKET} component={SupportEditPage} />
        <ContentRoute path={ModuleRoutes.SUPPORT} component={SupportPage} />
      </Switch>

    </SupportUIProvider>
  );
}
