export const searchKeys = [
  'email',
  'name',
  'surname',
  'license.type',
  'user_id'
];
export const searchKeysContact = [
  'owner',
  'company_name',
  'email',
  'phone',
  'mobile',
  'address.city',
  'address.post_code',
  'address.country_code',
];

export const searchKeysTemp = [
  'email',
  'name',
  'surname'
];
