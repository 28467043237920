import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RadioControl, SVFormControl } from '_metronic/_partials/controls';
import { customerTypeOptions } from '../../_context/CustomersUIHelpers';
import { CustomerActions } from '../../_redux/actions';
import { Field, useFormikContext } from 'formik';
import { Col, Row } from "react-bootstrap";
import { useIntl } from 'react-intl';



export function GeneralForm({ loading }) {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();
  const { values: { email, type, id } } = useFormikContext();

  const { findCustomer } = useSelector(state => ({
    findCustomer: state.customers.findCustomerForMatching
  }));

  const INPUTS = [
    {
      name: "type",
      label: "GENERAL.TYPE",
      component: RadioControl,
      options: customerTypeOptions,
      required: true,
    },
    {
      name: "name",
      label: "GENERAL.FULL_NAME",
      required: true,
    },
    {
      name: "email",
      label: "GENERAL.EMAIL",
      type: "email",
      feedbackLabel: findCustomer.length > 0
        ? intl({ id: "GENERAL.EMAIL_HAVE" })
        : true,
      required: true,
    },
    {
      name: "company",
      label: "GENERAL.COMPANY",
      required: type === "company",
    },
    {
      name: "phone",
      label: "GENERAL.PHONE",
    },
    {
      name: "mobile",
      label: "GENERAL.MOBILE",
    },
    {
      name: "fax",
      label: "GENERAL.FAX",
    },
    {
      name: "tax_number",
      label: "GENERAL.TAX_NUMBER",
      required: type === "company",
    },
    {
      name: "vat_id",
      label: "GENERAL.US_NUMBER",
    }
  ];


  useEffect(() => {
    let delayFindProductByProperty;
    delayFindProductByProperty = setTimeout(() => {
      email && dispatch(CustomerActions.findCustomerByProperty('email', email, id));
    }, 500);

    return () => {
      clearTimeout(delayFindProductByProperty)
    };
  }, [id, dispatch, email]);


  return (
    <Row>
      {INPUTS.map((item, i) => (
        !(item.name === "company" && type === 'private') &&
        <Col lg="4" md="6" sm="12" key={i}>
          <Field component={item.component || SVFormControl}
            {...{
              name: item.name,
              label: intl({ id: item.label }),
              options: item.options,
              withFeedbackLabel: true,
              feedbackLabel: item.feedbackLabel || true,
              required: item.required,
              type: item.type,
              disabled: loading || item.disabled,
            }}
          />
        </Col>
      ))}
    </Row>
  )
}
