import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { SearchControl } from '_metronic/_partials/controls';
import { TICKET_STATUS_FILTER, TICKET_TAG_FILTER, TICKET_PRIORITY_FILTER, TICKET_READ_FILTER } from '../_context/SupportUIHelper';
import { DatePickerDropdown } from '../DatePickerDropdown';
import { theme } from '_metronic/_helpers';
import { useQuery } from 'app/hooks';
import clsx from 'clsx';



export function SupportFilter({ UIProps }) {

  const query = useQuery();
  const [search, setSearch] = useState(query.get('search') ?? '');

  const { role } = useSelector(state => ({
    role: state.auth.user.role,
  }));


  const filter = UIProps.queryParams.filter;
  const statusItem = TICKET_STATUS_FILTER.find(item => item.value === filter?.status || item.value === 'all_tickets')
  const tagItem = TICKET_TAG_FILTER.find(item => item.value === filter?.tag || item.value === 'all_tags')
  const priorityItem = TICKET_PRIORITY_FILTER.find(item => item.value === filter?.priority || item.value === 'all_priorities')
  const readItem = TICKET_READ_FILTER.find(item => item.value === (role === "644c41e257d397eb064fe0f9" ? filter?.seen : filter?.read) || item.value === 'all_read')

  useEffect(() => {
    setSearch(query.get('search') ?? '');
  }, [query]);

  const handleSearch = (values) => {
    setSearch(values);
    UIProps.setSearchQuery(values);
  }

  const onFilterHandler = (name, value) => {
    UIProps.setFilterQuery(name, value);
  };


  return (
    <Row className="mb-3">

      <Col sm="12" md="6" lg="4" className="mb-lg-0 mb-2">
        <SearchControl search={search} handleSearch={handleSearch} />
      </Col>

      <Col sm="12" md="6" lg="8" className='d-flex flex-wrap align-items-start justify-content-start px-md-0'>

        {/* Status filter dropdown */}
        <Dropdown className="dropdown-inline symbol mr-2 mb-2" drop="down">
          <Dropdown.Toggle variant="outline-secondary" className="d-flex align-items-center svg-icon svg-icon-sm font-weight-bold" style={{ height: theme.units.input.height['sm'] }} id="dropdown-basic">
            <span className={clsx("label label-xl label-dot mr-3", `label-${statusItem?.className}`)}></span>
            {statusItem?.label}
          </Dropdown.Toggle>

          {statusItem?.value !== "all_tickets" && <i className="symbol-badge bg-danger"></i>}

          <Dropdown.Menu className='dropdown-menu-right dropdown-menu-anim-down' id='dropdown_document_grouping'>
            <ul className="navi navi-hover">
              {TICKET_STATUS_FILTER.map((filter, index) => (
                <li className="navi-item" key={index}>
                  {filter.value === 'all_tickets' && <Dropdown.Divider />}
                  <Dropdown.Item className='navi-link font-weight-bold' onClick={() => onFilterHandler('status', filter.value)} id={`dropdown_item_${filter.value}`}>
                    <span className={clsx("label label-xl label-dot mr-1", `label-${filter.className}`)} />
                    <span className="navi-text">
                      {filter.label}
                    </span>
                  </Dropdown.Item>
                </li>
              ))}
            </ul>
          </Dropdown.Menu>
        </Dropdown>


        {/* Date filter dropdown */}
        <DatePickerDropdown setFilterQuery={UIProps.setFilterQuery} />

        {role === "644c41e257d397eb064fe0f9"
          ? <>
            {/* Tag filter dropdown */}
            <Dropdown className="dropdown-inline symbol mr-2 mb-2" drop="down">
              <Dropdown.Toggle variant="outline-secondary" className="d-flex align-items-center svg-icon svg-icon-sm font-weight-bold" style={{ height: theme.units.input.height['sm'] }} id="dropdown-tag">
                <span className={clsx("label label-xl label-dot mr-3", `label-${tagItem?.className}`)}></span>
                {tagItem?.label}
              </Dropdown.Toggle>

              {tagItem?.value !== "all_tags" && <i className="symbol-badge bg-danger"></i>}

              <Dropdown.Menu className='dropdown-menu-right dropdown-menu-anim-down' id='dropdown_document_tag'>
                <ul className="navi navi-hover">
                  {TICKET_TAG_FILTER.map((filter, index) => (
                    <li className="navi-item" key={index}>
                      {filter.value === 'all_tags' && <Dropdown.Divider />}
                      <Dropdown.Item className='navi-link font-weight-bold' onClick={() => onFilterHandler('tag', filter.value)} id={`ticket_${filter.value}`}>
                        <span className={clsx("label label-xl label-dot mr-1", `label-${filter.className}`)} />
                        <span className="navi-text">
                          {filter.label}
                        </span>
                      </Dropdown.Item>
                    </li>
                  ))}
                </ul>
              </Dropdown.Menu>
            </Dropdown>


            {/* Priority filter dropdown */}
            <Dropdown className="dropdown-inline symbol mr-2 mb-2" drop="down">
              <Dropdown.Toggle variant="outline-secondary" className="d-flex align-items-center svg-icon svg-icon-sm font-weight-bold" style={{ height: theme.units.input.height['sm'] }} id="dropdown-tag">
                <span className={`${priorityItem?.value === "all_priorities" ? "label label-dot label-xl mr-3" : "svg-icon svg-icon-sm"} text-${priorityItem?.className}`}>
                  {TICKET_PRIORITY_FILTER.find(item => item.value === priorityItem?.value)?.icon}
                </span>
                <span className="navi-text">
                  {priorityItem?.label}
                </span>
              </Dropdown.Toggle>

              {priorityItem?.value !== "all_priorities" && <i className="symbol-badge bg-danger"></i>}

              <Dropdown.Menu className='dropdown-menu-right dropdown-menu-anim-down' id='dropdown_document_tag'>
                <ul className="navi navi-hover">
                  {TICKET_PRIORITY_FILTER.map((filter, index) => (
                    <li className="navi-item" key={index}>
                      {filter.value === 'all_priorities' && <Dropdown.Divider />}
                      <Dropdown.Item className='navi-link font-weight-bold' onClick={() => onFilterHandler('priority', filter.value)} id={`ticket_${filter.value}`}>
                        <span className={clsx("svg-icon svg-icon-sm mr-2", `text-${filter?.className}`)}>
                          {filter.icon}
                        </span>
                        <span className={clsx("navi-text", `${filter.value === "medium" ? "text-" + filter.className : ""}`)}>
                          {filter.label}
                        </span>
                      </Dropdown.Item>
                    </li>
                  ))}
                </ul>
              </Dropdown.Menu>
            </Dropdown>

          </>
          : null}


        {/* Read filter dropdown */}
        <Dropdown className="dropdown-inline symbol mr-2 mb-2" drop="down">
          <Dropdown.Toggle variant="outline-secondary" className="d-flex align-items-center svg-icon svg-icon-sm font-weight-bold" style={{ height: theme.units.input.height['sm'] }} id="dropdown-tag">
            <span className={clsx("label label-xl label-dot mr-3", `label-${readItem?.className}`)}></span>
            {readItem?.label}
          </Dropdown.Toggle>

          {readItem?.value !== "all_read" && <i className="symbol-badge bg-danger"></i>}

          <Dropdown.Menu className='dropdown-menu-right dropdown-menu-anim-down' id='dropdown_document_tag'>
            <ul className="navi navi-hover">
              {TICKET_READ_FILTER.map((filter, index) => (
                <li className="navi-item" key={index}>
                  {filter.value === 'all_read' && <Dropdown.Divider />}
                  <Dropdown.Item className='navi-link font-weight-bold' onClick={() => onFilterHandler((role === "644c41e257d397eb064fe0f9" ? 'seen' : 'read'), filter.value)} id={`ticket_${filter.value}`}>
                    <span className={clsx("label label-xl label-dot mr-1", `label-${filter.className}`)} />
                    <span className="navi-text">
                      {filter.label}
                    </span>
                  </Dropdown.Item>
                </li>
              ))}
            </ul>
          </Dropdown.Menu>
        </Dropdown>

      </Col>
    </Row>
  );
}
