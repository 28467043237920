import React from 'react'
import { Icon } from "_metronic/_icons";
import { ModuleRoutes } from "constants/moduleRoutes";
import { NewButton } from '_metronic/_partials';



export const SETTINGS_MENU = [
  {
    icon: <Icon.House />,
    title: "COMPANY.TITLE",
    route: ModuleRoutes.SETTINGS_COMPANY,
    toolbar: <NewButton link={ModuleRoutes.SETTINGS_COMPANY_EDIT} label="GENERAL.EDIT" className="ml-2" />,
  },
  {
    icon: <Icon.Bookmarks />,
    title: "CATEGORY.TITLE_PLURAL",
    route: ModuleRoutes.SETTINGS_CATEGORY,
  },
  {
    icon: <Icon.Lock />,
    title: "AUTH.GENERAL.ACCOUNT_SETTINGS",
    route: ModuleRoutes.SETTINGS_LOGIN,
  },
  {
    icon: <Icon.Box />,
    title: "PRODUCT.TITLE",
    route: ModuleRoutes.SETTINGS_PRODUCT,
  },
  {
    icon: <Icon.Truck />,
    title: "GENERAL.SHIPPING",
    route: ModuleRoutes.SETTINGS_SHIPPING,
  },
  {
    icon: <Icon.Layers />,
    title: "DOCUMENT.ORDER_TITLE",
    route: ModuleRoutes.SETTINGS_DOCUMENT_FN('orders'),
  },
  {
    icon: <Icon.Layers />,
    title: "DOCUMENT.INVOICE_TITLE",
    route: ModuleRoutes.SETTINGS_DOCUMENT_FN('invoices'),
  },
  {
    icon: <Icon.Layers />,
    title: "DOCUMENT.OFFER_TITLE",
    route: ModuleRoutes.SETTINGS_DOCUMENT_FN('offers'),
  },
  {
    icon: <Icon.Layers />,
    title: "DOCUMENT.WAYBILL_TITLE",
    route: ModuleRoutes.SETTINGS_DOCUMENT_FN('waybills'),
  },
  {
    icon: <Icon.Layers />,
    title: "DOCUMENT.CREDIT_TITLE",
    route: ModuleRoutes.SETTINGS_DOCUMENT_FN('credits'),
  },
];


export const INITIAL_DEFAULT_SHIPPING = {
  id: '',
  profile_name: '',
  unlabeled: false,
  service: '',
  type: 'shipment',
  weight: 2,
  price: 0,
  default: true,
};