import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Modal } from 'react-bootstrap';
import { useProductEditUIContext } from '../../../_context/ProductEditUIContext';
import { generateVariants, VARIANT_ACTION_TYPES } from '../core/helpers';
import { SettingsActions } from 'app/modules/settings/_redux/actions';
import { VariantGenerateForm } from './VariantGenerateForm';
import { ProductActions } from '../../../_redux/actions';
import { VariantAddForm } from './VariantAddForm';
import { maxValidation } from '_metronic/_helpers';
import { Icon } from '_metronic/_icons';
import { Portal } from 'react-portal';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';


const initialVariant = {
  show: false,
  aspects: []
};

export function VariantsGenerateDialog() {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  const [selectedOption, setSelectedOption] = useState('');
  const [selectedVariants, setSelectedVariants] = useState([]);
  const [variant, setVariant] = useState(initialVariant);

  const { id, variant_options } = useSelector(state => state.settings.documentSettingsForEdit, shallowEqual);

  const UIContext = useProductEditUIContext();
  const UIProps = useMemo(() => ({
    showGenerateVariantDialog: UIContext.newVariantsReducer.showGenerateVariantDialog,
    formValues: UIContext.newVariantsReducer.formValues,
    dispatchNewVariants: UIContext.dispatchNewVariants
  }), [UIContext.newVariantsReducer, UIContext.dispatchNewVariants]);


  useEffect(() => {
    if (variant_options?.length) {
      if (!selectedOption) {
        setSelectedOption(variant_options[0]?.name);
      }
    }
  }, [variant_options, selectedOption]);


  const aspectsSelectHandler = e => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedVariants(prev => {
        const findOption = prev.find(item => item.name === selectedOption);
        if (findOption) {
          return prev.map(item => {
            if (item.name === selectedOption) {
              return {
                ...item,
                values: [...item.values, value],
              };
            }
            return item;
          });
        }
        return [
          ...prev,
          {
            name: selectedOption,
            values: [value],
          },
        ];
      });
    } else {
      setSelectedVariants(prev => {
        const findOption = prev.find(item => item.name === selectedOption);
        if (findOption) {
          return prev.map(item => {
            if (item.name === selectedOption) {
              return {
                ...item,
                values: item.values.filter(item => item !== value),
              };
            }
            return item;
          });
        }
        return prev;
      });
    }
    setSelectedVariants(prev => prev.filter(item => item.values.length > 0));
  };


  const closeGenerateVariantDialog = () => {
    setSelectedOption('');
    setSelectedVariants([]);
    UIProps.dispatchNewVariants({ type: VARIANT_ACTION_TYPES.CLOSE_GENERATE_VARIANT_DIALOG });
  };

  const isExistVariant = (value) => {
    if (variant_options) {
      const isVariantExists = variant_options?.some(item => item?.name?.toLowerCase() === value?.trim()?.toLowerCase())
      return !isVariantExists
    }
    return true;
  }

  const variantValidationSchema = Yup.object().shape({
    variant_name: Yup.string()
      .max(50, maxValidation("50"))
      .required(intl({ id: "GENERAL.REQUIRED" }))
      .test('variant_name', intl({ id: 'SETTINGS.PRODUCT.VARIANT_NAME.EXISTS' }), isExistVariant),
    aspect_names: Yup.string()
      .max(50, maxValidation("50"))
      .test('aspect_names', intl({ id: 'SETTINGS.PRODUCT.ASPECT_NAME.EXISTS' }), value =>
        !variant?.aspects?.some(item => item?.toLowerCase() === value?.trim()?.toLowerCase()))
  });

  const aspectValidationSchema = Yup.object().shape({
    aspect_name: Yup.string()
      .max(50, maxValidation("50"))
      .test('aspect_name', intl({ id: 'SETTINGS.PRODUCT.ASPECT_NAME.EXISTS' }), (value) => {
        const variantOptions = variant_options?.find((el) => el.name === selectedOption);
        return !variantOptions || !variantOptions.aspects.some((aspect) => aspect?.toLowerCase() === value?.trim()?.toLowerCase());
      })
  });


  const handleSubmitGenerateVariant = (resetForm) => {
    const currentVariants = selectedVariants?.filter(variant => variant?.values?.length > 0);
    const [generatedVariants] = generateVariants(currentVariants, UIProps.formValues);
    dispatch(ProductActions.setVariants(generatedVariants));
    closeGenerateVariantDialog();
    resetForm()
  };


  const handleSubmitSaveVariant = (values) => {
    const variantOptions = [...variant_options, { name: values.variant_name, aspects: variant?.aspects }];
    if (id) {
      dispatch(SettingsActions.patchUserSettings(id, { variant_options: variantOptions }))
    } else {
      const data = {
        settings_type: "products",
        variant_options: variantOptions,
        default_product: {
          tax: "",
          category: "",
          unit: "",
          currency: ""
        },
        stock_control: {
          kaufland: false,
          amazon: false,
          ebay: false,
        }
      }
      dispatch(SettingsActions.createUserSettings(data))
    }
    setVariant(initialVariant);
  };


  const handleAddVariant = (values) => {
    const aspectName = values.aspect_names?.trim()
    const valid = variant?.aspects?.some(item => item?.toLowerCase() === aspectName?.toLowerCase());
    if (aspectName !== "" && !valid) {
      values.aspect_names = ""
      setVariant({ ...variant, aspects: [...variant?.aspects, aspectName] });
    }
  }


  const handleAddAspect = (values, { resetForm }) => {
    const aspectNames = values.aspect_name?.trim()
    const updatedVariantOptions = values.aspect_name && variant_options.map(item => {
      if (item.name === selectedOption) {
        const updatedItem = { ...item };
        if (!updatedItem?.aspects?.some(item => item?.toLowerCase() === aspectNames?.toLowerCase())) {
          updatedItem.aspects.push(aspectNames);
        }
        return updatedItem;
      }
      return item;
    });
    if (id) {
      dispatch(SettingsActions.patchUserSettings(id, { variant_options: updatedVariantOptions }));
    };
    resetForm()
  };

  const deleteAspect = (index) => setVariant({ ...variant, aspects: variant?.aspects.filter((item, i) => i !== index) });


  return (
    <Portal node={document && document.getElementById('modal-portal')}>
      <Modal show={UIProps.showGenerateVariantDialog} onHide={closeGenerateVariantDialog} size="md" backdrop="static" keyboard={false} className="mt-40">

        <Modal.Header>
          <Modal.Title>
            <Icon.Diagram3 className='mr-3' />
            <FormattedMessage id="PRODUCT.VARIANT.ATTRIBUTES_SELECT" />
          </Modal.Title>

          {!variant.show &&
            <Button variant='outline-primary' size='sm' className="svg-icon font-weight-bold svg-icon-sm" onClick={() => setVariant({ ...variant, show: true })} id='btn_add_variant'>
              <Icon.Plus />
              <span className="d-none d-sm-inline ml-2">
                <FormattedMessage id="PRODUCT.ADD_VARIANT.ADD_VARIANT" />
              </span>
            </Button>}
        </Modal.Header>

        {variant.show ?
          <Formik enableReinitialize={true} initialValues={{ variant_name: "", aspect_names: '' }} validationSchema={variantValidationSchema} onSubmit={handleAddVariant}>
            {({ values }) => (
              <Form>

                <Modal.Body>
                  <VariantAddForm aspects={variant?.aspects} deleteAspect={deleteAspect} />
                </Modal.Body>

                <Modal.Footer>
                  <Button variant='light' onClick={() => setVariant(initialVariant)} id='btn_cancel_modal'>
                    <FormattedMessage id="GENERAL.CANCEL" />
                  </Button>

                  <Button variant='primary' className="ml-2" onClick={() => handleSubmitSaveVariant(values)} disabled={!(variant?.aspects?.length && isExistVariant(values.variant_name))} id='btn_save_modal'>
                    <FormattedMessage id="GENERAL.SAVE" />
                  </Button>
                </Modal.Footer>

              </Form>
            )}
          </Formik>
          :
          <Formik enableReinitialize={true} initialValues={{ aspect_name: "" }} validationSchema={aspectValidationSchema} onSubmit={handleAddAspect}>
            {({ resetForm }) => (
              <Form>

                <Modal.Body>
                  <VariantGenerateForm
                    aspectsSelectHandler={aspectsSelectHandler}
                    variantSelectHandler={(value) => setSelectedOption(value)}
                    selectedOption={selectedOption}
                    selectedVariants={selectedVariants}
                  />
                </Modal.Body>

                <Modal.Footer>
                  {selectedVariants.length > 0 &&
                    <span className="svg-icon svg-icon-sm text-nowrap text-primary font-weight-bold p-2 mr-auto">
                      <Icon.CheckCircle className="mr-2" />
                      <FormattedMessage id="PRODUCT.ADD_VARIANT.SELECTED_TOTAL_VARIANT" /> : {selectedVariants.reduce((acc, curr) => acc * curr.values.length, 1)}
                    </span>}

                  <Button variant='light' onClick={() => closeGenerateVariantDialog()} id='btn_cancel_modal'>
                    <FormattedMessage id="GENERAL.CANCEL" />
                  </Button>

                  <Button variant='primary' className="ml-2" onClick={() => handleSubmitGenerateVariant(resetForm)} title={selectedVariants.length ? '' : intl({ id: 'PRODUCT.VARIANT.SELECT_VARIANT' })} id='btn_generate_modal' disabled={!selectedVariants.length}>
                    <FormattedMessage id="PRODUCT.VARIANT.PROCESS" />
                  </Button>
                </Modal.Footer>

              </Form>
            )}
          </Formik>
        }
      </Modal>
    </Portal>
  );
}
