import React from 'react';
import { Table } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { convertPriceCurrency } from '_metronic/_helpers';



export function EnterPaymentSummary({ payment: { totalProductPrice, totalPrice, totalPaid, paymentStatus, paymentAmountDifference, shippingPrice, totalPaymentWarning, currency } }) {
  return (
    <div className="table-responsive p-8">

      <Table className='mb-0'>
        <thead>
          <tr className='font-weight-bold text-uppercase text-muted text-center'>

            <th className="text-left pl-5">
              <FormattedMessage id="DOCUMENT.PAYMENT_STATUS" />
            </th>

            <th>
              <FormattedMessage id="DOCUMENT.POSITION_TOTAL_PRODUCT" />
            </th>

            <th>
              <FormattedMessage id="DOCUMENT.POSITION_TOTAL_SHIPPING" />
            </th>

            {totalPaymentWarning > 0 && (
              <th>
                <FormattedMessage id="DOCUMENT.PAYMENT.WARNINGS_PRICE" />
              </th>
            )}

            <th>
              <FormattedMessage id="DOCUMENT.POSITION_TOTAL_AMOUNT" />
            </th>

            <th>
              <FormattedMessage id="DOCUMENT.PAYMENT.AMOUNT_PAID" />
            </th>

            <th className="text-right pr-5">
              <FormattedMessage id="DOCUMENT.TABLE.COLUMN.OPEN_AMOUNT" />
            </th>

          </tr>
        </thead>

        <tbody>
          <tr className="font-weight-bolder text-center font-size-lg text-dark-75">

            <td className="text-left pl-5">
              <div className={`label label-inline label-xl label-${paymentStatus === 'paid' ? 'outline-success' : 'danger'}`}>
                <FormattedMessage id={paymentStatus === 'paid' ? 'GENERAL.PAID' : 'GENERAL.OPEN'} />
              </div>
            </td>

            <td>
              {convertPriceCurrency(totalProductPrice, currency)}
            </td>

            <td>
              {convertPriceCurrency(shippingPrice, currency)}
            </td>

            {totalPaymentWarning > 0 && (
              <td>
                {convertPriceCurrency(totalPaymentWarning, currency)}
              </td>
            )}

            <td>
              {convertPriceCurrency(totalPrice, currency)}
            </td>

            <td>
              {convertPriceCurrency(totalPaid, currency)}
            </td>

            <td className="font-weight-boldest text-primary font-size-h3 text-lg-right text-md-left pr-5">
              {convertPriceCurrency(paymentAmountDifference, currency)}
            </td>

          </tr>
        </tbody>

      </Table>
    </div>
  );
};