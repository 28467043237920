import React, { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useDocumentsUIContext } from '../_context/DocumentsUIContext';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { DocumentActions } from '../_redux/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { CloseButton, SelectCounter, Spinner } from '_metronic/_partials';
import { useDocumentLocalUIContext } from '../_context/DocumentLocalUIContext';
import * as columnFormatters from './column-formatters/ColumnFormatters';
import PerfectScrollbar from 'react-perfect-scrollbar';
import BootstrapTable from 'react-bootstrap-table-next';
import { useLoadingHandler } from 'app/hooks';
import { Icon } from '_metronic/_icons';



const initialState = {
  isInvoice: true,
  isFulfillments: true,
  isSendMail: true,
  isWaybill: false
};

export function ConvertingDocumentsDialog() {

  const { formatMessage: intl } = useIntl();
  const dispatch = useDispatch();

  const [selectedActions, setSelectedActions] = useState(initialState);
  const [processed, setProcessed] = useState(false);


  const { loading, convertDetails } = useSelector(state => ({
    loading: state.documents.actionLoading,
    convertDetails: state.documents.convertDetails,
  }), shallowEqual);


  const UIContext = useDocumentsUIContext();
  const contextLocal = useDocumentLocalUIContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    setIds: UIContext.setIds,
    documentQueryParams: contextLocal.queryParams,
    show: UIContext.showConvertDocumentsDialog,
    onHide: UIContext.closeConvertDocumentsDialog,
    openPrintDocumentDialog: UIContext.openPrintDocumentDialog,
  }), [
    UIContext.ids,
    UIContext.setIds,
    contextLocal.queryParams,
    UIContext.closeConvertDocumentsDialog,
    UIContext.showConvertDocumentsDialog,
    UIContext.openPrintDocumentDialog
  ]);


  useEffect(() => {
    return () => {
      dispatch(DocumentActions.cleanStatesInStore({ convertDetails: [] }))
    };
  }, [dispatch]);


  useEffect(() => {
    if (!selectedActions?.isInvoice) {
      setSelectedActions(prevState => ({ ...prevState, isSendMail: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedActions?.isInvoice]);

  const onCheckChange = (type) => setSelectedActions(prevState => ({ ...prevState, [type]: !prevState[type] }));
  // const sortedConvertDetails = convertDetails?.sort((a, b) => parseInt(a?.id, 10) - parseInt(b?.id, 10));

  const { isInvoice, isFulfillments, isSendMail, isWaybill } = selectedActions;

  const useLoading = useLoadingHandler(
    loading,
    DocumentActions.cleanStatesInStore({ actionLoading: false, convertDetails: [] }),
    150000
  );

  const handleOnHide = () => {
    UIProps.onHide();
    setSelectedActions(initialState);
    setProcessed(false)
    // UIProps.setIds([]);
  };

  const convertDocumentsHandler = () => {

    const data = {
      ids: convertDetails?.sort((a, b) => parseInt(a?.id, 10) - parseInt(b?.id, 10))?.map(i => i.id),
      selectedActions,
    }
  
    dispatch(DocumentActions.createFulfillments(data, UIProps.documentQueryParams));
    setProcessed(true)
  };

  const openPrintDocumentDialog = (type) => {
    const getIds = convertDetails
      ?.filter(i => (type === 'invoices' && i.invoice) || (type === 'waybills' && i.waybill))
      .map(i => type === 'invoices' ? i.invoiceId : i.waybillId)
     
    if (getIds.length > 0) {
      const data ={
        ids: getIds,
        type,
      }
      dispatch(DocumentActions.printDocument(data));
      UIProps.openPrintDocumentDialog();
    }
  };

  const COLUMNS = [
    {
      dataField: 'orderId',
      headerClasses: 'text-left pl-9',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.ORDER_ID' }),
      headerStyle: { minWidth: '100px', width: "20%" },
      classes: 'text-left font-weight-bold text-dark-75 text-nowrap py-3 pl-9',
      editable: false,
      formatter: columnFormatters.OrderIdFormatter
    },
    {
      dataField: 'payment_status',
      headerClasses: 'text-center',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.PAYMENT' }),
      headerStyle: { minWidth: '60px', width: "20%" },
      classes: 'text-center font-weight-bold text-dark-75',
      editable: false,
      formatter: columnFormatters.PaymentStatusFormatter
    },
    {
      dataField: 'shipping',
      headerClasses: 'text-center',
      text: intl({ id: 'GENERAL.SHIPPING' }),
      headerStyle: { minWidth: '60px', width: "20%" },
      classes: 'text-center font-weight-bold text-dark-75',
      editable: false,
      formatter: columnFormatters.ShippingFormatter
    },
    {
      dataField: 'email',
      text: intl({ id: 'GENERAL.EMAIL' }),
      headerStyle: { minWidth: '60px', width: "15%" },
      headerClasses: 'text-center',
      classes: 'text-center font-weight-bold text-dark-75',
      editable: false,
      formatter: columnFormatters.EmailFormatter
    },
    {
      dataField: 'invoice',
      text: intl({ id: 'DOCUMENT.INVOICE_PLURAL' }),
      headerStyle: { minWidth: '60px', width: "20%" },
      headerClasses: 'text-center',
      classes: 'text-center font-weight-bold text-dark-75',
      editable: false,
      formatter: columnFormatters.InvoiceFormatter
    },
    {
      dataField: 'waybill',
      text: intl({ id: 'DOCUMENT.WAYBILL_PLURAL' }),
      headerStyle: { minWidth: '60px', width: "20%" },
      headerClasses: 'text-center',
      classes: 'text-center font-weight-bold text-dark-75',
      editable: false,
      formatter: columnFormatters.WaybillFormatter
    },
    {
      dataField: 'fulfillment',
      text: intl({ id: 'GENERAL.CONVERT' }),
      headerStyle: { minWidth: '60px', width: "20%" },
      headerClasses: 'text-center pr-15',
      classes: 'text-center font-weight-bold text-dark-75',
      editable: false,
      formatter: columnFormatters.FulfillmentFormatter
    },
    {
      dataField: '#',
      text: "",
      classes: 'hover-menu',
      editable: false,
      hidden: processed,
      formatter: columnFormatters.ActionFormatter,
      formatExtraData: {
        deleteCompleteList: (id) => dispatch(DocumentActions.deleteCompleteList(id))
      },
    }
  ];


  return (
    <Modal show={UIProps.show} onHide={handleOnHide} aria-labelledby="example-modal-sizes-title-lg" size="lg">

      <Modal.Header>
        <Modal.Title>
          <Icon.CheckSquare className="mr-3" />
          <FormattedMessage id="DOCUMENT.CONVERT.TITLE" />
        </Modal.Title>
        <CloseButton onClick={handleOnHide} loading={useLoading} />
      </Modal.Header>

      <Modal.Body className='p-0 overflow-hidden'>

        <PerfectScrollbar className="scroll pr-5 mr-n5" style={{ maxHeight: '390px' }}>
          <BootstrapTable
            keyField="id"
            data={convertDetails || []}
            columns={COLUMNS}
            wrapperClasses="table-responsive"
            headerClasses="bg-info-o-10"
            classes="table table-head-custom table-vertical-center mb-0"
            bootstrap4
            bordered={false}
            deleteRow={true}
            condensed
          />
        </PerfectScrollbar>

        <Row className='border-top px-9 py-5'>

          <Col lg='6'>
            <input type="checkbox" className='d-none' />
            <label className="checkbox checkbox-single mb-3" style={{ pointerEvents: (useLoading || processed) ? "none" : null }}>
              <input type="checkbox" checked={isInvoice} onChange={() => onCheckChange('isInvoice')} disabled={useLoading || processed} id="checkbox_create_invoice" />
              <span className="mr-2" />
              <FormattedMessage id="DOCUMENT.CONVERT.CREATE_INVOICE" />
            </label>
            <input type="checkbox" className='d-none' />
            <label className="checkbox checkbox-single mb-3" style={{ pointerEvents: (useLoading || processed) ? "none" : null }}>
              <input type="checkbox" checked={isSendMail} onChange={() => onCheckChange('isSendMail')} title={!isInvoice ? "Enable 'Create Invoice' to send an email" : ""} disabled={useLoading || processed || !isInvoice} id="checkbox_send_email" />
              <span className="mr-2" />
              <FormattedMessage id="GENERAL.EMAIL.INVOICE" />
            </label>
          </Col>

          <Col lg='6'>
            {(convertDetails.some(s => s.marketplace.length > 0)) &&
              <>
                <input type="checkbox" className='d-none' />
                <label className="checkbox checkbox-single mb-3" style={{ pointerEvents: (useLoading || processed) ? "none" : null }}>
                  <input type="checkbox" checked={isFulfillments} onChange={() => onCheckChange('isFulfillments')} disabled={useLoading || processed} id="checkbox_fulfillments" />
                  <span className="mr-2" />
                  <FormattedMessage id="DOCUMENT.CONVERT.SUBMIT_MARKETPLACE" />
                </label>
              </>
            }
            <input type="checkbox" className='d-none' />
            <label className="checkbox checkbox-single mb-3" style={{ pointerEvents: (useLoading || processed) ? "none" : null }}>
              <input type="checkbox" checked={isWaybill} onChange={() => onCheckChange('isWaybill')} disabled={useLoading || processed} id="checkbox_create_waybill" />
              <span className="mr-2" />
              <FormattedMessage id="DOCUMENT.CONVERT.CREATE_WAYBILL" />
            </label>
          </Col>

        </Row>

      </Modal.Body>

      <Modal.Footer>

        <SelectCounter count={convertDetails.length} />

        <Spinner loading={useLoading} color="danger" className="mr-5" />

        <Button variant='light' style={useLoading ? { pointerEvents: "none" } : null} className="mx-2" onClick={handleOnHide} disabled={useLoading} id="btn_cancel_modal">
          <FormattedMessage id="GENERAL.CLOSE" />
        </Button>

        {!processed &&
          <Button variant='primary' onClick={convertDocumentsHandler} disabled={useLoading || !convertDetails.length || (!isInvoice && !isFulfillments && !isWaybill)} style={(useLoading || processed || !convertDetails.length) ? { pointerEvents: "none" } : null} id='btn_convert_modal'>
            <FormattedMessage id="DOCUMENT.CONVERT.COMPLETE" />
          </Button>
        }

        {processed && isWaybill && convertDetails.some(s => s.waybill === true) &&
          <Button variant='light-primary' style={useLoading ? { pointerEvents: "none" } : null} onClick={() => openPrintDocumentDialog('waybills')} disabled={useLoading} id='btn_convert_invoice_modal'>
            <FormattedMessage id="GENERAL.PRINT.WAYBILLS" />
          </Button>
        }

        {processed && isInvoice && convertDetails.some(s => s.invoice === true) &&
          <Button variant='primary' style={useLoading ? { pointerEvents: "none" } : null} onClick={() => openPrintDocumentDialog('invoices')} disabled={useLoading} id='btn_convert_waybill_modal'>
            <FormattedMessage id="GENERAL.PRINT.INVOICES" />
          </Button>
        }

      </Modal.Footer>

    </Modal>
  );
}