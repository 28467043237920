import React, { useEffect, useMemo, useState } from 'react';
import { Card, CardBody, CardSubHeader, SVReactSelect, SVFormControl, } from '_metronic/_partials/controls';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { UserInfoDropdown } from '_metronic/_partials';
import { Field, useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Icon } from '_metronic/_icons';
import moment from 'moment';



const documentNumberDateOptions = [
  {
    label: 'None',
    value: '',
  },
  {
    label: 'YYYY',
    value: 'YYYY',
  },
  {
    label: 'YYYYMM',
    value: 'YYYYMM',
  },
  {
    label: 'YY',
    value: 'YY',
  },
  {
    label: 'YYMM',
    value: 'YYMM',
  },
  {
    label: 'YYYYMMDD',
    value: 'YYYYMMDD',
  },
  {
    label: 'YYMMDD',
    value: 'YYMMDD',
  },
];




export function NumberCircle({ id, loading }) {


  const { formatMessage: intl } = useIntl();
  const { setFieldValue } = useFormikContext();

  const { document_count } = useSelector(state => ({
    document_count: state.settings.document_count
  }));

  const { values: { default_number_range: { prefix, date, next_value, min_length } } } = useFormikContext();

  const [generatedExampleNumber, setGeneratedExampleNumber] = useState('');

  const [minLengthOptions, setMinLengthOptions] = useState([]);

  useEffect(() => {

    const totalLength = (prefix ?? '').length + (date ?? '').length;
    const nextValueLength = ((next_value ?? '').length > 4) ? ((next_value ?? '').length - 4) : 0;


    const newMinLengthOptions = Array.from({ length: 5 }, (_, i) => ({
      label: (totalLength + nextValueLength + i + 4).toString(),
      value: totalLength + nextValueLength + i + 4,
    }));

    setMinLengthOptions(newMinLengthOptions);

    const initialMinLength = newMinLengthOptions[0].value;
    setFieldValue('default_number_range.min_length', initialMinLength);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefix, date, next_value]);


  useEffect(() => {
    id && setFieldValue('default_number_range.min_length', min_length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);


  useMemo(() => {

    const docNextWert = next_value ? next_value : '1';

    const docPref = prefix
      ? prefix
      : date
        ? ''
        : docNextWert.length >= +min_length ? '' : '1';

    const docDatePref = date ? date : '';

    const calculateZero = +min_length - docNextWert.length - docDatePref.length - docPref.length;

    const nullErsatzes = calculateZero > 0 ? '0'.repeat(calculateZero) : '';

    const invoiceNumber = `${docPref}${date ? moment().format(date) : ''}${nullErsatzes}${next_value ? next_value : 1}`;

    setGeneratedExampleNumber(invoiceNumber);

  }, [date, min_length, next_value, prefix,]);


  const INPUT = [
    {
      name: "default_number_range.prefix",
      component: SVFormControl,
      label: "SETTINGS.DOCUMENT_ABBREVIATION",
      withFeedbackLabel: false,
    },
    {
      name: "default_number_range.date",
      component: SVReactSelect,
      options: documentNumberDateOptions,
      label: "SETTINGS.DOCUMENT_DATE",
    },
    {
      name: "default_number_range.next_value",
      component: SVFormControl,
      label: "SETTINGS.DOCUMENT_NEXT",
      withFeedbackLabel: false,
    },
    {
      name: "default_number_range.min_length",
      component: SVReactSelect,
      options: minLengthOptions,
      label: "SETTINGS.DOCUMENT_MIN",
      isClearable: false,
      isSearchable: false,
    }
  ]

  return (
    <Card className='gutter-b'>

      <CardSubHeader
        icon={<Icon.SortDownAlt />}
        title={<FormattedMessage id='SETTINGS.DOCUMENT_NUMBER_TITLE' />}
        info={<UserInfoDropdown
          title={<FormattedMessage id='DOCUMENT.INFO.DOCUMENT_TYPE.TITLE' />}
          description={intl({ id: 'DOCUMENT.INFO.DOCUMENT_TYPE.DESCRIPTION' }).split("#").map((item, i) =>
            ["href=", "<b>", "<br/>"].some(el => item.includes(el))
              ? <FormattedHTMLMessage key={i} id={item} values={{ what: 'react-intl', a: chunks => <a href='/'>{chunks}</a>, b: chunks => <strong>{chunks}</strong> }} />
              : <FormattedMessage key={i} id={item} />)
          }
        />}
      />

      <CardBody style={{ pointerEvents: document_count > 0 ? 'none' : 'auto' }}>

        <div className="alert alert-custom alert-notice alert-light-warning mb-7">
          <div className='font-weight-bold font-size-h5'>
            <FormattedMessage id="SETTINGS.DOCUMENT_REVIEW" /> : {generatedExampleNumber}
          </div>
        </div>

        <Row>
          {INPUT.map((item, i) => (
            <Col lg={3} key={i}>
              <Field component={item.component}
                {...{
                  name: item.name,
                  label: intl({ id: item.label }),
                  options: item.options,
                  withFeedbackLabel: item.withFeedbackLabel,
                  feedbackLabel: true,
                  isClearable: item.isClearable,
                  isSearchable: item.isSearchable,
                  disabled: document_count > 0 || loading,
                }}
              />
            </Col>
          ))}
        </Row>

      </CardBody>
    </Card>
  );
}
