import React, { useMemo, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Portal } from 'react-portal';
import { useDispatch, useSelector } from 'react-redux';
import { ProductActions } from '../../../_redux/actions';
import { useProductEditUIContext } from '../../../_context/ProductEditUIContext';
import { getVariants, Variant, VARIANT_ACTION_TYPES } from '../core/helpers';
import { clone } from 'lodash';
import { EditVariantForm } from './EditVariantForm';



const initialFormValues = (variants, variantId) => {

  if (!variantId || !variants || variants.length === 0) {
    return null;
  }
  const variant = variants.find(variant => variant.id === variantId);
  if (!variant) {
    return null;
  }

  const initialValues = {
    id: variant.id,
    quantity: variant.quantity || 0,
    gross_price: variant.pricing.gross_price || 0,
    purchase_price: variant.pricing.purchase_price || 0,
    sku: variant.sku || '',
    ean: variant.ean || '',
    asin: variant.asin || '',
  };
  for (const spec of clone(variant.specs)) {
    initialValues[spec.name] = spec.value;
  }
  return initialValues;
};




export const EditVariantDialog = () => {

  const dispatch = useDispatch();

  // Get state and fn from ProductEditUIContext
  const productEditUIContext = useProductEditUIContext();
  const { showEditVariantDialog, variantId } = useMemo(() => productEditUIContext.newVariantsReducer, [productEditUIContext.newVariantsReducer]);
  const dispatchNewVariants = useMemo(() => productEditUIContext.dispatchNewVariants, [productEditUIContext.dispatchNewVariants]);

  // Get Current Variants from Redux Store
  const productVariants = useSelector(state => state.products.variants);

  // Close Dialog
  const closeEditVariantDialog = () => dispatchNewVariants({ type: VARIANT_ACTION_TYPES.CLOSE_EDIT_VARIANT_DIALOG });

  // Button Ref for Quick Submit
  const onSubmitBtnRef = useRef(null);
  const onSubmitBtnRefClick = () => {
    if (onSubmitBtnRef.current) {
      onSubmitBtnRef.current.click();
    }
  };

  // Submit Form
  const saveNewVariant = values => {
    const { id, sku, ean, asin, quantity, gross_price, purchase_price, image_urls, ...specs } = values;
    const variants = clone(productVariants);
    const variantIndex = variants.findIndex(v => v.id === id);
    variants[variantIndex] = new Variant({
      sku,
      quantity,
      pricing: {
        purchase_price: purchase_price,
        gross_price: gross_price,
      },
      image_urls,
      specs: Object.keys(specs).map(key => {
        return { name: key, value: specs[key] };
      }),
    });
    dispatch(ProductActions.setVariants(variants));
    closeEditVariantDialog();
  };


  return (
    <Portal node={document && document.getElementById('modal-portal')}>
      <Modal show={showEditVariantDialog} onHide={closeEditVariantDialog} size="md" backdrop="static" keyboard={false}>

        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id='PRODUCT.VARIANT.ATTRIBUTES_SELECT' />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <EditVariantForm
            initialValues={initialFormValues(productVariants, variantId)}
            currentSpecs={getVariants(productVariants)}
            onSubmit={saveNewVariant}
            submitBtnRef={onSubmitBtnRef}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button variant='light' onClick={closeEditVariantDialog} id="btn_cancel_modal">
            <FormattedMessage id='GENERAL.CANCEL' />
          </Button>
          <Button variant='primary' className="ml-2" onClick={onSubmitBtnRefClick} id="btn_save_modal">
            <FormattedMessage id='GENERAL.SAVE' />
          </Button>
        </Modal.Footer>

      </Modal>

    </Portal>
  );
};
