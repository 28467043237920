import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SettingsActions } from '../_redux/actions';
import { StandardSettings } from './StandardSettings';
import { ProductKeysSettings } from './ProductKeysSettings';
import VariantSettings from './VariantSettings';
import { StockControl } from './StockControl';
import { Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';



export function ProductSettings({ productBtnRef }) {

  const dispatch = useDispatch();

  const { documentSettingsForEdit } = useSelector(state => ({
    documentSettingsForEdit: state.settings.documentSettingsForEdit,
  }));


  useEffect(() => {
    dispatch(SettingsActions.getUserSettingsByType('products'));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(SettingsActions.clearState());
    };
  }, [dispatch]);

  const handleSubmit = (values) => {

    const { default_product, stock_control, variant_options, digital_product } = values;

    if (digital_product?.mail_template?.length > 2000) {
      return toast.warning('Mail template must be at most 2000 characters')
    }

    const newValues = {
      settings_type: 'products',
      default_product,
      stock_control,
      variant_options,
      digital_product
    };

    documentSettingsForEdit.id
      ? dispatch(SettingsActions.updateUserSettings(documentSettingsForEdit.id, newValues))
      : dispatch(SettingsActions.createUserSettings(newValues))
  };


  return (
    <>
      <Formik enableReinitialize={true} initialValues={documentSettingsForEdit} onSubmit={handleSubmit}>
        <Form>

          <Row>
            <Col lg={6}>
              <StandardSettings />
            </Col>
            <Col lg={6}>
              <StockControl />
            </Col>
          </Row>

          <ProductKeysSettings />

          <button type="submit" className='d-none' ref={productBtnRef} />

        </Form>
      </Formik>

      <VariantSettings />
    </>
  );
}
