import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AuthRoutes, BaseRoutes } from 'constants/moduleRoutes';
import { LanguageSelectorDropdown, useHtmlClassService } from '../..';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import moment from 'moment';



export function AuthLayout({ children }) {

  const pathname = useLocation().pathname?.split('/')[1];

  const UIService = useHtmlClassService();
  const layoutProps = useMemo(() => ({
    headerLogo: UIService.getLogo(),
  }), [UIService]);


  return (
    <div className="d-flex flex-column flex-root">

      {/*begin::Login*/}
      <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white vh-100" id="kt_login">

        {/*begin::Aside*/}
        <StyledBackground className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10" image={'/media/bg/main-bg.webp'}>

          {/*begin: Aside Container*/}
          <div className="d-flex flex-row-fluid flex-column justify-content-between">

            {/* start:: Aside header */}
            <div className="flex-column-auto text-center mb-8">
              <Link to="/">
                <img alt="Logo" className="max-h-70px" src={layoutProps.headerLogo} />
              </Link>
            </div>
            {/* end:: Aside header */}


            {/* start:: Aside content */}
            <div className="flex-column-fluid d-flex flex-column justify-content-center my-10">
              <h1 className="text-white text-center mb-5">
                <FormattedMessage id="AUTH.GENERAL.WELCOME" />
              </h1>
              <div className="font-weight-lighter text-white text-center">
                <FormattedMessage id="AUTH.GENERAL.MESSAGE" />
              </div>
            </div>
            {/* end:: Aside content */}


            {/* start:: Aside footer for desktop */}
            <div className="d-none flex-column-auto d-lg-flex justify-content-around align-items-center">
              <LanguageSelectorDropdown />
              <Link to={BaseRoutes.PRIVACY} className="text-white px-2">
                <FormattedMessage id="AUTH.GENERAL.PRIVACY" />
              </Link>
              <Link to={BaseRoutes.IMPRINT} className="text-white px-2">
                <FormattedMessage id="AUTH.GENERAL.IMPRINT" />
              </Link>
              <Link to={BaseRoutes.TERMS} className="text-white px-2">
                <FormattedMessage id="AUTH.GENERAL.TERMS" />
              </Link>
              {pathname !== 'auth' &&
                <Link to={AuthRoutes.LOGIN} className="text-white px-2">
                  <FormattedMessage id="AUTH.GENERAL.LOGIN" />
                </Link>
              }
            </div>
            {/* end:: Aside footer for desktop */}


          </div>
          {/*end: Aside Container*/}
        </StyledBackground>
        {/*begin::Aside*/}

        {/*begin::Content*/}
        <div className="d-flex flex-row-fluid flex-column p-7 overflow-auto" id="content">


          {/* begin::Content body */}
          <div className={`d-flex flex-column-fluid flex-center mt-lg-0 ${pathname === 'auth' ? "mt-10" : ""}`}>
            {children}
          </div>
          {/*end::Content body*/}


          {/* begin::Mobile footer */}
          <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-around align-items-center mt-5 pt-5">
            <div className="text-dark-50 font-weight-bold order-2 order-sm-1">
              &copy; {moment().format('YYYY')} SaleVali
            </div>
            <div className="d-flex align-items-center order-1 order-sm-2 my-2">
              <LanguageSelectorDropdown />
              <Link to={BaseRoutes.PRIVACY} className="text-dark-75 text-hover-primary px-2">
                <FormattedMessage id="AUTH.GENERAL.PRIVACY" />
              </Link>
              <Link to={BaseRoutes.IMPRINT} className="text-dark-75 text-hover-primary px-2">
                <FormattedMessage id="AUTH.GENERAL.IMPRINT" />
              </Link>
              <Link to={BaseRoutes.TERMS} className="text-dark-75 text-hover-primary px-2">
                <FormattedMessage id="AUTH.GENERAL.TERMS" />
              </Link>
              {pathname !== 'auth' &&
                <Link to={AuthRoutes.LOGIN} className="text-dark-75 text-hover-primary px-2">
                  <FormattedMessage id="AUTH.GENERAL.LOGIN" />
                </Link>
              }
            </div>
          </div>
          {/* end::Mobile footer */}


        </div>
        {/*end::Content*/}
      </div>
      {/*end::Login*/}
    </div>
  );
}

const StyledBackground = styled.div`
  background-image: linear-gradient(0deg, rgba(145, 56, 158, 0.75), rgba(145, 56, 158, 0.75)),
    url(${props => props.image});
  @media (max-width: 992px) {
    clip-path: none;
  }
`;
