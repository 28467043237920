import React, { useEffect, useMemo } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ModalProgressBar } from '_metronic/_partials/loading';
import { useProductsUIContext } from '../_context/ProductsUIContext';
import { ProductActions } from '../_redux/actions';
import { FormattedMessage } from 'react-intl';


export function ProductDeleteDialog({ id, show, onHide }) {

  const dispatch = useDispatch();
  const UIContext = useProductsUIContext();
  const UIProps = useMemo(() => ({
    setIds: UIContext.setIds,
  }),
    [UIContext]
  );

  const { entities, loading } = useSelector(state => state.products, shallowEqual);

  // if !id we should close modal
  useEffect(() => {
    !id && onHide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const deleteProductAction = () => {
    dispatch(ProductActions.deleteProduct(id));
    UIProps.setIds([]);
    onHide();
  };

  const findProduct = entities.find(product => product.id === id)
  const keyCount = findProduct?.digital_product?.keys?.length ?? 0
  const isDigitalProduct = findProduct?.category?.key === 'digital_product' && keyCount > 0;

  return (
    <Modal show={show} onHide={onHide} size="sm" backdrop="static" aria-labelledby="example-modal-sizes-title-sm" centered>
      {loading && <ModalProgressBar variant="query" />}

      <Modal.Body className="text-center line-height-md h6 py-10 mb-0">
        <FormattedMessage id="PRODUCT.DELETE_BODY" />

        {isDigitalProduct &&
          <div className='mt-2 text-danger'>
            <FormattedMessage id="PRODUCT.DELETE.DIGITAL_PRODUCT_WARNING" values={{ count: keyCount }} />
          </div>
        }

      </Modal.Body>

      <Modal.Footer className="flex-nowrap p-0 overflow-hidden">

        <Button variant="ios" className="border-right" onClick={onHide} id="btn_cancel_modal">
          <FormattedMessage id="GENERAL.CANCEL" />
        </Button>

        <Button variant="ios" className="text-danger" onClick={deleteProductAction} id="btn_delete_modal">
          <FormattedMessage id="GENERAL.DELETE" />
        </Button>

      </Modal.Footer>

    </Modal>
  );
}
