import React, { createContext, useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { useSearchQuery } from 'app/hooks';
import { useIntl } from 'react-intl';



const SupportUIContext = createContext();
export const SupportUIConsumer = SupportUIContext.Consumer;
export const useSupportUIContext = () => useContext(SupportUIContext)



const initialStatus = { id: "", status: "" }

export function SupportUIProvider({ children }) {

  const history = useHistory();
  const { formatMessage: intl } = useIntl()

  const [ticketId, setTicketId] = useState();
  const [ticketStatus, setTicketStatus] = useState(initialStatus);

  const [sessionId, setSessionId] = useState();

  // query params
  const [queryParams, setQueryBase] = useState({
    pagination: {
      page: 1,
      limit: 10,
    },
    order: 'id DESC',
    search: '',
    filter: {
      status: 'all_tickets',
      tag: 'all_tags',
      priority: 'all_priorities',
      read: 'all_read',
      seen: 'all_read',
    },
  });

  const setQueryParams = useCallback((params) => setQueryBase(params), [])

  const { setSearchQuery, setFilterQuery, setPaginationQuery, setOrderQuery } = useSearchQuery(setQueryParams);

  const initialDateRange = {
    id: "clear-date",
    label: intl({ id: "GENERAL.CLEAR" }),
  };

  const [dateRange, setDateRange] = useState(initialDateRange);

  const changeDateRange = (date = initialDateRange) => setDateRange(date);

  const value = {
    ticketId,
    setTicketId,

    sessionId,
    setSessionId,

    ticketStatus,
    setTicketStatus,
    initialStatus,

    queryParams,
    setSearchQuery,
    setFilterQuery,
    setPaginationQuery,
    setOrderQuery,
    changeDateRange,
    dateRange,
    initialDateRange,
    setDateRange,

    openDeleteTicketDialog: (id) => history.push(ModuleRoutes.DELETE_TICKET_FN(id)),
    openEditTicketDialog: (id) => history.push(ModuleRoutes.EDIT_TICKET_FN(id)),
    openEditViewDialog: (id) => history.push(ModuleRoutes.EDIT_VIEW_FN(id)),
  };

  return (
    <SupportUIContext.Provider value={value}>
      {children}
    </SupportUIContext.Provider>
  )
}
