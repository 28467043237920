/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';



export function ActionsColumnFormatter(cellContent, row, rowIndex, { getAddProductsUrl, market_id }) {
  return (
    <>
      <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip"><FormattedMessage id="GENERAL.PREPARE" /></Tooltip>}>
        <Link className="btn btn-icon btn-hover-primary btn-sm"
          to={getAddProductsUrl({
            tokenId: market_id,
            asin: row.identities.asin,
            productId: row.id
          })}
          style={row.identities.asin ? null : { pointerEvents: 'none', opacity: 0.5 }}
          id={`btn_edit_${row.id}`}
        >
          <span className="svg-icon svg-icon-md">
            <Icon.PencilSquare />
          </span>
        </Link>
      </OverlayTrigger>

      <OverlayTrigger overlay={<Tooltip id="ebay-listing-delete-tooltip"><FormattedMessage id="GENERAL.DELETE" /></Tooltip>}>
        <a className="btn btn-icon btn-hover-danger btn-sm" onClick={() => console.log('delete', row.id)} id={`btn_delete_${row?.id}`}>
          <span className="svg-icon svg-icon-md">
            <Icon.Trash />
          </span>
        </a>
      </OverlayTrigger>
    </>
  );
}
