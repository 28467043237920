import React, { useMemo } from "react";
import { Modal } from "react-bootstrap";
import { useSupplierUIContext } from "../../../_context/SupplierUIContext";
import { AddressEditForm } from "./AddressEditForm";
import { FormattedMessage } from "react-intl";
import { v4 as uuidv4 } from 'uuid';



export function AddressEditDialog() {

  // Address UI Context
  const UIContext = useSupplierUIContext();
  const UIProps = useMemo(() => ({
    id: UIContext.selectedId,
    show: UIContext.showEditAddressDialog,
    onHide: UIContext.closeEditAddressDialog,
    INITIAL_ADDRESS: UIContext.INITIAL_ADDRESS,
    addressForEdit: UIContext.addressForEdit,
    createAddressHandler: UIContext.createAddressHandler,
    updateAddressHandler: UIContext.updateAddressHandler,
  }), [UIContext]);


  const handleSubmit = (values) => {
    UIProps.id
      ? UIProps.updateAddressHandler(values)
      : UIProps.createAddressHandler({ ...values, id: uuidv4() })

    UIProps.onHide();
  };

  
  return (
    <Modal size="lg" show={UIProps.show} onHide={UIProps.onHide} aria-labelledby="example-modal-sizes-title-lg" backdrop="static" keyboard={false} centered>

      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id={UIProps.id ? "ADDRESS.TITLE" : "ADDRESS.TITLE_NEW"} />
        </Modal.Title>
      </Modal.Header>

      <AddressEditForm handleSubmit={handleSubmit} address={UIProps.addressForEdit ? UIProps.addressForEdit : UIProps.INITIAL_ADDRESS} onHide={UIProps.onHide} />
    </Modal>
  );
}
