/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { convertPriceCurrency } from '_metronic/_helpers';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';



export const ActionFormatterVariant = (cellContent, row, rowIndex, { deletePositionAction, createPositionAction, deleteLength, isDeleteIds }) => {
  if (!isDeleteIds?.includes(row.id)) {
    return (
      <OverlayTrigger placement='right' overlay={<Tooltip id="products-delete-tooltip"><FormattedMessage id="GENERAL.DELETE" /></Tooltip>}>
        <a className={`btn btn-icon btn-hover-danger btn-sm ${deleteLength - isDeleteIds?.length === 1 ? 'disabled' : ''}`} onClick={() => row.sku != null && deletePositionAction(row.id)} id={`btn_delete_${row?.id}`}>
          <span className="svg-icon svg-icon-md">
            <Icon.Trash />
          </span>
        </a>
      </OverlayTrigger>
    )
  } else {
    return (
      <OverlayTrigger placement='right' overlay={<Tooltip id="products-add-tooltip"><FormattedMessage id="GENERAL.ADD" /></Tooltip>}>
        <a className={`btn btn-icon btn-hover-success btn-sm`} onClick={() => row.sku != null && createPositionAction(row.id)} id={`btn_add_${row?.id}`}>
          <span className="svg-icon svg-icon-md">
            <Icon.Plus />
          </span>
        </a>
      </OverlayTrigger>
    )
  }
}


export const ActionFormatterCategory = (cellContent, row, rowIndex, { deletePositionAction }) => (
  !row.isRequired
    ? <OverlayTrigger overlay={<Tooltip id="products-delete-tooltip"><FormattedMessage id="GENERAL.DELETE" /></Tooltip>}>
      <a className="btn btn-icon btn-hover-danger btn-sm" onClick={() => { deletePositionAction(row.id) }} id={`btn_delete_${row?.id}`}>
        <span className="svg-icon svg-icon-md">
          <Icon.Trash />
        </span>
      </a>
    </OverlayTrigger>
    : <></>
);

export const ActionFormatterGrossPrice = (cell, row, isDeleted) => {
  const price = +row.pricing?.gross_price;
  return (
    <div type={isDeleted ? 'button' : 'text'} className={isDeleted ? 'edit-table' : 'pr-4'}>
      {convertPriceCurrency(price)}
    </div>
  );
}

export function ActionFormatterNetPrice(cell, row, tax) {
  const netPrice = (+row.pricing?.gross_price / (1 + +tax / 100));
  return (
    <div type='text' className='pr-4'>
      {convertPriceCurrency(netPrice)}
    </div>
  );
};

export const ActionFormatterSpecs = (cell, row) => {
  const specs = row.specs;
  if (Array.isArray(specs)) {
    return cell.map((spec, i) => (
      <label className="text-capitalize label label-inline label-light-primary m-1" key={i}>
        {spec.value}
      </label>
    ))
  }
}


export const ActionFormatterQuantity = (cell, row, isDeleted) => {
  return (
    <div type={isDeleted ? 'button' : 'text'} className={isDeleted ? 'edit-table' : ''}>
      {cell}
    </div>
  );
}