import React, { useEffect, useMemo } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { ProductsPage } from './ProductsPage';
import { ProductEdit } from './product-edit/ProductEdit';
import { ContentRoute } from '_metronic/layout';
import { ModuleRoutes, RELATIVE_PATH } from 'constants/moduleRoutes';
import { useProductsUIContext } from './_context/ProductsUIContext';



export default function Products() {

  const UIContext = useProductsUIContext();
  const UIProps = useMemo(() => ({
    setIds: UIContext.setIds,
  }), [UIContext]);

  useEffect(() => {
    return () => { UIProps.setIds([]); };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Switch>

      <Redirect exact={true} from={RELATIVE_PATH} to={ModuleRoutes.PRODUCT} />

      <ContentRoute path={ModuleRoutes.EDIT_PRODUCT} component={ProductEdit} />
      <ContentRoute path={ModuleRoutes.NEW_PRODUCT} component={ProductEdit} />
      <ContentRoute path={ModuleRoutes.COPY_PRODUCT} component={ProductEdit} />
      <ContentRoute path={ModuleRoutes.PRODUCT} component={ProductsPage} />
    </Switch>
  );
}
