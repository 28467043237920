import moment from 'moment';



export const UIDates = {
  getCurrentDate: (format = 'YYYY-MM-DD') => moment().format(format),
  addDate: (date, add, unit) => moment(date).add(add, unit),
  formatDate: (date, format = 'DD.MM.YYYY') => moment(date).format(format),
  formatDateTime: (date, format = 'DD.MM.YYYY HH:mm') => moment(date).format(format),

  getCurrentDateTime: (format = 'DD.MM.YYYY HH:mm') => moment().format(format),
  hourComparison: (dateString, diffHour) => { return moment().diff(moment(dateString), 'hours') <= diffHour },
  getYear: date => moment(date).year(),

  addDateTime: (date, add, unit) => moment(date).add(add, unit),
  getDateDiff: (date1, date2, unit) => moment(date1).diff(date2, unit),

  isWeekend: date => moment(date).day() === 0 || moment(date).day() === 6,
  isToday: date => moment(date).isSame(moment(), 'day'),
  isBetween: (date, start, end) => moment(date).isBetween(start, end, 'day', '[]'),

  exportName: (date) => moment(date).format("YYYY.MM.DD_HH.mm.ss"),

  dayCalculation: (day) => moment().add(day, "d").format("YYYY-MM-DD"),

  dateFormatter: (date) => moment(date).format(date?.length > 10 ? "DD/MM/YYYY, hh:mm" : "DD/MM/YYYY")
};