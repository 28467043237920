import React from 'react';
import { Route } from 'react-router-dom';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { ConnectionDeleteDialog, FetchOrdersDialog} from './connection-dialogs';
import { ConnectionEditDialog } from './connection-dialogs/ConnectionEditDialog';
import { ConnectionCard } from './ConnectionCard';



export const ConnectionPage = () => {
  return (
    <>
      <ConnectionCard />
      <FetchOrdersDialog />
      <ConnectionEditDialog/>
  
      <Route path={ModuleRoutes.CONNECTIONS_DELETE}>
        {({ history, match }) => (
          <ConnectionDeleteDialog
            show={match != null}
            id={match && match.params.connectionId}
            confirmName={match && match.params.connectionName}
            onHide={() => history.push(ModuleRoutes.CONNECTIONS)}
          />
        )}
      </Route>
    </>
  );
};
