import React, { useEffect, useRef, useMemo } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardHeaderToolbar, } from '_metronic/_partials/controls';
import { CreationAndUpdateInfo, BackButton, ResetButton, SaveButton } from '_metronic/_partials';
import { useCustomersUIContext } from '../_context/CustomersUIContext';
import { UserGuideHelper } from '../../../user-guides/UserGuideHelper';
import { capitalizeFirstLetter, utils } from '_metronic/_helpers';
import { CustomerNotAvailableDialog } from '../customer-dialogs';
import { FormattedMessage, useIntl } from 'react-intl';
import { LayoutSplashScreen } from '_metronic/layout';
import { CustomerActions } from '../_redux/actions';
import { GeneralCard } from './general/GeneralCard';
import { AddressCard } from './address/AddressCard';
import { SpecialCard } from './special/SpecialCard';
import { FormikStepper } from './FormikHandler';
import { useLoadingHandler } from 'app/hooks';
import { BankCard } from './bank/BankCard';
import { toast } from 'react-toastify';
import Navigator from './Navigator';



export function CustomerEditChildren({ onHide, show, modal }) {

  const { id } = useParams();
  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  const UIContext = useCustomersUIContext();
  const UIProps = useMemo(() => ({
    address: UIContext.address,
    cleanUpContextHandler: UIContext.cleanUpContextHandler,
    openNewAddressDialog: UIContext.openEditAddressDialogHandler,
  }),
    [
      UIContext.address,
      UIContext.cleanUpContextHandler,
      UIContext.openEditAddressDialogHandler,
    ]
  );


  const { loading, customerForEdit, findCustomerForMatching, success } = useSelector(state => ({
    loading: state.customers.loading,
    customerForEdit: state.customers.customerForEdit,
    customers: state.customers.entities,
    findCustomerForMatching: state.customers.findCustomerForMatching,
    success: state.customers.success,
  }),
    shallowEqual
  );
  const isLoading = useLoadingHandler(loading, CustomerActions.clearLoadingEffects());

  // if customer id existed =>
  useEffect(() => {
    id && dispatch(CustomerActions.getCustomerById(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);



  useEffect(() => {
    return () => {
      setTimeout(() => {
        dispatch(CustomerActions.resetFormHandler());
        UIProps.cleanUpContextHandler();
      }, 500);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveCustomer = (values) => {

    if (findCustomerForMatching.length > 0) {
      const _customer = findCustomerForMatching[0];
      return toast.error(<FormattedMessage id='CUSTOMER.ERROR_MESSAGE_CUSTOMER.MATCHING' values={{ email: _customer?.email }} />);
    }

    if (!UIProps.address || UIProps.address.length === 0) {
      toast.error(<FormattedMessage id='SUPPLIER.ALERT' />);
      UIProps.openNewAddressDialog()
      return
    }

    if (UIProps.address.length === 1) {
      const newAddress = {
        ...UIProps.address[0],
        id: utils.uuidv4(),
        type: UIProps.address[0].type === 'address_delivery' ? 'address_invoice' : 'address_delivery',
      };
      UIProps.address.push(newAddress);
    }

    values.name = capitalizeFirstLetter(values.name ?? '');
    values.company = capitalizeFirstLetter(values.company ?? '');
    values.source.buyer_id = values.source.buyer_id ? values.source.buyer_id : values.email;

    const customer = {
      ...values,
      address: UIProps.address,
    };

    if (id) {
      dispatch(CustomerActions.updateCustomer(customer));
    } else {
      dispatch(CustomerActions.createCustomer(customer));
    }

  };


  const btnRef = useRef();
  const saveCustomerClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const resetBtnRef = useRef();
  const resetFormHandler = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(intl({ id: "GENERAL.CONFIRM" }))) {
      if (resetBtnRef && resetBtnRef.current) {
        resetBtnRef.current.click();
      }
      dispatch(CustomerActions.resetFormHandler());
      UIProps.cleanUpContextHandler();
    }
  };


  return (<>
    {isLoading && <LayoutSplashScreen />}

    <Card className={!show ? "card-box" : ""}>

      <CardHeader
        back={<BackButton onClick={onHide} />}
        title={<FormattedMessage id={id ? 'CUSTOMER.UPDATE_CUSTOMER' : 'CUSTOMER.NEW_CUSTOMER'} />}
        info={!show ? <UserGuideHelper /> : null}
        id={id ? 'title_update_customer' : 'title_new_customer'}
        sticky={!show ? true : false}
      >
        <CardHeaderToolbar>

          {!(customerForEdit && id) && <ResetButton onClick={resetFormHandler} />}

          <SaveButton onClick={saveCustomerClick} disabled={isLoading} />

        </CardHeaderToolbar>

      </CardHeader>

      <CardBody className={!show && !modal ? "overflow-auto card-height" : "py-5"}>
        <FormikStepper initialValues={customerForEdit} btnRef={btnRef} resetBtnRef={resetBtnRef} saveCustomer={saveCustomer} loading={loading} success={success} onClose={() => { onHide(); dispatch(CustomerActions.resetFormHandler()) }}>

          {(!show && !modal)
            ? <>
              <GeneralCard customerForEdit={customerForEdit} loading={isLoading} />
              <BankCard loading={isLoading} />
              <AddressCard loading={isLoading} />
              <SpecialCard loading={isLoading} />
            </>
            : <Navigator />
          }

        </FormikStepper>
      </CardBody>

      <CreationAndUpdateInfo data={customerForEdit} />
      <CustomerNotAvailableDialog customerForEdit={customerForEdit} />

    </Card>
  </>
  );
}
