export default class ActionTypes {
  static get PREFIX() {
    return '[product]';
  }

  
  // Create Product action types
  static get CREATE_PRODUCT() {
    return `${ActionTypes.PREFIX}CREATE_PRODUCT`;
  }
  static get CREATE_PRODUCT_SUCCESS() {
    return `${ActionTypes.PREFIX}CREATE_PRODUCT_SUCCESS`;
  }
  static get CREATE_PRODUCT_FAIL() {
    return `${ActionTypes.PREFIX}CREATE_PRODUCT_FAIL`;
  }


  // Update Product
  static get UPDATE_PRODUCT() {
    return `${ActionTypes.PREFIX}UPDATE_PRODUCT`;
  }
  static get UPDATE_PRODUCT_SUCCESS() {
    return `${ActionTypes.PREFIX}UPDATE_PRODUCT_SUCCESS`;
  }
  static get UPDATE_PRODUCT_FAIL() {
    return `${ActionTypes.PREFIX}UPDATE_PRODUCT_FAIL`;
  }


  // Update Product
  static get UPDATE_PRODUCT_FROM_TABLE_FIELD() {
    return `${ActionTypes.PREFIX}UPDATE_PRODUCT_FROM_TABLE_FIELD`;
  }
  static get UPDATE_PRODUCT_FROM_TABLE_FIELD_SUCCESS() {
    return `${ActionTypes.PREFIX}UPDATE_PRODUCT_FROM_TABLE_FIELD_SUCCESS`;
  }
  static get UPDATE_PRODUCT_FROM_TABLE_FIELD_FAIL() {
    return `${ActionTypes.PREFIX}UPDATE_PRODUCT_FROM_TABLE_FIELD_FAIL`;
  }


  // Patch Product
  static get PATCH_PRODUCT_BY_FIELD() {
    return `${ActionTypes.PREFIX}PATCH_PRODUCT_BY_FIELD`;
  }
  static get PATCH_PRODUCT_BY_FIELD_SUCCESS() {
    return `${ActionTypes.PREFIX}PATCH_PRODUCT_BY_FIELD_SUCCESS`;
  }
  static get PATCH_PRODUCT_BY_FIELD_FAIL() {
    return `${ActionTypes.PREFIX}PATCH_PRODUCT_BY_FIELD_FAIL`;
  }


  // Delete Product
  static get DELETE_PRODUCT() {
    return `${ActionTypes.PREFIX}DELETE_PRODUCT`;
  }
  static get DELETE_PRODUCT_SUCCESS() {
    return `${ActionTypes.PREFIX}DELETE_PRODUCT_SUCCESS`;
  }
  static get DELETE_PRODUCT_FAIL() {
    return `${ActionTypes.PREFIX}DELETE_PRODUCT_FAIL`;
  }


  // Delete Product
  static get DELETE_ALL_SELECTED_PRODUCTS() {
    return `${ActionTypes.PREFIX}DELETE_ALL_SELECTED_PRODUCTS`;
  }
  static get DELETE_ALL_SELECTED_PRODUCTS_SUCCESS() {
    return `${ActionTypes.PREFIX}DELETE_ALL_SELECTED_PRODUCTS_SUCCESS`;
  }
  static get DELETE_ALL_SELECTED_PRODUCTS_FAIL() {
    return `${ActionTypes.PREFIX}DELETE_ALL_SELECTED_PRODUCTS_FAIL`;
  }


  // Get Products
  static get GET_PRODUCTS() {
    return `${ActionTypes.PREFIX}GET_PRODUCTS`;
  }
  static get GET_PRODUCTS_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_PRODUCTS_SUCCESS`;
  }
  static get GET_PRODUCTS_FAIL() {
    return `${ActionTypes.PREFIX}GET_PRODUCTS_FAIL`;
  }


  // Get Product
  static get GET_PRODUCT_BY_ID() {
    return `${ActionTypes.PREFIX}GET_PRODUCT_BY_ID`;
  }
  static get GET_PRODUCT_BY_ID_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_PRODUCT_BY_ID_SUCCESS`;
  }
  static get GET_PRODUCT_BY_ID_FAIL() {
    return `${ActionTypes.PREFIX}GET_PRODUCT_BY_ID_FAIL`;
  }


  // Find Product by SKU
  static get FIND_PRODUCT_BY_SKU() {
    return `${ActionTypes.PREFIX}FIND_PRODUCT_BY_SKU`;
  }
  static get FIND_PRODUCT_BY_SKU_SUCCESS() {
    return `${ActionTypes.PREFIX}FIND_PRODUCT_BY_SKU_SUCCESS`;
  }
  static get FIND_PRODUCT_BY_SKU_FAIL() {
    return `${ActionTypes.PREFIX}FIND_PRODUCT_BY_SKU_FAIL`;
  }


  // Find Product by property
  static get FIND_PRODUCT_BY_PROPERTY() {
    return `${ActionTypes.PREFIX}FIND_PRODUCT_BY_PROPERTY`;
  }
  static get FIND_PRODUCT_BY_PROPERTY_SUCCESS() {
    return `${ActionTypes.PREFIX}FIND_PRODUCT_BY_PROPERTY_SUCCESS`;
  }
  static get FIND_PRODUCT_BY_PROPERTY_FAIL() {
    return `${ActionTypes.PREFIX}FIND_PRODUCT_BY_PROPERTY_FAIL`;
  }


  // Find Product by property
  static get FIND_PRODUCT_BY_PROPERTY_WARNING() {
    return `${ActionTypes.PREFIX}FIND_PRODUCT_BY_PROPERTY_WARNING`;
  }
  static get FIND_PRODUCT_BY_PROPERTY_BARCODE_SUCCESS() {
    return `${ActionTypes.PREFIX}FIND_PRODUCT_BY_PROPERTY_BARCODE_SUCCESS`;
  }
  static get FIND_PRODUCT_BY_PROPERTY_EAN_SUCCESS() {
    return `${ActionTypes.PREFIX}FIND_PRODUCT_BY_PROPERTY_EAN_SUCCESS`;
  }
  static get FIND_PRODUCT_BY_PROPERTY_GTIN_SUCCESS() {
    return `${ActionTypes.PREFIX}FIND_PRODUCT_BY_PROPERTY_GTIN_SUCCESS`;
  }
  static get FIND_PRODUCT_BY_PROPERTY_ISBN_SUCCESS() {
    return `${ActionTypes.PREFIX}FIND_PRODUCT_BY_PROPERTY_ISBN_SUCCESS`;
  }
  static get FIND_PRODUCT_BY_PROPERTY_ASIN_SUCCESS() {
    return `${ActionTypes.PREFIX}FIND_PRODUCT_BY_PROPERTY_ASIN_SUCCESS`;
  }
  static get FIND_PRODUCT_BY_PROPERTY_UPC_SUCCESS() {
    return `${ActionTypes.PREFIX}FIND_PRODUCT_BY_PROPERTY_UPC_SUCCESS`;
  }
  static get FIND_PRODUCT_BY_PROPERTY_SERIENNUMMER_SUCCESS() {
    return `${ActionTypes.PREFIX}FIND_PRODUCT_BY_PROPERTY_SERIENNUMMER_SUCCESS`;
  }
  static get FIND_PRODUCT_BY_PROPERTY_BARCODE_FAIL() {
    return `${ActionTypes.PREFIX}FIND_PRODUCT_BY_PROPERTY_BARCODE_FAIL`;
  }


  // Create Product Container for images and files
  static get CREATE_PRODUCT_CONTAINER() {
    return `${ActionTypes.PREFIX}CREATE_PRODUCT_CONTAINER`;
  }
  static get CREATE_PRODUCT_CONTAINER_SUCCESS() {
    return `${ActionTypes.PREFIX}CREATE_PRODUCT_CONTAINER_SUCCESS`;
  }
  static get CREATE_PRODUCT_CONTAINER_FAIL() {
    return `${ActionTypes.PREFIX}CREATE_PRODUCT_CONTAINER_FAIL`;
  }


  // Image Upload
  static get IMAGE_UPLOAD() {
    return `${ActionTypes.PREFIX}IMAGE_UPLOAD`;
  }
  static get IMAGE_UPLOAD_SUCCESS() {
    return `${ActionTypes.PREFIX}IMAGE_UPLOAD_SUCCESS`;
  }
  static get IMAGE_UPLOAD_FAIL() {
    return `${ActionTypes.PREFIX}IMAGE_UPLOAD_FAIL`;
  }


  // Image Delete
  static get DELETE_FILE() {
    return `${ActionTypes.PREFIX}DELETE_FILE`;
  }
  static get DELETE_FILE_SUCCESS() {
    return `${ActionTypes.PREFIX}DELETE_FILE_SUCCESS`;
  }
  static get DELETE_FILE_FAIL() {
    return `${ActionTypes.PREFIX}DELETE_FILE_FAIL`;
  }


  // Get Lieferant
  static get GET_SUPPLIER() {
    return `${ActionTypes.PREFIX}GET_SUPPLIER`;
  }
  static get GET_SUPPLIER_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_SUPPLIER_SUCCESS`;
  }
  static get GET_SUPPLIER_FAIL() {
    return `${ActionTypes.PREFIX}GET_SUPPLIER_FAIL`;
  }


  // Description Handler
  /* static get SHORT_DESCRIPTION_HANDLER() {
    return `${ActionTypes.PREFIX}SHORT_DESCRIPTION_HANDLER`;
  } */
  static get DESCRIPTION_HANDLER() {
    return `${ActionTypes.PREFIX}DESCRIPTION_HANDLER`;
  }


  // Update Stoks by Shops
  static get UPDATE_STOCK_BY_SHOPS() {
    return `${ActionTypes.PREFIX}UPDATE_STOCK_BY_SHOPS`;
  }
  static get UPDATE_STOCK_BY_SHOPS_SUCCESS() {
    return `${ActionTypes.PREFIX}UPDATE_STOCK_BY_SHOPS_SUCCESS`;
  }
  static get UPDATE_STOCK_BY_SHOPS_FAIL() {
    return `${ActionTypes.PREFIX}UPDATE_STOCK_BY_SHOPS_FAIL`;
  }


  // Add Key
  static get ADD_PRODUCT_KEYS() {
    return `${ActionTypes.PREFIX}ADD_PRODUCT_KEYS`;
  }
  static get ADD_PRODUCT_KEYS_SUCCESS() {
    return `${ActionTypes.PREFIX}ADD_PRODUCT_KEYS_SUCCESS`;
  }
  static get ADD_PRODUCT_KEYS_FAIL() {
    return `${ActionTypes.PREFIX}ADD_PRODUCT_KEYS_FAIL`;
  }


  // delete Key
  static get DELETE_PRODUCT_KEYS() {
    return `${ActionTypes.PREFIX}DELETE_PRODUCT_KEYS`;
  }
  static get DELETE_PRODUCT_KEYS_SUCCESS() {
    return `${ActionTypes.PREFIX}DELETE_PRODUCT_KEYS_SUCCESS`;
  }
  static get DELETE_PRODUCT_KEYS_FAIL() {
    return `${ActionTypes.PREFIX}DELETE_PRODUCT_KEYS_FAIL`;
  }


  // Import Example action types
  static get IMPORT_EXAMPLE_PRODUCT_KEYS() {
    return `${ActionTypes.PREFIX}IMPORT_EXAMPLE_PRODUCT_KEYS`;
  }
  static get IMPORT_EXAMPLE_PRODUCT_KEYS_SUCCESS() {
    return `${ActionTypes.PREFIX}IMPORT_EXAMPLE_PRODUCT_KEYS_SUCCESS`;
  }
  static get IMPORT_EXAMPLE_PRODUCT_KEYS_FAIL() {
    return `${ActionTypes.PREFIX}IMPORT_EXAMPLE_PRODUCT_KEYS_FAIL`;
  }


  // guides Handler
  static get ADD_PRODUCT_GUIDES() {
    return `${ActionTypes.PREFIX}ADD_PRODUCT_GUIDES`;
  }


  // Reset Handler
  static get RESET_FORM_HANDLER() {
    return `${ActionTypes.PREFIX}RESET_FORM_HANDLER`;
  }


  // {Pictures} Handler
  static get PICTURES_HANDLER() {
    return `${ActionTypes.PREFIX}PICTURES_HANDLER`;
  }


  // Clear loading
  static get CLEAR_LOADING_EFFECTS() {
    return `${ActionTypes.PREFIX}CLEAR_LOADING_EFFECTS`;
  }
  static get CLEAR_REQUEST() {
    return `${ActionTypes.PREFIX}CLEAR_REQUEST`;
  }


  // New Variant Types
  static get SET_VARIANTS() {
    return `${ActionTypes.PREFIX}SET_VARIANTS`;
  }
}
