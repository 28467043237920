import React, { useMemo, useEffect } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import { Field } from "formik";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, useIntl, } from "react-intl";
import { useSelector, shallowEqual } from "react-redux";
import { Card, CardBody, CardSubHeader, SVReactSelectInput } from "_metronic/_partials/controls";
import { useProductListingsUIContext } from "../../../_context/ProductListingsUIContext";
import { getAspectRows, getVariantOptionsKeysAndValues, /*getVariantOptionsKeysAndValues*/ } from "../../KauflandListingsUIHelper";
import * as columnFormatters from '../ColumnFormatters';
import { Spinner } from "_metronic/_partials";



export default function KauflandCategory({ method, market_id, loading }) {

  const { formatMessage: intl } = useIntl();

  const UIContext = useProductListingsUIContext();
  const UIProps = useMemo(() => ({
    categoryInputValue: UIContext.categoryInputValue,
    setCategoryInputValue: UIContext.setCategoryInputValue,
    selectedCategoryAspects: UIContext.selectedCategoryAspects,
    setCategoryAspects: UIContext.setCategoryAspects,
  }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [UIContext.categoryInputValue, UIContext.selectedCategoryAspects]
  );

  const { productForEdit, /*productVariants,*/ categorySuggestions, isLoaded } = useSelector(state => ({
    productForEdit: state.listings.productForEdit,
    categorySuggestions: state.listings.categorySuggestions,
    isLoaded: state.listings.isLoaded,
    // productVariants: state.products.variants,
  }), shallowEqual);

  useEffect(() => {

    if (categorySuggestions?.length > 0 && productForEdit) {
      if (method === "new") {
        const firstCategory = categorySuggestions[0];
        onCategoryChangeHandler({ value: firstCategory.id_category, label: firstCategory.title_singular });
      } else if (method === "edit") {
        const listingCategory = productForEdit.kauflandInventory?.category?.id;
        const categoryFind = categorySuggestions.find(item => item.id_category === listingCategory);
        const category = categoryFind ? categoryFind : categorySuggestions[0];
        onCategoryChangeHandler({ value: category.id_category, label: category.title_singular });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, categorySuggestions, productForEdit, method, market_id]);

  useEffect(() => {
    if (productForEdit?.kauflandInventory) {
      const listing = productForEdit?.kauflandInventory;
      const aspects = listing?.aspects;

      UIProps.setCategoryInputValue({
        value: listing.category.id,
        label: listing.category.name,
      });

      const findCategory = listing.suggestions.categorySuggestions.find(
        cs => cs.id_category === listing.category.id
      );

      if (findCategory) {
        const variantOptions = getVariantOptionsKeysAndValues(productForEdit.variantOptionen);

        const mapAspect = (aspect, isRequired) => {
          const { title, type } = aspect;
          const mode = type === 'Bool' ? 'SELECTION_ONLY' : 'FREE_TEXT';
          const findMatchedAspect = productForEdit.features.find(f => f.name === title);
          const isAspectNameInVariantOptions = variantOptions.find(option => option.value === title);
          const getSavedMerkmale = aspects.find(asp => asp.name === title);

          if (
            isAspectNameInVariantOptions ||
            title === 'EAN' ||
            title === 'Titel' ||
            title === 'Beschreibung' ||
            title === 'Kurzbeschreibung' ||
            title === 'Bild' ||
            title === 'Kategorie'
          ) {
            return null;
          }

          let herstellerName = '';
          let herstellerNummer = '';

          if (title === 'Hersteller') {
            herstellerName = productForEdit?.brand ? productForEdit.brand : getSavedMerkmale?.value || '';
          }
          if (title === 'Herstellernummer') {
            herstellerNummer = productForEdit?.identities?.gtin ? productForEdit.identities.gtin : getSavedMerkmale?.value || '';
          }

          return getAspectRows({
            isRequired,
            options: mode === 'SELECTION_ONLY' && ['Ja', 'Nein'],
            name: title,
            value: findMatchedAspect
              ? findMatchedAspect.value
              : title === 'Hersteller'
                ? herstellerName
                : title === 'Herstellernummer'
                  ? herstellerNummer
                  : getSavedMerkmale?.value || '',
            mode,
            dataType: 'string',
          });
        };

        const selectedAspectsRequired = findCategory.required_attributes
          ?.map(aspect => mapAspect(aspect, true))
          .filter(aspect => aspect !== null);

        const selectedAspectsOptional = findCategory.optional_attributes
          ?.map(aspect => mapAspect(aspect, false))
          .filter(aspect => aspect !== null);

        const concatenatedAspects = [...selectedAspectsRequired, ...selectedAspectsOptional];
        UIProps.setCategoryAspects(concatenatedAspects);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorySuggestions, productForEdit]);


  const onCategoryChangeHandler = (cat) => {
    UIProps.setCategoryInputValue(cat);

    const findCategory = categorySuggestions?.find(cs => cs.id_category === cat.value);

    if (findCategory) {
      const variantOptions = getVariantOptionsKeysAndValues(productForEdit.variantOptionen);

      const mapAspect = (aspect, isRequired) => {
        const { title, type } = aspect;
        const mode = type === 'Bool' ? 'SELECTION_ONLY' : 'FREE_TEXT';
        const findMatchedAspect = productForEdit.features?.find(f => f.name === title);
        const isAspectNameInVariantOptions = variantOptions?.find(option => option.value === title);

        if (
          isAspectNameInVariantOptions ||
          title === 'EAN' ||
          title === 'Titel' ||
          title === 'Beschreibung' ||
          title === 'Bild' ||
          title === 'Kurzbeschreibung' ||
          title === 'Kategorie'
        ) {
          return null;
        }

        return getAspectRows({
          isRequired,
          name: title,
          options: mode === 'SELECTION_ONLY' && ['Ja', 'Nein'],
          value: findMatchedAspect
            ? findMatchedAspect.value
            : title === 'Hersteller'
              ? productForEdit?.brand
              : title === 'Herstellernummer'
                ? productForEdit?.identities?.gtin
                : '',
          mode,
          dataType: 'string',
        });
      };

      const selectedAspectsRequired = findCategory.required_attributes
        ?.map(aspect => mapAspect(aspect, true))
        .filter(aspect => aspect !== null);

      const selectedAspectsOptional = findCategory.optional_attributes
        ?.map(aspect => mapAspect(aspect, false))
        .filter(aspect => aspect !== null);

      const concatenatedAspects = [...selectedAspectsRequired, ...selectedAspectsOptional];
      UIProps.setCategoryAspects(concatenatedAspects);
    }
  };

  const aspectColumns = [
    {
      dataField: 'position',
      isDummyField: true,
      text: intl({ id: 'GENERAL.NO' }),
      headerStyle: { width: '5%', textAlign: 'center' },
      classes: 'text-center',
      formatter: (cell, row, rowIndex, formatExtraData) => { return rowIndex + 1; },
    },
    {
      dataField: 'isRequired',
      text: intl({ id: 'GENERAL.STATUS' }),
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <label className={`label label-inline label-sm label-${cell ? 'success' : 'light'}`}>
          <FormattedMessage id={cell ? 'GENERAL.REQUIRED' : 'GENERAL.OPTIONAL'} />
        </label>
      ),
      headerStyle: { width: '15%', textAlign: 'center' },
      classes: 'text-center',
    },
    {
      dataField: 'name',
      text: intl({ id: 'GENERAL.NAME' }),
      headerStyle: { width: '15%', textAlign: 'center' },
      classes: 'text-center',
    },
    {
      dataField: 'value',
      text: intl({ id: 'PRODUCT.EBAY.VALUE' }),
      formatter: (cell, row, rowIndex, formatExtraData) => (
        row.mode === 'SELECTION_ONLY'
          ? <select className="form-control form-control-sm"
            onChange={e => UIProps.setCategoryAspects(prev => {
              prev[rowIndex].value = e.target.value; return prev;
            })}
          >
            <option value="" style={{ display: 'none' }}>{intl({ id: 'PRODUCT_LISTINGS.EBAY.SELECT_VALUE' })}</option>
            {row.options != null && row.options.length > 0 && row.options.map(val => (
              <option key={val} value={val}>{val}</option>
            ))}
          </select>
          : <input
            className="form-control form-control-sm"
            defaultValue={row.value}
            onBlur={e => UIProps.setCategoryAspects(prev => {
              prev[rowIndex].value = e.target.value; return prev;
            })}
          />
      ),
      headerStyle: { width: '45%', textAlign: 'center' },
    },
    {
      dataField: 'action',
      text: intl({ id: 'GENERAL.ACTIONS' }),
      headerClasses: 'text-center',
      formatter: columnFormatters.ActionFormatterCategory,
      headerStyle: { width: '10%', textAlign: 'center' },
      classes: 'text-center',
      formatExtraData: { deletePositionAction: id => UIProps.setCategoryAspects(prev => prev.filter(aspect => aspect.id !== id)) },
    },
  ];

  return (
    <Card>

      <CardSubHeader title={<FormattedMessage id="PRODUCT.CATEGORIES" />} />

      <CardBody>

        <Row>
          <Col sm="12">
            <div className="font-weight-bold mb-2">
              <FormattedMessage id="PRODUCT.EBAY.CATEGORY_TITLE" /> <span className="text-danger">*</span>
            </div>
            <Field component={SVReactSelectInput}
              {...{
                name: 'categorySelect',
                label: intl({ id: 'PRODUCT.EBAY.CATEGORY_TITLE' }),
                value: (isLoaded || method === "edit") && UIProps.categoryInputValue,
                onChange: value => onCategoryChangeHandler(value),
                options: categorySuggestions.map(category => ({
                  value: category.id_category,
                  label: category.title_singular,
                })),
                withFeedbackLabel: true,
                isLoading: categorySuggestions?.length > 0 ? false : true,
                required: true,
                disabled: loading
              }}
            />
          </Col>

          <Col sm="12" className="mt-5">
            <div className="font-weight-bold mb-2">
              <FormattedMessage id="PRODUCT_LISTINGS.EBAY.FEATURE" />
            </div>
            <BootstrapTable
              keyField="id"
              data={(isLoaded || method === "edit") ? UIProps.selectedCategoryAspects : []}
              columns={aspectColumns}
              wrapperClasses="table-responsive"
              headerClasses="bg-info-o-10 border-0"
              classes="table table-head-custom table-vertical-center overflow-hidden my-0"
              bootstrap4
              bordered={true}
              condensed={false}
            />
          </Col>

          {!isLoaded && method === 'new' && (
            <Col sm="12" className="py-20 text-center">
              <Spinner loading={!isLoaded && method === 'new'} color="primary" className="spinner-lg" />
            </Col>
          )}
        </Row>

      </CardBody>
    </Card>
  )
}
