
import React, { useState, useEffect } from 'react';
import { LICENSE_STATUS, ACCOUNT_STATUS_FILTER } from '../../_context/AdminUIHelper';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { CONNECTION_SETTINGS } from 'constants/connectionSettings';
import { SearchControl } from '_metronic/_partials/controls';
import { DatePickerDropdown } from '../DatePickerDropdown';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { theme } from '_metronic/_helpers';
import { Icon } from '_metronic/_icons';
import { useQuery } from 'app/hooks';
import clsx from 'clsx';



export function ReportsUserFilter({ UIProps, reports }) {

  const query = useQuery();
  const [search, setSearch] = useState(query.get('search') ?? '');

  const [filterParamsBase, setFilterParamsBase] = useState({
    status: "active",
    license: "all_licenses",
    marketplace: null,
  });

  useEffect(() => {
    const status = query.get('filter.status');
    const license = query.get('filter.license');
    const marketplace = query.get('filter.marketplace');

    setFilterParamsBase(prev => ({
      ...prev,
      status: ACCOUNT_STATUS_FILTER.map(item => item.value).includes(status) ? status : "active",
      license: LICENSE_STATUS.map(item => item.value).includes(license) ? license : "all_licenses",
      marketplace: CONNECTION_SETTINGS.map(item => item.name).includes(marketplace) ? marketplace : null,
    }));

  }, [query])

  useEffect(() => {
    setSearch(query.get('search') ?? '');
  }, [query]);

  const handleSearch = (values) => {
    setSearch(values);
    UIProps.setSearchQuery(values);
  }

  const onFilterLicenseHandler = (value) => {
    UIProps.setFilterQuery('license', value);
    setFilterParamsBase(prev => ({
      ...prev,
      license: value,
    }));
  };

  const onFilterAccountStatusHandler = (value) => {
    UIProps.setFilterQuery('status', value);
    setFilterParamsBase(prev => ({
      ...prev,
      status: value,
    }));
  };

  const onFilterConnectionHandler = (value) => {
    UIProps.setFilterQuery('marketplace', value)
    setFilterParamsBase(prev => ({
      ...prev,
      marketplace: value
    }))
  }

  const onDeleteFilterHandler = (key, value) => {
    // if (key === 'date_from' || key === 'date_to') {
    //   return onDateSwitcherHandler(false);
    // }
    setFilterParamsBase(prev => ({
      ...prev,
      [key]: false,
    }));
    UIProps.setFilterQuery('DELETE_PARAMS', key);
  };

  const getFilteredConnections = () => {
    const allConnectionsInReports = reports.flatMap(report => report.connections.map(conn => conn.name));
    return CONNECTION_SETTINGS.filter(setting => allConnectionsInReports.includes(setting.name));
  };

  const filteredConnections = getFilteredConnections()
    .filter(token => token.active && !['dhl_warenpost', 'dhl_new_warenpost'].includes(token.name))
    .sort((a, b) => {
      const typeOrder = { marketplace: 0, shop: 1, shipper: 2 };
      const typeComparison = typeOrder[a.type] - typeOrder[b.type];
      if (typeComparison === 0) {
        return a.name.localeCompare(b.name);
      }
      return typeComparison;
    });

  const selectMarketplace = filteredConnections?.find(i => i.name === filterParamsBase.marketplace);

  return (
    <Row className="mb-3">

      <Col sm="12" md="6" lg="4" className="mb-lg-0 mb-2">
        <SearchControl search={search} handleSearch={handleSearch} />
      </Col>

      <Col sm="12" md="6" lg="8" className='d-flex align-items-start justify-content-start px-md-0'>

        <Dropdown className="dropdown-inline" drop="down">
          <Dropdown.Toggle variant="outline-secondary" className="d-flex align-items-center svg-icon svg-icon-sm font-weight-bold" style={{ height: theme.units.input.height['sm'] }} id="dropdown-basic">
            <span className={clsx("label label-xl label-dot mr-3", `label-${LICENSE_STATUS.find(item => item.value === filterParamsBase.license)?.className}`)}></span>
            {LICENSE_STATUS.find(i => i.value === filterParamsBase?.license)?.label}
          </Dropdown.Toggle>

          <Dropdown.Menu className='dropdown-menu-right dropdown-menu-anim-down' id='dropdown_document_grouping'>
            <ul className="navi navi-hover">
              {LICENSE_STATUS.map((filter) => (
                <div key={filter.value}>
                  <li className="navi-item">
                    {filter.value === 'all_licenses' && <Dropdown.Divider />}
                    <Dropdown.Item className='navi-link font-weight-bold' id={`dropdown_item_${filter.value}`}
                      onClick={() => onFilterLicenseHandler(filter.value)}>
                      <span className={clsx("label label-xl label-dot mr-1", `label-${filter.className}`)}></span>
                      <span className="navi-text">
                        {filter.label}
                      </span>
                    </Dropdown.Item>
                  </li>
                </div>
              ))}
            </ul>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown className="dropdown-inline ml-3" drop="down">
          <Dropdown.Toggle variant="outline-secondary" className="d-flex align-items-center svg-icon svg-icon-sm font-weight-bold" style={{ height: theme.units.input.height['sm'] }} id="dropdown-basic">
            <span className={clsx("label label-xl label-dot mr-3", `label-${ACCOUNT_STATUS_FILTER.find(item => item.value === UIProps.queryParams.filter?.status)?.className}`)}></span>
            {ACCOUNT_STATUS_FILTER.find(i => i.value === UIProps.queryParams.filter?.status)?.label}
          </Dropdown.Toggle>

          <Dropdown.Menu className='dropdown-menu-right dropdown-menu-anim-down' id='dropdown_document_grouping'>
            <ul className="navi navi-hover">
              {ACCOUNT_STATUS_FILTER.map((filter) => (
                <li className="navi-item" key={filter.value}>
                  {filter.value === 'all_statuses' && <Dropdown.Divider />}
                  <Dropdown.Item className='navi-link font-weight-bold' id={`dropdown_item_${filter.value}`}
                    onClick={() => onFilterAccountStatusHandler(filter.value)}>
                    <span className={clsx("label label-xl label-dot mr-1", `label-${filter.className}`)}></span>
                    <span className="navi-text">
                      {filter.label}
                    </span>
                  </Dropdown.Item>
                </li>
              ))}
            </ul>
          </Dropdown.Menu>
        </Dropdown>

        {/* --------------------------------------- Connections filter started --------------------------------------- */}
        <Dropdown className="dropdown-inline symbol mx-3" drop="down">

          <Dropdown.Toggle variant="outline-secondary" className='d-flex justify-content-between align-items-center font-weight-bold' style={{ height: theme.units.input.height['sm'] }} id="dropdown-basic">
            <span className="svg-icon svg-icon-sm mr-3">
              {selectMarketplace?.icon ?? <Icon.Connection />}
            </span>
            {selectMarketplace?.label ?? <FormattedMessage id="MENU.ASIDE.CONNECTIONS" />}
          </Dropdown.Toggle>

          {filterParamsBase.marketplace && <i className="symbol-badge bg-danger"></i>}
          <Dropdown.Menu className="dropdown-menu-right dropdown-menu-anim-down" id="dropdown_market_options">
            <ul className="navi navi-hover">
              {filteredConnections.length
                ? <>
                  {filteredConnections?.map((opt, index) => (
                    <li className="navi-item" key={index}>
                      <Dropdown.Item className="navi-link" onClick={() => onFilterConnectionHandler(opt.name)}
                        id={`dropdown_item_${opt.name}`}>
                        <span className="svg-icon svg-icon-md mr-2">
                          {opt.icon}
                        </span>
                        <span className="navi-text text-nowrap font-weight-bold">
                          {opt.label}
                        </span>
                      </Dropdown.Item>
                    </li>
                  ))}

                  <Dropdown.Divider />

                  <li className="navi-item">
                    <Dropdown.Item
                      className='navi-link font-weight-bolder text-danger justify-content-center bg-white py-1'
                      onClick={() => onDeleteFilterHandler('marketplace', CONNECTION_SETTINGS.find(i => i.name === filterParamsBase.marketplace)?.name)}
                      id='clear-marketplace'
                    >
                      <FormattedMessage id='GENERAL.CLEAR' />
                    </Dropdown.Item>
                  </li>
                </>
                : <li className="navi-item px-3">
                  <span className="svg-icon svg-icon-sm text-info text-hover-primary mr-2">
                    <Icon.InfoCircle />
                  </span>
                  <span className="navi-text">
                    <FormattedHTMLMessage id="DOCUMENT.SHIPPING.NO_MARKETPLACE_FILTER" values={{ url: ModuleRoutes.CONNECTIONS, a: chunks => <a href='/'>{chunks}</a> }} />
                  </span>
                </li>
              }
            </ul>
          </Dropdown.Menu>
        </Dropdown>

        {/* --------------------------------------- Connections filter ended --------------------------------------- */}

        <DatePickerDropdown setFilterQuery={UIProps.setFilterQuery} />
      </Col>

    </Row>
  );
}
