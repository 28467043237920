import ActionTypes from './actionTypes';
import { initialProductKey, initialProductValues, initialRequestValues } from '../_context/ProductsUIHelpers';
import { utils } from '_metronic/_helpers';
import { clone } from 'lodash';



const INITIAL_STATE = {
  loading: false,
  listLoading: false,
  searchLoading: false,
  updateFieldLoading: false,
  findPropertyLoading: false,
  entities: [],
  suppliers: [],
  countProducts: 0,
  totalProducts: 0,
  error: '',
  productForEdit: initialProductValues,
  //shortDescription: '',
  description: '',
  productImages: [],
  digitalProductKeys: {},
  searchText: '',
  variants: [],
  findProductBySku: [],
  findProductForMatching: '',
  findProductForMatchingBarcode: '',
  findProductForMatchingEan: '',
  findProductForMatchingGtin: '',
  findProductForMatchingIsbn: '',
  findProductForMatchingAsin: '',
  findProductForMatchingUpc: '',
  findProductForMatchingSeriennummer: '',
  existedProductImages: [],
  request: initialRequestValues,
  imageDeleted: false,
};



export function productsReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {

    //  Create Product
    case ActionTypes.CREATE_PRODUCT:
      return {
        ...state,
        loading: true,
        request: {
          ...state.request,
          loading: true,
          id: payload.requestId,
        },
      };

    case ActionTypes.CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        request: {
          ...state.request,
          loading: false,
          status: 'success',
        },
        countProducts: state.countProducts + 1,
        totalProducts: state.totalProducts + 1,
        error: '',
      };

    case ActionTypes.CREATE_PRODUCT_FAIL: {
      return {
        ...state,
        loading: false,
        error: 'PRODUCT.ERROR_MESSAGE_CREATE',
        request: {
          ...state.request,
          loading: false,
          status: 'error',
          error: JSON.stringify(payload.error),
        },
      };
    }



    // Delete Product
    case ActionTypes.DELETE_PRODUCT:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        entities: state.entities.filter(product => product.id !== payload.id),
        countProducts: state.countProducts - 1,
        totalProducts: state.totalProducts - 1,
      };

    case ActionTypes.DELETE_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        error: 'PRODUCT.ERROR_MESSAGE_DELETE',
      };



    // Update Product
    case ActionTypes.UPDATE_PRODUCT:
      return {
        ...state,
        loading: true,
        request: {
          ...state.request,
          loading: true,
          id: payload.requestId,
        },
      };

    case ActionTypes.UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        request: {
          ...state.request,
          loading: false,
          status: 'success',
        },
        error: '',
      };

    case ActionTypes.UPDATE_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        request: {
          ...state.request,
          loading: false,
          status: 'error',
          error: typeof payload.error.message === 'string' ? payload.error.message : JSON.stringify(payload.error),
        },
      };



    // Update Product from field
    case ActionTypes.UPDATE_PRODUCT_FROM_TABLE_FIELD:
      return {
        ...state,
        updateFieldLoading: true,
      };

    case ActionTypes.UPDATE_PRODUCT_FROM_TABLE_FIELD_SUCCESS:
      return {
        ...state,
        updateFieldLoading: false,
      };

    case ActionTypes.UPDATE_PRODUCT_FROM_TABLE_FIELD_FAIL:
      return {
        ...state,
        updateFieldLoading: false,
      };



    // Update Product
    case ActionTypes.PATCH_PRODUCT_BY_FIELD:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.PATCH_PRODUCT_BY_FIELD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };

    case ActionTypes.PATCH_PRODUCT_BY_FIELD_FAIL:
      return {
        ...state,
        loading: false,
        error: 'PRODUCT.ERROR_MESSAGE_UPDATE',
      };



    // Get Products
    case ActionTypes.GET_PRODUCTS:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        updateFieldLoading: false,
        entities: payload.products,
        countProducts: payload.count.count,
        totalProducts: payload.count.total,
      };

    case ActionTypes.GET_PRODUCTS_FAIL:
      return {
        ...state,
        loading: false,
        updateFieldLoading: false,
        error: 'GENERAL.ERROR_MESSAGE_GENERAL',
      };



    // Find Product BY Sku for Variant
    case ActionTypes.FIND_PRODUCT_BY_SKU:
      return {
        ...state,
        findPropertyLoading: true,
      };

    case ActionTypes.FIND_PRODUCT_BY_SKU_SUCCESS:
      const currentFindProductBySku = clone(state.findProductBySku);
      return {
        ...state,
        findPropertyLoading: false,
        findProductBySku: currentFindProductBySku.concat(payload.product),
      };

    case ActionTypes.FIND_PRODUCT_BY_SKU_FAIL:
      return {
        ...state,
        findPropertyLoading: false,
      };



    // Find Product Property
    case ActionTypes.FIND_PRODUCT_BY_PROPERTY:
      return {
        ...state,
        findPropertyLoading: true,
      };

    case ActionTypes.FIND_PRODUCT_BY_PROPERTY_SUCCESS:
      return {
        ...state,
        findPropertyLoading: false,
        findProductForMatching: payload.product.length > 0 && payload.product[0],
      };

    case ActionTypes.FIND_PRODUCT_BY_PROPERTY_FAIL:
      return {
        ...state,
        findPropertyLoading: false,
        error: 'GENERAL.ERROR_MESSAGE_GENERAL',
      };



    // Find Product Property Warning
    case ActionTypes.FIND_PRODUCT_BY_PROPERTY_WARNING:
      return {
        ...state,
        findPropertyLoading: true,
      };

    case ActionTypes.FIND_PRODUCT_BY_PROPERTY_BARCODE_SUCCESS:
      return {
        ...state,
        findPropertyLoading: false,
        findProductForMatchingBarcode: payload.product,
      };

    case ActionTypes.FIND_PRODUCT_BY_PROPERTY_EAN_SUCCESS:
      return {
        ...state,
        findPropertyLoading: false,
        findProductForMatchingEan: payload.product,
      };

    case ActionTypes.FIND_PRODUCT_BY_PROPERTY_GTIN_SUCCESS:
      return {
        ...state,
        findPropertyLoading: false,
        findProductForMatchingGtin: payload.product,
      };

    case ActionTypes.FIND_PRODUCT_BY_PROPERTY_ISBN_SUCCESS:
      return {
        ...state,
        findPropertyLoading: false,
        findProductForMatchingIsbn: payload.product,
      };

    case ActionTypes.FIND_PRODUCT_BY_PROPERTY_ASIN_SUCCESS:
      return {
        ...state,
        findPropertyLoading: false,
        findProductForMatchingAsin: payload.product,
      };

    case ActionTypes.FIND_PRODUCT_BY_PROPERTY_UPC_SUCCESS:
      return {
        ...state,
        findPropertyLoading: false,
        findProductForMatchingUpc: payload.product,
      };

    case ActionTypes.FIND_PRODUCT_BY_PROPERTY_SERIENNUMMER_SUCCESS:
      return {
        ...state,
        findPropertyLoading: false,
        findProductForMatchingSeriennummer: payload.product,
      };

    case ActionTypes.FIND_PRODUCT_BY_PROPERTY_BARCODE_FAIL:
      return {
        ...state,
        findPropertyLoading: false,
        error: 'GENERAL.ERROR_MESSAGE_GENERAL',
      };



    // Get Supplier
    case ActionTypes.GET_SUPPLIER:
      return {
        ...state,
      };

    case ActionTypes.GET_SUPPLIER_SUCCESS:
      return {
        ...state,
        suppliers: payload.suppliers,
      };

    case ActionTypes.GET_SUPPLIER_FAIL:
      return {
        ...state,
        error: payload.error,
      };



    // Get Product By Id
    case ActionTypes.GET_PRODUCT_BY_ID:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.GET_PRODUCT_BY_ID_SUCCESS: {
      const { images, digital_product, /* short_description, */ description, id, variants, quantity, ...restProduct } = payload.product;

      return {
        ...state,
        loading: false,
        productForEdit: { ...restProduct, images, variants, quantity: payload.isCopy && digital_product ? 0 : quantity },
        productImages: Array.isArray(images) && images.length > 0 ? images : [],
        existedProductImages: Array.isArray(images) && images.length > 0 ? images : [],
        //shortDescription: short_description,
        description: description,
        digitalProductKeys: payload.isCopy ? null : digital_product,
        variants: variants,
      };
    }

    case ActionTypes.GET_PRODUCT_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: 'GENERAL.ERROR_MESSAGE_GENERAL',
      };



    // Update products by shops
    case ActionTypes.UPDATE_STOCK_BY_SHOPS:
      return {
        ...state,
      };

    case ActionTypes.UPDATE_STOCK_BY_SHOPS_SUCCESS:
      return {
        ...state,
      };

    case ActionTypes.UPDATE_STOCK_BY_SHOPS_FAIL:
      return {
        ...state,
      };



    // Delete Image
    case ActionTypes.DELETE_FILE:
      return {
        ...state,
        loading: true,
        imageDeleted: false,
      };

    case ActionTypes.DELETE_FILE_SUCCESS:

      let updatedFile = {};
      if (payload.fileType === 'product_images') {
        updatedFile = {
          productForEdit: {
            ...state.productForEdit,
            images: state.productForEdit.images.filter(pic => pic.id !== payload.fileId),
          },
        };
      }

      if (payload.fileType === 'product_guides') {
        updatedFile = {
          digitalProductKeys: {
            ...state.digitalProductKeys,
            guides: state.digitalProductKeys.guides.filter(guide => guide.id !== payload.fileId),
          }
        };
      }

      return {
        ...state,
        loading: false,
        imageDeleted: true,
        ...updatedFile,
      };

    case ActionTypes.DELETE_FILE_FAIL:
      return {
        ...state,
        loading: false,
        imageDeleted: false,
      };



    // Short and Long Description Handler
    /* case ActionTypes.SHORT_DESCRIPTION_HANDLER:
      return {
        ...state,
        shortDescription: payload.data,
      }; */
    case ActionTypes.DESCRIPTION_HANDLER:
      return {
        ...state,
        description: payload.data,
      };



    // Pictures Handler
    case ActionTypes.PICTURES_HANDLER:
      return {
        ...state,
        productImages: payload.image,
        // productForEdit: {
        //   ...state.productForEdit,
        //   images: payload.image.filter(img => !img.file),
        // },
      };



    // add keys
    case ActionTypes.ADD_PRODUCT_KEYS:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.ADD_PRODUCT_KEYS_SUCCESS:
      const startTime = new Date().getTime()
      const newKeys = payload.productId
        ? payload.keys
        : payload.keys.map((item, index) => ({
          id: utils.uuidv4(),
          ...initialProductKey,
          key: item,
          created_at: new Date(startTime + index)
        }));

      return {
        ...state,
        loading: false,
        digitalProductKeys: {
          ...state.digitalProductKeys,
          keys: [...newKeys, ...(state.digitalProductKeys?.keys || [])]
        }
      };

    case ActionTypes.ADD_PRODUCT_KEYS_FAIL:
      return {
        ...state,
        loading: false,
        error: 'PRODUCT.ERROR_MESSAGE_DELETE',
      };



    //* delete key Row
    case ActionTypes.DELETE_PRODUCT_KEYS:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.DELETE_PRODUCT_KEYS_SUCCESS:
      return {
        ...state,
        loading: false,
        digitalProductKeys: {
          ...state.digitalProductKeys,
          keys: state.digitalProductKeys.keys.filter(key => !payload.res?.deletedKeys.includes(key.id))
        }
      };

    case ActionTypes.DELETE_PRODUCT_KEYS_FAIL:
      return {
        ...state,
        loading: false,
        error: 'PRODUCT.ERROR_MESSAGE_DELETE',
      };



    // Import Example
    case ActionTypes.IMPORT_EXAMPLE_PRODUCT_KEYS:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.IMPORT_EXAMPLE_PRODUCT_KEYS_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case ActionTypes.IMPORT_EXAMPLE_PRODUCT_KEYS_FAIL:
      return {
        ...state,
        loading: false,
        error: 'IMPORT_EXPORT.EXAMPLE_UPLOAD_ERROR',
      };


    // Pdf Handler
    case ActionTypes.ADD_PRODUCT_GUIDES:
      return {
        ...state,
        digitalProductKeys: {
          ...state.digitalProductKeys,
          guides: payload.guides
        }
      };


    // Clean Up Form Handler
    case ActionTypes.RESET_FORM_HANDLER:
      return {
        ...INITIAL_STATE
      };



    // Clean Up Form Handler
    case ActionTypes.CLEAR_LOADING_EFFECTS:
      return {
        ...state,
        loading: false,
        listLoading: false,
        searchLoading: false,
        updateFieldLoading: false,
        findPropertyLoading: false,
      };



    // Clear Request
    case ActionTypes.CLEAR_REQUEST: {
      return {
        ...state,
        request: initialRequestValues,
      };
    }



    // New Variants Ceses
    case ActionTypes.SET_VARIANTS: {
      return {
        ...state,
        variants: payload.variants,
      };
    }



    default:
      return state;
  }
}
