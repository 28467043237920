export default class ActionTypes {
  static get PREFIX() {
    return '[connection]';
  }



  // Get connections action types
  static get GET_CONNECTIONS() {
    return `${ActionTypes.PREFIX}GET_CONNECTIONS`;
  }
  static get GET_CONNECTIONS_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_CONNECTIONS_SUCCESS`;
  }
  static get GET_CONNECTIONS_FAIL() {
    return `${ActionTypes.PREFIX}GET_CONNECTIONS_FAIL`;
  }


  // Cron Job Start action types
  static get CONNECTION_CHECK() {
    return `${ActionTypes.PREFIX}CONNECTION_CHECK`;
  }
  static get CONNECTION_CHECK_SUCCESS() {
    return `${ActionTypes.PREFIX}CONNECTION_CHECK_SUCCESS`;
  }
  static get CONNECTION_CHECK_FAIL() {
    return `${ActionTypes.PREFIX}CONNECTION_CHECK_FAIL`;
  }


  // connections fetch orders action types
  static get CONNECTION_FETCH_ORDERS() {
    return `${ActionTypes.PREFIX}CONNECTION_FETCH_ORDERS`;
  }
  static get CONNECTION_FETCH_ORDERS_SUCCESS() {
    return `${ActionTypes.PREFIX}CONNECTION_FETCH_ORDERS_SUCCESS`;
  }
  static get CONNECTION_FETCH_ORDERS_FAIL() {
    return `${ActionTypes.PREFIX}CONNECTION_FETCH_ORDERS_FAIL`;
  }


  // Create settings action types
  static get CREATE_SETTINGS() {
    return `${ActionTypes.PREFIX}CREATE_SETTINGS`;
  }
  static get CREATE_SETTINGS_SUCCESS() {
    return `${ActionTypes.PREFIX}CREATE_SETTINGS_SUCCESS`;
  }
  static get CREATE_SETTINGS_FAIL() {
    return `${ActionTypes.PREFIX}CREATE_SETTINGS_FAIL`;
  }


  // Update settings action types
  static get UPDATE_SETTINGS() {
    return `${ActionTypes.PREFIX}UPDATE_SETTINGS`;
  }
  static get UPDATE_SETTINGS_SUCCESS() {
    return `${ActionTypes.PREFIX}UPDATE_SETTINGS_SUCCESS`;
  }
  static get UPDATE_SETTINGS_FAIL() {
    return `${ActionTypes.PREFIX}UPDATE_SETTINGS_FAIL`;
  }


  // Get settings action types
  static get GET_SETTINGS() {
    return `${ActionTypes.PREFIX}GET_SETTINGS`;
  }
  static get GET_SETTINGS_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_SETTINGS_SUCCESS`;
  }
  static get GET_SETTINGS_FAIL() {
    return `${ActionTypes.PREFIX}GET_SETTINGS_FAIL`;
  }


  // Get settings by id action types
  static get GET_CONNECTION_BY_ID() {
    return `${ActionTypes.PREFIX}GET_CONNECTION_BY_ID`;
  }
  static get GET_CONNECTION_BY_ID_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_CONNECTION_BY_ID_SUCCESS`;
  }
  static get GET_CONNECTION_BY_ID_FAIL() {
    return `${ActionTypes.PREFIX}GET_CONNECTION_BY_ID_FAIL`;
  }


  // Delete settings action types
  static get DELETE_SETTINGS() {
    return `${ActionTypes.PREFIX}DELETE_SETTINGS`;
  }
  static get DELETE_SETTINGS_SUCCESS() {
    return `${ActionTypes.PREFIX}DELETE_SETTINGS_SUCCESS`;
  }
  static get DELETE_SETTINGS_FAIL() {
    return `${ActionTypes.PREFIX}DELETE_SETTINGS_FAIL`;
  }


  //* ---------------  DEUTSCHE POST --------------- *//

  // Deutche Post Configs action types
  static get GET_DEUTSCHE_POST_CONFIGS() {
    return `${ActionTypes.PREFIX}GET_DEUTSCHE_POST_CONFIGS`;
  }
  static get GET_DEUTSCHE_POST_CONFIGS_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_DEUTSCHE_POST_CONFIGS_SUCCESS`;
  }
  static get GET_DEUTSCHE_POST_CONFIGS_FAIL() {
    return `${ActionTypes.PREFIX}GET_DEUTSCHE_POST_CONFIGS_FAIL`;
  }


  //* -------------------  DHL ------------------- *//

  /*   static get DELETE_DHL_SUBSCRIPTION() {
      return `${ActionTypes.PREFIX}DELETE_DHL_SUBSCRIPTION`;
    } */


  //* -------------------  EBAY -------------------- *//

  // Get Ebay auth token action types
  static get GET_EBAY_AUTH_TOKEN() {
    return `${ActionTypes.PREFIX}GET_EBAY_AUTH_TOKEN`;
  }
  static get GET_EBAY_AUTH_TOKEN_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_EBAY_AUTH_TOKEN_SUCCESS`;
  }
  static get GET_EBAY_AUTH_TOKEN_FAIL() {
    return `${ActionTypes.PREFIX}GET_EBAY_AUTH_TOKEN_FAIL`;
  }


  // Set Ebay auth token action types
  static get SET_EBAY_AUTH_TOKEN() {
    return `${ActionTypes.PREFIX}SET_EBAY_AUTH_TOKEN`;
  }
  static get SET_EBAY_AUTH_TOKEN_SUCCESS() {
    return `${ActionTypes.PREFIX}SET_EBAY_AUTH_TOKEN_SUCCESS`;
  }
  static get SET_EBAY_AUTH_TOKEN_FAIL() {
    return `${ActionTypes.PREFIX}SET_EBAY_AUTH_TOKEN_FAIL`;
  }


  //* ------------------  AMAZON ------------------ *//

  // Get Amazon auth token action types
  static get SET_AMAZON_AUTH_TOKEN() {
    return `${ActionTypes.PREFIX}SET_AMAZON_AUTH_TOKEN`;
  }
  static get SET_AMAZON_AUTH_TOKEN_SUCCESS() {
    return `${ActionTypes.PREFIX}SET_AMAZON_AUTH_TOKEN_SUCCESS`;
  }
  static get SET_AMAZON_AUTH_TOKEN_FAIL() {
    return `${ActionTypes.PREFIX}SET_AMAZON_AUTH_TOKEN_FAIL`;
  }



  static get GET_AMAZON_AUTH_TOKEN() {
    return `${ActionTypes.PREFIX}GET_AMAZON_AUTH_TOKEN`;
  }
  static get GET_AMAZON_AUTH_TOKEN_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_AMAZON_AUTH_TOKEN_SUCCESS`;
  }
  static get GET_AMAZON_AUTH_TOKEN_FAIL() {
    return `${ActionTypes.PREFIX}GET_AMAZON_AUTH_TOKEN_FAIL`;
  }


  static get GET_AMAZON_CONNECTION() {
    return `${ActionTypes.PREFIX}GET_AMAZON_CONNECTION`;
  }
  static get GET_AMAZON_CONNECTION_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_AMAZON_CONNECTION_SUCCESS`;
  }
  static get GET_AMAZON_CONNECTION_FAIL() {
    return `${ActionTypes.PREFIX}GET_AMAZON_CONNECTION_FAIL`;
  }


  static get AMAZON_SETTINGS() {
    return `${ActionTypes.PREFIX}AMAZON_SETTINGS`;
  }


  //* --------------------  ETSY -------------------- *//

  // Set Etsy auth token action types
  static get SET_ETSY_AUTH_TOKEN() {
    return `${ActionTypes.PREFIX}SET_ETSY_AUTH_TOKEN`;
  }
  static get SET_ETSY_AUTH_TOKEN_SUCCESS() {
    return `${ActionTypes.PREFIX}SET_ETSY_AUTH_TOKEN_SUCCESS`;
  }
  static get SET_ETSY_AUTH_TOKEN_FAIL() {
    return `${ActionTypes.PREFIX}SET_ETSY_AUTH_TOKEN_FAIL`;
  }


  // Get Etsy auth token action types
  static get GET_ETSY_AUTH_TOKEN() {
    return `${ActionTypes.PREFIX}GET_ETSY_AUTH_TOKEN`;
  }
  static get GET_ETSY_AUTH_TOKEN_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_ETSY_AUTH_TOKEN_SUCCESS`;
  }
  static get GET_ETSY_AUTH_TOKEN_FAIL() {
    return `${ActionTypes.PREFIX}GET_ETSY_AUTH_TOKEN_FAIL`;
  }


  // Clear loading action types
  static get CLEAR_LOADING_EFFECTS() {
    return `${ActionTypes.PREFIX}CLEAR_LOADING_EFFECTS`;
  }


  // Clear loading action types
  static get CLEAR_STATES_IN_STORE() {
    return `${ActionTypes.PREFIX}CLEAR_STATES_IN_STORE`;
  }

}
