import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl';
import { Card, CardBody, CardHeaderToolbar, CardSubHeader } from '_metronic/_partials/controls';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useUserUIContext } from '../../_context/UserUIContext';
import { Icon } from '_metronic/_icons';



export function NoteCard({ id, note }) {

  const UIContext = useUserUIContext();
  const UIProps = useMemo(() => ({
    openUserNoteDialog: UIContext.openUserNoteDialog,
  }),
    [UIContext.openUserNoteDialog]
  );

  return (
    <Card className="card-stretch gutter-b">

      <CardSubHeader icon={<Icon.Clipboard />} title={<FormattedMessage id="DOCUMENT.TABLE.COLUMN.NOTE" />}>
        <CardHeaderToolbar>
          <OverlayTrigger overlay={<Tooltip id="admin-user-note"><FormattedMessage id="GENERAL.EDIT" /></Tooltip>}>
            <Button variant='outline-primary' size='sm' className="svg-icon svg-icon-sm" onClick={() => UIProps.openUserNoteDialog({ id, note })} id="btn_edit_note">
              <Icon.PencilSquare />
            </Button>
          </OverlayTrigger>
        </CardHeaderToolbar>
      </CardSubHeader>

      <CardBody>
        {note
          ? <div className="line-height-lg bg-gray-100 text-dark-75 rounded h-100 p-5">
            {note}
          </div>
          : null}
      </CardBody>

    </Card>
  );
}