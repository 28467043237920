import ActionTypes from "./actionTypes";



export class ReportActions {

  // Get Order list Data
  static getOrderList = (dateRange) => {
    return {
      type: ActionTypes.GET_ORDER_LIST,
      payload: {
        dateRange
      },
    };
  };

  static getOrderListSuccess = (data) => {
    return {
      type: ActionTypes.GET_ORDER_LIST_SUCCESS,
      payload: {
        data
      },
    };
  };

  static getOrderListFail = () => {
    return {
      type: ActionTypes.GET_ORDER_LIST_FAIL,
    };
  };

  // Get Email Logs Data
  static getEmailLogs = (dateRange,queryParams) => {
    return {
      type: ActionTypes.GET_EMAIL_LOGS,
      payload: {
        dateRange,
        queryParams
      },
    };
  };

  static getEmailLogsSuccess = (data, count) => {
    return {
      type: ActionTypes.GET_EMAIL_LOGS_SUCCESS,
      payload: {
        data,
        count
      },
    };
  };

  static getEmailLogsFail = () => {
    return {
      type: ActionTypes.GET_EMAIL_LOGS_FAIL,
    };
  };

}
