import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { ShippingsActions } from "../_redux/actions";
import { Icon } from '_metronic/_icons';
import SelectCounter from "_metronic/_partials/indicators/SelectCounter";
import { useShippingsUIContext } from "../_context/ShippingsUIContext";



export function ShippingsGrouping() {

  const dispatch = useDispatch();

  const UIContext = useShippingsUIContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    openPrintLabelsDialog: UIContext.openPrintLabelsDialog,

  }), [UIContext]);

  const openPrintLabelsDialog = () => {
    if (UIProps.ids.length) {
      dispatch(ShippingsActions.getShipping(UIProps.ids))
      UIProps.openPrintLabelsDialog("all-labels");
    }
  };


  return (
    <div className="d-flex flex-wrap justify-content-end mb-2">

      <SelectCounter count={UIProps.ids.length} />

      <Button variant="light-primary" className="svg-icon svg-icon-sm font-weight-bold ml-2 mb-2"
        onClick={openPrintLabelsDialog}
        disabled={!UIProps.ids || UIProps.ids.length <= 0}
        style={{ cursor: !UIProps.ids || (UIProps.ids.length <= 0 && "not-allowed") }}
        id="btn_print"
      >
        <Icon.Printer className="mr-2" />
        <FormattedMessage id="GENERAL.PRINT" />
      </Button>

    </div>
  );
}