import React from "react";
import { Field } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { Col, Row } from "react-bootstrap";
import { Card, CardBody, CardSubHeader, SVFormControl } from "_metronic/_partials/controls";
import { Icon } from '_metronic/_icons';




const INPUTS = [
  {
    name: "bank.name",
    label: "BANK.NAME"
  },
  {
    name: "bank.bic",
    label: "BANK.BIC"
  },
  {
    name: "bank.iban",
    label: "BANK.IBAN"
  },
  {
    name: "vat_id",
    label: "GENERAL.US_NUMBER"
  },
  {
    name: "tax_number",
    label: "GENERAL.TAX_NUMBER"
  },
];

export function BankAndTaxInfo({loading}) {

  const { formatMessage: intl } = useIntl();

  return (
    <Card className="gutter-b">

      <CardSubHeader
        icon={<Icon.Bank />}
        title={<FormattedMessage id="COMPANY.BANK_TITLE" />}
      />

      <CardBody>

        <Row>
          {INPUTS.map((item, i) => (
            <Col lg="6" className="col-xl-4" key={i}>
              <Field component={SVFormControl}
                {...{
                  name: item.name,
                  label: intl({ id: item.label }),
                  withFeedbackLabel: true,
                  feedbackLabel: true,
                  disabled: loading
                }}
              />
            </Col>
          ))}
        </Row>

      </CardBody>
    </Card>
  );
};