import React from "react"
import { ImportCustomer } from "../import/import-customer/ImportCustomer"
import { ImportCategory } from "../import/import-category/ImportCategory"
import { ImportProduct } from "../import/import-product/ImportProduct"
import { FormattedMessage } from "react-intl"
import { Icon } from "_metronic/_icons"
import { UIDates } from "_metronic/_helpers"
import moment from 'moment'



export const allQueryKeys = [
  'data_type',
  'search',
];

export const infoType = [
  {
    type: "customers",
    title: "IMPORT_EXPORT.CUSTOMER.IMPORT.TITLE",
    description: "USER_GUIDE.IMPORT_EXPORT.IMPORT_CUSTOMER"
  },
  {
    type: "categories",
    title: "IMPORT_EXPORT.CATEGORY.IMPORT.TITLE",
    description: "USER_GUIDE.IMPORT_EXPORT.IMPORT_CATEGORY"
  },
  {
    type: "products",
    title: "IMPORT_EXPORT.PRODUCT.IMPORT.TITLE",
    description: "USER_GUIDE.IMPORT_EXPORT.IMPORT_PRODUCT"
  },
]


export const IMPORT_TITLE = [
  {
    module: "customers",
    label: "IMPORT_EXPORT.CUSTOMER.TITLE",
    icon: <Icon.Customer />,
    component: <ImportCustomer />
  },
  {
    module: "categories",
    label: "IMPORT_EXPORT.CATEGORY.TITLE",
    icon: <Icon.Bookmarks />,
    component: <ImportCategory />
  },
  {
    module: "products",
    label: "IMPORT_EXPORT.PRODUCT.TITLE",
    icon: <Icon.Catalog />,
    component: <ImportProduct />
  },
]

export const IMPORT_STATUS = [
  ...IMPORT_TITLE,
  {
    module: 'all_types',
    label: "GENERAL.TYPE_FILTER",
    icon: <Icon.Filter />
  }
];

export const EXPORT_TITLE = [
  {
    module: "accounting",
    label: "IMPORT_EXPORT.ACCOUNTING.TITLE",
    icon: <Icon.Cash />
  },
  {
    module: "orders",
    label: "IMPORT_EXPORT.ORDER.TITLE",
    icon: <Icon.Document />
  },
  {
    module: "invoices",
    label: "IMPORT_EXPORT.INVOICE.TITLE",
    icon: <Icon.Document />
  },
  {
    module: "credits",
    label: "IMPORT_EXPORT.CREDIT.TITLE",
    icon: <Icon.Document />
  },
  {
    module: "offers",
    label: "IMPORT_EXPORT.OFFER.TITLE",
    icon: <Icon.Document />
  },
  {
    module: "customers",
    label: "IMPORT_EXPORT.CUSTOMER.TITLE",
    icon: <Icon.Customer />
  },
  {
    module: "categories",
    label: "IMPORT_EXPORT.CATEGORY.TITLE",
    icon: <Icon.Bookmarks />
  },
  {
    module: "products",
    label: "IMPORT_EXPORT.PRODUCT.TITLE",
    icon: <Icon.Catalog />
  },
]

export const EXPORT_STATUS = [
  ...EXPORT_TITLE,
  {
    module: 'all_types',
    label: "GENERAL.TYPE_FILTER",
    icon: <Icon.Filter />
  }
];


export const EXPORT_OPTIONS = [
  {
    value: 'accounting',
    label: <FormattedMessage id='IMPORT_EXPORT.ACCOUNTING.EXPORT.TITLE' />
  },
  {
    value: 'orders',
    label: <FormattedMessage id='IMPORT_EXPORT.ORDER.EXPORT.TITLE' />
  },
  {
    value: 'offers',
    label: <FormattedMessage id='IMPORT_EXPORT.OFFER.EXPORT.TITLE' />
  },
  {
    value: 'invoices',
    label: <FormattedMessage id='IMPORT_EXPORT.INVOICE.EXPORT.TITLE' />
  },
  {
    value: 'credits',
    label: <FormattedMessage id='IMPORT_EXPORT.CREDIT.EXPORT.TITLE' />
  },
  {
    value: 'customers',
    label: <FormattedMessage id='IMPORT_EXPORT.CUSTOMER.EXPORT.TITLE' />
  },
  {
    value: 'categories',
    label: <FormattedMessage id='IMPORT_EXPORT.CATEGORY.EXPORT.TITLE' />
  },
  {
    value: 'products',
    label: <FormattedMessage id='IMPORT_EXPORT.PRODUCT.EXPORT.TITLE' />
  },
]


export const DATE_OPTION = [
  {
    label: "None",
    value: "none",
  },
  {
    label: <FormattedMessage id="DASHBOARD.DATE.LAST_7_DAYS" />,
    value: {
      title: "last-7-days",
      start_date: UIDates.dayCalculation(-7),
      end_date: UIDates.getCurrentDate(),
    },
  },
  {
    label: <FormattedMessage id="DASHBOARD.DATE.LAST_30_DAYS" />,
    value: {
      title: "last-30-days",
      start_date: UIDates.dayCalculation(-30),
      end_date: UIDates.getCurrentDate(),
    },
  },
  {
    label: <FormattedMessage id="DASHBOARD.DATE.THIS_MONT" />,
    value: {
      title: "this-month",
      start_date: moment().format("YYYY-MM-01"),
      end_date: UIDates.getCurrentDate(),
    }
  },
  {
    label: <FormattedMessage id="DASHBOARD.DATE.LAST_MONT" />,
    value: {
      title: "last-month",
      start_date: moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD"),
      end_date: moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD"),
    }
  },
  {
    label: <FormattedMessage id="DASHBOARD.DATE.THIS_YEAR" />,
    value: {
      title: "this-year",
      start_date: moment().format("YYYY-01-01"),
      end_date: UIDates.getCurrentDate(),
    },
  },
  {
    label: <FormattedMessage id='GENERAL.SET_CUSTOM_RANGE' />,
    value: {
      title: "custom",
      start_date: UIDates.dayCalculation(-30),
      end_date: UIDates.getCurrentDate(),
    },
  },
]

export const selectModule = (item) => EXPORT_TITLE?.find(x => x.module === item)