import React, { useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { EU_COUNTRIES, INITIAL_SHIPPING, LABEL_OPTIONS } from 'app/modules/documents/_context/DocumentsUIHelpers'
import { useDocumentsUIContext } from 'app/modules/documents/_context/DocumentsUIContext';
import { DocumentActions } from 'app/modules/documents/_redux/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Modal } from 'react-bootstrap';
import { ShippingAdd } from './ShippingAdd';
import { maxValidation, minValidation } from '_metronic/_helpers';
import { Icon } from '_metronic/_icons';
import { Portal } from 'react-portal';
import { Form, Formik } from 'formik'
import { toast } from 'react-toastify';
import * as Yup from 'yup';



export function ShippingAddDialog({ saveDocumentClick }) {

  const { formatMessage: intl } = useIntl();
  const dispatch = useDispatch();

  //delivery address değişirse güncel addresi gönder
  const { documentForEdit, default_shipping, positions } = useSelector(state => ({
    documentForEdit: state.documents.documentForEdit,
    default_shipping: state.documents.userSettings.default_shipping,
    positions: state.documents.positions,
  }), shallowEqual);

  ;
  const countryCode = documentForEdit?.customer_details?.delivery_address?.country_code
  const isAbroadEU = !EU_COUNTRIES.map(item => item.value).includes(countryCode);

  const UIContext = useDocumentsUIContext();
  const UIProps = useMemo(() => ({
    show: UIContext.showLabelSettingsDialog,
    onHide: UIContext.closeLabelSettingsDialog,
    documentId: UIContext.documentId,
    openPrintLabelsDialog: UIContext.openPrintLabelsDialog,
    requestedDocument: UIContext.requestedDocument,
  }),
    [
      UIContext.closeLabelSettingsDialog,
      UIContext.openPrintLabelsDialog,
      UIContext.showLabelSettingsDialog,
      UIContext.documentId,
      UIContext.requestedDocument
    ]);


  const defaultShippingMethod = LABEL_OPTIONS.find(item => item.value === default_shipping?.find(item => item.default)?.service)?.value ?? "";
  const defaultProfileName = default_shipping?.find(item => item.default)?.profile_name ?? "";
  const defaultDPostProductId = default_shipping?.find(item => item.service === 'deutsche_post')?.dp_product_id ?? "";

  const validationSchema = Yup.object().shape({
    service: Yup.string()
      .required(intl({ id: 'GENERAL.REQUIRED' })),
    type: Yup.string()
      .required(intl({ id: 'GENERAL.REQUIRED' })),
    billing_number: Yup.string()
      .when('service', {
        is: (service) => service === 'dhl_new' || service === 'dhl_new_warenpost',
        then: Yup.string().required(intl({ id: 'GENERAL.REQUIRED' })),
      }),
    tracking_id: Yup.string(),
    price: Yup.number()
      .min(0, intl({ id: 'PRODUCT.GENERAL.PRICE_THAN' }))
      .max(999999, intl({ id: 'DOCUMENT.SHIPPING.MAX_PRICE' })),
    dp_product_id: Yup.string().when(['service', 'labeled'], {
      is: (service, labeled) => service === 'deutsche_post' && labeled === 'labeled',
      then: Yup.string().required(intl({ id: 'GENERAL.REQUIRED' })),
      otherwise: Yup.string().notRequired()
    }),
    weight: Yup.number()
      .transform((originalValue, originalObject) => {
        const cleanedValue = originalValue ? originalValue.toString().replace(',', '.') : originalValue;
        return isNaN(cleanedValue) ? undefined : parseFloat(cleanedValue);
      })
      .required(intl({ id: 'GENERAL.REQUIRED' }))
      .min(0.001, intl({ id: 'DOCUMENT.SHIPPING.WEIGHT.LESS_THAN_NOT_0001' }))
      .max(1000, intl({ id: 'DOCUMENT.SHIPPING.WEIGHT.GREATER_THAN_NOT_1000' }))
      .test('no-trailing-zeros', intl({ id: 'DOCUMENT.SHIPPING.WEIGHT.TRAILING_ZEROS' }), function (value) {
        if (value) {
          const weightString = value.toString();
          return !weightString.includes('.') || !weightString.split('.')[1].endsWith('0');
        }
        return true;
      })
      .test('max-decimal-places', intl({ id: 'DOCUMENT.SHIPPING.WEIGHT.DECIMAL_PLACES' }), function (value) {
        if (value) {
          const decimalPlaces = (value.toString().split('.')[1] || '').length;
          return decimalPlaces <= 3;
        }
        return true;
      })
      .when(['service'], {
        is: (service) => (service === 'dhl_warenpost' || service === 'dhl_new_warenpost'),
        then: Yup.number()
          .typeError(intl({ id: 'DOCUMENT.SHIPPING.WEIGHT.MUST_A_NUMBER' }))
          .max(1, intl({ id: 'DOCUMENT.SHIPPING.WEIGHT.GREATER_THAN_1' })),
      })
      .test('weight-check', intl({ id: 'DOCUMENT.SHIPPING.DHL.CUSTOMS.EXCEEDS_POSITIONS_TOTAL' }), function (value) {
        if (isAbroadEU && ['dhl_new', 'dhl_new_warenpost'].includes(this.parent.service)) {
          const positionsTotalWeight = positions.reduce((sum, item) => sum + (item.weight * item.quantity), 0);
          return positionsTotalWeight < value;
        }
        return true;
      }),
    additional_insurance: Yup.object()
      .when(['service_options'], {
        is: (service_options) => service_options?.additional_insurance,
        then: Yup.object().shape({
          currency: Yup.string()
            .required(intl({ id: 'GENERAL.REQUIRED' })),
          value: Yup.number()
            .required(intl({ id: 'GENERAL.REQUIRED' }))
            .min(0.01, intl({ id: 'DOCUMENT.SHIPPING.ADDITIONAL_INSURANCE_PRICE.GREATER_THAN_0_01' }))
            .max(25000, intl({ id: 'DOCUMENT.SHIPPING.ADDITIONAL_INSURANCE_PRICE.LESS_THAN_25000' }))
        })
      }),
    customs: Yup.object()
      .when(['service'], {
        is: (service) => isAbroadEU && ['dhl_new', 'dhl_new_warenpost'].includes(service),
        then: Yup.object().shape({
          export_type: Yup.string()
            .required(intl({ id: 'GENERAL.REQUIRED' })),
          export_description: Yup.string()
            .when('export_type', {
              is: 'OTHER',
              then: Yup.string()
                .required(intl({ id: 'GENERAL.REQUIRED' }))
                .max(80, maxValidation("80"))
                .min(1, minValidation("1")),
              otherwise: Yup.string().notRequired()
            })
        })
      }),
  });

  const saveShipping = async (values) => {

    const { service, delivery_date, tracking_id, dp_product_id, is_service, service_options, additional_insurance, billing_number, profile_name, labeled, export_type, export_description, customs, ...rest } = values;

    if (customs.export_type && isAbroadEU && ['dhl_new', 'dhl_new_warenpost'].includes(service)) {
      const isWeightLessThan0 = positions.some(item => item.weight < 0.001);
      if (isWeightLessThan0) {
        toast.warning(intl({ id: 'DOCUMENT.SHIPPING.DHL.CUSTOMS.VALID_WEIGHT_MIN' }));
        return;
      }
    }

    const isLabeled = labeled === 'labeled';
    const shipping = {
      ...rest,
      ...(service === "deutsche_post" && isLabeled && { dp_product_id }),
      ...((service === 'dhl_new' || service === 'dhl_new_warenpost') && {
        billing_number,
        service_options: {
          ...(service_options.premium && {
            premium: service_options.premium, //waren post icin premium modalda swich olacak oradan true false gelecek
          }),
          endorsement: service_options.endorsement,//enum:["RETURN","ABANDON"] default: "RETURN"
          additional_insurance: service_options.additional_insurance,
          goGreen: service_options.goGreen,
        },
        ...(service_options.additional_insurance && {
          additional_insurance: {
            currency: additional_insurance.currency,
            value: additional_insurance.value,
          }
        }),
        ...(customs.export_type && isAbroadEU && ['dhl_new', 'dhl_new_warenpost'].includes(service) && {
          customs: {
            export_type: customs.export_type,
            ...((customs.export_type === 'OTHER' && customs.export_description) && {
              export_description: customs.export_description
            }),
            customItems: positions.map(item => ({
              id: item.id,
              title: item.title,
              quantity: item.quantity,
              weight: item.weight,
              currency: item.currency,
            }))
          }
        })
      }),
    };

    // address reducerda tutulduğu için güncel address alınacak
    const { id, order_id, order_number, pricing_summary: { currency }, customer_details: { email, number, delivery_address } } = documentForEdit;
    const documents = {
      id,
      order_id,
      order_number,
      shipping,
      ...((customs.export_type && isAbroadEU && ['dhl_new', 'dhl_new_warenpost'].includes(service)) && { pricing_summary: { currency } }),
      customer_details: { email, number, delivery_address },
      ...((isLabeled && ['dhl', 'dhl_warenpost', 'dhl_new', 'dhl_new_warenpost'].includes(service)) && {
        positions
      })
    };

    if (UIProps.documentId) {
      if (isLabeled) {
        dispatch(DocumentActions.createLabels(service, [documents]))
        UIProps.openPrintLabelsDialog(service, UIProps.documentId)
      } else {
        documents.shipping = {
          ...shipping,
          service,
          tracking_id,
          delivery_date: delivery_date ?? null,
        }
        dispatch(DocumentActions.createUnlabeled(documents))
      };
    };

    UIProps.onHide();
  };


  return (
    <Portal node={document && document.getElementById("modal-portal")}>

      <Formik enableReinitialize={true} initialValues={{ ...INITIAL_SHIPPING, profile_name: defaultProfileName, service: defaultShippingMethod, dp_product_id: defaultDPostProductId }} validationSchema={validationSchema} onSubmit={saveShipping}>
        {({ values }) => (

          <Modal show={UIProps.show} onHide={UIProps.onHide} size={(isAbroadEU && ['dhl_new', 'dhl_new_warenpost'].includes(values.service)) ? 'lg' : 'md'} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered>
            <Form autoComplete="off">

              {UIProps.documentId
                ? <ShippingAdd saveDocumentClick={saveDocumentClick} UIProps={UIProps} />
                : <>
                  <Modal.Body className="text-center line-height-lg h6 py-10 mb-0">
                    <div className="d-flex justify-content-center icon-4x my-4">
                      <Icon.InfoCircle className="text-info" />
                    </div>
                    <FormattedMessage id="DOCUMENT.SHIPPING.SAVE_DOCUMENT" />
                  </Modal.Body>

                  <Modal.Footer className="flex-nowrap overflow-hidden p-0">

                    <Button variant='ios' className="border-right" onClick={UIProps.onHide} id="btn_cancel_modal">
                      <FormattedMessage id="GENERAL.CANCEL" />
                    </Button>

                    <Button variant='ios' className="text-danger" onClick={saveDocumentClick} id="btn_delete_modal">
                      <FormattedMessage id="GENERAL.SAVE" />
                    </Button>

                  </Modal.Footer>
                </>
              }

            </Form>
          </Modal>
        )}
      </Formik>

    </Portal>
  )
}
