import React from 'react';
import { SVFormControl } from '_metronic/_partials/controls';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik, Form, Field } from 'formik';



export function FeatureEditForm({ saveFeature, initialValues, onHide }) {

  const { formatMessage: intl } = useIntl();

  const INPUT = [
    {
      name: "name",
      label: "PRODUCT.FEATURES.NAME",
    },
    {
      name: "value",
      label: "PRODUCT.FEATURES.VALUE",
    }
  ]


  return (
    <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={values => saveFeature(values)}>
      <Form>

        <Modal.Body className="overlay overlay-block cursor-default">
          <Row>
            {INPUT.map((item, i) => (
              <Col sm={12} key={i}>
                <Field component={SVFormControl}
                  {...{
                    name: item.name,
                    label: intl({ id: item.label }),
                    value: item.value,
                    withFeedbackLabel: true,
                    feedbackLabel: true,
                    required: true,
                  }}
                />
              </Col>
            ))}
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant='light' onClick={onHide} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button variant='primary' type="submit" className="ml-2" id="btn_add_modal">
            <FormattedMessage id="PRODUCT.FEATURES.ADD" />
          </Button>
        </Modal.Footer>

      </Form>
    </Formik>
  );
}
