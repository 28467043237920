import { API } from 'constants/apiUrl';
import { paginationParams, searchParams } from '../_helpers/query-params';
import { searchKeys } from './params';



export const customerMiddleware = {

  createCustomer: async function (accessToken, customer) {
    return await API.post(`/customers`, customer, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  putCustomer: async function (accessToken, customer) {
    return await API.put(`/customers/${customer.id}`, customer, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  deleteCustomer: async function (accessToken, ids) {
    return await API.delete(`/customers/${ids}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  getCustomerById: async function (accessToken, id) {
    return await API.get(`/customers/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  getCustomers: async function (accessToken, { pagination, search, filter, order }) {
    return await API.get(`/customers`, {
      params: {
        filter: {
          ...paginationParams(pagination.page, pagination.limit),
          order: [`${order ? order : 'id DESC'}`],
          where: {
            ...(search && {
              ...searchParams(searchKeys, search),
            }),
            ...(filter && filter),
            active: true,
          },
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  getCount: async function (accessToken, { search, filter }) {
    return await API.get(`/customers/count`, {
      params: {
        where: {
          ...(search && {
            ...searchParams(searchKeys, search),
          }),
          ...(filter && filter),
          active: true,
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  getCustomersForDocument: async function (accessToken, { search }) {
    return await API.get(`/customers`, {
      params: {
        filter: {
          where: {
            ...(search.searchKeys &&
              search.searchText && {
              ...searchParams(search.searchKeys, search.searchText),
            }),
            active: true,
          },
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  findCustomer: async function (accessToken, key, value, customerId) {
    return await API.get(`/customers`, {
      params: {
        filter: {
          where: {
            [key]: value,
            ...(customerId && { id: { $ne: customerId } }),
            active: true,
          },
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  getCustomerDocumentCounts: async function (accessToken, customerNumber) {
    return await API.get(`/customers/document-counts/${customerNumber}`,  {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
};