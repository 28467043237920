import { useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { SettingsActions } from 'app/modules/settings/_redux/actions';



export function useToggleDisplayCard(property) {
  const dispatch = useDispatch();

  const { default_card_view, id } = useSelector(state => state.settings.documentSettingsForEdit);

  const dispatchProperty = useCallback(() => {
    const data = {
      settings_type: 'dashboard',
      default_card_view: { ...default_card_view, [property]: !default_card_view[property] }
    }
    id && dispatch(SettingsActions.patchUserSettings(id, data))

  }, [default_card_view, id, dispatch, property])

  return [default_card_view[property], dispatchProperty]
}