import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SettingsActions } from '../_redux/actions';
import { Text, footerLength, headerLength } from './Text';
import { PaymentsMethod } from './PaymentsMethod';
import { NumberCircle } from './NumberCircle';
import { maxValidation } from '_metronic/_helpers';
import { PrintDetails } from './PrintDetails';
import { Col, Row } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';



export function DocumentSettings({ docBtnRef }) {

  const { docs } = useParams();
  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  const { documentSettingsForEdit, loading } = useSelector(state => ({
    documentSettingsForEdit: state.settings.documentSettingsForEdit,
    loading: state.settings.loading
  }));

  useEffect(() => {
    dispatch(SettingsActions.getUserSettingsByType(docs));
  }, [dispatch, docs]);

  useEffect(() => {
    return () => {
      dispatch(SettingsActions.clearState())
    };
  }, [dispatch, docs]);

  const handleSubmit = (values) => {
    const { default_number_range, default_payment, default_subtitle, print_details } = values;

    const newValues = {
      settings_type: docs,
      default_number_range,
      default_payment,
      default_subtitle,
      ...(docs === 'invoices' && { print_details })
    };

    documentSettingsForEdit.id
      ? dispatch(SettingsActions.updateUserSettings(documentSettingsForEdit.id, newValues))
      : dispatch(SettingsActions.createUserSettings(newValues))

  };

  // const deleteUserSettings = () => {
  //   documentSettingsForEdit.id &&  dispatch(SettingsActions.deleteUserSettings(documentSettingsForEdit.id));
  // };


  const documentValidationSchema = Yup.object().shape({
    default_number_range: Yup.object().shape({
      prefix: Yup.string()
        .max(10, maxValidation("10")),
      next_value: Yup.string()
        .max(10, maxValidation("10"))
        .test('is-number-format', intl({ id: 'SETTINGS.FIRST_LOGIN.SETTINGS.NEXT_VALUE_INVALID' }), (value) => !value || /^[0-9]+$/.test(value)),
    }),
    default_subtitle: Yup.object().shape({
      header: Yup.string()
        .max(headerLength, maxValidation(`${headerLength}`)),
      footer: Yup.string()
        .max(footerLength, maxValidation(`${footerLength}`)),
    })
  });

  return (
    <Formik enableReinitialize={true} key={JSON.stringify(documentSettingsForEdit)} initialValues={documentSettingsForEdit} validationSchema={documentValidationSchema} onSubmit={handleSubmit}>
      <Form>
        <Row>

          <Col lg={12}>

            {docs !== 'orders' && <NumberCircle id={documentSettingsForEdit.id} loading={loading} />}

          </Col>

          <Col lg={12}>
            <Text loading={loading} />
          </Col>

          <Col lg={6}>
            <PaymentsMethod loading={loading} />
          </Col>

          <Col lg={6}>
            {docs === 'invoices' && <PrintDetails loading={loading} />}
          </Col>

        </Row>

        <button type="submit" className='d-none' ref={docBtnRef} />

      </Form>
    </Formik>
  );
}
