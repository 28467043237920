import { API } from 'constants/apiUrl';
import { paginationParams, searchParams } from '../_helpers/query-params';
import { searchKeys, searchKeysContact, searchKeysTemp } from './params';



export const adminMiddleware = {

  // ------------------------------------ USER ------------------------------------ //

  getUsers: async function (accessToken, dateRange, { pagination, filter, order, search }) {
    const { license, status, date_from, date_to, ...rest } = filter;

    return await API.get(`/users`, {
      params: {
        filter: {
          ...paginationParams(pagination.page, pagination.limit),
          order: [`${order ? order : 'id DESC'}`],
          where: {
            ...((dateRange.startDate && dateRange.endDate) && {
              created_at: { between: [`${dateRange.startDate}T00:00:00.001Z`, `${dateRange.endDate}T23:59:59.999Z`] }
            }),
            ...(search && {
              ...searchParams(searchKeys, search),
            }),
            ...(filter && {
              ...rest,
              ...(status !== 'all_statuses' && {
                status,
              }),
              ...(license !== 'all_licenses' && {
                'license.type': license,
              })
            }),
          },
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  getUser: async function (accessToken, id) {
    return await API.get(`/user/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  getUsersCount: async function (accessToken, dateRange, { pagination, filter, order, search }) {
    const { license, status, date_from, date_to, ...rest } = filter;

    return await API.get(`/users/count`, {
      params: {
        where: {
          ...((dateRange.startDate && dateRange.endDate) &&{
            created_at: { between: [`${dateRange.startDate}T00:00:00.001Z`, `${dateRange.endDate}T23:59:59.999Z`] }
          }),
          ...(filter && {
            ...rest,
            ...(status !== 'all_statuses' && {
              status,
            }),
            ...(license !== 'all_licenses' && {
              'license.type': license,
            })
          }),
          ...(search && {
            ...searchParams(searchKeys, search),
          }),
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  patchUser: async function (accessToken, id, data) {
    return await API.patch(`/users/${id}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  deleteUser: async function (accessToken, id, data) {
    return await API.post(`/users/${id}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },



  // ------------------------------------ TEMP USER ------------------------------------ //

  getTempUsers: async function (accessToken, { pagination, filter, order, search }) {
    return await API.get(`/temp-users`, {
      params: {
        filter: {
          ...paginationParams(pagination.page, pagination.limit),
          order: [`${order ? order : 'id DESC'}`],
          where: {
            ...(search && {
              ...searchParams(searchKeysTemp, search),
            }),
            ...(filter && filter),
          },
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  getTempUsersCount: async function (accessToken, { pagination, filter, order, search }) {
    return await API.get(`/temp-users/count`, {
      params: {
        where: {
          ...(filter && filter),
          ...(search && {
            ...searchParams(searchKeysTemp, search),
          }),
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  patchTempUser: async function (accessToken, tempUser) {
    return await API.patch(`/temp-users/${tempUser.id}`, tempUser, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  deleteTempUser: async function (accessToken, ids) {
    return await API.delete(`/temp-users/${ids}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  // ------------------------------------ REPORTS ------------------------------------ //

  getReportsUsers: async function (accessToken, dateRange, { pagination, filter, search, order }) {
    const { license, status, date_from, date_to, ...rest } = filter;

    return await API.get(`/reports-users`, {
      params: {
        filter: {
          ...paginationParams(pagination.page, pagination.limit),
          order: [`${order ? order : 'id DESC'}`],
          where: {
            ...((dateRange.startDate && dateRange.endDate) && {
              created_at: { between: [`${dateRange.startDate}T00:00:00.001Z`, `${dateRange.endDate}T23:59:59.999Z`] }
            }),
            ...(search && {
              ...searchParams(searchKeys, search),
            }),
            ...(filter && {
              ...rest,
              ...(status !== 'all_statuses' && {
                status,
              }),
              ...(license !== 'all_licenses' && {
                'license.type': license,
              })
            }),
          },
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  // ------------------------------------ GET USER DETAILS ------------------------------------ //

  getUserCompany: async function (accessToken, id) {
    return await API.get(`/user-details/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  // ------------------------------------ GET USERS CONTACT ------------------------------------ //

  getUsersContact: async function (accessToken, { pagination, filter, order, search }) {
    return await API.get(`/users-contact`, {
      params: {
        filter: {
          ...paginationParams(pagination.page, pagination.limit),
          order: [`${order ? order : 'id DESC'}`],
          where: {
            ...(search && {
              ...searchParams(searchKeysContact, search),
            }),
          },
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

};
