// when set specific route constant for specific application then use this
// example: const PATH = '/sv-v1';
const PATH = '';

export const RELATIVE_PATH = PATH === '' ? PATH + '/' : PATH;

//Base routes
export class BaseRoutes {
  static get path() {
    return PATH;
  }
  static ERROR = `${BaseRoutes.path}/error`;
  static TERMS = `${BaseRoutes.path}/terms-conditions`;
  static PRIVACY = `${BaseRoutes.path}/privacy`;
  static IMPRINT = `${BaseRoutes.path}/imprint`;
  static CONTACT = `${BaseRoutes.path}/contact`;
  static MAINTENANCE = `${BaseRoutes.path}/maintenance`;
}

//Auth routes
export class AuthRoutes {
  static get path() {
    return `${PATH}/auth`;
  }
  static LOGIN = `${AuthRoutes.path}/login`;
  static REGISTER = `${AuthRoutes.path}/registration`;
  static FORGOT_PASSWORD = `${AuthRoutes.path}/forgot-password`;
  static RESET_PASSWORD = `${AuthRoutes.path}/reset-password`;
  static LOGOUT = `${PATH}/logout`;
}


export class AdminRoutes {
  static get path() {
    return `${PATH}/admin`;
  }

  static ADMIN_DASHBOARD = `${AdminRoutes.path}/dashboard`;


  static get USER() {
    return `${AdminRoutes.path}/user`;
  }
  static ADMIN_USERS = `${AdminRoutes.USER}`;
  static ADMIN_USERS_EDIT_FN = (id) => `${AdminRoutes.USER}/${id}/edit`;
  static ADMIN_USERS_EDIT = `${AdminRoutes.USER}/:id/edit`;
  static DELETE_USER = `${AdminRoutes.USER}/:userId/delete`;
  static DELETE_USER_FN = (userId) => `${AdminRoutes.USER}/${userId}/delete`;


  static get TEMP_USER() {
    return `${AdminRoutes.path}/temp-user`;
  }
  static ADMIN_TEMP_USERS = `${AdminRoutes.TEMP_USER}`;
  static ADMIN_TEMP_USERS_EDIT_FN = (id) => `${AdminRoutes.TEMP_USER}/${id}/edit`;
  static ADMIN_TEMP_USERS_EDIT = `${AdminRoutes.TEMP_USER}/:id/edit`;
  static DELETE_TEMP_USER = `${AdminRoutes.TEMP_USER}/:id/delete`;
  static DELETE_TEMP_USER_FN = (id) => `${AdminRoutes.TEMP_USER}/${id}/delete`;
  static DELETE_TEMP_USER_LISTS = `${AdminRoutes.TEMP_USER}/:ids/multiple-delete`;
  static DELETE_TEMP_USER_LISTS_FN = (ids) => `${AdminRoutes.TEMP_USER}/${ids}/multiple-delete`;


  static ADMIN_USERS_REPORTS = `${AdminRoutes.path}/reports-user`;
  static ADMIN_USERS_CONTACT = `${AdminRoutes.path}/contacts`;

}


//Module routes
export class ModuleRoutes {
  static get path() {
    return PATH;
  }

  //Dashboard routes
  static get DASHBOARD() {
    return `${ModuleRoutes.path}/dashboard`;
  }
  static DASHBOARD_PAGE = `${ModuleRoutes.DASHBOARD}?filter.currency=EUR`;

  //Document routes
  static get DOCUMENT() {
    return `${ModuleRoutes.path}/document`;
  }
  static DOCUMENT_BASE = `${ModuleRoutes.DOCUMENT}/:doc`;
  static DOCUMENT_ORDER = `${ModuleRoutes.DOCUMENT}/orders`;
  static DOCUMENT_OFFER = `${ModuleRoutes.DOCUMENT}/offers`;
  static DOCUMENT_INVOICE = `${ModuleRoutes.DOCUMENT}/invoices`;
  static DOCUMENT_CREDIT = `${ModuleRoutes.DOCUMENT}/credits`;
  static DOCUMENT_WAYBILL = `${ModuleRoutes.DOCUMENT}/waybills`;
  static DOCUMENT_NAME_FN = (name) => `${ModuleRoutes.DOCUMENT}/${name}`;
  static DOCUMENT_NEW_FN = (name) => `${ModuleRoutes.DOCUMENT}/${name}/new`;
  static DOCUMENT_COPY_FN = (name) => `${ModuleRoutes.DOCUMENT}/${name}/:copyId/copy`;
  static DOCUMENT_EDIT_FN = (name) => `${ModuleRoutes.DOCUMENT}/${name}/:id/edit`;
  static DOCUMENT_CONVERT_FN = (name) => `${ModuleRoutes.DOCUMENT}/${name}/:convertedDocumentId/:convertedDocumentUrl/edit`;
  static DOCUMENT_CONVERTtoINVOICE_FN = (name) => `${ModuleRoutes.DOCUMENT}/${name}/converting/:convertFrom/:convertTo/:ids`;
  static DOCUMENT_ENTER_PAYMENT_FN = (name) => `${ModuleRoutes.DOCUMENT}/${name}/:id/enter-payment`;
  static DOCUMENT_PAYMENT_REMINDER_FN = (name) => `${ModuleRoutes.DOCUMENT}/${name}/:id/payment/:type`;

  //DocumentUIContext routes
  static DOCUMENT_UI_CONTEXT_COPY_FN = (name, id) => `${ModuleRoutes.DOCUMENT}/${name}/${id}/copy`;
  static DOCUMENT_UI_CONTEXT_EDIT_FN = (name, id) => `${ModuleRoutes.DOCUMENT}/${name}/${id}/edit`;
  static DOCUMENT_UI_CONTEXT_REMINDER_FN = (name, id, type) => `${ModuleRoutes.DOCUMENT}/${name}/${id}/payment/${type}`;
  static DOCUMENT_UI_CONTEXT_PAYMENT_FN = (name, id) => `${ModuleRoutes.DOCUMENT}/${name}/${id}/enter-payment`;
  static DOCUMENT_UI_CONTEXT_CONVERT_ORDER_FN = (id, from) => `${ModuleRoutes.DOCUMENT}/order/${id}/${from}/edit`;
  static DOCUMENT_UI_CONTEXT_CONVERT_INVOICE_FN = (id, from) => `${ModuleRoutes.DOCUMENT}/invoice/${id}/${from}/edit`;
  static DOCUMENT_UI_CONTEXT_CONVERT_CREDIT_FN = (id, from) => `${ModuleRoutes.DOCUMENT}/credit/${id}/${from}/edit`;
  static DOCUMENT_UI_CONTEXT_CONVERT_WAYBILL_FN = (id, from) => `${ModuleRoutes.DOCUMENT}/waybill/${id}/${from}/edit`;
  static DOCUMENT_UI_CONTEXT_OPEN_CONVERT_DIALOG_FN = (ids, convertFrom, convertTo) => `${ModuleRoutes.DOCUMENT}/${convertTo}/${ids}/${convertFrom}/edit`;



  static get SHIPPING() {
    return `${ModuleRoutes.path}/shipping`;
  }
  static SHIPPING_PAGE = `${ModuleRoutes.SHIPPING}/?page=1`;

  
  static get DIGITAL_PRODUCT() {
    return `${ModuleRoutes.path}/digital-product`;
  }
  static DIGITAL_PRODUCT_PAGE = `${ModuleRoutes.DIGITAL_PRODUCT}/?page=1`;

  static get EMAIL_LOG() {
    return `${ModuleRoutes.path}/email-log`;
  }
  static EMAIL_LOG_PAGE = `${ModuleRoutes.EMAIL_LOG}/?page=1&filter.email_type=all_types`;


  //Customer routes
  static get CUSTOMER() {
    return `${ModuleRoutes.path}/customer`;
  }
  static CUSTOMER_PAGE = `${ModuleRoutes.CUSTOMER}?page=1`;
  static NEW_CUSTOMER = `${ModuleRoutes.CUSTOMER}/new`;
  static EDIT_CUSTOMER = `${ModuleRoutes.CUSTOMER}/:id/edit`;
  static EDIT_CUSTOMER_FN = (id) => `${ModuleRoutes.CUSTOMER}/${id}/edit`;
  static DELETE_CUSTOMER = `${ModuleRoutes.CUSTOMER}/:id/delete`;
  static DELETE_CUSTOMER_FN = (id) => `${ModuleRoutes.CUSTOMER}/${id}/delete`;
  static DELETE_CUSTOMER_LISTS = `${ModuleRoutes.CUSTOMER}/:ids/multiple-delete`;
  static DELETE_CUSTOMER_LISTS_FN = (ids) => `${ModuleRoutes.CUSTOMER}/${ids}/multiple-delete`;
  static SET_DOCUMENT_CUSTOMER = `${ModuleRoutes.CUSTOMER}/:id/set-document`;
  static SET_DOCUMENT_CUSTOMER_FN = (id) => `${ModuleRoutes.CUSTOMER}/${id}/set-document`;
  static DOCUMENT_FOR_EDIT_FN = (id, route) => `/documents/${route}/${id}/edit`;

  //Supplier routes
  static get SUPPLIER() {
    return `${ModuleRoutes.path}/supplier`;
  }
  static SUPPLIER_PAGE = `${ModuleRoutes.SUPPLIER}?page=1`;
  static NEW_SUPPLIER = `${ModuleRoutes.SUPPLIER}/new`;
  static EDIT_SUPPLIER = `${ModuleRoutes.SUPPLIER}/:id/edit`;
  static EDIT_SUPPLIER_FN = (id) => `${ModuleRoutes.SUPPLIER}/${id}/edit`;
  static DELETE_SUPPLIER = `${ModuleRoutes.SUPPLIER}/:id/delete`;
  static DELETE_SUPPLIER_FN = (id) => `${ModuleRoutes.SUPPLIER}/${id}/delete`;
  static DELETE_SUPPLIER_LISTS = `${ModuleRoutes.SUPPLIER}/:ids/multiple-delete`;
  static DELETE_SUPPLIER_LISTS_FN = (ids) => `${ModuleRoutes.SUPPLIER}/${ids}/multiple-delete`;

  //Product routes
  static get PRODUCT() {
    return `${ModuleRoutes.path}/products`;
  }
  static PRODUCT_PAGE = `${ModuleRoutes.PRODUCT}?page=1`;
  static NEW_PRODUCT = `${ModuleRoutes.PRODUCT}/new`;
  static EDIT_PRODUCT = `${ModuleRoutes.PRODUCT}/:productId/edit`;
  static EDIT_PRODUCT_FN = (productId, tab) => `${ModuleRoutes.PRODUCT}/${productId}/edit${tab ? `#${tab}` : ''}`;
  static DELETE_PRODUCT = `${ModuleRoutes.PRODUCT}/:productId/delete`;
  static DELETE_PRODUCT_FN = (productId) => `${ModuleRoutes.PRODUCT}/${productId}/delete`;
  static DELETE_PRODUCT_LISTS = `${ModuleRoutes.PRODUCT}/:ids/multiple-delete`;
  static DELETE_PRODUCT_LISTS_FN = (ids) => `${ModuleRoutes.PRODUCT}/${ids}/multiple-delete`;
  static UPDATE_SHOPS_QUANTITY = `${ModuleRoutes.PRODUCT}/update-shops`;
  static UPDATE_SHOPS_QUANTITY_FN = () => `${ModuleRoutes.PRODUCT}/update-shops`;
  static COPY_PRODUCT =`${ModuleRoutes.PRODUCT}/:copyId/copy`;
  static COPY_PRODUCT_FN = (productId) => `${ModuleRoutes.PRODUCT}/${productId}/copy`;


  //Listing Module routes
  static get LISTINGS() {
    return `${ModuleRoutes.path}/listings`;
  }
  //Ebay listings routes
  static EBAY_LISTINGS = `${ModuleRoutes.LISTINGS}/ebay/:market_id`;
  static EBAY_LISTINGS_FN = (id) => `${ModuleRoutes.LISTINGS}/ebay/${id}`;
  static EBAY_LISTINGS_PREPARE_EDIT = `${ModuleRoutes.EBAY_LISTINGS}/:id/:method/prepare-ebay-listing`;
  static EBAY_LISTINGS_PREPARE_EDIT_FN = (market_id, id, method = 'new') => `${ModuleRoutes.EBAY_LISTINGS_FN(market_id)}/${id}/${method}/prepare-ebay-listing`;
  static EBAY_LISTINGS_SYNC_ERROR_DIALOG = `${ModuleRoutes.EBAY_LISTINGS}/:id/sync-error-dialog/:errors`;
  static EBAY_LISTINGS_SYNC_ERROR_DIALOG_FN = (market_id, id, errors) => `${ModuleRoutes.EBAY_LISTINGS_FN(market_id)}/${id}/sync-error-dialog/${errors}`;

  //Kuafland listings routes
  static KAUFLAND_LISTINGS = `${ModuleRoutes.LISTINGS}/kaufland/:market_id`;
  static KAUFLAND_LISTINGS_FN = (id) => `${ModuleRoutes.LISTINGS}/kaufland/${id}`;
  static KAUFLAND_LISTINGS_PREPARE_EDIT = `${ModuleRoutes.KAUFLAND_LISTINGS}/:id/:method/prepare-kaufland-listing`;
  static KAUFLAND_LISTINGS_PREPARE_EDIT_FN = (market_id, id, method = 'new') => `${ModuleRoutes.KAUFLAND_LISTINGS_FN(market_id)}/${id}/${method}/prepare-kaufland-listing`;

  //Amazon listings routes
  static AMAZON_LISTINGS = `${ModuleRoutes.LISTINGS}/amazon/:tokenId`;
  static AMAZON_LISTINGS_FN = (tokenId) => `${ModuleRoutes.LISTINGS}/amazon/${tokenId}`;
  static AMAZON_LISTINGS_ADD_PRODUCT = `${ModuleRoutes.AMAZON_LISTINGS}/add-product`;
  static AMAZON_LISTINGS_ADD_PRODUCT_FN = (tokenId, query) => `${ModuleRoutes.LISTINGS}/amazon/${tokenId}/add-product${query ? `?${query}` : ''}`;
  static AMAZON_LISTINGS_SEARCH_PRODUCT = `${ModuleRoutes.AMAZON_LISTINGS}/search-product`;
  static AMAZON_LISTINGS_SEARCH_PRODUCT_FN = (tokenId, query) => `${ModuleRoutes.LISTINGS}/amazon/${tokenId}/search-product${query ? `?${query}` : ''}`;

  //Import routes
  static get IMPORT() {
    return `${ModuleRoutes.path}/import`;
  }
  static IMPORT_PRODUCT = `${ModuleRoutes.IMPORT}/prepare-product-edit`;
  static IMPORT_CUSTOMER = `${ModuleRoutes.IMPORT}/prepare-customer-edit`;
  static IMPORT_CATEGORY = `${ModuleRoutes.IMPORT}/prepare-category-edit`;

  //Export routes
  static get EXPORT() {
    return `${ModuleRoutes.path}/export`;
  }

  static get CONNECTIONS() {
    return `${ModuleRoutes.path}/connections`;
  }
  static CONNECTIONS_SETTINGS = `${ModuleRoutes.CONNECTIONS}/edit/:connectionName/:connectionId`;
  static CONNECTIONS_SETTINGS_FN = (name, id) => `${ModuleRoutes.CONNECTIONS}/edit/${name}/${id}`;
  static CONNECTIONS_DELETE = `${ModuleRoutes.CONNECTIONS}/delete/:connectionName/:connectionId`;
  static CONNECTIONS_DELETE_FN = (name, id) => `${ModuleRoutes.CONNECTIONS}/delete/${name}/${id}`;



  // Settings routes
  static get SETTINGS() {
    return `${ModuleRoutes.path}/settings`;
  }
  static SETTINGS_DOCUMENT = `${ModuleRoutes.SETTINGS}/documents/:docs`;
  static SETTINGS_DOCUMENT_FN = (docs) => `${ModuleRoutes.SETTINGS}/documents/${docs}`;
  static SETTINGS_PRODUCT = `${ModuleRoutes.SETTINGS}/product`;
  static SETTINGS_LOGIN = `${ModuleRoutes.SETTINGS}/login`;
  static SETTINGS_SHIPPING = `${ModuleRoutes.SETTINGS}/shipping`;

  static SETTINGS_COMPANY = `${ModuleRoutes.SETTINGS}/company`;
  static SETTINGS_COMPANY_EDIT = `${ModuleRoutes.SETTINGS}/company/edit`;

  //Settings Category routes
  static get SETTINGS_CATEGORY() {
    return `${ModuleRoutes.path}/settings/category`;
  }
  static NEW_CATEGORY = `${ModuleRoutes.SETTINGS_CATEGORY}/:id/:level/new`;
  static NEW_CATEGORY_FN = (id, level) => `${ModuleRoutes.SETTINGS_CATEGORY}/${id}/${level}/new`;
  static EDIT_CATEGORY = `${ModuleRoutes.SETTINGS_CATEGORY}/:id/edit`;
  static EDIT_CATEGORY_FN = (id) => `${ModuleRoutes.SETTINGS_CATEGORY}/${id}/edit`;
  static DELETE_CATEGORY = `${ModuleRoutes.SETTINGS_CATEGORY}/:id/:name/delete`;
  static DELETE_CATEGORY_FN = (id, name) => `${ModuleRoutes.SETTINGS_CATEGORY}/${id}/${encodeURIComponent(name)}/delete`;

  //Archive routes
  static get ARCHIVE() {
    return `${ModuleRoutes.path}/archive`;
  }
  static ARCHIVE_PAGE = `${ModuleRoutes.ARCHIVE}?page=1&filter.doc_type=orders&order=updated_at%20DESC`;

  //Icon routes
  static get ICON() {
    return `${ModuleRoutes.path}/icons`;
  }


  //Ticket routes
  static get SUPPORT() {
    return `${ModuleRoutes.path}/support`;
  }
  static SUPPORT_PAGE = `${ModuleRoutes.SUPPORT}?page=1&filter.status=open`;
  static SUPPORT_PAGE_ALL = `${ModuleRoutes.SUPPORT}?page=1&filter.status=all_tickets`;
  static NEW_TICKET = `${ModuleRoutes.SUPPORT}/new`;
  static VIEW_TICKET = `${ModuleRoutes.SUPPORT}/:id/view`;
  static VIEW_TICKET_FN = (id) => `${ModuleRoutes.SUPPORT}/${id}/view`;
  static EDIT_TICKET = `${ModuleRoutes.SUPPORT}/:id/view/reply`;
  static EDIT_TICKET_FN = (id) => `${ModuleRoutes.SUPPORT}/${id}/view/reply`;
  static DELETE_TICKET = `${ModuleRoutes.SUPPORT}/:id/delete`;
  static DELETE_TICKET_FN = (id) => `${ModuleRoutes.SUPPORT}/${id}/delete`;


  //Reports routes
  static get REPORT() {
    return `${ModuleRoutes.path}/report`;
  }
  static REPORT_ORDER_LIST = `${ModuleRoutes.REPORT}/order-list`;




}
