import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '_metronic/_partials/controls';
import { SuppliersFilter } from './supplier-filter/SuppliersFilter';
import { SuppliersTable } from './supplier-table/SuppliersTable';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { FormattedMessage } from 'react-intl';
import { UserGuideHelper } from '../../user-guides/UserGuideHelper';
import { useSearchQueryContext } from './_context/SearchQueryProvider';
import { LoadingDialog } from "_metronic/_partials/loading";
import { BlankMessage } from '_metronic/_partials/components';
import { NewButton } from '_metronic/_partials';
import { SupplierActions } from './_redux/actions';
import { Icon } from '_metronic/_icons';



export function SuppliersCard() {

  const dispatch = useDispatch();

  const { entities, loading, countSupplier, totalSupplier } = useSelector(state => ({
    entities: state.suppliers.entities,
    loading: state.suppliers.loading,
    countSupplier: state.suppliers.countSupplier,
    totalSupplier: state.suppliers.totalSupplier,
  }));

  const queryContext = useSearchQueryContext();
  const { queryParams, setPaginationQuery, setOrderQuery, setSearchQuery } = useMemo(() => ({
    queryParams: queryContext.queryParams,
    setPaginationQuery: queryContext.setPaginationQuery,
    setOrderQuery: queryContext.setOrderQuery,
    setSearchQuery: queryContext.setSearchQuery,
  }), [queryContext]);


  useEffect(() => {
    let delayDebounceFn;
    if (queryParams.search) {
      delayDebounceFn = setTimeout(() => {
        dispatch(SupplierActions.getSupplier(queryParams));
      }, 350);
    } else {
      dispatch(SupplierActions.getSupplier(queryParams));
    }

    return () => { clearTimeout(delayDebounceFn) };
  }, [dispatch, queryParams]);


  return (
    <Card className="card-height">

      <CardHeader
        icon={<Icon.Buildings />}
        title={<FormattedMessage id="SUPPLIER.TITLE" />}
        info={<UserGuideHelper />}
        className="display-5"
        id='title_supplier'
      >

        <CardHeaderToolbar>
          <NewButton label="SUPPLIER.NEW" link={ModuleRoutes.NEW_SUPPLIER} />
        </CardHeaderToolbar>

      </CardHeader>

      <CardBody>
        {totalSupplier === 0
          ? <BlankMessage
            icon={<Icon.Buildings />}
            title={<FormattedMessage id='SUPPLIER.BLANK_MESSAGE_TITLE' />}
            message={<FormattedMessage id='SUPPLIER.BLANK_MESSAGE' />}
            className='min-50-height'
            loading={loading}
            arrow
          />
          : <>
            <SuppliersFilter setSearchQuery={setSearchQuery} />
            <SuppliersTable
              setOrderQuery={setOrderQuery}
              pagination={queryParams.pagination}
              setPaginationQuery={setPaginationQuery}
              entities={entities}
              loading={loading}
              countSupplier={countSupplier}
              setSearchQuery={setSearchQuery}
            />
          </>
        }
      </CardBody>

      <LoadingDialog isLoading={loading} />

    </Card>
  );
}
