import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CompanyActions } from '../_redux/action';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { FormikHandler } from './FormikHandler';
import { useHistory } from 'react-router-dom';
import { BankAndTaxInfo } from './BanAndTaxInfo';
import { Address } from './Address';
import { Logo } from './Logo';
import { useLoadingHandler } from 'app/hooks';
import { LayoutSplashScreen } from '_metronic/layout';



export function CompanyEdit({ companyBtnRef }) {

  const history = useHistory();
  const dispatch = useDispatch();

  const { companyBaseData, logo, request,loading } = useSelector(state => ({
    companyBaseData: state.company.companyBaseData,
    logo: state.company.logo,
    request: state.company.request,
    loading: state.company.loading,
  }));

  const isLoading = useLoadingHandler(loading, CompanyActions.clearLoadingEffects());

  useEffect(() => {
    dispatch(CompanyActions.getCompanyBaseData());
  }, [dispatch]);

  useEffect(() => {
    if (request.result === 'request_fulfilled') {
      dispatch(CompanyActions.cleanErrorHandler());
      setTimeout(() => {
        return history.push(ModuleRoutes.SETTINGS_COMPANY);
      }, 300);
    }
  }, [dispatch, history, request.result]);


  const saveCompany = (values) => {

    const form_data = new FormData();
    let logoIsExisted = false;

    if (logo?.file) {
      logoIsExisted = true;
      form_data.append('company_logo', logo.file);
    }

    const upload = { is: logoIsExisted, file: form_data };

    dispatch(CompanyActions.updateCompanyBaseData(values, upload));
  };


  return (
    <>
    {isLoading && <LayoutSplashScreen />}

      <FormikHandler company={companyBaseData} saveCompany={saveCompany} companyBtnRef={companyBtnRef}>
        <Address loading={isLoading} />
        <BankAndTaxInfo loading={isLoading} />
      </FormikHandler>
      <Logo loading={isLoading}/>
    </>
  );
};
