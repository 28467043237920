import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import PerfectScrollbar from "react-perfect-scrollbar";
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useProductEditUIContext } from 'app/modules/products/_context/ProductEditUIContext';
import { ActionsColumnFormatter } from './column-formatters/ActionsColumnFormatter';
import { ProductKeysFilter } from '../product-keys-filter/ProductKeysFilter';
import { BlankMessage } from '_metronic/_partials/components';
import { UIDates, getSelectRow } from '_metronic/_helpers';
import { ProductActions } from '../../../_redux/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { Icon } from '_metronic/_icons';
import { toast } from 'react-toastify';



export function ProductKeysTable({ productId }) {

  const { formatMessage: intl } = useIntl();

  const dispatch = useDispatch();

  const UIContext = useProductEditUIContext();
  const UIProps = useMemo(() => ({
    keyIds: UIContext.keyIds,
    setKeyIds: UIContext.setKeyIds,
    openDeleteProductKeysDialog: UIContext.openDeleteProductKeysDialog,
  }),
    [
      UIContext.keyIds,
      UIContext.setKeyIds,
      UIContext.openDeleteProductKeysDialog
    ]
  );

  const { keys, guides } = useSelector(state => state.products.digitalProductKeys || [], shallowEqual);

  const [fileNames, setFileNames] = useState([...(guides ?? [])]);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (search) => setSearchTerm(search);


  const filteredKeys = useMemo(() => {
    const sortKeys = keys?.length ? keys.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) : [];
    if (!searchTerm) return sortKeys || [];
    const search = searchTerm.toLowerCase();
    return sortKeys?.filter(item => (
      item.key?.toLowerCase().includes(search) ||
      item.order_id?.toLowerCase().includes(search)
    )) || [];
  }, [keys, searchTerm]);



  const handleFileChange = async (event) => {
    const selectedFiles = Array.from(event.target.files);
    const currentFileNames = fileNames.map(file => file.name);

    const newFiles = selectedFiles.filter(file => {
      if (currentFileNames.includes(file.name)) {
        return false;
      }
      if (file.size > 5 * 1024 * 1024) { // 5 MB
        toast.error(`File ${file.name} is larger than 5 MB.`);
        return false;
      }
      if (file.type !== 'application/pdf') { // PDF type check
        toast.error(`File ${file.name} is not a PDF.`);
        return false;
      }
      return true;
    });

    if (fileNames.length + newFiles.length > 2) {
      return toast.error('You can only import up to 2 PDF files at a time.');
    }

    const newFileNames = newFiles.map(file => ({ id: Date.now().toString() + file.name, name: file.name, file }));

    dispatch(ProductActions.addProductGuides([...fileNames, ...newFileNames]))

    setFileNames(prevFileNames => [...prevFileNames, ...newFileNames]);

    event.target.value = null;
  };


  const removeFile = (id) => {
    setFileNames(prevFileNames => prevFileNames.filter((item) => item.id !== id))

    if (productId) {
      const deletedPdf = fileNames.find((item) => item.id === id);
      deletedPdf?.link && dispatch(ProductActions.deleteFile(productId, "product_guides", deletedPdf?.id));
    }
  };


  const COLUMNS = [
    {
      dataField: 'key',
      text: intl({ id: 'PRODUCT.KEYS' }),
      headerClasses: 'text-left pl-4',
      headerStyle: { minWidth: '120px', width: '15%' },
      classes: 'text-left pl-4',
      formatter: (cell, row) => {
        const maskedCell = cell ? `***${cell.slice(-4)}` : '';
        return (
          <OverlayTrigger overlay={<Tooltip id="quick-category-tooltip">{cell}</Tooltip>}>
            <span className="font-weight-bold text-nowrap">{maskedCell}</span>
          </OverlayTrigger>);
      },
    },
    {
      dataField: 'created_at',
      text: intl({ id: 'GENERAL.ADDED_DATE' }),
      headerClasses: 'text-center',
      headerStyle: { width: '20%' },
      classes: 'text-center',
      formatter: (cell, row) => UIDates.formatDateTime(cell)
    },
    {
      dataField: 'order_id',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.ORDER_ID' }),
      headerClasses: 'text-center',
      headerStyle: { width: '10%' },
      classes: 'text-center',
      formatter: (cell, row) => cell &&
        <Link className="navi-link" to={{ pathname: ModuleRoutes.DOCUMENT_ORDER, search: `page=1&filter.status=all-orders&search=${cell}`, hash: '', }} target="_blank" rel="noopener noreferrer">
          {cell}
        </Link>
    },
    {
      dataField: 'order_date',
      text: intl({ id: 'DOCUMENT.DETAIL_DIALOG.ORDER.DATE' }),
      headerClasses: 'text-center',
      headerStyle: { width: '20%' },
      classes: 'text-center',
      formatter: (cell, row) => cell && UIDates.formatDateTime(cell)
    },
    {
      dataField: 'status',
      text: intl({ id: 'GENERAL.STATUS' }),
      headerClasses: 'text-center',
      headerStyle: { width: '5%' },
      classes: 'text-center',
      formatter: (cell, row) => {
        return row.order_date && (
          <OverlayTrigger overlay={<Tooltip id="quick-category-tooltip"><FormattedMessage id={row.confirmation_date ? 'PRODUCT.KEYS.SENT' : 'PRODUCT.KEYS.RESERVE'} /> <div>{row.confirmation_date ? UIDates.formatDateTime(row.confirmation_date) : UIDates.formatDateTime(row.order_date)}</div></Tooltip>}>
            <Icon.CheckCircleFill className={`icon-md text-${row.confirmation_date ? "success" : "warning"}`} />
          </OverlayTrigger>
        );
      },
    },
    {
      dataField: 'email_sended_date',
      text: intl({ id: 'GENERAL.EMAIL' }),
      headerClasses: 'text-center',
      headerStyle: { width: '10%' },
      classes: 'text-center',
      formatter: (cell, row) => {
        if (row.order_date && row.email_sended_date) {
          return (
            <OverlayTrigger overlay={<Tooltip id="quick-category-tooltip"><FormattedMessage id='PRODUCT.KEYS.SENT_EMAIL' /> <div>{UIDates.formatDateTime(row.email_sended_date)}</div></Tooltip>}>
              <Icon.CheckCircleFill className="icon-md text-success" />
            </OverlayTrigger>
          );
        }
        return null;
      },
    },
    {
      dataField: 'confirmation_date',
      text: intl({ id: 'GENERAL.USED_DATE' }),
      headerClasses: 'text-center',
      headerStyle: { width: '20%' },
      classes: 'text-center',
      formatter: (cell, row) => cell && UIDates.formatDateTime(cell)
    },
    {
      dataField: 'action',
      text: "...",
      headerClasses: 'text-right icon-lg pr-5',
      headerStyle: { minWidth: '40px', width: '5%' },
      classes: 'text-right pr-3',
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openDeleteProductKeysDialog: UIProps.openDeleteProductKeysDialog
      },
    },
  ];

  const downloadFile = (link, filename) => {
    const a = document.createElement('a');
    a.href = link;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };


  return (
    <>
      {keys?.length
        ? <>
          <ProductKeysFilter handleSearch={handleSearch} />

          <PerfectScrollbar className="scroll pr-5 mr-n5" options={{ wheelSpeed: 1, wheelPropagation: false }} style={{ maxHeight: '342px' }}>
            <BootstrapTable
              keyField="id"
              key={UIProps.keyIds}
              data={filteredKeys || []}
              columns={COLUMNS}
              wrapperClasses="table-responsive"
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              bordered={false}
              striped
              condensed
              selectRow={getSelectRow({
                ids: UIProps.keyIds,
                setIds: UIProps.setKeyIds,
                entities: keys,
              })}
            />
          </PerfectScrollbar>
        </>
        : <BlankMessage
          icon={<Icon.Password />}
          title={<FormattedMessage id='PRODUCT.KEYS.BLANK_MESSAGE_TITLE' />}
          message={<FormattedMessage id='PRODUCT.KEYS.BLANK_MESSAGE' />}
          arrow
        />
      }

      {keys?.length ?
        <div className="d-flex justify-content-between w-100 mt-8 pr-5">
          <span className='d-flex align-items-start'>
            
            <Button variant="outline-primary" size="sm" className="font-weight-bold svg-icon svg-icon-sm" onClick={() => document.getElementById('pdfUpload').click()} id="btn_add_product" >
              <Icon.Plus className="mr-2" />
              <FormattedMessage id="PRODUCT.KEYS_GUIDE" />
              <input type="file" id="pdfUpload" accept="application/pdf" style={{ display: 'none' }} onChange={handleFileChange} multiple />
            </Button>

            <div className='align-self-end'>
              {fileNames.map((file, index) => (
                <Button variant='link' key={index} className="label label-inline label-light-primary label-xl ml-3" id='btn_close'>
                  <span className=" svg-icon svg-icon-sm">
                    <Icon.FileTypePdf />
                  </span>
                  <span className='mx-2' onClick={() => downloadFile(file.link, file.name)}>{file.name}</span>
                  <Icon.Close onClick={() => removeFile(file.id)} />
                </Button>
              ))}
            </div>
          </span>

          <span className="label label-lg label-inline label-light font-weight-bolder text-nowrap px-4 py-5">
            <FormattedMessage id="GENERAL.AMOUNT" />: {keys.filter(i => i.order_date)?.length} / {keys?.length}
          </span>

        </div>
        : <></>
      }
    </>
  );
};