import React, { useEffect, useState } from 'react';
import { SearchControl } from '_metronic/_partials/controls';
import { DigitalProductsGrouping } from './DigitalProductsGrouping';
import { Col, Row } from 'react-bootstrap';
import { useQuery } from 'app/hooks';



export function DigitalProductsFilter({ setSearchQuery }) {

  const query = useQuery();
  const [search, setSearchBase] = useState(query.get('search') ?? '');

  useEffect(() => {
    setSearchBase(query.get('search') ?? '');
  }, [query]);

  const handleSearch = value => {
    setSearchQuery(value);
    setSearchBase(value);
  };


  return (
    <Row className="mb-3">

      <Col md="4" className="mb-lg-0 mb-2">
        <SearchControl search={search} handleSearch={handleSearch} />
      </Col>

      <Col md="8">
        <DigitalProductsGrouping />
      </Col>

    </Row>
  );
}