import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { Formik, Form, Field } from "formik";
import { ConnectionsActions } from "../../_redux/actions";
import { SVFormControl } from '_metronic/_partials/controls';
import * as Yup from "yup";



const INPUTS = [
  {
    name: "shopware6.website",
    label: "CONNECTIONS.SHOP_WEBSITE",
    lg: '12'
  },
  {
    name: "shopware6.client_id",
    label: "CONNECTIONS.SHOP.SHOPWARE6_ACCESSS_KEY_ID",
    editable: true
  },
  {
    name: "shopware6.client_secret",
    label: "CONNECTIONS.SHOP.SHOPWARE6_SECRET_KEY_ID",
    type: "password",
    editable: true
  }
];

export default function Shopware6Form({ children, connectionId, initialValue, loading, onHide }) {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  const { shopware6ForEdit, connections } = useSelector((state) => ({
    shopware6ForEdit: state.connections.shopware6ForEdit,
    connections: state.connections.entities.filter(connection => connection.name === "shopware6") ?? []
  }));

  const existingWebsites = connections?.map(connection => connection.shopware6.website);

  const validationSchema = Yup.object().shape({
    ...(!connectionId && {
      shopware6: Yup.object().shape({
        website: Yup.string()
          .required(intl({ id: 'GENERAL.REQUIRED' }))
          .test('match', intl({ id: "CONNECTIONS.SHOP.TOKEN_MATCH" }), (value) => !existingWebsites?.includes(value)),
        client_id: Yup.string()
          .required(intl({ id: 'GENERAL.REQUIRED' })),
        client_secret: Yup.string()
          .required(intl({ id: 'GENERAL.REQUIRED' }))
      })
    })
  });


  const handleSubmit = (values) => {
    connectionId
      ? dispatch(ConnectionsActions.updateSettings(values))
      : dispatch(ConnectionsActions.createSettings(values));
  };


  return (
    <Formik enableReinitialize={true} initialValues={connectionId ? shopware6ForEdit : initialValue} validationSchema={validationSchema} onSubmit={handleSubmit}>
      <Form autoComplete='off'>

        <Modal.Body>
          <Row>
            {INPUTS.map((input, i) => (
              <Col key={i} lg={input.lg ?? "6"}>
                <Field component={SVFormControl}
                  {...{
                    name: input.name,
                    type: input.type,
                    label: intl({ id: input.label }),
                    withFeedbackLabel: true,
                    feedbackLabel: true,
                    required: true,
                    disabled: loading || (connectionId && !input.editable),
                  }}
                />
              </Col>
            ))}
          </Row>
        </Modal.Body>

        <Modal.Footer>

          {children}

          <Button variant="light" onClick={onHide} disabled={loading} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button variant="primary" type="submit" className="ml-2" disabled={loading} id="btn_save_modal">
            <FormattedMessage id="GENERAL.SAVE" />
          </Button>

        </Modal.Footer>

      </Form>
    </Formik>
  )
}
