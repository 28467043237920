import { useSearchQuery } from 'app/hooks';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { useIntl } from 'react-intl';


const EmailLogsUIContext = createContext();
export const useEmailLogsUIContext = () => useContext(EmailLogsUIContext)


export const EmailLogsUIProvider = ({ children }) => {

  const { formatMessage: intl } = useIntl();

  const [ids, setIds] = useState([]);

  // Print
  const [showPrintDocumentDialog, setShowPrintDocumentDialog] = useState(false);
  const openPrintDocumentDialog = () => {
    setShowPrintDocumentDialog(true);
  };
  const closePrintDocumentDialog = () => {
    setShowPrintDocumentDialog(false)
  };
    // Shipping
    const [showPrintLabel, setShowPrintLabel] = useState(false);
    const [shipperName, setShipperName] = useState(null);
    const openPrintLabelsDialog = (service) => {
      setShipperName(service);
      setShowPrintLabel(true);
    };
    const closePrintLabelsDialog = () => {
      setShipperName(null);
      setShowPrintLabel(false);
    };

    //* Send_Email_Dial0g
    const [showSendEmailDialog, setShowSendEmailDialog] = useState(false);
    const [reminder, setReminder] = useState(null);
    const handleOpenSendEmailDialog = () => {
      setShowSendEmailDialog(true);
    };
    const handleCloseSendEmailDialog = () => {
      setShowSendEmailDialog(false);
    };

    // query params
    const [queryParams, setQueryBase] = useState({
      pagination: {
        page: 1,
        limit: 10,
      },
      order: 'created_at DESC',
      search: '',
      filter: {
        email_type: 'all_types',
      },
    });
    const setQueryParams = useCallback((params) => setQueryBase(params), [])
  
    const { setFilterQuery, setPaginationQuery, setSearchQuery,setOrderQuery } = useSearchQuery(setQueryParams);
    const initialDateRange = {
      id: "clear-date",
      label: intl({ id: "GENERAL.CLEAR" }),
    };
    const [dateRange, setDateRange] = useState(initialDateRange);
    const changeDateRange = (date = initialDateRange) => setDateRange(date);


  const value = {
    ids,
    setIds,

    showPrintDocumentDialog,
    openPrintDocumentDialog,
    closePrintDocumentDialog,

    showSendEmailDialog,
    handleOpenSendEmailDialog,
    handleCloseSendEmailDialog,
    reminder,
    setReminder,

    showPrintLabel,
    shipperName,
    openPrintLabelsDialog,
    closePrintLabelsDialog,

    queryParams,
    dateRange,
    setQueryBase,
    setFilterQuery,
    setPaginationQuery,
    setSearchQuery,
    setOrderQuery,
    changeDateRange,
    setDateRange,

  };

  return (
    <EmailLogsUIContext.Provider value={value}>
      {children}
    </EmailLogsUIContext.Provider>
  );
};
