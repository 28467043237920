import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl';
import { Card, CardBody, CardHeaderToolbar, CardSubHeader } from '_metronic/_partials/controls';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ACCOUNT_STATUS } from 'app/admin/_context/AdminUIHelper';
import { GeneralEditDialog } from './GeneralEditDialog';
import { LicenseBadge } from '_metronic/_partials';
import { Icon } from '_metronic/_icons';



export function General({ user }) {

  const [show, setShow] = useState(false)

  const userInfo = [
    {
      label: "AUTH.REGISTER.NAME",
      content: user.name ?? '',
    },
    {
      label: "AUTH.REGISTER.SURNAME",
      content: user.surname ?? '',
    },
    {
      label: "ADMIN.USER.LICENSE_TYPE",
      content: <LicenseBadge license={user?.license} />,
    },
    {
      label: "ADMIN.USER.ACCOUNT_STATUS",
      content: ACCOUNT_STATUS?.find(item => item.value === user.status)?.label ?? '',
    }
  ];


  return (
    <>
      <Card className="card-stretch gutter-b">

        <CardSubHeader icon={<Icon.Tag />} title={<FormattedMessage id="GENERAL.GENERAL" />}>
          <CardHeaderToolbar>
            <OverlayTrigger overlay={<Tooltip id="document-print-tooltip"><FormattedMessage id="GENERAL.EDIT" /></Tooltip>}>
              <Button variant='outline-primary' size='sm' className="svg-icon svg-icon-sm" onClick={() => setShow(true)} id="btn_edit_general">
                <Icon.PencilSquare />
              </Button>
            </OverlayTrigger>
          </CardHeaderToolbar>
        </CardSubHeader>

        <CardBody>
          {userInfo.length && (
            <div className="d-flex flex-column py-2 px-4">
              {userInfo?.map((item, i) => (
                <h6 className={`d-flex align-items-start flex-column flex-md-row ${userInfo?.length - 1 === i ? "" : "mb-4"}`} key={i}>
                  <span className="text-dark-50 mw-200px mb-3">
                    {item.label && <FormattedMessage id={item.label} />}
                  </span>
                  <span>
                    {item.content}
                  </span>
                </h6>
              ))}
            </div>
          )}
        </CardBody>

      </Card>

      <GeneralEditDialog show={show} onHide={() => setShow(false)} user={user} />
    </>
  );
}