import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Card, CardBody, CardSubHeader, SVFormControl, SVReactSelect, SVReactSelectCreatable } from '_metronic/_partials/controls';
import { getCurrencyList } from '_metronic/_helpers/constant/optionData';
import { useProductEditUIContext } from '../../_context/ProductEditUIContext';
import { TAX_OPTIONS, UNIT_OPTIONS } from '../../_context/ProductsUIHelpers';
import { convertPriceCurrency } from '_metronic/_helpers';
import { ProductActions } from '../../_redux/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSkuValidationControl } from 'app/hooks';
import { Field, useFormikContext } from 'formik';
import { useLang } from '_metronic/i18n';
import { Col, Row } from 'react-bootstrap';
import { Icon } from '_metronic/_icons';
import { isArray } from 'lodash';
import { CategoryAdd } from '../category/CategoryAdd';



export function General({ productId, loading }) {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();
  const locale = useLang();

  const UIContext = useProductEditUIContext();
  const UIProps = useMemo(() => ({
    setValidateForm: UIContext.setValidateForm,
    categoryKey: UIContext.selectedKategorie?.key
  }),
    [UIContext]
  );

  const unitOptions = UNIT_OPTIONS.map(item => ({
    value: item.value,
    label: intl({ id: item.label })
  }));

  const [inherits, setInherits] = useState(unitOptions);
  const [selectedInherit, setSelectedInherit] = useState(unitOptions[0]);

  const { values: { sku, pricing, name, condition, unit }, setFieldValue, validateForm, } = useFormikContext();
  const settingsCurrency = useSelector(state => state.settings.documentSettingsForEdit.default_product.currency, shallowEqual);

  const { checkIsSkuValid } = useSkuValidationControl();

  const { variants, suppliers, digitalProduct } = useSelector(state => ({
    variants: state.products.variants,
    suppliers: state.products.suppliers,
    digitalProduct: state.products.digitalProductKeys,
  }),
    shallowEqual
  );

  const handleChange = (value) => {
    value?.__isNew__ === true && setInherits(prev => [...prev, { value: value.value, label: value.label }]);
    setSelectedInherit({ value: value.value, label: value.label });
    setFieldValue('unit', value.value);
  };

  const currencyOptions = getCurrencyList(locale);
  const selectedCurrencyOption = currencyOptions.find(option => option.value === settingsCurrency);
  const [defaultCurrency, setDefaultCurrency] = useState(selectedCurrencyOption);

  const isDigitalProduct = UIProps.categoryKey === "digital_product";

  const handleChangeCurrency = (value) => {
    setDefaultCurrency({ value: value.value, label: value.label });
    setFieldValue('pricing.currency', value.value);
  };

  useMemo(() => {
    if (pricing.currency) {
      setDefaultCurrency(currencyOptions.find(f => f.value === pricing.currency));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricing]);

  useEffect(() => {
    validateForm().then(val => {
      const keys = Object.keys(val);
      UIProps.setValidateForm(keys);
    });
    setFieldValue('pricing.currency', defaultCurrency?.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, sku, pricing, condition]);

  useEffect(() => {
    if (isDigitalProduct) {
      const keysQuantity = digitalProduct?.keys?.filter(f => !f.order_date) || [];
      setFieldValue('quantity', keysQuantity.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDigitalProduct, digitalProduct]);

  useEffect(() => {
    let delayFindProductByProperty;
    delayFindProductByProperty = setTimeout(() => {
      sku && dispatch(ProductActions.findProductBySku(sku, productId && productId));
    }, 350);
    return () => clearTimeout(delayFindProductByProperty);
  }, [dispatch, productId, sku]);

  useMemo(() => {
    if (unit && inherits.find(f => f.value === unit)) {
      setSelectedInherit(inherits.find(f => f.value === unit));
    } else {
      if (sku !== '') {
        setInherits(prev => [...prev, { value: unit, label: unit }]);
        setSelectedInherit({ value: unit, label: unit });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unit]);



  const INPUTS = [
    {
      name: "name",
      label: "PRODUCT.GENERAL.NAME",
      required: true,
      feedbackLabel: true,
      size: "12",
    },
    {
      name: "sku",
      label: "GENERAL.SKU",
      required: true,
      feedbackLabel: checkIsSkuValid(sku) ? intl({ id: "PRODUCT.GENERAL.SKU_WARNING" }) : true
    },
    //{
    //  name: "condition",
    //  label: "PRODUCT.GENERAL.CONDITIONS",
    //  component: SVReactSelect,
    //  options: productConditions,
    //  defaultValue: productConditions[0],
    //  required: true,
    //},
    {
      name: "brand",
      label: "PRODUCT.MORE_SETTINGS.BRAND"
    },
    {
      name: "pricing.purchase_price",
      label: "PRODUCT.GENERAL.PURCHASE",
      type: "number",
      price: true,
      size: "3",
      symbol: defaultCurrency?.symbol,
      feedbackLabel: (pricing?.purchase_price?.toString()?.split('.')[1]?.length ?? 0) > 2
        ? intl({ id: "DOCUMENT.POSITION.IS_VALID.DECIMALS" })
        : true,
      // ? <span className="text-muted">
      //   Nettopreis {+pricing?.tax > 0 ? 'mit' : 'ohne'}
      //   (<span className='text-success'>{+pricing?.tax}%</span>)
      //   Steuern: {" "}
      //   <span className='text-success'>{(+pricing.gross_price / (1 + +pricing.tax / 100)).toFixed(2)}</span>
      // </span>
      // : true,
    },
    {
      name: "pricing.gross_price",
      type: "number",
      label: "PRODUCT.GENERAL.SALE_PRICE_GROSS",
      price: true,
      symbol: defaultCurrency?.symbol,
      size: "3",
      feedbackLabel: (pricing?.gross_price?.toString()?.split('.')[1]?.length ?? 0) > 2
        ? intl({ id: "DOCUMENT.POSITION.IS_VALID.DECIMALS" })
        : pricing.gross_price > 0
          ? <div className='font-weight-bold'>
            <span className="text-muted">
              <FormattedMessage id='PRODUCT.PRICE.NET_PRICE' /> : </span>
            <span className='text-success'>
              {convertPriceCurrency(+pricing.gross_price / (1 + +pricing.tax / 100), defaultCurrency?.value)}
            </span>
          </div>
          : true,
      // ? <span className="text-muted">
      //   Nettopreis {+pricing?.tax > 0 ? 'mit' : 'ohne'}
      //   (<span className='text-success'>{+pricing?.tax}%</span>)
      //   Steuern: {" "}
      //   <span className='text-success'>{(+pricing.gross_price / (1 + +pricing.tax / 100)).toFixed(2)}</span>
      // </span>
      // : true,
    },
    {
      name: "pricing.currency",
      label: "PRODUCT.GENERAL.CURRENCY",
      component: SVReactSelectCreatable,
      options: currencyOptions,
      value: defaultCurrency,
      onChange: handleChangeCurrency,
      isClearable: false,
      required: true,
      size: "3",
    },
    {
      name: "pricing.tax",
      label: "PRODUCT.GENERAL.TAX",
      feedbackLabel: true,
      component: SVReactSelect,
      options: TAX_OPTIONS.map(item => ({
        value: item.value,
        label: intl({ id: item.label})
      })),
      required: true,
      size: "3",
    },
    {
      name: "quantity",
      type: "number",
      label: "PRODUCT.GENERAL.QUANTITY",
      feedbackLabel: true,
      size: "3",
      disabled: isDigitalProduct,
      ...(isDigitalProduct && {info: "PRODUCT.MORE_SETTINGS.QUANTITY_INFO"}),
    },
    {
      name: "unit",
      label: "PRODUCT.GENERAL.UNIT",
      component: SVReactSelectCreatable,
      options: inherits,
      formatCreateLabel: inputValue => `${intl({ id: "PRODUCT.GENERAL.UNIT_WARNING" }) + inputValue}`,
      value: selectedInherit,
      onChange: handleChange,
      isClearable: false,
      size: "3",
    },
    //{
    //  name: "pricing.net_price",
    //  type: "number",
    //  label: "PRODUCT.GENERAL.SALE_PRICE",
    //  feedbackLabel: true,
    //  price: true,
    //}, 
    {
      name: "supplier_id",
      label: "PRODUCT.GENERAL.SUPPLIER",
      component: SVReactSelect,
      options: suppliers && isArray(suppliers) && suppliers.map(item => ({ value: item.id, label: `${item.name} / ${item.company}` })),
      size: variants.length > 0 ? "3" : "6",
    },
  ];


  return (
    <Card className="gutter-b">
      <CardSubHeader title={<FormattedMessage id="PRODUCT.GENERAL" />} icon={<Icon.Tag />} id="general" />

      <CardBody>
        <Row>
          {INPUTS.map((item, i) =>
            !(item.type === 'number' && !variants.length <= 0) && (
              <Col md="6" lg={item.size || "6"} key={i}>
                <Field component={item.component || SVFormControl}
                  {...{
                    name: item.name,
                    type: item.type,
                    label: intl({ id: item.label }),
                    options: item.options,
                    placeholder: item.placeholder,
                    withFeedbackLabel: true,
                    defaultValue: item.defaultValue,
                    feedbackLabel: item.feedbackLabel,
                    isClearable: item.isClearable,
                    onChange: item.onChange,
                    required: item.required,
                    price: item.price,
                    symbol: item.symbol,
                    onFocus: item.onFocus,
                    value: item.value,
                    min: 0,
                    step: item.price && 0.01,
                    disabled: loading || item.disabled,
                    info: item.info ? intl({ id: item.info }) : '',
                  }}
                />
              </Col>
            ))}
        </Row>

        <CategoryAdd loading={loading} keys={digitalProduct?.keys ?? []} />

      </CardBody>
    </Card>
  );
}
