import React, { useEffect, useState } from 'react';
import { EMAIL_TEMPLATE_STATUS, EMAIL_TEMPLATE_TYPE } from '../../_context/ReportUIHelpers';
import { SearchControl } from '_metronic/_partials/controls';
import { DatePickerDropdown } from './DatePickerDropdown';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { theme } from '_metronic/_helpers';
import { useQuery } from 'app/hooks';
import { FormattedMessage } from 'react-intl';



export function EmailLogFilter({ UIProps }) {

  const query = useQuery();
  const [search, setSearch] = useState(query.get('search') ?? '');
  const [filterParamsBase, setFilterParamsBase] = useState({
    email_type: 'all_types',
  });

  useEffect(() => {
    const email_type = query.get('filter.email_type');

    setFilterParamsBase(prev => ({
      ...prev,
      email_type: EMAIL_TEMPLATE_STATUS.map(item => item.type).includes(email_type) ? email_type : 'all_types',
    }));

  }, [query])

  useEffect(() => {
    setSearch(query.get('search') ?? '');
  }, [query]);

  const handleSearch = (values) => {
    setSearch(values);
    UIProps.setSearchQuery(values);
  }

  const onFilterTypeHandler = (value) => {
    UIProps.setFilterQuery('email_type', value)
    setFilterParamsBase(prev => ({
      ...prev,
      email_type: value
    }))
  }

  const onDeleteFilterHandler = (key, value) => {
    setFilterParamsBase(prev => ({
      ...prev,
      [key]: 'all_types',
    }));
    UIProps.setFilterQuery('DELETE_PARAMS', key);
  }

  return (
    <Row className="mb-3">
      <Col md="4">
        <SearchControl search={search} handleSearch={handleSearch} />
      </Col>

      <Col sm="12" md="6" lg="8" className='d-flex align-items-start justify-content-start px-md-0'>

        {/* --------------------------------------- Connections filter started --------------------------------------- */}
        <Dropdown className="dropdown-inline symbol mx-3" drop="down">

          <Dropdown.Toggle variant="outline-secondary" className='d-flex justify-content-between align-items-center font-weight-bold' style={{ height: theme.units.input.height['sm'] }} id="dropdown-basic">
            <span className="svg-icon svg-icon-sm mr-3">
              {EMAIL_TEMPLATE_STATUS.find(i => i.type === filterParamsBase.email_type)?.icon}
            </span>
            {EMAIL_TEMPLATE_STATUS.find(i => i.type === filterParamsBase.email_type)?.label}
          </Dropdown.Toggle>

          {filterParamsBase.email_type !== 'all_types' && <i className="symbol-badge bg-danger"></i>}
          <Dropdown.Menu className="dropdown-menu-right dropdown-menu-anim-down" id="dropdown_email_type_options">
            <ul className="navi navi-hover">

              {EMAIL_TEMPLATE_TYPE.map((item) => (
                <li key={item.type} className="navi-item">
                  {/* {item.type === 'all_types' && <Dropdown.Divider />} */}
                  <Dropdown.Item className='navi-link font-weight-bold' id={`dropdown_item_${item.type}`}
                    onClick={() => onFilterTypeHandler(item.type)}>
                    <span className="svg-icon svg-icon-md mr-2">
                      {item.icon}
                    </span>
                    <span className="navi-text">
                      {item.label}
                    </span>
                  </Dropdown.Item>
                </li>
              ))}
              <Dropdown.Divider />

              <li className="navi-item">
                <Dropdown.Item
                  className='navi-link font-weight-bolder text-danger justify-content-center bg-white py-1'
                  onClick={() => onDeleteFilterHandler('email_type', EMAIL_TEMPLATE_STATUS.find(i => i.name === filterParamsBase.email_type)?.type)}
                  id='clear-email-type'
                >
                  <FormattedMessage id='GENERAL.CLEAR' />
                </Dropdown.Item>
              </li>


            </ul>
          </Dropdown.Menu>
        </Dropdown>

        {/* --------------------------------------- Connections filter ended --------------------------------------- */}
        <DatePickerDropdown />
      </Col>
    </Row>
  );
}
