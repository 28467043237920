import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useQuery } from 'app/hooks';
import { ShippingsGrouping } from './ShippingsGrouping';
import { SearchControl } from '_metronic/_partials/controls';



export function ShippingsFilter({ setSearchQuery }) {

  const query = useQuery();
  const [search, setSearchBase] = useState(query.get('search') ?? '');

  useEffect(() => {
    setSearchBase(query.get('search') ?? '');
  }, [query]);

  const handleSearch = value => {
    setSearchQuery(value);
    setSearchBase(value);
  };


  return (
    <Row className="mb-3">

      <Col sm="12" md="6" lg="4">
        <SearchControl search={search} handleSearch={handleSearch} />
      </Col>

      <Col sm="12" md="6" lg="8">
        <ShippingsGrouping />
      </Col>

    </Row>
  );
}
