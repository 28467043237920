import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '_metronic/_partials/controls';
import { UserGuideHelper } from '../../user-guides/UserGuideHelper';
import { useSupportUIContext } from './_context/SupportUIContext';
import { SupportFilter } from './support-filter/SupportFilter';
import { LoadingDialog } from "_metronic/_partials/loading";
import { BlankMessage } from '_metronic/_partials/components';
import { SupportTable } from './support-table/SupportTable';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { SupportActions } from './_redux/actions';
import { NewButton } from '_metronic/_partials';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';



export function SupportCard() {

  const dispatch = useDispatch();

  const UIContext = useSupportUIContext();
  const UIProps = useMemo(() => ({
    setTicketId: UIContext.setTicketId,
    openEditTicketDialog: UIContext.openEditTicketDialog,
    openDeleteTicketDialog: UIContext.openDeleteTicketDialog,
    queryParams: UIContext.queryParams,
    setPaginationQuery: UIContext.setPaginationQuery,
    setOrderQuery: UIContext.setOrderQuery,
    setSearchQuery: UIContext.setSearchQuery,
    setFilterQuery: UIContext.setFilterQuery,
    dateRange: UIContext.dateRange,
    changeDateRange: UIContext.changeDateRange,
  }), [UIContext]);

  const { loading, totalTickets, role } = useSelector(state => ({
    loading: state.supports.loading,
    totalTickets: state.supports.totalTickets,
    role: state.auth.user.role
  }));

  useEffect(() => {
    let delayDebounceFn;

    if (UIProps.queryParams.search) {
      delayDebounceFn = setTimeout(() => {
        dispatch(SupportActions.getTickets(UIProps.dateRange, UIProps.queryParams));
      }, 350);
    } else {
      dispatch(SupportActions.getTickets(UIProps.dateRange, UIProps.queryParams));
    }
    return () => {
      clearTimeout(delayDebounceFn);
    };
    // eslint-disable-next-line
  }, [dispatch, UIProps.queryParams, UIProps.dateRange]);


  return (
    <Card className="card-height">

      <CardHeader
        icon={<Icon.Support />}
        title={<FormattedMessage id="SUPPORT.TITLE" />}
        info={<UserGuideHelper />}
        className="display-5"
        id='title_support'
      >

        <CardHeaderToolbar>
          {role !== "644c41e257d397eb064fe0f9" && <NewButton link={ModuleRoutes.NEW_TICKET} label="SUPPORT.NEW_TICKET" />}
        </CardHeaderToolbar>

      </CardHeader>

      <CardBody>
        {totalTickets === 0
          ? <BlankMessage
            icon={<Icon.ChatLeft />}
            title={<FormattedMessage id='SUPPORT.BLANK_MESSAGE_TITLE' />}
            message={<FormattedMessage id='SUPPORT.BLANK_MESSAGE' />}
            className='min-50-height'
            loading={loading}
            arrow
          />
          : <>
            <SupportFilter UIProps={UIProps} />
            <SupportTable UIProps={UIProps} loading={loading} role={role} />
          </>
        }
      </CardBody>

      <LoadingDialog isLoading={loading} />

    </Card>
  );
}