import React, { useContext, createContext, useState, useCallback } from 'react';
import { useSearchQuery } from 'app/hooks';



const UserContactUIContext = createContext({});
export const useUserContactUIContext = () => useContext(UserContactUIContext);


export const UserContactUIProvider = ({ children }) => {

  const [ids, setIds] = useState([]);
  const [userId, setUserId] = useState();

  // query params
  const [queryParams, setQueryBase] = useState({
    pagination: {
      page: 1,
      limit: 10,
    },
    order: 'id DESC',
    search: '',
  });

  const setQueryParams = useCallback((params) => setQueryBase(params), [])

  const { setSearchQuery, setFilterQuery, setPaginationQuery, setOrderQuery } = useSearchQuery(setQueryParams);

 //* User details dialog
 const [showUserDetailsDialog, setShowUserDetailsDialog] = useState(false);
 const openUserDetailsDialog = id => {
  setUserId(id);
  setShowUserDetailsDialog(true);
 };
 const closeUserDetailsDialog = () => {
  setUserId(undefined);
  setShowUserDetailsDialog(false);
 };

  const value = {
    ids,
    setIds,
    userId,
    setUserId,
    queryParams,
    setSearchQuery,
    setFilterQuery,
    setPaginationQuery,
    setOrderQuery,

    showUserDetailsDialog,
    closeUserDetailsDialog,
    openUserDetailsDialog,
  };


  return (
    <UserContactUIContext.Provider value={value}>
      {children}
    </UserContactUIContext.Provider>
  );
};
