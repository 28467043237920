import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { userGuides } from './guides/index';
import UserGuidesConnectionInfo from './UserGuidesConnectionInfo';




export function UserGuideConnectionHelper({ connectionName, edit }) {

  const [guide, setGuide] = useState({
    title: '',
    description: '',
  });

  const { pathname } = useLocation();

  useEffect(() => {

    let subGuide = {};
    if (pathname.split('/')[1] === 'connections') {
      const findGuide = userGuides.find(guide => guide.main.location === 'add');
      subGuide = findGuide && findGuide[connectionName];
    }

    //const pathNames = pathname.split('/');
    //const lastPath = pathNames[pathNames.length - 1];

    //let findGuide, subGuide;

    //if (pathNames[1] === 'connections' && pathNames[2] === 'add') {
    //  findGuide = userGuides.find(guide => guide.main.location === pathNames[2]);
    //  subGuide = findGuide && findGuide[lastPath];
    //}
    //if (pathNames[1] === 'connections' && lastPath === 'edit') {
    //  findGuide = userGuides.find(guide => guide.main.location === lastPath);
    //  subGuide = findGuide && findGuide[pathNames[2]];
    //}

    subGuide && setGuide(subGuide);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);


  return (
    guide.description
      ? <UserGuidesConnectionInfo guide={guide} edit={edit} />
      : null
  )
}

