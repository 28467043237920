import React from 'react';
import { SVFormControl, SVFormTextarea } from '_metronic/_partials/controls';
import * as uiHelpers from '../_context/DocumentsUIHelpers';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { maxValidation } from '_metronic/_helpers';




export function PaymentReminderAddForm({ btnRef, handleSubmit, type, error, showForm }) {

  const { formatMessage: intl } = useIntl();

  const reminderValidationSchema = Yup.object().shape({
    after_text: Yup.string()
      .required(intl({ id: 'GENERAL.REQUIRED' }))
      .max(500, maxValidation("500")),
    before_text: Yup.string()
      .required(intl({ id: 'GENERAL.REQUIRED' }))
      .max(1500, maxValidation("1500")),
    amount: Yup.number()
      .when('type', {
        is: (type) => type !== 'reminder',
        then: Yup.number()
          .typeError(intl({ id: 'DOCUMENT.PAYMENT.REMINDER.INVALID_AMOUNT' }))
          .min(0, intl({ id: 'DOCUMENT.PAYMENT.REMINDER.AMOUNT.GREATER_OR_EQUAL' }))
          .test('maxDecimal', intl({ id: 'DOCUMENT.PAYMENT.REMINDER.MAX_DECIMAL_ALLOWED' }), (value) => {
            if (value !== undefined) {
              const decimalCount = value.toString().split('.')[1]?.length || 0;
              return decimalCount <= 2;
            }
            return true;
          })
          .required(intl({ id: 'GENERAL.REQUIRED' })),
      }),


  });

  return (
    <>
      {error
        ? <h5 className='p-20 text-center'>{error || <FormattedMessage id='DOCUMENT.PAYMENT.REMINDER.ERROR' />}</h5>
        : showForm && (
          <Formik validationSchema={reminderValidationSchema} initialValues={{ ...uiHelpers.initialPaymentWarnings, type: type }} enableReinitialize={true} onSubmit={(values) => handleSubmit(values)}>
            {({ values, handleSubmit }) => (

              <Form className="form" onSubmit={handleSubmit}>

                <Field component={SVFormTextarea}
                  {...{
                    name: "after_text",
                    label: <FormattedMessage id='DOCUMENT.PAYMENT.REMINDER.BEFORE_TEXT' />,
                    rows: 6,
                    withFeedbackLabel: true,
                    feedbackLabel: values?.after_text?.length
                      ? <span className={`${values?.after_text?.length >= 500 ? 'text-danger' : 'text-success'}`}>
                        <FormattedMessage id="SETTINGS.DOCUMENT.REMAINING_CHARACTER" values={{ character: 500 - values?.after_text?.length }} />
                      </span>
                      : true,
                  }}
                />

                <Field component={SVFormTextarea}
                  {...{
                    name: "before_text",
                    label: <FormattedMessage id='DOCUMENT.PAYMENT.REMINDER.AFTER_TEXT' />,
                    rows: 6,
                    withFeedbackLabel: true,
                    feedbackLabel: values?.before_text?.length
                      ? <span className={`${values?.before_text?.length >= 1500 ? 'text-danger' : 'text-success'}`}>
                        <FormattedMessage id="SETTINGS.DOCUMENT.REMAINING_CHARACTER" values={{ character: 1500 - values?.before_text?.length }} />
                      </span>
                      : true,
                  }}
                />

                {type !== 'reminder' && (
                  <Field component={SVFormControl}
                    {...{
                      name: "amount",
                      label: <FormattedMessage id='DOCUMENT.TABLE.PAYMENT_REMINDER' />,
                      withFeedbackLabel: true,
                      type: 'number',
                      min: 0,
                      feedbackLabel: true
                    }}
                  />
                )}

                <button type="submit" className='d-none' ref={btnRef} />
              </Form>
            )}
          </Formik>
        )}
    </>)

}