import { useCallback } from 'react';
import { isArray } from 'lodash';
import { useSelector } from 'react-redux';



export function useSkuValidationControl() {
  
  const { findProductBySku } = useSelector(state => ({
    findProductBySku: state.products.findProductBySku,
  }));

  const checkIsSkuValid = useCallback( value => { 
      if (isArray(findProductBySku) && findProductBySku.length > 0) {
        const isMatchedProduct = findProductBySku.find(prod => prod.sku === value);
        const isMatchedVariant = findProductBySku.find(prod =>
          prod.variants.find(variant => variant.sku === value)
        );
        return isMatchedProduct || isMatchedVariant ? true : false;
      }
      return false;
    },
    [findProductBySku]
  );

  const checkIsSkuValidForVariants = useCallback(
    value => {
      if (isArray(findProductBySku)) {
        const isMatchedProduct = findProductBySku.find(prod => prod.sku === value);
        const isMatchedVariant = findProductBySku.find(prod =>
          prod.variants.find(variant => variant.sku === value)
        );
        return isMatchedProduct || isMatchedVariant ? true : false;
      }
      return false;
    },
    [findProductBySku]
  );

  return { checkIsSkuValid, checkIsSkuValidForVariants };
}
