import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

export const OrderFormatter = (cell, row) => {
  return (
    cell === 0
      ? <div className={`label label-xl label-inline label-rounded font-weight-bolder font-size-lg px-2 label-light-danger text-danger`}>
        <span className={`label label-xl label-dot mr-2 label-danger`}></span>
        {cell}
      </div>
      : cell
  )
}

export const SupportFormatter = (cell, row) => {
  return (
    cell.support_count > 0
      ? <OverlayTrigger placement="top" overlay={
        <Tooltip id={`support-tooltip-${row.id}`}>
          <li className='text-left pb-0'>
            <FormattedMessage id="SUPPORT.TOTAL_TICKET" />: {cell.support_count}
          </li>
          <li className='text-left pb-0'>
            <FormattedMessage id="GENERAL.COMPLETED" />: {cell.support_completed_count}
          </li>
        </Tooltip>
      }>
        <div className={`label label-xl label-inline label-rounded font-weight-bolder font-size-lg px-2 ${cell.support_completed_count === 0 ? 'label-light-danger' : cell.support_count === cell.support_completed_count ? 'label-light-success' : 'label-light-warning'}`}>
          {cell.support_completed_count === 0 && cell.support_count > 0 ? <span className={`label label-xl label-dot mr-2 label-danger`}></span> : null}
          {cell.support_count
            ? <span className={`${cell.support_completed_count === 0 ? 'text-danger' : cell.support_count === cell.support_completed_count ? 'text-success' : 'text-warning'} mr-1`}>
              {cell.support_count}  {cell.support_completed_count ? <>/ {cell.support_completed_count}</> : null}
            </span>
            : null
          }
        </div>
      </OverlayTrigger>
      : cell.support_count
  )
}