import { call, put, select, takeEvery } from 'redux-saga/effects';
import { importExportMiddleware, productMiddleware, tokenErrorHandler } from 'middlewares';
import { digitalProductsMiddleware } from 'middlewares/digital-products/middleware';
import { SettingsActions } from 'app/modules/settings/_redux/actions';
import { ProductActions } from './actions';
import ActionTypes from './actionTypes';



// Create Products
function* createProduct({ payload: { product, upload } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const response = yield call(productMiddleware.createProduct, accessToken, product);
    if (response && upload.is) {
      yield call(productMiddleware.uploadImages, accessToken, response.data.id, upload.file);
    }

    yield put(ProductActions.createProductSuccess());
    yield put(SettingsActions.getUserSettingsByType("products"));

  } catch (err) {
    yield put(ProductActions.createProductFail(err.response));
  }
}



// Image Delete
function* deleteFile({ payload: { productId, fileType, fileId } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(productMiddleware.deleteFile, accessToken, productId, fileType, fileId);
    yield put(ProductActions.deleteFileSuccess(fileType, fileId));
  } catch (err) {
    yield put(ProductActions.deleteFileFail());
  }
}



// Get Products
function* getProducts({ payload: { queryParams } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const countProducts = yield call(productMiddleware.getCount, accessToken, queryParams);
    const response = yield call(productMiddleware.getProducts, accessToken, queryParams);

    yield put(ProductActions.getProductsSuccess(response.data, countProducts.data));
  } catch (err) {
    yield call(tokenErrorHandler, err, ProductActions.getProductsFail());
  }
}



// Get Product
function* getProduct({ payload: { id, isCopy } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const product = yield call(productMiddleware.getProductById, accessToken, id);
    yield put(ProductActions.getProductByIdSuccess(product.data, isCopy));

  } catch (err) {
    yield call(tokenErrorHandler, err, ProductActions.getProductByIdFail(JSON.stringify(err)));
  }
}



// Find Product By Property
function* findProductByProperty({ payload: { key, value } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const product = yield call(productMiddleware.findProduct, accessToken, key, value);
    yield put(ProductActions.findProductByPropertySuccess(product.data));
  } catch (err) {
    yield call(tokenErrorHandler, err, ProductActions.findProductByPropertyFail());
  }
}



function* findProductBySku({ payload: { value, productId } }) {
  const accessToken = yield select(state => state.auth.access_token);

  try {
    const product = yield call(productMiddleware.findProductBySku, accessToken, value, productId);
    yield put(ProductActions.findProductBySkuSuccess(product.data));
  } catch (error) {
    yield call(tokenErrorHandler, error, ProductActions.findProductBySkuFail());
  }
}



// Find Product By Property Warning
function* findProductByPropertyWarning({ payload: { key, value } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const product = yield call(productMiddleware.findProduct, accessToken, key, value);
    if (key === 'identities.barcode') {
      return yield put(ProductActions.findProductByPropertyBarcodeSuccess(product.data));
    }
    if (key === 'identities.ean') {
      return yield put(ProductActions.findProductByPropertyEanSuccess(product.data));
    }
    if (key === 'identities.gtin') {
      return yield put(ProductActions.findProductByPropertyGtinSuccess(product.data));
    }
    if (key === 'identities.isbn') {
      return yield put(ProductActions.findProductByPropertyIsbnSuccess(product.data));
    }
    if (key === 'identities.asin') {
      return yield put(ProductActions.findProductByPropertyAsinSuccess(product.data));
    }
    if (key === 'identities.upc') {
      return yield put(ProductActions.findProductByPropertyUpcSuccess(product.data));
    }
    /*if (key === 'serieNumber') {
      return yield put(
        ProductActions.findProductByPropertySeriennummerSuccess(product.data)
      );
    } */
  } catch (err) {
    yield call(tokenErrorHandler, err, ProductActions.findProductByPropertyBarcodeFail());
  }
}



// Update Product
function* updateProduct({ payload: { productId, product, upload } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const response = yield call(productMiddleware.patchProduct, accessToken, productId, product);

    if (response && upload.is) {
      yield call(productMiddleware.uploadImages, accessToken, productId, upload.file);
    }

    yield put(ProductActions.updateProductSuccess());
    yield put(SettingsActions.getUserSettingsByType("products"));

  } catch (err) {
    const error = err?.response?.data?.error || err?.message || 'Something went wrong';
    yield put(ProductActions.updateProductFail(error));
  }
}



// Update Product From Field
function* updateProductFromField({ payload: { id, updatedFields, queryParams } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(productMiddleware.patchProduct, accessToken, id, updatedFields);
    yield put(ProductActions.updateProductFromTableFieldSuccess());
    yield put(ProductActions.getProducts(queryParams));
  } catch (err) {
    const error = err?.response ? err.response.data.error : err?.message;
    yield call(tokenErrorHandler, err, ProductActions.updateProductFromTableFieldFail(error));
  }
}



// Delete Product
function* deleteProduct({ payload: { id } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(productMiddleware.deleteProduct, accessToken, id);
    yield put(ProductActions.deleteProductSuccess(id));
    yield put(SettingsActions.getUserSettingsByType("products"));

  } catch (err) {
    yield call(tokenErrorHandler, err, ProductActions.deleteProductFail());
  }
}



// Update Product
function* patchProductByField({ payload: { id, dataObject } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(productMiddleware.patchProduct, accessToken, id, dataObject);
    yield put(ProductActions.patchProductByFieldSuccess());

  } catch (err) {
    yield call(tokenErrorHandler, err, ProductActions.patchProductByFieldFail());
  }
}



// Delete All Selected Product
function* deleteAllSelectedProduct({ payload: { selectedProductIds, queryParams } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(productMiddleware.deleteProduct, accessToken, selectedProductIds);
    yield put(ProductActions.getProducts(queryParams));
    yield put(SettingsActions.getUserSettingsByType("products"));

  } catch (err) {
    yield call(tokenErrorHandler, err, ProductActions.patchProductByFieldFail());
  }
}



// Update All Selected Product
function* updateAllSelectedProductsBeiShops({ payload: { productData } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(productMiddleware.updateAllSelectedProductsBeiShops, accessToken, productData);
    yield put(ProductActions.updateAllSelectedProductsBeiShopsSuccess());

  } catch (err) {
    yield call(tokenErrorHandler, err, ProductActions.updateAllSelectedProductsBeiShopsFail(JSON.stringify(err)));
  }
}


// Product General page supplier
function* getSupplier() {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const suppliers = yield call(productMiddleware.getSupplier, accessToken);
    yield put(ProductActions.getSupplierSuccess(suppliers.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, ProductActions.getSupplierFail());
  }
}

// Import Example Download
function* importExample({ payload: { data } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const response = yield call(importExportMiddleware.importExample, accessToken, data);

    // CSV dosyasını indir 
    function downloadFile(data, filename) {
      const csvContent = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data);
      const linkElement = document.createElement('a');
      linkElement.setAttribute('href', csvContent);
      linkElement.setAttribute('download', filename);
      linkElement.style.display = 'none';
      document.body.appendChild(linkElement);
      linkElement.click();
      document.body.removeChild(linkElement);
    };

    let fileNamesList = {
      "de": "DigitalProductKeysImportBeispiel.csv",
      "en": "DigitalProductKeysImportExample.csv",
      "tr": "DijitalUrunAnahtarlariImportOrnegi.csv"
    };

    const fileName = fileNamesList[data.locale];

    downloadFile(response.data, fileName);

    yield put(ProductActions.importExampleSuccess());
  } catch (err) {
    yield call(tokenErrorHandler, err, ProductActions.importExampleFail());
  }
}

// Add Keys
function* addProductKeys({ payload: { productId, keys } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);
    const response = yield call(digitalProductsMiddleware.addProductKeys, accessToken, productId, keys);

    yield put(ProductActions.addProductKeysSuccess(productId, response.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, ProductActions.addProductKeysFail());
  }
}


// Delete Keys
function* deleteProductKeys({ payload: { productId, keyIds } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);
    const response = yield call(digitalProductsMiddleware.deleteProductKeys, accessToken, productId, keyIds);

    yield put(ProductActions.deleteProductKeysSuccess(response.data));

    if (productId && response.data.notDeletedKeys?.length) {
      yield put(ProductActions.getProductById(productId));
    }

  } catch (err) {
    yield call(tokenErrorHandler, err, ProductActions.deleteProductKeysFail());
  }
}


export function* productSaga() {
  yield takeEvery(ActionTypes.GET_PRODUCTS, getProducts);
  yield takeEvery(ActionTypes.GET_PRODUCT_BY_ID, getProduct);
  yield takeEvery(ActionTypes.DELETE_PRODUCT, deleteProduct);
  yield takeEvery(ActionTypes.CREATE_PRODUCT, createProduct);
  yield takeEvery(ActionTypes.UPDATE_PRODUCT, updateProduct);
  yield takeEvery(ActionTypes.PATCH_PRODUCT_BY_FIELD, patchProductByField);
  yield takeEvery(ActionTypes.DELETE_FILE, deleteFile);
  yield takeEvery(ActionTypes.FIND_PRODUCT_BY_PROPERTY, findProductByProperty);
  yield takeEvery(ActionTypes.FIND_PRODUCT_BY_SKU, findProductBySku);
  yield takeEvery(ActionTypes.UPDATE_STOCK_BY_SHOPS, updateAllSelectedProductsBeiShops);
  yield takeEvery(ActionTypes.FIND_PRODUCT_BY_PROPERTY_WARNING, findProductByPropertyWarning);
  yield takeEvery(ActionTypes.UPDATE_PRODUCT_FROM_TABLE_FIELD, updateProductFromField);
  yield takeEvery(ActionTypes.DELETE_ALL_SELECTED_PRODUCTS, deleteAllSelectedProduct);
  yield takeEvery(ActionTypes.GET_SUPPLIER, getSupplier);
  yield takeEvery(ActionTypes.IMPORT_EXAMPLE_PRODUCT_KEYS, importExample);
  yield takeEvery(ActionTypes.DELETE_PRODUCT_KEYS, deleteProductKeys);
  yield takeEvery(ActionTypes.ADD_PRODUCT_KEYS, addProductKeys);
}
