import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { UserGuideConnectionHelper } from '../../../user-guides/UserGuideConnectionHelper'
import { useSettingsEditContext } from '../_context/SettingsEditContext'
import { CONNECTION_SETTINGS } from 'constants/connectionSettings'
import { INITIAL_VALUES } from '../_context/SettingsUIHelpers'
import { ConnectionsActions } from '../_redux/actions'
import { formComponents } from '../connection-forms'
import { CloseButton } from '_metronic/_partials'
import { Alert, Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { Icon } from '_metronic/_icons';



export function ConnectionEditDialog() {

  const dispatch = useDispatch();
  const { search } = useLocation();

  const UIContext = useSettingsEditContext();
  const UIProps = useMemo(() => ({
    show: UIContext.showConnectionAddDialog,
    close: UIContext.closeConnectionAddDialog,
    connectionName: UIContext.connection.connectionName,
    connectionId: UIContext.connection.connectionId,
    openConnectionAddDialog: UIContext.openConnectionAddDialog,
  }), [UIContext]);

  const { error, loading } = useSelector(state => state.connections);

  const findToken = CONNECTION_SETTINGS.find((token) => UIProps.connectionName === token.name);
  const TokenForm = findToken ? formComponents[findToken.name]?.component : null;

  const isLoading = (["code", "MarketplaceID", "state"].some(param => search.includes(param)) && !error) || loading;


  const handleClose = () => {
    UIProps.close();
    dispatch(ConnectionsActions.cleanStatesInStore({ error: '' }))
  };


  return (
    <Modal show={UIProps.show} onHide={handleClose} size='lg' aria-labelledby="example-modal-sizes-title-lg" backdrop="static" keyboard={false} centered>

      <CloseButton onClick={handleClose} className="position-absolute top-0 right-0 mr-10 mt-10" />

      <div className="d-flex justify-content-center icon-4x pt-15">
        {findToken?.iconLong}
      </div>

      <span style={{ opacity: isLoading ? 0.5 : 1 }}>
        {TokenForm &&
          <TokenForm connectionId={UIProps.connectionId} initialValue={INITIAL_VALUES[UIProps.connectionName]} loading={isLoading} onHide={handleClose}>
            {!UIProps.connectionId &&
              (error &&
                <Alert variant='danger' className="alert-custom svg-icon py-2 px-4 my-0 mr-auto">
                  <div className="alert-text">
                    <Icon.ExclamationCircle className="mr-2" />
                    <FormattedMessage id={error ? error : 'GENERAL.ERROR_MESSAGE_GENERAL'} />
                  </div>
                </Alert>)
            }
          </TokenForm>
        }
      </span>
      {!UIProps.connectionId && <UserGuideConnectionHelper connectionName={UIProps.connectionName} />}

      {isLoading && <div className="spinner spinner-xl spinner-center spinner-danger mt-17" />}

    </Modal>
  )
}
