import ActionTypes from './actionTypes';
import { INITIAL_VALUES, setConnectionByName } from '../_context/SettingsUIHelpers';


const INITIAL_CONNECTION = {
  amazonForEdit: INITIAL_VALUES.amazon,
  ebayForEdit: INITIAL_VALUES.ebay,
  check24ForEdit: INITIAL_VALUES.check24,
  etsyConnection: INITIAL_VALUES.etsyConnection,
  etsyForEdit: INITIAL_VALUES.etsy,
  hoodForEdit: INITIAL_VALUES.hood,
  kauflandForEdit: INITIAL_VALUES.kaufland,
  manomanoForEdit: INITIAL_VALUES.manomano,
  ottoForEdit: INITIAL_VALUES.otto,

  prestashopForEdit: INITIAL_VALUES.prestashop,
  shopifyForEdit: INITIAL_VALUES.shopify,
  shopware5ForEdit: INITIAL_VALUES.shopware5,
  shopware6ForEdit: INITIAL_VALUES.shopware6,
  woocommerceForEdit: INITIAL_VALUES.woocommerce,

  dpProductList: [],
  deutschePostConfigs: {},
  deutschePostForEdit: INITIAL_VALUES.deutsche_post,
  dpdForEdit: INITIAL_VALUES.dpd,
  dhlForEdit: INITIAL_VALUES.dhl,
  glsForEdit: INITIAL_VALUES.gls,
  dhlNewForEdit: INITIAL_VALUES.dhl_new,
};


const INITIAL_STATE = {
  loading: false,
  success: false,
  error: '',
  entities: [],
  orders: [],
  ...INITIAL_CONNECTION
};



export function connectionsReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {


    // Get Settings By Id
    case ActionTypes.GET_CONNECTIONS:
      return {
        ...state,
        loading: true
      };

    case ActionTypes.GET_CONNECTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        entities: payload.connections
      };

    case ActionTypes.GET_CONNECTIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: 'GENERAL.ERROR_MESSAGE_GENERAL'
      };



    // Get Settings By Id
    case ActionTypes.GET_CONNECTION_BY_ID:
      return {
        ...state,
        loading: true
      };

    case ActionTypes.GET_CONNECTION_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        ...setConnectionByName(payload.connection)
      };

    case ActionTypes.GET_CONNECTION_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: 'GENERAL.ERROR_MESSAGE_GENERAL'
      };



    // Create Settings
    case ActionTypes.CREATE_SETTINGS:
      return {
        ...state,
        loading: true,
        success: false
      };

    case ActionTypes.CREATE_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        ...setConnectionByName(payload.settings),
        entities: [
          payload.settings,
          ...state.entities,
        ]
      };

    case ActionTypes.CREATE_SETTINGS_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: 'GENERAL.ERROR_MESSAGE_GENERAL'
      };



    // Update Settings
    case ActionTypes.UPDATE_SETTINGS:
      return {
        ...state,
        loading: true,
        success: false
      };

    case ActionTypes.UPDATE_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        ...setConnectionByName(payload.settings),
        entities: state.entities.map((item) => (item.id === payload.settings.id ? { ...item, ...payload.settings } : item))
      };

    case ActionTypes.UPDATE_SETTINGS_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: payload.error
      };



    // Delete Settings
    case ActionTypes.DELETE_SETTINGS:
      return {
        ...state,
        loading: true
      };

    case ActionTypes.DELETE_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        entities: state.entities.filter((item) => item.id !== payload.settingsId)
      };

    case ActionTypes.DELETE_SETTINGS_FAIL:
      return {
        ...state,
        loading: false,
        error: 'GENERAL.ERROR_MESSAGE_GENERAL'
      };



    // Connection Check
    case ActionTypes.CONNECTION_CHECK:
      return {
        ...state,
        loading: true
      };

    case ActionTypes.CONNECTION_CHECK_SUCCESS:
      return {
        ...state,
        loading: false,
        ...setConnectionByName(payload.response),
        entities: state.entities.map((item) => (item.id === payload.response.id ? { ...item, ...payload.response } : item))
      };

    case ActionTypes.CONNECTION_CHECK_FAIL:
      return {
        ...state,
        loading: false,
        entities: state.entities.map((item) => (item.id === payload.error.id ? { ...item, ...payload.error } : item))
      };



    //* --------------------- Deutsche Post --------------------- *//

    // Deutsche Post Configs
    case ActionTypes.GET_DEUTSCHE_POST_CONFIGS:
      return {
        ...state,
        loading: true
      };

    case ActionTypes.GET_DEUTSCHE_POST_CONFIGS_SUCCESS:
      return {
        ...state,
        loading: false,
        deutschePostConfigs: payload.configs
      };

    case ActionTypes.GET_DEUTSCHE_POST_CONFIGS_FAIL:
      return {
        ...state,
        loading: false,
        error: 'GENERAL.ERROR_MESSAGE_GENERAL'
      };


    //* -------------------------- DHL -------------------------- *//
    /*     case ActionTypes.DELETE_DHL_SUBSCRIPTION:
    
        const { connectionId, subscriptionId } = payload;
    
          const isDhl = state.entities.find((item) => item.id === connectionId)?.name ==='dhl'
    
          const updatedEntities = state.entities.map((item) => {
            if (item.id === connectionId) {
              return {
                ...item,
                dhl: {
                  ...item.dhl,
                  subscriptions: item.dhl.subscriptions.filter((sub) => sub.id !== subscriptionId)
                }
              };
            }
            return item;
          });
    
          return {
            ...state,
            entities: updatedEntities,
            ...(isDhl ? {
              dhlForEdit: {
                ...state.dhlForEdit,
                dhl: {
                  ...state.dhlForEdit.dhl,
                  subscriptions: state.dhlForEdit.dhl.subscriptions.filter((sub) => sub.id !== subscriptionId)
                }
              }
            } : {
              dhlNewForEdit: {
                ...state.dhlNewForEdit,
                dhl: {
                  ...state.dhlNewForEdit.dhl,
                  subscriptions: state.dhlNewForEdit.dhl.subscriptions.filter((sub) => sub.id !== subscriptionId)
                }
              }
            })
          }; */


    //* -------------------------- Ebay -------------------------- *//

    // Ebay get Auth token
    case ActionTypes.GET_EBAY_AUTH_TOKEN:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case ActionTypes.GET_EBAY_AUTH_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };

    case ActionTypes.GET_EBAY_AUTH_TOKEN_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
      };



    // Ebay set Auth token
    case ActionTypes.SET_EBAY_AUTH_TOKEN:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case ActionTypes.SET_EBAY_AUTH_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        ebayForEdit: payload.settings,
        entities: state.entities.some(item => item.id === payload.settings.id)
          ? state.entities.map(item => item.id === payload.settings.id ? payload.settings : item)
          : [...state.entities, payload.settings]
      };

    case ActionTypes.SET_EBAY_AUTH_TOKEN_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: "GENERAL.ERROR_MESSAGE_GENERAL",
      };



    //* -------------------------- Etsy -------------------------- *//

    // Etsy Get Auth token
    case ActionTypes.GET_ETSY_AUTH_TOKEN:
      return {
        ...state,
        etsyForEdit: {
          ...state.etsyForEdit
        }
      };

    case ActionTypes.GET_ETSY_AUTH_TOKEN_SUCCESS:
      return {
        ...state,
        etsyForEdit: payload.settings,
        entities: state.entities.some(item => item.id === payload.settings.id)
          ? state.entities.map(item => item.id === payload.settings.id ? payload.settings : item)
          : [...state.entities, payload.settings]
      };

    case ActionTypes.GET_ETSY_AUTH_TOKEN_FAIL:
      return {
        ...state,
        etsyForEdit: {
          ...state.etsyForEdit
        },
        error: 'GENERAL.ERROR_MESSAGE_GENERAL'
      };



    // Etsy set Auth token
    case ActionTypes.SET_ETSY_AUTH_TOKEN:
      return {
        ...state,
        success: false,
      };

    case ActionTypes.SET_ETSY_AUTH_TOKEN_SUCCESS:
      return {
        ...state,
        success: true,
        etsyConnection: payload.settings
      };

    case ActionTypes.SET_ETSY_AUTH_TOKEN_FAIL:
      return {
        ...state,
        success: false,
        etsyForEdit: {
          ...state.etsyForEdit
        },
        error: 'GENERAL.ERROR_MESSAGE_GENERAL'
      };

    // Fetch Orders
    case ActionTypes.CONNECTION_FETCH_ORDERS:
      return {
        ...state,
        loading: true
      };

    case ActionTypes.CONNECTION_FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: payload.orders
      };

    case ActionTypes.CONNECTION_FETCH_ORDERS_FAIL:
      return {
        ...state,
        loading: false,
        error: 'GENERAL.ERROR_MESSAGE_GENERAL'
      };


    case ActionTypes.AMAZON_SETTINGS:
      return {
        ...state,
        loading: false,
        success: true,
        amazonForEdit: payload.settings
      };

    case ActionTypes.CLEAR_LOADING_EFFECTS:
      return {
        ...state,
        loading: false,
        success: false,
        error: '',
        orders: [],
        ...INITIAL_CONNECTION
      };

    case ActionTypes.CLEAR_STATES_IN_STORE:
      return {
        ...state,
        ...payload.cleanedStates,
      };

    default:
      return state;
  }
}
