import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { DashboardPage, DocumentPages, SupportPage, CustomerPage, ProductPage, SuppliersPage, SettingsPage, ArchivePage, ProductListingsPage, ConnectionPage, ImportPage, ExportPage, OrderListPage, ShippingPage, DigitalProductsPage, EmailLogsPage } from '../modules';
import { RELATIVE_PATH, ModuleRoutes, BaseRoutes } from 'constants/moduleRoutes';
import { ContentRoute, Layout } from '_metronic/layout';
import { IconPage } from '../pages';



export default function BasePage({ license }) {
  return (
    <Layout>

      <Switch>

        <Redirect exact from={RELATIVE_PATH} to={ModuleRoutes.DASHBOARD} />

        <ContentRoute path={ModuleRoutes.DASHBOARD} component={DashboardPage} />
        <ContentRoute path={ModuleRoutes.DOCUMENT_BASE} component={DocumentPages} />
        <ContentRoute path={ModuleRoutes.SUPPLIER} component={SuppliersPage} />
        <ContentRoute path={ModuleRoutes.CUSTOMER} component={CustomerPage} />
        <ContentRoute path={ModuleRoutes.PRODUCT} component={ProductPage} />
        <ContentRoute path={ModuleRoutes.LISTINGS} component={ProductListingsPage} />
        <ContentRoute path={ModuleRoutes.CONNECTIONS} component={ConnectionPage} />
        <ContentRoute path={ModuleRoutes.IMPORT} component={ImportPage} />
        <ContentRoute path={ModuleRoutes.EXPORT} component={ExportPage} />
        <ContentRoute path={ModuleRoutes.ARCHIVE} component={ArchivePage} />
        <ContentRoute path={ModuleRoutes.SUPPORT} component={SupportPage} />
        <ContentRoute path={ModuleRoutes.REPORT_ORDER_LIST} component={OrderListPage} />
        <ContentRoute path={ModuleRoutes.SETTINGS} component={SettingsPage} />
        <ContentRoute path={ModuleRoutes.SHIPPING} component={ShippingPage} />
        <ContentRoute path={ModuleRoutes.DIGITAL_PRODUCT} component={DigitalProductsPage} />
        <ContentRoute path={ModuleRoutes.EMAIL_LOG} component={EmailLogsPage} />

        {['development', 'admin'].includes(license?.type) &&
          <ContentRoute path={ModuleRoutes.ICON} component={IconPage} />
        }

        <Redirect to={BaseRoutes.ERROR} />

      </Switch>

    </Layout>
  );
}
