import React, { useState } from 'react'
import { useFormikContext } from 'formik';
import { Nav } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { customerEditTabs } from '../_context/CustomersUIHelpers';
import { GeneralForm } from './general/GeneralForm';
import { SpecialForm } from './special/SpecialForm';
import { AddressForm } from './address/AddressForm';
import { BankForm } from './bank/BankForm';



export default function Navigator() {

  const { formatMessage: intl } = useIntl();
  // Tab control
  const [tab, setTab] = useState(customerEditTabs[0].name);
  const { errors } = useFormikContext();

  return (
    <div style={{ minHeight: "350px" }}>

      <Nav variant="tabs" className="nav-bold nav-tabs-line nav-bolder mb-5" activeKey={tab} onSelect={(key) => setTab(key)}>
        {customerEditTabs.map((item, i) => (
          <Nav.Item key={i}>
            <Nav.Link eventKey={item.name} className="mx-1 px-3 pb-2" id={`btn_${item.name}`}>
              <div className="symbol">
                {intl({ id: item.label }).toLocaleUpperCase()}
                {item.name === 'general' && Object.keys(errors).length
                  ? <i className="symbol-badge bg-danger"></i>
                  : null}
              </div>
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>

      {tab === 'general' && <GeneralForm />}
      {tab === 'bank' && <BankForm />}
      {tab === 'special' && <SpecialForm />}
      {tab === 'address' && <AddressForm />}

    </div>
  )
}
