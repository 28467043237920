import React, { useCallback, useEffect, useMemo } from 'react';
import { useQuery } from 'app/hooks';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { selectAmazonItem, selectAmazonListings } from '../_redux/reducer';
import { AmazonListingsActions } from '../_redux/actions';
// components
import { Icon } from '_metronic/_icons';
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls';
import { AmazonListingsAddProductForm } from './AmazonListingsAddProductForm';
import { AmazonSuggestedProductList } from '../amazon-suggested-product-list/AmazonSuggestedProductList';
import { AmazonListingsAddProductHeader } from './AmazonListingsAddProductHeader';
import { FormattedMessage } from 'react-intl';
import { useProductListingsUIContext } from '../../_context/ProductListingsUIContext';

const setupInitialValues = (product) => {
  if (!product)
    return {
      sku: '',
      price: 0,
      quantity: 0,
      condition: 'new_new',
      fulfillmentStrategy: 'FBM'
    };
  return {
    sku: product.sku,
    price: product.pricing.gross_price,
    quantity: product.quantity,
    condition: 'new_new',
    fulfillmentStrategy: 'FBM'
  };
};

export function AmazonListingsAddProduct({
  match: {
    params: { tokenId }
  }
}) {
  const productListingsUIContext = useProductListingsUIContext();
  const UIProps = useMemo(
    () => ({
      backToMainPage: productListingsUIContext.backToMainPage
    }),
    [productListingsUIContext.backToMainPage]
  );
  const dispatch = useDispatch();
  // extract query params
  const query = useQuery();
  const asin = query.get('asin');
  const productId = query.get('productId');
  // const identifier = query.get('identifier');

  // get amazon item from store
  const item = useSelector(selectAmazonItem);
  // get product from store by productId
  const { product } = useSelector(selectAmazonListings);

  useEffect(() => {
    // fetch item from Amazon by ASIN
    if (asin) {
      dispatch(AmazonListingsActions.amazonFindListingItemRequest({ asin, tokenId }));
    }

    // fetch product fron database by productId
    if (productId) {
      dispatch(AmazonListingsActions.getProductByIdRequest(productId));
    }

    return () => {
      dispatch(AmazonListingsActions.amazonListingReset());
    };
  }, [dispatch, asin, productId, tokenId]);

  const onAddListingToAmazon = useCallback(
    (values, action) => {
      const payload = {
        tokenId: tokenId,
        sku: values.sku,
        productType: values.productType,
        asin: values.asin,
        attributes: {
          merchant_suggested_asin: values.asin,
          condition_type: values.condition,
          fulfillment_availability: values.fulfillmentStrategy,
          merchant_shipping_group: 'legacy-template-id',
          purchasable_offer: {
            currency: 'EUR',
            price: values.price
          },
          quantity: values.quantity
        }
      };
      dispatch(AmazonListingsActions.amazonCreateListingRequest(payload));
    },
    [dispatch, tokenId]
  );

  return (
    <>
      {/* <small>B07BS89D3Q</small> */}
      <Card className="card-box">
        <AmazonListingsAddProductHeader backToMainPage={UIProps.backToMainPage} />

        {product && item && (
          <Card className="gutter-b mt-4">
            <CardSubHeader title={<FormattedMessage id="PRODUCT.GENERAL" />} icon={<Icon.Tag />} id="general" />

            <CardBody>
              <div className="row justify-content-between flex-sm-row-reverse">
                {/* Item info */}
                <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                  <AmazonSuggestedProductList item={item} />
                </div>

                {/* Add product form */}
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <AmazonListingsAddProductForm onAddListingToAmazon={onAddListingToAmazon} initialValues={setupInitialValues(product)} item={item} />
                </div>
              </div>
            </CardBody>
          </Card>
        )}
      </Card>
    </>
  );
}
