import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl';
import { Card, CardBody, CardHeader, CardHeaderToolbar, SearchControl } from "_metronic/_partials/controls";
import { LoadingDialog } from '_metronic/_partials/loading';
import { Dropdown } from 'react-bootstrap';
import { theme } from '_metronic/_helpers';
import { Icon } from '_metronic/_icons';
import { iconIndex } from './Icons'



const typeOption = [
  {
    value: "general",
    label: <FormattedMessage id="ICONS.GENERAL" />,
  },
  {
    value: "dashboard",
    label: <FormattedMessage id="ICONS.DASHBOARD" />,
  },
  {
    value: "flag",
    label: <FormattedMessage id="ICONS.FLAG" />,
  },
  {
    value: "company",
    label: <FormattedMessage id="ICONS.COMPANY" />,
  },
  {
    value: "all",
    label: <FormattedMessage id="ICONS.ALL" />,
  },
];


export default function IconPage() {

  const [search, handleSearch] = useState('')
  const [copiedIcon, setCopiedIcon] = useState(null);
  const [iconType, setIconType] = useState(typeOption[typeOption.length - 1]);

  const sortedIcons = (icons) => {
    return icons.sort((a, b) => a.name.localeCompare(b.name));
  };

  const filteredIcons = (items) => {
    const filteredType = iconType.value === 'all' ? items : items.filter((el) => el.type === iconType.value)
    const filteredIcons = filteredType.filter((el) => el.name.toLowerCase().includes(search.toLowerCase()));
    return sortedIcons(filteredIcons);
  };

  const copyClipboard = (text) => {
    navigator.clipboard.writeText(`<Icon.${text} />`)
    setCopiedIcon(text);
    setTimeout(() => setCopiedIcon(null), 3000);
  };


  return (
    <>
      <LoadingDialog isLoading={copiedIcon} text='Copied...' />

      <Card className="card-box">
        <CardHeader icon={<Icon.Salevali />} title={<FormattedMessage id="ICONS" />} className="display-5" sticky>

          <CardHeaderToolbar>

            <span className="d-none d-sm-inline mr-2">
              <SearchControl search={search} handleSearch={handleSearch} />
            </span>

            <Dropdown className="dropdown-inline ml-2" drop="down">
              <Dropdown.Toggle variant="outline-secondary" className='font-weight-bold svg-icon' style={{ height: theme.units.input.height['sm'] }} id='icon_type_button'>
                <Icon.Filter className="mr-3" />
                {iconType.label}
              </Dropdown.Toggle>

              <Dropdown.Menu className='dropdown-menu-right dropdown-menu-anim-down font-size-bold' id='dropdown_grouping'>
                <ul className="navi navi-hover">
                  {typeOption.map((type, index) => (
                    <div key={index}>
                      <li className="navi-item">
                        <Dropdown.Item className='navi-link font-weight-bold' onClick={() => setIconType(type)} id={`dropdown_item_${type.label}`}>
                          {type.label}
                        </Dropdown.Item>
                      </li>
                      {index === typeOption.length - 2 && <Dropdown.Divider />}
                    </div>
                  ))}
                </ul>
              </Dropdown.Menu>

            </Dropdown>

          </CardHeaderToolbar>

        </CardHeader>

        <CardBody>
          <ul className='item-grid-square list-unstyled mt-5 mb-0'>
            {filteredIcons(iconIndex).map((icon, index) =>
              <li type="button" className='text-center mx-auto mb-5' onClick={() => copyClipboard(icon.name)} key={index}>
                <div className={`btn icons-card mb-3 mx-auto rounded ${copiedIcon === icon.name ? 'bg-success text-light' : 'bg-white btn-hover-primary'}`}>
                  <span className='icon-3x'>{icon.icon}</span>
                </div>
                <div className='font-weight-bold'>{icon.name}</div>
              </li>
            )}
          </ul>
        </CardBody>

      </Card>
    </>
  )
}
