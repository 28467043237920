export const INITIAL_TEMP_USER = {
  id: '',
  name: "",
  surname: "",
  email: "",
  email_verification: {
    sending_date: "",
    code: "",
  },
  language: "",
  newsletter: false,
  created_at: '',
  contract: {},
  password: '',
}