import React, { useEffect } from 'react';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { CustomAlert, SVFormControl } from '_metronic/_partials/controls';
import { emailRegExp, maxValidation, passwordRedExp } from '_metronic/_helpers';
import { AuthRoutes, BaseRoutes } from 'constants/moduleRoutes';
import { useLoadingHandler, useQuery } from 'app/hooks';
import { AuthActions } from './_redux/actions';
import { Field, Form, Formik } from 'formik';
import { Spinner } from '_metronic/_partials';
import { Button, Col, Row } from 'react-bootstrap';
import { useLang } from '_metronic/i18n';
import { Link } from 'react-router-dom';
import AuthHeader from './AuthHeader';
import * as Yup from 'yup';



export default function Registration({ recaptchaRef }) {

  const dispatch = useDispatch();
  const lang = useLang();
  const query = useQuery();
  const { formatMessage: intl } = useIntl();

  const { request: { where, loading, status, error, message } } = useSelector(state => ({
    request: state.auth.request,
  }), shallowEqual);

  const isLoading = useLoadingHandler(
    loading,
    AuthActions.cleanUpReducer(),
    6000
  );

  const initialValues = {
    name: '',
    email: '',
    surname: '',
    password: '',
    language: lang,
    acceptTerms: true,
    newsletter: true,
  };


  const INPUTS = [
    {
      name: "name",
      label: intl({ id: 'AUTH.REGISTER.NAME' }),
      size: 6,
    },
    {
      name: "surname",
      label: intl({ id: 'AUTH.REGISTER.SURNAME' }),
      size: 6,
    },
    {
      name: "email",
      label: intl({ id: 'AUTH.INPUT.EMAIL' }),
    },
    {
      type: "password",
      name: "password",
      label: intl({ id: 'AUTH.INPUT.PASSWORD' }),
    },
    {
      type: "checkbox",
      name: "newsletter",
      label: intl({ id: 'AUTH.REGISTER.NEWSLETTER' }),
    },
    // {
    //   type: "checkbox",
    //   name: "acceptTerms",
    //   label: <FormattedHTMLMessage id="AUTH.REGISTER.ACCEPT_TERMS" values={{ privacyUrl: BaseRoutes.PRIVACY, termsUrl: BaseRoutes.TERMS, a: chunks => <a href='/'>{chunks}</a> }} />,
    // },
  ];


  const RegistrationSchema = Yup.object().shape({
    name: Yup.string()
      .trim(intl({ id: 'AUTH.GENERAL.BLANK_CHARACTER' }))
      .strict(true)
      .min(1, intl({ id: 'AUTH.REGISTER.NAME.LENGTH_MIN' }))
      .max(100, intl({ id: 'AUTH.REGISTER.NAME.LENGTH_MAX' }))
      .required(intl({ id: 'AUTH.VALIDATION.NAME_REQUIRED' })),
    surname: Yup.string()
      .trim(intl({ id: 'AUTH.GENERAL.BLANK_CHARACTER' }))
      .strict(true)
      .min(1, intl({ id: 'AUTH.REGISTER.NAME.LENGTH_MIN' }))
      .max(100, intl({ id: 'AUTH.REGISTER.NAME.LENGTH_MAX' }))
      .required(intl({ id: 'AUTH.VALIDATION.SURNAME_REQUIRED' })),
    email: Yup.string()
      .max(50, maxValidation("50"))
      .matches(emailRegExp, intl({ id: 'GENERAL.EMAIL_EMAIL' }))
      .email(intl({ id: 'AUTH.GENERAL.EMAIL_FORMAT' }))
      .required(intl({ id: 'AUTH.VALIDATION.EMAIL_REQUIRED' })),
    password: Yup.string()
      .trim(intl({ id: 'AUTH.GENERAL.BLANK_CHARACTER' }))
      .strict(true)
      .min(8, intl({ id: 'AUTH.REGISTER.PASSWORD.LENGTH_MIN' }))
      .max(50, intl({ id: 'AUTH.REGISTER.PASSWORD.LENGTH_MAX' }))
      .matches(passwordRedExp, intl({ id: 'AUTH.REGISTER.PASSWORD.MATCHES' }))
      .required(intl({ id: 'AUTH.VALIDATION.PASSWORD_REQUIRED' })),
    // acceptTerms: Yup.boolean()
    //   .oneOf([true], 'Sie müssen unserer AGB und Datenschutzerklärung zustimmen.')
  });


  const requestResult = () => {
    if (!isLoading && where === 'Register') {
      return status
    }
  };

  const handleSubmit = (values) => {
    query.get('recaptcha_token')
      ? dispatch(AuthActions.register({ ...values, recaptcha_token: query.get('recaptcha_token') }))
      : onSubmitWithReCAPTCHA(values)
  }

  const onSubmitWithReCAPTCHA = async (values) => {
    const recaptcha_token = await recaptchaRef.current.executeAsync();
    dispatch(AuthActions.register({ ...values, recaptcha_token }));
  }


  // --------------------- Email Verification --------------------- //
  const handleEmailVerification = (values) => {
    if (values.email !== '' && values.password !== '') {
      emailVerificationWithReCAPTCHA(values);
    }
  };

  const emailVerificationWithReCAPTCHA = async (values) => {
    const recaptcha_token = await recaptchaRef.current.executeAsync();
    dispatch(AuthActions.emailVerification({ ...values, recaptcha_token }));
  }
  // --------------------- Email Verification --------------------- //


  useEffect(() => {
    recaptchaRef.current.reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, error])


  useEffect(() => {
    return () => {
      dispatch(AuthActions.cleanUpReducer());
    };
  }, [dispatch]);


  return (
    <div className="login-form">

      <AuthHeader
        title={<FormattedMessage id="AUTH.REGISTER.TITLE" />}
        subtitle={<FormattedMessage id="AUTH.REGISTER.TITLE_TEXT" />}
      />

      <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={RegistrationSchema} onSubmit={handleSubmit}>
        {({ values, errors, touched }) => (
          <Form>
            <Row>
              {INPUTS.map((item, i) => (
                <Col key={i} md={item.size ?? 12}>
                  {item.type === 'checkbox'
                    ? <Field name={item.name} key={i}>
                      {({ field }) => (
                        <label className={`checkbox checkbox-primary font-weight-bold mb-3 mt-4 ${item.name === "acceptTerms" && touched.acceptTerms && errors.acceptTerms ? "text-danger" : ""}`}>
                          <input type="checkbox" id={`checkbox_${item.name}`} checked={field.value} {...field} disabled={isLoading || requestResult() === 'fulfilled'} />
                          <span />
                          <div className="ml-2">
                            {item.label}
                          </div>
                        </label>
                      )}
                    </Field>
                    : <Field component={SVFormControl} key={i}
                      {...{
                        type: item.type,
                        name: item.name,
                        label: item.label,
                        withFeedbackLabel: true,
                        feedbackLabel: true,
                        eyeIconClick: item.eyeIconClick,
                        disabled: isLoading || requestResult() === 'fulfilled',
                      }}
                    />}
                </Col>
              ))}
              <Col>

                <div className='font-weight-bold text-dark-50 text-center mt-10 mb-5'>
                  <FormattedHTMLMessage id="AUTH.REGISTER.ACCEPT_TERMS" values={{ privacyUrl: BaseRoutes.PRIVACY, termsUrl: BaseRoutes.TERMS, a: chunks => <a href='/'>{chunks}</a> }} />
                </div>

                <Button variant='primary' type="submit" className="font-weight-bold text-white w-100 py-4" disabled={isLoading || requestResult() === 'fulfilled'} id='btn_register'>
                  <FormattedMessage id="AUTH.REGISTER.FREE_TEST" />
                  <Spinner loading={isLoading} />
                </Button>


                <div className="d-flex justify-content-center font-weight-bold my-4 my-lg-6">
                  <FormattedMessage id="AUTH.REGISTER.LOGIN" />
                  <Link to={AuthRoutes.LOGIN} className='ml-2' id='btn_login'>
                    <FormattedMessage id="AUTH.GENERAL.LOGIN" />
                  </Link>
                </div>

              </Col>
              {requestResult() === 'fulfilled' &&
                <CustomAlert
                  type="success"
                  title={intl({ id: 'GENERAL.TEAM' })}
                  message={intl({ id: "AUTH.REGISTER.SUCCESS_MESSAGE" })}
                />
              }

              {requestResult() === 'failed' &&
                <CustomAlert
                  type="danger"
                  title={intl({ id: 'AUTH.GENERAL.ERRORS.TITLE' })}
                  message={intl({ id: `${error}` })}
                  onClose={() => dispatch(AuthActions.cleanUpReducer())}
                />
              }

              {where === 'emailVerification' && status === 'fulfilled' &&
                <CustomAlert
                  type="success"
                  title={intl({ id: 'GENERAL.TEAM' })}
                  message={intl({ id: `${message}` })}
                  onClose={() => dispatch(AuthActions.cleanUpReducer())}
                />
              }

              {(where === 'Register' && status === 'failed') &&
                <div className="d-flex justify-content-center font-weight-bold my-4 my-lg-6">
                  <FormattedMessage id='AUTH.LOGIN.NOT_RECEIVE_EMAIL' />
                  <Link to="#" className='ml-1' id='btn_email_verification' onClick={() => handleEmailVerification(values)}>
                    <FormattedMessage id='AUTH.LOGIN.RESEND_EMAIL' />
                  </Link>
                </div>
              }
            </Row>
          </Form>
        )}
      </Formik>

    </div>
  );
}