import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PositionsTable } from './positions-table/PositionsTable';
import { useProductsUIContext } from '../../../products/_context/ProductsUIContext';
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls';
import { useDocumentsUIContext } from '../../_context/DocumentsUIContext';
import { ProductSelectDialog } from './product-select-dialog/ProductSelectDialog';
import { ProductEdit } from 'app/modules/products/product-edit/ProductEdit';
import { useDocumentEditUIContext } from '../DocumentEditUIContext';
import { Button, Dropdown, ButtonGroup } from 'react-bootstrap';
import { UserInfoDropdown } from '_metronic/_partials';
import { DocumentActions } from '../../_redux/actions';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';



export function PositionsCard({ loading }) {

  const dispatch = useDispatch();

  //UIContext
  const UIContext = useDocumentsUIContext();
  const ProductUIContext = useProductsUIContext();
  const EditUIContext = useDocumentEditUIContext();
  const UIProps = useMemo(() => ({
    handleOpenProductSelectDialog: EditUIContext.handleOpenProductSelectDialog,
    setIds: ProductUIContext.setIds,
    showVariantSelectDialog: EditUIContext.showVariantSelectDialog,
    handleCloseVariantSelectDialog: UIContext.handleCloseVariantSelectDialog,
    showProductEditDialog: EditUIContext.showProductEditDialog,
    handleCloseProductEditDialog: EditUIContext.handleCloseProductEditDialog,
    selectedProduct: EditUIContext.selectedProduct,
  }), [
    EditUIContext.handleCloseProductEditDialog,
    EditUIContext.selectedProduct,
    EditUIContext.showProductEditDialog,
    EditUIContext.showVariantSelectDialog,
    UIContext.handleCloseVariantSelectDialog,
    ProductUIContext.setIds,
    EditUIContext.handleOpenProductSelectDialog
  ]);

  const isMarketplace = useSelector(state => !!state.documents.documentForEdit?.marketplace);

  useEffect(() => {
    return () => { UIProps.setIds([]); };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (<>
    <Card className="gutter-b">

      <CardSubHeader
        icon={<Icon.Layers />}
        title={<FormattedMessage id="GENERAL.POSITIONS" />}
        info={<UserInfoDropdown
          title={<FormattedMessage id='DOCUMENT.POSITION.INFO_TITLE' />}
          description={<FormattedMessage id={isMarketplace ? 'DOCUMENT.POSITION_ADD.SHOP_INFO_DESCRIPTION' : 'DOCUMENT.POSITION_ADD.LOCAL_INFO_DESCRIPTION'} />} />}
      >

        {!isMarketplace &&
          <Dropdown as={ButtonGroup} drop='down' size='sm'>

            <Button variant='outline-primary' className="svg-icon svg-icon-sm" onClick={UIProps.handleOpenProductSelectDialog} disabled={loading} id='btn_add_title'>
              <Icon.Plus />
              <span className="d-none d-sm-inline font-weight-bold ml-2">
                <FormattedMessage id="DOCUMENT.POSITION.PRODUCTS_TITLE" />
              </span>
            </Button>

            <Dropdown.Toggle split variant="outline-primary" id="dropdown-split-basic" />

            <Dropdown.Menu>
              <Dropdown.Item type='button' onClick={() => dispatch(DocumentActions.addNewPositionRow())} id='dropdown_new_position'>
                <FormattedMessage id="DOCUMENT.POSITION_NEW_POSITION" />
              </Dropdown.Item>
            </Dropdown.Menu>

          </Dropdown>}

      </CardSubHeader>

      <CardBody className="pt-0 px-0">
        <PositionsTable loading={loading} />
      </CardBody>

    </Card>

    {UIProps.showProductEditDialog && (
      <ProductEdit modal show={UIProps.showProductEditDialog} onHide={UIProps.handleCloseProductEditDialog} />
    )}

    <ProductSelectDialog />

  </>);
}
