import { call, put, takeEvery, select, all } from 'redux-saga/effects';
import { CompanyActions } from 'app/modules/settings/company/_redux/action';
import { SettingsActions } from 'app/modules/settings/_redux/actions';
import { setLanguage } from '_metronic/i18n';
import { userMiddleware } from 'middlewares';
import { AuthActions } from './actions';
import ActionTypes from './actionTypes';
// import packagejson from "../../../../../package.json";



// Login
function* login({ payload: { user } }) {
  try {
    const response = yield call(userMiddleware.login, user);

    yield put(AuthActions.loginSuccess(response.data));
    yield put(CompanyActions.getCompanyBaseData());
    yield put(SettingsActions.getUserSettingsByType("products"));

  } catch (error) {
    return yield put(
      AuthActions.loginFail(JSON.stringify(error?.response?.data || { message: 'Login has failed.' }))
    );
  }
}


// Logout
function* logout() {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(userMiddleware.logout, accessToken);
    yield put(AuthActions.logoutSuccess());

    // Clear local storage
    setTimeout(() => {
      // localStorage.removeItem(`persist:salevali.v${packagejson.version}`);
      // localStorage.removeItem(`lastLocation`);
      // localStorage.removeItem(`lastLocation_expiresIn`);
      localStorage.clear();
    }, 100);

  } catch (err) {
    yield put(AuthActions.logoutFail());
  }
}


// Register
function* register({ payload: { user } }) {
  try {

    yield call(userMiddleware.createUser, user);
    yield put(AuthActions.registerSuccess());

  } catch (err) {
    if (err.response && err.response.status === 422) {
      return yield put(AuthActions.registerFail(JSON.stringify(err.response), 'validation'));
    }
    yield put(AuthActions.registerFail(err?.response?.data?.error, 'server'));
  }
}


// Email Verification
function* emailVerification({ payload: { user } }) {
  try {

    const { data } = yield call(userMiddleware.emailVerification, user);
    yield put(AuthActions.emailVerificationSuccess(data.message));

  } catch (err) {
    yield put(AuthActions.emailVerificationFail(err.response.data.error.message));
  }
}


// Reset password
function* resetPassword({ payload: { email } }) {
  try {

    const response = yield call(userMiddleware.resetPassword, email);
    yield put(AuthActions.resetPasswordSuccess(response.data));

  } catch (err) {
    yield put(AuthActions.resetPasswordFail(err.response.data.error.message));
  }
}


// Create new password
function* reCreatePassword({ payload }) {
  try {

    yield all([
      call(userMiddleware.createNewPassword, payload),
    ]);
    yield put(AuthActions.reCreatePasswordSuccess());

  } catch (err) {
    yield put(AuthActions.reCreatePasswordFail(err.response.data.error));
  }
}


// Get user data
function* getUser() {
  try {
    const accessToken = yield select((state) => state.auth.access_token);

    const fetchUserData = yield call(userMiddleware.fetchUserData, accessToken);
    yield put(AuthActions.getUserSuccess(fetchUserData.data));

  } catch (err) {
    yield put(AuthActions.getUserFail(JSON.stringify(err?.response)));
  }
}


// Get user update data (License)
function* updateUsersSaga({ payload: { userUpdate } }) {
  try {
    const accessToken = yield select((state) => state.auth.access_token);

    const response = yield call(userMiddleware.patchUserData, accessToken, userUpdate);

    yield put(AuthActions.getUser());
    yield put(AuthActions.updateUsersSuccess());

    if (userUpdate?.language && response?.status === 204) {
      setLanguage(userUpdate);
    }

  } catch (err) {
    yield put(AuthActions.updateUsersFail(err?.response?.data || err?.message || 'Update users has failed.'));
  }
}


// Get complete user 
function* loginSettingsSaga({ payload: { updateUser } }) {
  try {
    const accessToken = yield select((state) => state.auth.access_token);

    yield call(userMiddleware.patchUserData, accessToken, updateUser);

    yield put(AuthActions.loginSettingsSuccess(null));

  } catch (err) {
    yield put(AuthActions.loginSettingsFail(typeof err === 'string' ? err : JSON.stringify(err?.response || err?.message)));
  }
}


// Change Password
function* changePassword({ payload: { changePassword } }) {
  try {
    const accessToken = yield select((state) => state.auth.access_token);

    let { data } = yield call(userMiddleware.changePassword, accessToken, changePassword);
    yield put(AuthActions.changePasswordSuccess(data?.message));

  } catch (err) {
    if (err.response && err.response.status === 401) {
      return yield put(AuthActions.logout());
    }
    yield put(AuthActions.changePasswordFail(err?.response?.data || err?.message || 'Update users has failed.'));
  }
}


// Change Email 
function* changeEmail({ payload: { changeEmail } }) {
  try {
    const accessToken = yield select((state) => state.auth.access_token);

    const { data: { new_email, message } } = yield call(userMiddleware.changeEmail, accessToken, changeEmail);

    if (new_email) {
      const changeEmailUserDB = { new_email, userDb: true };
      yield call(userMiddleware.changeEmail, accessToken, changeEmailUserDB);
    }

    yield put(AuthActions.changeEmailSuccess(changeEmail?.new_email ?? "", message ?? "update email success"));
  } catch (err) {
    if (err.response && err.response.status === 401) {
      return yield put(AuthActions.logout());
    }
    yield put(AuthActions.changeEmailFail(err?.response?.data || err?.message || 'Update users has failed.'));
  }
}


// Contract
function* contract({ payload: { type } }) {
  try {
    const response = yield call(userMiddleware.contract, type);

    yield put(AuthActions.contractSuccess(response.data));

  } catch (error) {
    return yield put(
      AuthActions.contractFail(JSON.stringify(error?.response?.data || { message: 'Contract has failed.' }))
    );
  }
}


// Deleted Request
function* deletedRequest({ payload: { data } }) {
  try {
    const accessToken = yield select((state) => state.auth.access_token);

    const response = yield call(userMiddleware.deletedRequest, accessToken, data);
    yield put(AuthActions.deletedRequestSuccess(response?.data));

  } catch (err) {
    if (err.response && err.response.status === 401) {
      return yield put(AuthActions.logout());
    }
    yield put(AuthActions.deletedRequestFail(err?.response?.data || err?.message || 'Deleted request has failed.'));
  }
}


// getCounts
function* getCounts() {
  try {
    const accessToken = yield select((state) => state.auth.access_token);
    const { data } = yield call(userMiddleware.getCounts, accessToken);
    yield put(AuthActions.getCountsSuccess(data));

  } catch (err) {
    yield put(AuthActions.getCountsFail(err?.response?.data || err?.message || 'get counts has failed.'));
  }
}


export function* authSaga() {
  yield takeEvery(ActionTypes.LOGIN, login);
  yield takeEvery(ActionTypes.LOGOUT, logout);
  yield takeEvery(ActionTypes.RESET_PASSWORD, resetPassword);
  yield takeEvery(ActionTypes.RE_CREATE_PASSWORD, reCreatePassword);
  yield takeEvery(ActionTypes.REGISTER, register);
  yield takeEvery(ActionTypes.EMAIL_VERIFICATION, emailVerification);
  yield takeEvery(ActionTypes.GET_USER_DATA, getUser);
  yield takeEvery(ActionTypes.UPDATE_USERS, updateUsersSaga);
  yield takeEvery(ActionTypes.LOGIN_SETTINGS, loginSettingsSaga);
  yield takeEvery(ActionTypes.CHANGE_PASSWORD, changePassword);
  yield takeEvery(ActionTypes.CHANGE_EMAIL, changeEmail);
  yield takeEvery(ActionTypes.CONTRACT, contract);
  yield takeEvery(ActionTypes.DELETED_REQUEST, deletedRequest);
  yield takeEvery(ActionTypes.GET_COUNTS, getCounts);
}