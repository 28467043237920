import React, { forwardRef } from 'react';
import { SVFeedbackUnlabeled, SVFormControlLabel, SVFormControlWrapper } from './SVFormHelpers';
import UserInfoDropdown from '_metronic/_partials/user-info/UserInfoDropdown';
import { theme } from '_metronic/_helpers';
import styled from 'styled-components';



export const SVTextarea = forwardRef((props, ref) => (
  <StyledTextarea {...props} ref={ref} />
));

export const SVFormTextarea = ({
  field,
  form: { touched, errors },
  label,
  id,
  placeholder,
  maxlength,
  disabled,
  withFeedbackLabel,
  feedbackLabel = '',
  rows,
  cols,
  size,
  resize,
  className,
  info,
  required,
  ...props
}) => {

  const fieldCheck = (type, field) => {
    if (field.includes(".")) {
      return type[field.split(".")[0]]?.[field.split(".")[1]];
    } else {
      return type[field];
    }
  };

  return (
    <SVFormControlWrapper className={className}>

      {label &&
        <SVFormControlLabel size={size} errors={fieldCheck(touched, field.name) && (fieldCheck(errors, field.name) || typeof feedbackLabel === "string") && true}>
          {label} {required && <span className="text-danger">*</span>}
          {info && <UserInfoDropdown size="w-75" title={label} description={info} />}
        </SVFormControlLabel>
      }

      <StyledTextarea
        id={id}
        placeholder={placeholder}
        errors={fieldCheck(touched, field.name) && (fieldCheck(errors, field.name) || typeof feedbackLabel === "string") && true}
        maxlength={maxlength}
        disabled={disabled}
        rows={rows}
        cols={cols}
        resize={resize}
        size={size}
        {...props}
        {...field}
      />

      {feedbackLabel &&
        <SVFeedbackUnlabeled size={size} withFeedbackLabel={true} errors={fieldCheck(touched, field.name) && fieldCheck(errors, field.name) && true} label={label}>
          {fieldCheck(touched, field.name) && fieldCheck(errors, field.name) ? fieldCheck(errors, field.name) : <span className='text-danger'>{feedbackLabel}</span>}
        </SVFeedbackUnlabeled>
      }

    </SVFormControlWrapper>
  )
};


const StyledTextarea = styled.textarea`
  border: ${p => p.errors
    ? `1px solid ${theme.palette.danger['color']}`
    : `1px solid ${theme.palette.gray['300']}`};
  border-radius: ${theme.shape.borderRadius};
  padding: ${theme.units.textarea.padding};
  height: ${p => theme.units.textarea.height[p.size || 'default']};
  font-size: ${p => theme.typography.fontSize['md']};
  line-height: ${p => theme.typography.lineHeight['md']};
  background-color: ${theme.palette.base.white};
  color: ${theme.palette.base.black};
  width: 100%;
  box-shadow: ${theme.shadows.input};
  ${p => p.resize && `resize: ${p.resize}`};
  &:hover {
    cursor: context-menu;
    outline: 1px solid ${theme.palette.primary['100']};
  }
  &:focus {
    outline: 1px solid ${theme.palette.primary['300']};
    box-shadow: ${theme.shadows.inputHover};
  }
  &::placeholder {
    opacity: 0.7;
  }
  &:disabled {
    background: ${theme.palette.gray['50']};
    color: ${theme.palette.gray['500']};
    border-color: ${theme.palette.gray['300']};
  }
`;
