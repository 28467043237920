import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardBody, Card, CardHeader } from '_metronic/_partials/controls';
import { UserDetailsDialog } from '../user-dialogs/UserDetailsDialog';
import { AdminActions } from '../_redux/actions';
import { UserFilter } from './user-filter/UserFilter';
import { useUserUIContext } from './_context/UserUIContext';
import { LoadingDialog } from "_metronic/_partials/loading";
import { BlankMessage } from '_metronic/_partials/components';
import { UserTable } from './user-table/UserTable';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';



export function UserCard() {

  const dispatch = useDispatch();
  const [filterParamsBase, setFilterParamsBase] = useState({});

  const UIContext = useUserUIContext();
  const UIProps = useMemo(() => ({
    queryParams: UIContext.queryParams,
    setOrderQuery: UIContext.setOrderQuery,
    setPaginationQuery: UIContext.setPaginationQuery,
    setSearchQuery: UIContext.setSearchQuery,
    setFilterQuery: UIContext.setFilterQuery,
    ids: UIContext.ids,
    setIds: UIContext.setIds,
    openUserNoteDialog: UIContext.openUserNoteDialog,
    openUserDetailsDialog: UIContext.openUserDetailsDialog,
    show: UIContext.showUserDetailsDialog,
    onHide: UIContext.closeUserDetailsDialog,
    userId: UIContext.userId,
    dateRange: UIContext.dateRange,
    changeDateRange: UIContext.changeDateRange,
  }),
    [UIContext]
  );

  const { count, users, loading, totalCount } = useSelector(state => state.admin);

  useEffect(() => {

    let delayDebounceFn;
    if (UIProps.queryParams?.search) {
      delayDebounceFn = setTimeout(() => {
        dispatch(AdminActions.getUsers(UIProps.dateRange, UIProps.queryParams))
      }, 350);
    } else {
      dispatch(AdminActions.getUsers(UIProps.dateRange, UIProps.queryParams));
    };

    return () => {
      clearTimeout(delayDebounceFn)
    };
  }, [dispatch, UIProps.queryParams, UIProps.dateRange]);

  // const btnRef = useRef();
  // const handleSubmit = () => {
  //   if (btnRef && btnRef.current) {
  //     btnRef.current.click();
  //   }
  // }

  return (
    <Card className='card-height'>

      <CardHeader
        icon={<Icon.Customer />}
        title={<FormattedMessage id="ADMIN.USERS.TITLE" />}
        className="display-5"
        id='title_user'
      >
        {/* <CardHeaderToolbar>
          {users?.length !== 0 &&
            <Button variant='primary' className="font-weight-bold ml-auto" onClick={handleSubmit}>
              <FormattedMessage id='REPORT.EXPORT_TO_CSV' />
            </Button>}
        </CardHeaderToolbar> */}
      </CardHeader>

      <CardBody>
        {totalCount === 0 && UIProps.queryParams.search === ''
          ? <BlankMessage
            icon={<Icon.Customer />}
            title={<FormattedMessage id='ADMIN.USER.BLANK_MESSAGE_TITLE' />}
            message={<FormattedMessage id='ADMIN.USER.BLANK_MESSAGE' />}
            className='min-50-height'
            loading={loading}
          />
          : <>
            <UserFilter
              UIProps={UIProps}
              filterParamsBase={filterParamsBase}
            />
            <UserTable
              loading={loading}
              users={users}
              count={count}
              UIProps={UIProps}
              setFilterParamsBase={setFilterParamsBase}
            // btnRef={btnRef}
            />
          </>
        }
      </CardBody>

      <LoadingDialog isLoading={loading} />
      <UserDetailsDialog UIProps={UIProps} />

    </Card>
  );
}
