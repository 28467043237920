import React, { useEffect } from 'react'
import { Field, useFormikContext } from 'formik'
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl'
import { SVFormControl, SVFormTextarea } from '_metronic/_partials/controls';
import { AuthActions } from 'app/pages/auth/_redux/actions';
import { Alert, Col, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { Icon } from '_metronic/_icons';



export default function AccountDeleteForm({ isConfirmed, isSurveyed, success, loading, message, survey, setSurveyButton, counts, userName }) {

  const { formatMessage: intl } = useIntl();
  const dispatch = useDispatch();

  const { values: { deleted_request } } = useFormikContext();

  useEffect(() => {
    const isOtherSelected = deleted_request?.surveys.other === true;
    const areOtherValuesSelected = Object.values(deleted_request?.surveys)
      .filter((key) => key !== 'other')
      .some((value) => value === true);

    if (
      (isOtherSelected && deleted_request?.description !== '') ||
      (!isOtherSelected && areOtherValuesSelected && deleted_request?.description === '')
    ) {
      setSurveyButton(true);
    } else {
      setSurveyButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleted_request]);

  useEffect(() => {
    if (isSurveyed) {
      dispatch(AuthActions.getCounts())
    }
  }, [dispatch, isSurveyed]);


  return (
    <Row>

      <Col md={12}>
        {isConfirmed
          ? isSurveyed
            ? <>

              <Alert variant='light-warning' className="alert-custom alert-notice text-dark-75 flex-column line-height-xl py-4 mb-8">

                <p><FormattedMessage id='SETTINGS.LOGIN.DELETE_ACCOUNT.MESSAGE_ONE' values={{ name: userName }} /></p>
                <p><FormattedMessage id='SETTINGS.LOGIN.DELETE_ACCOUNT.MESSAGE_TWO' /></p>
                <div className="font-weight-bold">
                  {intl({ id: 'SETTINGS.LOGIN.DELETE_ACCOUNT.MESSAGE_THREE' }, { order: counts?.order_count, offer: counts?.offer_count, invoice: counts?.invoice_count, credit: counts?.credit_count, archive: counts?.archived_count, customer: counts?.customer_count, supplier: counts?.supplier_count, product: counts?.product_count, connection: counts?.connection_count }).split("#").map((item, i) => ["href=", "<b>", "class="].some(el => item.includes(el))
                    ? <p key={i}>
                      <FormattedHTMLMessage id={item} values={{ what: 'react-intl', a: chunks => <a href='/'>{chunks}</a>, b: chunks => <strong>{chunks}</strong>, span: chunks => <span>{chunks}</span> }} />
                    </p>
                    : <p key={i}>{item}</p>
                  )}
                </div>
                <p><FormattedMessage id='SETTINGS.LOGIN.DELETE_ACCOUNT.MESSAGE_FOUR' /></p>

              </Alert>

              <Field component={SVFormControl}
                {...{
                  type: 'password',
                  name: 'password',
                  label: intl({ id: 'SETTINGS.LOGIN.DELETE_ACCOUNT.DELETE.DESCRIPTION' }),
                  withFeedbackLabel: true,
                  feedbackLabel: true,
                  disabled: success || loading,
                }}
              />

              {success &&
                <Alert variant='success' className="svg-icon mb-0">
                  <Icon.CheckCircle className="mr-2" />
                  <FormattedMessage id={message ? message : 'SERVER.LOGIN.DELETE_ACCOUNT.DELETE.SUCCESS'} />
                </Alert>
              }

            </>
            : <>
              <div>
                <h4 className='font-weight-bold text-muted text-center'>
                  <FormattedMessage id='SETTINGS.LOGIN.DELETE_ACCOUNT.SURVEY.TITLE' />
                </h4>
                <p>
                  <FormattedMessage id='SETTINGS.LOGIN.DELETE_ACCOUNT.SURVEY.DESCRIPTION' />
                </p>
              </div>

              <div>

                {survey.map((item, i) => (
                  <Field name={item.name} key={i}>
                    {({ field }) => (
                      <label className='checkbox checkbox-primary font-weight-bold my-3'>
                        <input type="checkbox" id={`checkbox_${item.name}`} checked={field.value} {...field} />
                        <span />
                        <div className="ml-2">
                          {item.label}
                        </div>
                      </label>
                    )}
                  </Field>
                ))}

                {deleted_request.surveys.other &&
                  <Field component={SVFormTextarea}
                    {...{
                      name: 'deleted_request.description',
                      label: intl({ id: 'SETTINGS.LOGIN.DELETE_ACCOUNT.SURVEY.COMMENT' }),
                      rows: 6,
                      maxlength: 10,
                      withFeedbackLabel: true,
                      feedbackLabel: true,
                      disabled: loading,
                    }}
                  />}

              </div>
            </>
          : <>
            <Alert variant='light-danger' className="alert-custom alert-notice line-height-xl mb-8">
              <FormattedMessage id='SETTINGS.LOGIN.DELETE_ACCOUNT.DESCRIPTION' />
            </Alert>

            <Field component={SVFormControl}
              {...{
                name: 'confirm',
                label: <FormattedHTMLMessage id='SETTINGS.LOGIN.DELETE_ACCOUNT.CONFIRM.DESCRIPTION' values={{ span: chunks => <span>{chunks}</span> }} />,
                withFeedbackLabel: true,
                feedbackLabel: true,
                disabled: success || loading,
              }}
            />
          </>
        }
      </Col>

    </Row>
  )
}