import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { CloseButton } from '_metronic/_partials';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { CustomerActions } from '../_redux/actions';
import { Icon } from '_metronic/_icons';



const DOCUMENTS_COUNT = [
  {
    to: ModuleRoutes.DOCUMENT_INVOICE,
    name: 'DOCUMENT.INVOICE_TITLE',
    type: 'invoice'
  },
  {
    to: ModuleRoutes.DOCUMENT_ORDER,
    name: 'DOCUMENT.ORDER_TITLE',
    type: 'order'
  },
  {
    to: ModuleRoutes.DOCUMENT_OFFER,
    name: 'DOCUMENT.OFFER_TITLE',
    type: 'offer'
  },
  {
    to: ModuleRoutes.DOCUMENT_CREDIT,
    name: 'DOCUMENT.CREDIT_TITLE',
    type: 'credit'
  },
  {
    to: ModuleRoutes.DOCUMENT_WAYBILL,
    name: 'DOCUMENT.WAYBILL_TITLE',
    type: 'waybill'
  },
];


export function SetDocumentDialog({ show, onHide, id }) {

  const dispatch = useDispatch();

  const { countDocuments } = useSelector(state => ({
    countDocuments: state.customers.countDocuments,
  }));

  useEffect(() => {
    if (id) {
      dispatch(CustomerActions.getCustomerDocumentCounts(id))
    } else {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);


  return (
    <Modal show={show} onHide={onHide} backdrop="static" aria-labelledby="example-modal-sizes-title-sm" size="sm" centered>

      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id="CUSTOMER.FIND_DOCUMENTS" />
        </Modal.Title>
        <CloseButton onClick={() => { onHide(); CustomerActions.cleanStatesInStore({ countDocuments: {} }) }} />
      </Modal.Header>

      <Modal.Body className='p-3'>
        <ul className="navi navi-hover">
          {DOCUMENTS_COUNT.map((item, i) => (
            <li key={i} className="navi-item font-weight-bold">
              {countDocuments[item.type] > 0
                ? <Link className="navi-link"
                  to={{
                    pathname: item.to,
                    search: `page=1&search=${id}${item.type === "order" ? '&filter.status=all-orders' : ''}`,
                    hash: '',
                  }}
                  id={`btn_${item.name}`}
                >
                  <span className="navi-icon">
                    <Icon.Search />
                  </span>
                  <span className="navi-text">
                    <FormattedMessage id={item.name} />
                  </span>
                  <span className="menu-text">
                    <span className="label label-lg label-pill label-inline label-light-primary ml-auto font-weight-bolder">
                      {countDocuments[item.type] > 9999 ? "9999+" : countDocuments[item.type]}
                    </span>
                  </span>
                </Link>
                : <div className="navi-link">
                  <span className="navi-icon">
                    <Icon.Search />
                  </span>
                  <span className="navi-text">
                    <FormattedMessage id={item.name} />
                  </span>
                </div>}
            </li>
          ))}
        </ul>
      </Modal.Body>

    </Modal>
  );
};
