import React from 'react';
import { ArchiveCard } from './ArchiveCard';
import { ArchivePrint } from './archive-dialogs/ArchivePrint';
import { SearchQueryProvider } from './_context/SearchQueryProvider';
import { ArchiveDeleteDialog } from './archive-dialogs/ArchiveDeleteDialog';



export function ArchivePage() {
  return (
    <SearchQueryProvider>
      <ArchiveDeleteDialog />
      <ArchivePrint/>
      <ArchiveCard />
    </SearchQueryProvider>
  );
}