import React from 'react';
import { FormattedMessage } from 'react-intl';
import { LABEL_OPTIONS } from '../../_context/DocumentsUIHelpers';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '_metronic/_icons';
import { isArray } from 'lodash';
import clsx from 'clsx';



export function ShippingDataColumnFormatter(cell, row, rowIndex, { orderStatusOpen, dispatch, openLabelsTable, openLabelSettingDialog }) {

  if (cell && isArray(cell) && cell.length > 0) {
    return (
      <div className="symbol-group symbol-hover justify-content-center">
        {cell.map((item, index) => {

          const service = LABEL_OPTIONS.find(service => service.value === item?.service)

          return (
            <OverlayTrigger key={index} overlay={
              <Tooltip id="shipping-tooltip">
                <div className='font-weight-bolder border-bottom'>
                  {service.label}
                </div>
                <div className={clsx('font-weight-bold mt-2', row?.error && 'text-danger')}>
                  {item?.error ? item?.error : item?.tracking_id}
                </div>
              </Tooltip>
            }>

              <div className="symbol d-flex bg-white" onClick={() => openLabelsTable(row?.id)} style={{ fontSize: "2.5rem" }}>
                {item?.unlabeled ? <Icon.SupplierFIll /> : item?.error ? <Icon.ErrorFill /> : service.icon}
              </div>

            </OverlayTrigger>
          )
        })}

        {/* <div className="symbol d-flex align-items-center justify-content-center bg-light-primary" style={{ width: "2.9rem", height: "2.9rem" }}>
          <span className="font-weight-bold text-primary">5+</span>
        </div> */}

      </div>

    )
  } else {
    return (
      <span type="button" className="font-weight-bold text-muted text-hover-primary" onClick={() => openLabelSettingDialog(row?.id)} style={(orderStatusOpen && row?.marketplace !== "amazon_prime") ? null : { pointerEvents: "none", opacity: 0.5 }}>
        <FormattedMessage id="GENERAL.CREATE" />
      </span>
    );
  }
}