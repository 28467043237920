import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, useFormikContext } from 'formik';
import { Card, CardBody, CardSubHeader, CkEditor, RadioControl, SVFormControl } from "_metronic/_partials/controls";
import { Icon } from '_metronic/_icons';
import { UserInfoDropdown } from '_metronic/_partials';



const mailTempMaxLength = 2000;

const triggerOptions = [
  {
    value: "is_order",
    label: <FormattedMessage id="SETTINGS.PRODUCT.KEYS" />
  },
  /*{
     value: "is_invoice",
     label: "Invoice"
   },
   {
     value: "is_paid",
     label: "Payment Paid"
   } */
]


export function ProductKeysSettings() {

  const { formatMessage: intl } = useIntl();
  const { values: { digital_product }, setFieldValue } = useFormikContext();
  const [mailTempCharacters, setMailTempCharacters] = useState(digital_product?.mail_template?.length);

  const INPUTS = [
    {
      name: "digital_product.contact_email",
      label: "SETTINGS.PRODUCT.CONTACT_EMAIL",
      type: "email",
      feedbackLabel: true,
      info: "PRODUCT.KEYS.CONTACT_EMAIL_INFO",
    },
    {
      name: "digital_product.trigger",
      label: "PRODUCT.KEYS_INFO",
      component: RadioControl,
      options: triggerOptions,
    }
  ];


  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setFieldValue("digital_product.mail_template", data)
    const strippedData = data.replace(/<[^>]*>/g, '');
    setMailTempCharacters(strippedData.length);
  };


  return (
    <Card className="gutter-b">

      <CardSubHeader
        title={<FormattedMessage id="PRODUCT.KEYS_TITLE" />}
        icon={<Icon.Password />}
        info={<UserInfoDropdown
          title={<FormattedMessage id='PRODUCT.KEYS_TITLE' />}
          description={<FormattedMessage id='USER_GUIDE.SETTINGS.PRODUCT_KEY' />} />}
        id="digital_product_settings"
      />

      <CardBody>
        <Row>

          <Col lg={8}>
            <div>
              <CkEditor
                name="mail_template"
                data={digital_product?.mail_template}
                onChange={handleEditorChange}
                style={{ height: "400px" }}
              />

              {mailTempCharacters
                ? <div className="text-right mt-2">
                  <span className={`${mailTempCharacters <= (mailTempMaxLength * 80 / 100) ? 'text-success' : mailTempCharacters <= mailTempMaxLength ? 'text-warning' : 'text-danger'}`}>
                    <FormattedMessage id="SETTINGS.DOCUMENT.REMAINING_CHARACTER" values={{ character: mailTempMaxLength - mailTempCharacters }} />
                  </span>
                </div>
                : null}
            </div>
          </Col>

          <Col lg={4}>
            {INPUTS.map((item, i) => (
              <Field component={item.component || SVFormControl} key={i}
                {...{
                  name: item.name,
                  label: intl({ id: item.label }),
                  options: item.options,
                  withFeedbackLabel: true,
                  feedbackLabel: item.feedbackLabel || true,
                  required: item.required,
                  info: item.info ? intl({ id: item.info }) : '',
                  type: item.type,
                }}
              />
            ))}
          </Col>

        </Row>
      </CardBody>

    </Card>
  );
}