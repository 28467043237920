import React from 'react';
import ActionTypes from './actionTypes';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';



export class CompanyActions {


  // Create company
  static createCompany = (createCompany) => {
    return {
      type: ActionTypes.CREATE_COMPANY,
      payload: {
        createCompany
      },
    };
  };

  static createCompanySuccess = (updatedUser) => {
    toast.success(<FormattedMessage id="COMPANY.REDUX.CREATE_COMPANY_BASE_DATA.SUCCESS" />);
    return {
      type: ActionTypes.CREATE_COMPANY_SUCCESS,
      payload: {
        updatedUser,
      },
    };
  };

  static createCompanyFail = (error) => {
    return {
      type: ActionTypes.CREATE_COMPANY_FAIL,
      payload: { error },
    };
  };

  // Complete User Register
  static completeUserRegister = (userUpdate) => {
    return {
      type: ActionTypes.COMPLETE_USER_REGISTER,
      payload: {
        userUpdate,
      },
    };
  };

  static completeUserRegisterSuccess = () => {
    return {
      type: ActionTypes.COMPLETE_USER_REGISTER_SUCCESS,
    };
  }

  static completeUserRegisterFail = (error) => {
    return {
      type: ActionTypes.COMPLETE_USER_REGISTER_FAIL,
      payload: {
        error,
      },
    };
  }

  // Update Company Data
  static updateCompanyBaseData = (company, upload) => {
    return {
      type: ActionTypes.UPDATE_COMPANY_BASE_DATA,
      payload: {
        company,
        upload,
      },
    };
  };

  static updateCompanyBaseDataSuccess = () => {
    toast.success(<FormattedMessage id="COMPANY.REDUX.UPDATE_COMPANY_BASE_DATA.SUCCESS" />);
    return {
      type: ActionTypes.UPDATE_COMPANY_BASE_DATA_SUCCESS,
    };
  };

  static updateCompanyBaseDataFail = error => {
    toast.error(<FormattedMessage id="COMPANY.REDUX.UPDATE_COMPANY_BASE_DATA.FAIL" />);
    return {
      type: ActionTypes.UPDATE_COMPANY_BASE_DATA_FAIL,
      payload: {
        error,
      },
    };
  };



  // Get Company Data
  static getCompanyBaseData = () => {
    return {
      type: ActionTypes.GET_COMPANY_BASE_DATA,
    };
  };

  static getCompanyBaseDataSuccess = company => {
    return {
      type: ActionTypes.GET_COMPANY_BASE_DATA_SUCCESS,
      payload: {
        company,
      },
    };
  };

  static getCompanyBaseDataFail = error => {
    return {
      type: ActionTypes.GET_COMPANY_BASE_DATA_FAIL,
      payload: {
        error,
      },
    };
  };



  // Delete Company Data
  static deleteCompanyBaseData = id => {
    return {
      type: ActionTypes.DELETE_COMPANY_BASE_DATA,
      payload: {
        id,
      },
    };
  };

  static deleteCompanyBaseDataSuccess = () => {
    return {
      type: ActionTypes.DELETE_COMPANY_BASE_DATA_SUCCESS,
    };
  };

  static deleteCompanyBaseDataFail = error => {
    return {
      type: ActionTypes.DELETE_COMPANY_BASE_DATA_FAIL,
      payload: {
        error,
      },
    };
  };


  // Logo handler
  static logoOnChangeHandler = logo => {
    return {
      type: ActionTypes.LOGO_ONCHANGE_HANDLER,
      payload: {
        logo,
      },
    };
  };



  // Clean handler
  static cleanErrorHandler = () => {
    return {
      type: ActionTypes.CLEAN_ERROR_HANDLER,
    };
  };



  // Clear Loading Handler
  static clearLoadingEffects = () => {
    return {
      type: ActionTypes.CLEAR_LOADING_EFFECTS,
    };
  };



  // Image Delete
  static deleteFile = (companyId, fileType, fileId) => {
    return {
      type: ActionTypes.DELETE_FILE,
      payload: {
        companyId, 
        fileType, 
        fileId
      },
    };
  };

  static deleteFileSuccess = () => {
    return {
      type: ActionTypes.DELETE_FILE_SUCCESS,
    };
  };

  static deleteFileFail = () => {
    return {
      type: ActionTypes.DELETE_FILE_FAIL,
    };
  };
}
