import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { CustomAlert, SVFormControl } from '_metronic/_partials/controls';
import { emailRegExp, maxValidation } from '_metronic/_helpers';
import { useQuery, useLoadingHandler } from 'app/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { AuthRoutes } from 'constants/moduleRoutes';
import { AuthActions } from './_redux/actions';
import { Spinner } from '_metronic/_partials';
import { Field, Form, Formik } from 'formik';
import { Button } from 'react-bootstrap';
import AuthHeader from './AuthHeader';
import * as Yup from 'yup';



const initialValues = {
  email: '',
  password: '',
  remember: false,
};

const INPUTS = [
  {
    name: "email",
    label: 'AUTH.INPUT.EMAIL',
  },
  {
    type: "password",
    name: "password",
    label: 'AUTH.INPUT.PASSWORD'
  },
  {
    type: "checkbox",
    name: "remember",
    label: 'AUTH.LOGIN.REMEMBER_ME'
  },
];


export default function Login({ recaptchaRef }) {

  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const { formatMessage: intl } = useIntl();

  const { request: { where, loading, status, error, message } } = useSelector(state => ({
    request: state.auth.request,
  }), shallowEqual);


  const isLoading = useLoadingHandler(
    loading,
    AuthActions.clearLoadingEffects(),
    160000
  );

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .max(50, maxValidation("50"))
      .matches(emailRegExp, intl({ id: 'GENERAL.EMAIL_EMAIL' }))
      .email(intl({ id: 'AUTH.GENERAL.EMAIL_FORMAT' }))
      .required(intl({ id: 'AUTH.VALIDATION.EMAIL_REQUIRED' })),
    password: Yup.string()
      .min(6, intl({ id: 'AUTH.REGISTER.PASSWORD.LENGTH_MIN' }))
      .max(50, intl({ id: 'AUTH.REGISTER.PASSWORD.LENGTH_MAX' }))
      .required(intl({ id: 'AUTH.VALIDATION.PASSWORD_REQUIRED' })),
  });


  useEffect(() => {
    const email_verification = query.get('email-verification');
    if (email_verification) {
      dispatch(AuthActions.emailVerification({ emailVerification: email_verification }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const requestResult = () => {
    if (!loading && where === 'emailVerification') {
      return status
    }
  };

  const handleSubmit = (values) => {
    query.get('recaptcha_token')
      ? dispatch(AuthActions.login({ ...values, recaptcha_token: query.get('recaptcha_token') }))
      : onSubmitWithReCAPTCHA(values)
  }

  const onSubmitWithReCAPTCHA = async (values) => {
    const recaptcha_token = await recaptchaRef.current.executeAsync();
    dispatch(AuthActions.login({ ...values, recaptcha_token }));
  }


  useEffect(() => {
    recaptchaRef.current.reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, error])


  useEffect(() => {
    return () => {
      dispatch(AuthActions.clearLoadingEffects());
    };
  }, [dispatch]);


  const handleEmailVerification = (values) => {
    if (values.email !== '' && values.password !== '') {
      emailVerificationWithReCAPTCHA(values);
    }
  };

  const emailVerificationWithReCAPTCHA = async (values) => {
    const recaptcha_token = await recaptchaRef.current.executeAsync();
    dispatch(AuthActions.emailVerification({ ...values, recaptcha_token }));
  }

  useEffect(() => {
    let countDown;
    if (requestResult() === 'fulfilled') {
      history.push(AuthRoutes.LOGIN)
    }

    return () => clearInterval(countDown)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [where, status]);


  return (
    <div className="login-form">

      <AuthHeader
        title={<FormattedMessage id="AUTH.GENERAL.LOGIN" />}
        subtitle={<FormattedMessage id="AUTH.LOGIN.TITLE_TEXT" />}
      />

      <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={LoginSchema} onSubmit={handleSubmit}>
        {({ values }) => (
          <Form>

            {INPUTS.map((item, i) => (
              item.type === 'checkbox'
                ? <Field name={item.name} key={i}>
                  {({ field }) => (
                    <label className="checkbox checkbox-primary font-weight-bold mb-3">
                      <input type="checkbox" id={`checkbox_${item.name}`} checked={field.value} {...field} disabled={isLoading} />
                      <span />
                      <div className="ml-2">
                        {intl({ id: item.label })}
                      </div>
                    </label>
                  )}
                </Field>
                : <Field component={SVFormControl} key={i}
                  {...{
                    type: item.type,
                    name: item.name,
                    label: intl({ id: item.label }),
                    withFeedbackLabel: true,
                    feedbackLabel: true,
                    disabled: isLoading,
                  }}
                />
            ))}

            <div className="d-flex flex-column mt-3">
              <Link to={AuthRoutes.FORGOT_PASSWORD} className="text-dark-50 text-hover-primary font-weight-bold text-right mb-3 mr-2" id='btn_forgot'>
                <FormattedMessage id="AUTH.FORGOT.BUTTON" />
              </Link>

              <Button variant='primary' type="submit" className='font-weight-bold py-4' disabled={isLoading} id='btn_login'>
                <FormattedMessage id="AUTH.GENERAL.LOGIN" />
                <Spinner loading={isLoading} />
              </Button>
            </div>


            <div className="d-flex justify-content-center font-weight-bold my-4 my-lg-6">
              <FormattedMessage id="AUTH.LOGIN.REGISTER" />
              <Link to={AuthRoutes.REGISTER} className='ml-1' id='btn_login'>
                <FormattedMessage id="AUTH.LOGIN.REGISTER_LINK" />
              </Link>
            </div>


            {requestResult() === 'fulfilled' &&
              <CustomAlert
                type="success"
                title={intl({ id: 'GENERAL.TEAM' })}
                message={intl({ id: `${message}` })}
                onClose={() => dispatch(AuthActions.cleanUpReducer())}
              />
            }

            {(where === 'login' || where === 'emailVerification') && status === 'failed' && error &&
              <CustomAlert
                type="danger"
                title={intl({ id: 'AUTH.GENERAL.ERRORS.TITLE' })}
                message={intl({ id: `${error}` })}
                onClose={() => dispatch(AuthActions.cleanUpReducer())}
              />
            }

            {(where === 'login' || where === 'emailVerification') && status === 'failed' &&
              <div className="d-flex justify-content-center font-weight-bold my-4 my-lg-6">
                <FormattedMessage id='AUTH.LOGIN.NOT_RECEIVE_EMAIL' />
                <Link to="#" className='ml-1' id='btn_email_verification' onClick={() => handleEmailVerification(values)}>
                  <FormattedMessage id='AUTH.LOGIN.RESEND_EMAIL' />
                </Link>
              </div>
            }

          </Form>
        )}
      </Formik>

    </div>
  );
}