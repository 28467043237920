import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader } from '_metronic/_partials/controls'
import { OrderReportCard } from './order-report/OrderReportCard';
import { UsageReportCard } from './usage-report/UsageReportCard';
import { LoadingDialog } from '_metronic/_partials/loading';
import DeleteRequest from './delete-request/DeleteRequest';
import { AdminActions } from '../../_redux/actions';
import { AdminRoutes } from 'constants/moduleRoutes'
import { BackButton } from '_metronic/_partials';
import { General } from './general/General';
import { Col, Row } from 'react-bootstrap';
import { NoteCard } from './note/NoteCard';
import Address from './address/Address';
import { useIntl } from 'react-intl';
import Info from './info/Info';



export function UserEdit({ match: { params: { id } } }) {

  const dispatch = useDispatch();

  const { formatMessage: intl } = useIntl();

  const { company, user, order_report, usage_report, loading } = useSelector(state => ({
    company: state.admin.company,
    order_report: state.admin.order_report,
    user: state.admin.user,
    usage_report: state.admin.usage_report,
    loading: state.admin.loading
  }), shallowEqual);

  useEffect(() => {
    if (id) {
      dispatch(AdminActions.getUser(id));
      dispatch(AdminActions.getUserCompany(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    return () => {
      dispatch(AdminActions.cleanUpUserModule());
    }
  }, [dispatch]);


  return (
    <Card className="card-box">
      <LoadingDialog isLoading={loading} />

      <CardHeader
        back={<BackButton link={AdminRoutes.ADMIN_USERS} />}
        title={intl({ id: 'ADMIN.USER.EDIT' })}
        // info={<UserGuideHelper />}
        id='title_user_edit'
        sticky
      />

      <CardBody>
        <Row>

          <Col lg="12" className="col-xl-6">
            <General user={user} />
          </Col>

          <Col lg="12" className="col-xl-6">
            <Info userBaseData={user} connections={usage_report.connections} />
          </Col>

          <Col lg="12" className="col-xl-6 flex-lg-column">
            <Address companyInfo={company} />
          </Col>

          <Col lg="12" className="col-xl-6 flex-lg-column">
            <NoteCard note={user?.note} id={id} />
          </Col>

          <Col lg="12" className="col-xl-6 flex-lg-column">
            <UsageReportCard usageReport={usage_report} />
          </Col>

          <Col lg="12" className="col-xl-6 flex-lg-column">
            <OrderReportCard orderReport={order_report} />
          </Col>

          {user?.status === 'deleted' &&
            <Col lg="12" className="col-xl-6 flex-lg-column">
              <DeleteRequest userBaseData={user} />
            </Col>
          }

        </Row>
      </CardBody>


    </Card>
  )
}