import React from "react";
import { Field, useFormikContext } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { Col, Row } from "react-bootstrap";
import { Card, CardBody, CardSubHeader, SVFormControl } from "_metronic/_partials/controls";
import { shallowEqual, useSelector } from "react-redux";


const feedbackLabelFn = (values, label) => {
  return (
    <span className="text-muted">
      <FormattedMessage id={label} />
      <span className="text-success">{values}</span>
    </span>
  )
}


export default function EbayGeneral({ productId }) {

  const { values } = useFormikContext();
  const { formatMessage: intl } = useIntl();

  const products = useSelector(state => state.listings.products, shallowEqual);

  const product = products.find(item => item.id === productId);

  const INPUTS = [
    {
      name: 'name',
      label: 'PRODUCT.GENERAL.NAME',
      feedbackLabel: values?.name?.length ? (
        <span className="text-muted">
          <FormattedMessage id="PRODUCT_LISTINGS.ARTIKEL_NAME_MAX" />
          <span className={`${values?.name?.length >= 80 ? 'text-danger' : 'text-success'}`}>
            <FormattedMessage id="PRODUCT_LISTINGS.ARTIKEL_CURRENT" values={{ length: values?.name?.length }} />
          </span>
        </span>
      )
        : true,
      required: true,
    },
    {
      name: 'sku',
      label: 'GENERAL.SKU',
      readonly: true,
      disabled: true,
      required: true,
    },
    {
      name: 'pricing.gross_price',
      label: 'PRODUCT.GENERAL.SALE_PRICE_GROSS',
      type: 'number',
      onFocus: e => e.target.select(),
      readonly: true,
      price: true,
      feedbackLabel: product ? feedbackLabelFn(product?.pricing?.gross_price, "PRODUCT_LISTINGS.PRODUCT.PRICE_CURRENT") : true,
      required: true,
    },
    {
      name: 'quantity',
      label: 'PRODUCT.GENERAL.QUANTITY',
      type: 'number',
      onFocus: e => e.target.select(),
      readonly: true,
      feedbackLabel: product ? feedbackLabelFn(product?.quantity, "PRODUCT_LISTINGS.PRODUCT.QUANTITY_CURRENT") : true,
      required: true,
    }
  ];

  return (
    <Card className="gutter-b">

      <CardSubHeader title={<FormattedMessage id="PRODUCT.TITLE" />} />

      <CardBody>
        <Row>
          {INPUTS.map((item, i) =>
            !((item.name === 'pricing.gross_price' || item.name === 'quantity') && values?.variants?.length !== 0) && (
              <Col sm="12" lg="6" key={i}>
                <Field
                  component={item.component || SVFormControl}
                  {...{
                    type: item.type,
                    name: item.name,
                    label: intl({ id: item.label }),
                    onFocus: item.onFocus,
                    withFeedbackLabel: true,
                    readonly: item.readonly,
                    disabled: item.disabled,
                    feedbackLabel: item.feedbackLabel,
                    required: item.required,
                    price: item.price,
                  }}
                />
              </Col>
            ))}
        </Row>
      </CardBody>

    </Card>
  );
};





