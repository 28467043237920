import React from 'react'
import { Redirect, Switch } from 'react-router-dom';
import { ContentRoute } from '_metronic/layout';
import { ToastContainer } from 'react-toastify'
import { TempUserUIProvider } from './_context/TempUserUIContext'
import { AdminRoutes, RELATIVE_PATH } from 'constants/moduleRoutes'
import { TempUserPage } from './TempUserPage'
import { TempUserEdit } from './temp-user-edit/TempUserEdit'



export default function TempUser() {
  return (
    <TempUserUIProvider>
      <ToastContainer />

      <Switch>
        <Redirect exact={true} from={RELATIVE_PATH} to={AdminRoutes.ADMIN_TEMP_USERS} />
        <ContentRoute path={AdminRoutes.ADMIN_TEMP_USERS_EDIT} component={TempUserEdit} />
        <ContentRoute path={AdminRoutes.ADMIN_TEMP_USERS} component={TempUserPage} />
      </Switch>
    </TempUserUIProvider>
  )
}
