import React, { useContext, createContext, useState, useCallback } from 'react';
import { useSearchQuery } from 'app/hooks';
import { useIntl } from 'react-intl';



const UserUIContext = createContext({});
export const useUserUIContext = () => useContext(UserUIContext);


export const UserUIProvider = ({ children }) => {

  const { formatMessage: intl } = useIntl()
  const [ids, setIds] = useState([]);
  const [userId, setUserId] = useState();

  // query params
  const [queryParams, setQueryBase] = useState({
    pagination: {
      page: 1,
      limit: 10,
    },
    order: 'id DESC',
    search: '',
    filter: { status: 'active' },
  });

  const setQueryParams = useCallback((params) => setQueryBase(params), [])

  const { setSearchQuery, setFilterQuery, setPaginationQuery, setOrderQuery } = useSearchQuery(setQueryParams);

  const initialDateRange = {
    id: "clear-date",
    label: intl({ id: "GENERAL.CLEAR" }),
  };

  const [dateRange, setDateRange] = useState(
    initialDateRange
  );
  const changeDateRange = (date = initialDateRange) => setDateRange(date);

  //* User details dialog
  const [showUserDetailsDialog, setShowUserDetailsDialog] = useState(false);
  const openUserDetailsDialog = id => {
    setUserId(id);
    setShowUserDetailsDialog(true);
  };
  const closeUserDetailsDialog = () => {
    setUserId(undefined);
    setShowUserDetailsDialog(false);
  };

  //* User note dialog
  const [user, setUser] = useState({ id: '', note: '' });
  const [showUserNoteDialog, setShowUserNoteDialog] = useState(false);
  const openUserNoteDialog = data => {
    setUser(data);
    setShowUserNoteDialog(true);
  };
  const closeUserNoteDialog = () => {
    setUser({ id: '', note: '' });
    setShowUserNoteDialog(false);
  };

  const value = {
    ids,
    setIds,
    userId,
    setUserId,
    queryParams,
    setSearchQuery,
    setFilterQuery,
    setPaginationQuery,
    setOrderQuery,
    changeDateRange,
    dateRange,
    setDateRange,

    showUserDetailsDialog,
    closeUserDetailsDialog,
    openUserDetailsDialog,

    user,
    showUserNoteDialog,
    openUserNoteDialog,
    closeUserNoteDialog,
  };


  return (
    <UserUIContext.Provider value={value}>
      {children}
    </UserUIContext.Provider>
  );
};
