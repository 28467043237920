import React, { useMemo, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';
import { SelectCounter } from '_metronic/_partials';
import { useDigitalProductsUIContext } from '../_context/DigitalProductsUIContext';
import { digitalProductsActions } from '../_redux/actions';
import { useSearchQueryContext } from '../_context/SearchQueryProvider';




export function SendEmailsKeyDialog() {

  const dispatch = useDispatch();

  const { loading, error, emailSuccess } = useSelector(state => ({
    loading: state.digitalProduct.loading,
    emailSuccess: state.digitalProduct.emailSuccess,
    error: state.digitalProduct.error,
  }), shallowEqual);


  const UIContext = useDigitalProductsUIContext();
  const queryContext = useSearchQueryContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    setIds: UIContext.setIds,
    show: UIContext.showSendMailKeyDialog,
    handleClose: UIContext.closeSendMailKeyDialog,
    queryParams: queryContext.queryParams,
  }),
    [UIContext, queryContext]
  );


  const handleSendMailKey = (ids) => {
    if (ids.length) {
      const mailIds = ids.map(item => item.id);
      dispatch(digitalProductsActions.sendMailKey(mailIds, UIProps.queryParams));
    }
  };


  useEffect(() => {
    if (emailSuccess) {
      setTimeout(() => {
        UIProps.handleClose()
        UIProps.setIds([]);
      }, 2500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailSuccess]);


  useEffect(() => {
    return () => {
      dispatch(digitalProductsActions.cleanStatesInStore({ emailSuccess: false, error: "" }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UIProps.ids]);

  const openPaymentIds = UIProps.ids.filter(item => item.paymentStatus === "open").map(item => item.orderId);

  return (
    <Modal show={UIProps.show} onHide={UIProps.handleClose} aria-labelledby="example-modal-sizes-title-standard" centered>

      <Modal.Body className='p-12'>

        <div className="d-flex flex-column align-items-center">
          {emailSuccess
            ? <Icon.CheckCircle className="text-success icon-6x my-5" />
            : <Icon.EnvelopeAt className="text-dark-75 icon-5x my-6" />
          }
        </div>

        <h5 className="text-center font-weight-bold">
          {emailSuccess
            ? <FormattedMessage id='DIGITAL_PRODUCT.EMAIL_SEND.SUCCESS' />
            : error
              ? <span className="text-danger"> {error.message ? error.message : <FormattedMessage id='GENERAL.ERROR_MESSAGE' />}</span>
              : <span>
                <FormattedMessage id="DIGITAL_PRODUCT.EMAIL_SEND.CONTENT" />
                {openPaymentIds.length > 0 && (
                  <div className="text-danger mt-2">
                    <FormattedMessage id="DIGITAL_PRODUCT.EMAIL_SEND.WARNING" />
                    <div>{openPaymentIds.join(', ')}</div>
                  </div>
                )}
              </span>
          }
        </h5>

      </Modal.Body>

      <Modal.Footer>

        <SelectCounter count={UIProps.ids.length} />

        <Button variant='light' className='mr-2' onClick={UIProps.handleClose} disabled={loading} id="btn_cancel_modal">
          <FormattedMessage id="GENERAL.CANCEL" />
        </Button>

        {!emailSuccess &&
          <Button variant='primary' onClick={() => handleSendMailKey(UIProps.ids)} disabled={loading} id="btn_send_email">
            <FormattedMessage id="GENERAL.SEND" />
          </Button>}

      </Modal.Footer>

    </Modal>
  );
}
