import React, { useState } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl'
import { Icon } from '_metronic/_icons';



export default function UserGuidesConnectionInfo({ guide, edit }) {

  const [isOpen, setIsOpen] = useState(false);

  const { formatMessage: intl } = useIntl();

  return (
    <Accordion defaultActiveKey={edit ? "0" : ""} className='accordion-toggle-arrow'>
      <Card>

        <Accordion.Toggle as={Card.Header} className='d-flex justify-content-between bg-white align-items-center px-7 py-5' onClick={() => setIsOpen(!isOpen)} eventKey="0">

          <div className='font-weight-bold font-size-lg d-flex align-items-center'>
            <Icon.InfoCircle className='mr-3' />
            <FormattedMessage id={guide.title} />
          </div>

          <span className="accordion-icon">
            <Icon.ChevronDown style={{ transition: "all 0.15s ease", transform: isOpen ? "" : "rotate(-90deg)" }} />
          </span>

        </Accordion.Toggle>

        <Accordion.Collapse eventKey="0">
          <Card.Body className={`bg-${edit ? "white" : "info"}-o-20`}>

            <ul className="alert-text text-justify text-dark-75 line-height-xl pl-8">
              {intl({ id: guide.description }).split("#").map((item, i) => ["href=", "<b>"].some(el => item.includes(el))
                ? <li className="mb-3 font-weight-bold text-muted" key={i}>
                  <FormattedHTMLMessage id={item} values={{ what: 'react-intl', a: chunks => <a href='/'>{chunks}</a>, b: chunks => <strong>{chunks}</strong> }} />
                </li>
                :
                <li className="mb-3 font-weight-bold text-muted" key={i}>
                  <span className="text-muted">
                    {item}
                  </span>
                </li>
              )}
            </ul>

          </Card.Body>
        </Accordion.Collapse>

      </Card>
    </Accordion>
  )
}