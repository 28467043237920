import React, { createContext, useContext, useCallback, useState } from 'react';
import * as uiHelpers from './ArchiveUIHelpers';
import { find } from 'lodash';
import { useIntl } from 'react-intl';


const ArchiveUIContext = createContext();
export const useArchiveUIContext = () => useContext(ArchiveUIContext)


export const ArchiveUIProvider = ({ children }) => {

  const { formatMessage: intl } = useIntl()
  const [ids, setIds] = useState([]);
  const [requestedArchive, setRequestedArchiveBase] = useState(uiHelpers.DOCUMENTS_OPTIONS[0]);

  const setRequestedArchive = useCallback(value => {

    const findDocument = find(uiHelpers.DOCUMENTS_OPTIONS, { value });
    if (!findDocument) {
      return setRequestedArchiveBase(uiHelpers.DOCUMENTS_OPTIONS[0]);
    }
    setRequestedArchiveBase(findDocument);
  }, []);

  // Archive Relocate
  const [showArchiveRelocateDialog, setShowArchiveRelocateDialog] = useState(false);
  const openArchiveRelocateDialog = (ids) => {
    setShowArchiveRelocateDialog(true);
    setIds(ids);
  };
  const closeArchiveRelocateDialog = () => {
    setShowArchiveRelocateDialog(false)
    setIds([]);
  };

  // Print
  const [showArchivePrintDialog, setShowArchivePrintDialog] = useState(false);
  const openArchivePrintDialog = () => {
    setShowArchivePrintDialog(true);
  };
  const closeArchivePrintDialog = () => {
    setShowArchivePrintDialog(false)
  };

  const initialDateRange = {
    id: "clear-date",
    label: intl({ id: "GENERAL.CLEAR" }),
  };

  const [dateRange, setDateRange] = useState(initialDateRange);

  const changeDateRange = (date = initialDateRange) => setDateRange(date);

  const value = {
    ids,
    setIds,
    requestedArchive,
    setRequestedArchive,

    showArchiveRelocateDialog,
    openArchiveRelocateDialog,
    closeArchiveRelocateDialog,

    showArchivePrintDialog,
    openArchivePrintDialog,
    closeArchivePrintDialog,

    changeDateRange,
    dateRange,
    initialDateRange,
    setDateRange,
  };

  return (
    <ArchiveUIContext.Provider value={value}>
      {children}
    </ArchiveUIContext.Provider>
  );
};
