import React, { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { upcRegExp, barcodeRegExp, minValidation, maxValidation, nameRegExp } from '_metronic/_helpers';
import { useProductEditUIContext } from '../_context/ProductEditUIContext';
import { VARIANT_ACTION_TYPES } from './variants/core/helpers';
import { DirtyDialog } from '_metronic/_partials/dialogs';
import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';



const FormObserver = () => {
  const { values } = useFormikContext();
  const productEditUIContext = useProductEditUIContext();
  const dispatchNewVariants = useMemo(() => productEditUIContext.dispatchNewVariants, [productEditUIContext.dispatchNewVariants]);

  useEffect(() => {
    dispatchNewVariants({ type: VARIANT_ACTION_TYPES.SET_FORM_VALUES, payload: values });
  }, [dispatchNewVariants, values]);

  return null;
};



export function FormikStepper({ product, btnRef, saveProduct, children, autoComplete = 'off', resetBtnRef, success, onClose }) {

  const { formatMessage: intl } = useIntl();

  const { variants } = useSelector(state => ({ variants: state.products.variants }));
  const onKeyDown = key => {
    (key.charCode || key.keyCode) === 13 && key.preventDefault();
  };

  const productValidationSchema = Yup.object().shape({
    name: Yup.string().strict(true)
      .required(intl({ id: "PRODUCT.GENERAL.NAME_REQUIRED" }))
      .max(250, maxValidation("250"))
      .matches(nameRegExp, intl({ id: 'AUTH.GENERAL.BLANK_CHARACTER' })),
    sku: Yup.string().strict(true)
      .trim(intl({ id: "PRODUCT.GENERAL.SKU_TRIM" }))
      // .matches(/^(?!.*\s)(?!.*[A-Za-z]{4}\s)[A-Za-z0-9\s]+$/, intl({ id: 'AUTH.GENERAL.BLANK_CHARACTER' }))
      .required(intl({ id: "PRODUCT.GENERAL.SKU_REQUIRED" }))
      .max(50, maxValidation("50")),
    condition: Yup.string()
      .required(intl({ id: "PRODUCT.GENERAL.STATUS_REQUIRED" })),
    supplier_id: Yup.string(),
    quantity: Yup.number()
      .moreThan(-1, intl({ id: "PRODUCT.GENERAL.PRICE_THAN" }))
      .max(999999, maxValidation("999.999"))
      .test('is-integer-if-piece', intl({ id: "PRODUCT.GENERAL.INTEGER_REQUIRED" }), function (value) {
        const { unit } = this.parent;
        if (unit === 'piece' && value != null) {
          return Number.isInteger(value);
        }
        return true;
      })
      .nullable(true),
    unit: Yup.string(),
    ...(variants?.length === 0 && {}),

    //test edilecek obje içindeki değerler 
    pricing: Yup.object().shape({
      /*       net_price: Yup.number()
              .min(0, intl({ id: "PRODUCT.GENERAL.PRICE_THAN" }))
              .max(1000000000000, maxValidation("1.000.000.000.000")), */
      gross_price: Yup.number()
        .min(0, intl({ id: "PRODUCT.GENERAL.PRICE_THAN" }))
        .test('Is valid decimal?', intl({ id: "DOCUMENT.POSITION.IS_VALID.DECIMALS" }), value => {
          if (!value) return true;
          const decimalPart = (value.toString().split('.')[1] || '').length;
          return decimalPart <= 2;
        })
        .max(10000000, intl({ id: "PRODUCT.GENERAL.PRICE_MAX" })),
      purchase_price: Yup.number()
        .min(0, intl({ id: "PRODUCT.GENERAL.PRICE_THAN" }))
        .test('Is valid decimal?', intl({ id: "DOCUMENT.POSITION.IS_VALID.DECIMALS" }), value => {
          if (!value) return true;
          const decimalPart = (value.toString().split('.')[1] || '').length;
          return decimalPart <= 2;
        })
        .max(10000000, intl({ id: "PRODUCT.GENERAL.PRICE_MAX" })),
      tax: Yup.number()
        .required(intl({ id: "PRODUCT.GENERAL.VAT_REQUIRED" })),
    }),

    //test edilecek obje içindeki değerler
    identities: Yup.object().shape({
      barcode: Yup.string()
        .matches(barcodeRegExp, intl({ id: "PRODUCT.GENERAL.BARCODE_MATCHES" })),
      ean: Yup.string()
        .max(13, maxValidation("13")),
      // .length(13, intl({ id: "PRODUCT.GENERAL.EAN_LENGTH" })),
      // .matches(eanRegExp, intl({ id: "PRODUCT.GENERAL.EAN_MATCHES" })),
      // .test('ean', intl({ id: "PRODUCT.GENERAL.EAN_TEST" }), eanFunctions),
      gtin: Yup.string()
        .max(50, maxValidation("50")),
      isbn: Yup.string()
        .min(10, minValidation("10"))
        .max(13, maxValidation("13")),
      // .matches(isbnRegExp, intl({ id: "PRODUCT.GENERAL.ISBN_MATCHES" })),
      // .test('isbn', intl({ id: "PRODUCT.MORE_SETTINGS.ISBN_INFO" }), isbnFunctions),
      mpn: Yup.string()
        .max(50, maxValidation("50")),
      upc: Yup.string()
        .matches(upcRegExp, intl({ id: "PRODUCT.MORE_SETTINGS.UPC_INFO" })),
      asin: Yup.string()
        .length(10, intl({ id: "PRODUCT.MORE_SETTINGS.ASIN_INFO" }))
      // .test('upc', intl({ id: "PRODUCT.MORE_SETTINGS.UPC_INFO" }), upcFunctions),
    }),

    dimensions: Yup.object().shape({
      weight: Yup.number()
        .min(0, intl({ id: "PRODUCT.GENERAL.PRICE_THAN" }))
        .max(1000000, intl({ id: 'PRODUCT.GENERAL.DIMENSIONS_LESS' }, { unit: '1.000.000 kg' })),
      width: Yup.number()
        .min(0, intl({ id: "PRODUCT.GENERAL.PRICE_THAN" }))
        .max(500, intl({ id: 'PRODUCT.GENERAL.DIMENSIONS_LESS' }, { unit: '500 cm' })),
      height: Yup.number()
        .min(0, intl({ id: "PRODUCT.GENERAL.PRICE_THAN" }))
        .max(300, intl({ id: 'PRODUCT.GENERAL.DIMENSIONS_LESS' }, { unit: '300 cm' })),
      length: Yup.number()
        .min(0, intl({ id: "PRODUCT.GENERAL.PRICE_THAN" }))
        .max(300, intl({ id: 'PRODUCT.GENERAL.DIMENSIONS_LESS' }, { unit: '300 cm' })),
    }),

  })


  return (
    <Formik enableReinitialize={true} initialValues={product} validationSchema={productValidationSchema} onSubmit={saveProduct}>
      {({ handleSubmit, handleReset }) => {
        return (
          <Form onKeyDown={onKeyDown} autoComplete={autoComplete}>
            <FormObserver />
            <DirtyDialog success={success} onClose={onClose} />
            {children}
            <button type="submit" className='d-none' ref={btnRef} onClick={handleSubmit} />
            <button type="button" className='d-none' ref={resetBtnRef} onClick={() => handleReset()} />
          </Form>
        );
      }}
    </Formik>
  );
}
