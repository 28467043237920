import React from 'react';

import { FormattedMessage } from 'react-intl';
import { UserGuideHelper } from 'app/user-guides/UserGuideHelper';
// components
import { BackButton, SaveButton } from '_metronic/_partials';
import { CardHeader, CardHeaderToolbar } from '_metronic/_partials/controls';

export function AmazonListingsAddProductHeader({ backToMainPage, showSaveBtn = true }) {
  return (
    <CardHeader back={<BackButton onClick={backToMainPage} />} title={<FormattedMessage id={'PRODUCT.PRODUCT_NEW'} />} info={<UserGuideHelper />} id={'title_product_new'} sticky>
      <CardHeaderToolbar>{showSaveBtn && <SaveButton onClick={() => console.log('done')} />}</CardHeaderToolbar>
    </CardHeader>
  );
}
