import React from 'react';
import { SearchControl } from '_metronic/_partials/controls';
import { Col, Row } from 'react-bootstrap';



export function OrderListFilter({ search, handleSearch }) {

  return (
    <Row className="mb-3">

      <Col md="4">
        <SearchControl search={search} handleSearch={handleSearch} />
      </Col>

    </Row>
  );
}
