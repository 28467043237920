import { call, fork, put, select, takeEvery } from "redux-saga/effects";
import { getTicketCounts } from "app/modules/support/_redux/saga";
import { AuthActions } from "app/pages/auth/_redux/actions";
import { notificationMiddleware } from "middlewares";
import { NotificationActions } from "./actions";
import ActionTypes from "./actionTypes";



// Notification
export function* getNotification() {
  try {

    const accessToken = yield select(state => state.auth.access_token);

    const response = yield call(notificationMiddleware.getNotification, accessToken);
    yield put(NotificationActions.getNotificationSuccess(response.data));

    // Get ticket count
    return yield fork(getTicketCounts);

  } catch (err) {
    if (err.response && err.response.status === 401) {
      return yield put(AuthActions.logout());
    }
    yield put(NotificationActions.getNotificationFail(err));
  }
}



export function* notificationSaga() {
  yield takeEvery(ActionTypes.GET_NOTIFICATION, getNotification);
}