import React from 'react';
import { Button, Col, ListGroup, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Icon } from '_metronic/_icons';
import { SVFormControl } from '_metronic/_partials/controls';
import { Field } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import PerfectScrollbar from "react-perfect-scrollbar";


export const VariantAddForm = ({ aspects, deleteAspect }) => {

  const { formatMessage: intl } = useIntl();

  return (
    <Row>
      <Col lg="6">
        <Field component={SVFormControl}
          {...{
            name: "variant_name",
            label: intl({ id: "PRODUCT.ADD_VARIANT.VARIANT_NAME" }),
            feedbackLabel: true,
            required: true,
          }}
        />
      </Col>

      <Col lg="6">
        <Field component={SVFormControl}
          {...{
            name: "aspect_names",
            label: intl({ id: "PRODUCT.ADD_VARIANT.ASPECT_NAME" }),
            feedbackLabel: true,
            required: true,
            onClickSymbol: <Button variant='secondary' type="submit" id='btn_adds_variant' ><Icon.Plus /></Button>
          }}
        />
      </Col>

      <Col lg="12" className='mt-4 ml-2'>
        <PerfectScrollbar className="scroll pr-5 mr-n5" options={{ wheelSpeed: 1, wheelPropagation: false }} style={{ maxHeight: '400px' }}>
          
          <ListGroup variant="flush" className="active">
            {aspects?.length > 0 && aspects?.map((aspect, index) =>

              <ListGroup.Item className="d-flex justify-content-between align-items-center p-0 pr-2" key={aspect}>
                <span className='font-weight-bold'>{aspect}</span>
                
                <OverlayTrigger overlay={<Tooltip id="customers-delete-tooltip"><FormattedMessage id="GENERAL.DELETE" /></Tooltip>}>
                  <span className="btn btn-icon btn-sm text-muted text-hover-danger" onClick={() => deleteAspect(index)} id="btn_delete">
                    <span className="svg-icon svg-icon-md">
                      <Icon.Trash />
                    </span>
                  </span>
                </OverlayTrigger>
                
              </ListGroup.Item>

            )}
          </ListGroup>

        </PerfectScrollbar>
      </Col>
    </Row>
  );
};