/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';



export const ActionsColumnFormatter = (cellContent, row, rowIndex, { openEditProductPage, openDeleteProductDialog, copyProductForCreateNewOne }) => {

  const ebayInventory =
    Array.isArray(row?.inventories) &&
    row.inventories.length > 0 &&
    row.inventories.some(ei => ei.status === "PUBLISHED");

  return (
    <>
      <OverlayTrigger overlay={<Tooltip id="products-copy-tooltip"><FormattedMessage id="PRODUCT.PRODUCT_COPY" /></Tooltip>}>
        <Link className="btn btn-icon btn-hover-info btn-sm" to={copyProductForCreateNewOne(row.id)}
          style={ebayInventory ? { pointerEvents: "none", opacity: 0.5 } : null} id={`btn_copy_${row.id}`}>
          <span className="svg-icon svg-icon-md">
            <Icon.Copy />
          </span>
        </Link>
      </OverlayTrigger>

      <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip"><FormattedMessage id="PRODUCT.PRODUCT_EDIT" /></Tooltip>}>
        <Link className="btn btn-icon btn-hover-primary btn-sm" to={openEditProductPage(row.id)}
          style={ebayInventory ? { pointerEvents: "none", opacity: 0.5 } : null} id={`btn_edit_${row.id}`}>
          <span className="svg-icon svg-icon-md">
            <Icon.PencilSquare />
          </span>
        </Link>
      </OverlayTrigger>

      <OverlayTrigger overlay={<Tooltip id="products-delete-tooltip"><FormattedMessage id="PRODUCT.PRODUCT_DELETE" /></Tooltip>}>
        <Link className="btn btn-icon btn-hover-danger btn-sm" to={openDeleteProductDialog(row.id)}
          style={ebayInventory ? { pointerEvents: "none", opacity: 0.5 } : null} id={`btn_delete_${row.id}`}>
          <span className="svg-icon svg-icon-md">
            <Icon.Trash />
          </span>
        </Link>
      </OverlayTrigger>
    </>
  )
};
