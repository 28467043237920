export default class ActionTypes {
  static get PREFIX() {
    return '[admin]';
  }


  // Get all users
  static get GET_USERS() {
    return `${ActionTypes.PREFIX}USERS_GET_CALL`;
  }
  static get GET_USERS_SUCCESS() {
    return `${ActionTypes.PREFIX}USERS_GET_SUCCESS`;
  }
  static get GET_USERS_FAIL() {
    return `${ActionTypes.PREFIX}USERS_GET_FAIL`;
  }


  // Get user
  static get GET_USER() {
    return `${ActionTypes.PREFIX}USER_GET_CALL`;
  }
  static get GET_USER_SUCCESS() {
    return `${ActionTypes.PREFIX}USER_GET_SUCCESS`;
  }
  static get GET_USER_FAIL() {
    return `${ActionTypes.PREFIX}USER_GET_FAIL`;
  }


  // Update users
  static get UPDATE_USER() {
    return `${ActionTypes.PREFIX}USER_UPDATE_CALL`;
  }
  static get UPDATE_USER_SUCCESS() {
    return `${ActionTypes.PREFIX}USER_UPDATE_SUCCESS`;
  }
  static get UPDATE_USER_FAIL() {
    return `${ActionTypes.PREFIX}USER_UPDATE_FAIL`;
  }


  // Delete user
  static get DELETE_USER() {
    return `${ActionTypes.PREFIX}DELETE_USER`;
  }
  static get DELETE_USER_SUCCESS() {
    return `${ActionTypes.PREFIX}DELETE_USER_SUCCESS`;
  }
  static get DELETE_USER_FAIL() {
    return `${ActionTypes.PREFIX}DELETE_USER_FAIL`;
  }


  // Get all temp users
  static get GET_TEMP_USERS() {
    return `${ActionTypes.PREFIX}TEMP_USERS_GET_CALL`;
  }
  static get GET_TEMP_USERS_SUCCESS() {
    return `${ActionTypes.PREFIX}TEMP_USERS_GET_SUCCESS`;
  }
  static get GET_TEMP_USERS_FAIL() {
    return `${ActionTypes.PREFIX}TEMP_USERS_GET_FAIL`;
  }


  // Update temp users
  static get UPDATE_TEMP_USER() {
    return `${ActionTypes.PREFIX}TEMP_USER_UPDATE_CALL`;
  }
  static get UPDATE_TEMP_USER_SUCCESS() {
    return `${ActionTypes.PREFIX}TEMP_USER_UPDATE_SUCCESS`;
  }
  static get UPDATE_TEMP_USER_FAIL() {
    return `${ActionTypes.PREFIX}TEMP_USER_UPDATE_FAIL`;
  }


  // Delete temp users
  static get DELETE_TEMP_USERS() {
    return `${ActionTypes.PREFIX}DELETE_TEMP_USERS_GET_CALL`;
  }
  static get DELETE_TEMP_USERS_SUCCESS() {
    return `${ActionTypes.PREFIX}DELETE_TEMP_USERS_GET_SUCCESS`;
  }
  static get DELETE_TEMP_USERS_FAIL() {
    return `${ActionTypes.PREFIX}DELETE_TEMP_USERS_GET_FAIL`;
  }


  // Get all users reports
  static get GET_REPORTS_USERS() {
    return `${ActionTypes.PREFIX}GET_REPORTS_USERS`;
  }
  static get GET_REPORTS_USERS_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_REPORTS_USERS_SUCCESS`;
  }
  static get GET_REPORTS_USERS_FAIL() {
    return `${ActionTypes.PREFIX}GET_REPORTS_USERS_FAIL`;
  }


  // Get users company
  static get GET_USER_COMPANY() {
    return `${ActionTypes.PREFIX}GET_USER_COMPANY`;
  }
  static get GET_USER_COMPANY_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_USER_COMPANY_SUCCESS`;
  }
  static get GET_USER_COMPANY_FAIL() {
    return `${ActionTypes.PREFIX}GET_USER_COMPANY_FAIL`;
  }


  // Get users contact
  static get GET_USERS_CONTACT() {
    return `${ActionTypes.PREFIX}GET_USERS_CONTACT`;
  }
  static get GET_USERS_CONTACT_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_USERS_CONTACT_SUCCESS`;
  }
  static get GET_USERS_CONTACT_FAIL() {
    return `${ActionTypes.PREFIX}GET_USERS_CONTACT_FAIL`;
  }


  // Clean up the user module
  static get CLEAN_UP_USER_MODULE() {
    return `${this.PREFIX}USERS_CLEAN_UP`;
  }
  static get CLEAN_UP_USER_MODULE_PARAMS() {
    return `${this.PREFIX}USERS_CLEAN_UP_PARAMS`;
  }

}