export const documentTypes = {
  invoices: 'invoice',
  waybills: 'waybill',
  credits: 'credit',
  orders: 'order',
  offers: 'offer',
};

export const uiSetIdsArrayToString = (multipleIds, joinString) => {
  const ids = [];
  multipleIds.map(id => Object.values(id).forEach(id => ids.push(id)));
  return ids.join(`${joinString}`);
};


export const uiGetMwstTextByTaxCategory = (steuerkategorie) => {
  switch (steuerkategorie) {

    case 'domestic':
      return '';

    case 'abroad':
      return 'Umsatzsteuerfreie Lieferung gemäß §4 Nr. 1a,  §6 UStG';

    case 'eu_region':
      return 'Umsatzsteuerfreie Lieferung gemäß §4 Nr. 1b,  §6a UStG';

    case 'eu_region_other':
      return 'Die Leistung unterliegen dem Reverse-Change-Verfahren - die Mehrwertsteuer schuldet der Leistungsemfänger It. §13b UStG.';

    case 'small_business':
      return 'Als Kleinunternehmer im Sinne von § 19 Abs. 1 UStG wird keine Umsatzsteuer berechnet';

    case 'different_tax':
      return 'Hinweis: Für die angegebene Position, die als Sonderregelung vermerkt wurde, gilt die Differenzbesteuerungsbesteuerung gemäß § 25a UstG.';

    default:
      return '';
  }
};


// NOTE: Şuan kullanılmıyor
// export const taxCalculator = (position, shippingPreis) => {

//   const newArray = [];

//   for (let index = 0; index < position.length; index++) {
//     const totalBrutto = ((position[index].price * position[index].tax_rate) / 100 + position[index].price) * position[index].quantity;
//     const MwSt = position[index].tax_rate;
//     const result = { totalBrutto: totalBrutto, MwSt: MwSt, };
//     const findIndex = newArray.findIndex(arr => arr.MwSt === MwSt);
//     if (findIndex !== -1) {
//       newArray[findIndex].totalBrutto += totalBrutto;
//     } else {
//       newArray.push(result);
//     }
//   }

//   const totalVerkauf = newArray.reduce((acc, arr) => acc + arr.totalBrutto, 0);
//   const taxArray = [];

//   for (let index = 0; index < newArray.length; index++) {
//     if (newArray[index].MwSt === 0) {
//       continue;
//     }
//     const produktmwst = newArray[index].totalBrutto - (newArray[index].totalBrutto * 100) / (100 + newArray[index].MwSt);
//     const teiligung = totalVerkauf / newArray[index].totalBrutto;
//     const newMwst = shippingPreis / teiligung - ((shippingPreis / teiligung) * 100) / (100 + newArray[index].MwSt);
//     const taxesNew = {
//       tax_rate: newArray[index].MwSt,
//       // mwst: produktmwst + newMwst, //FIXME: NaN 0 kontrolü yapılmalı
//       mwst: newArray.totalBrutto === 0 ? produktmwst : produktmwst + newMwst,
//     };
//     taxArray.push(taxesNew);
//   }
//   return taxArray;
// };



export const uiGetExtendedInitialDocument = (initial, documentDefaultProps, firmaName, extendedProps) => {

  const extendedDocuments = {
    ...initial,
    subtitle: {
      header: extendedProps?.default_subtitle?.header || `${documentDefaultProps.inputHeaderText}`,
      footer: extendedProps?.default_subtitle?.footer || `${documentDefaultProps.inputFooterText}\n\n${firmaName || ''}`,
    },
    //shipping_method: extendedProps?.default_shipping?.method || 'Standard',
    // payment_method: extendedProps?.default_payment?.method || 'transfer',
    pricing_summary: {
      ...initial.pricing_summary,
      shipping_price: extendedProps?.default_shipping?.price || '0',
    },
  };
  return extendedDocuments;
};


export const uiGetDocumentFormValues = ({ id, copyId, convertedDocumentId, documentForEdit, customerId, documentProps, settingProps, firmaName, initialDocument }) => {

  if (id) {
    return documentForEdit;
  }

  if (copyId) {
    return documentForEdit;
  }

  if (convertedDocumentId) {
    return {
      ...documentForEdit,
      subtitle: {
        header: settingProps?.default_subtitle?.header || `${documentProps.inputHeaderText}`,
        footer: settingProps?.default_subtitle?.footer || `${documentProps.inputFooterText}\n\n${firmaName || ''}`,
      }
    };
  }

  if (documentForEdit && customerId) {
    return uiGetExtendedInitialDocument(documentForEdit, documentProps, firmaName, settingProps);
  }

  return uiGetExtendedInitialDocument(initialDocument, documentProps, firmaName, settingProps);
};
