import React from 'react';
import { SearchQueryProvider } from './_context/SearchQueryProvider';
import { DigitalProductsCard } from './DigitalProductsCard';
import { CustomerDetailsDialog } from './digital-products-dialogs/CustomerDetailsDialogs';
import { SendEmailsKeyDialog } from './digital-products-dialogs/SendEmailsKeyDialog';
import { KeyReserveDeleteDialog } from './digital-products-dialogs/KeyReserveDeleteDialog';



export default function DigitalProductsPage() {
  return (
    <SearchQueryProvider>
      <DigitalProductsCard />
      <CustomerDetailsDialog />
      <SendEmailsKeyDialog />
      <KeyReserveDeleteDialog/>
    </SearchQueryProvider>
  );
}