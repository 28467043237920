import React from 'react'
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls';
import { FormattedMessage } from 'react-intl';
import { flags, UIDates } from "_metronic/_helpers";
import { Icon } from '_metronic/_icons';



export default function Info({ userBaseData }) {

  const currentLanguage = flags.find((x) => x.lang === userBaseData?.language);

  const info = [
    {
      label: "ADMIN.LANGUAGE",
      content: <>
        <span className="svg-icon svg-icon-md mr-2">{currentLanguage?.flag}</span>
        <span>{currentLanguage?.name}</span>
      </>,
    },
    {
      label: "GENERAL.REGISTRATION_DATE",
      content: UIDates.formatDateTime(userBaseData?.created_at),
    },
    {
      label: "ADMIN.USER.EMAIL_SENT.DATE",
      content: UIDates.formatDateTime(userBaseData?.email_verification?.sending_date),
    },

  ];

  return (
    <Card>

      <CardSubHeader icon={<Icon.PersonInfo />} title={<FormattedMessage id="ADMIN.USER.INFO" />} />

      <CardBody>
        {info.length && (
          <div className="py-2 px-4 d-flex flex-column">
            {info?.map((item, i) => (
              <h6 className={`d-flex align-items-start flex-column flex-md-row ${info.length - 1 === i ? "" : "mb-6"}`} key={i}>
                <span className="text-dark-50 mw-200px mb-2">
                  <FormattedMessage id={item.label} />
                </span>
                <span>
                  {item.content}
                </span>
              </h6>
            ))}
          </div>
        )}
      </CardBody>


    </Card>
  );
}
