import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useDocumentsUIContext } from "../_context/DocumentsUIContext";
import { CloseButton, DeleteButton, MailButton } from "_metronic/_partials";
import { getShipperName } from "../_context/DocumentsUIHelpers";
import { PdfViewer } from "_metronic/_partials/components";
import { FormattedMessage, useIntl } from "react-intl";
import { DocumentActions } from "../_redux/actions";
import PDFMerger from "pdf-merger-js/browser";
import { Modal } from "react-bootstrap";
import { Portal } from "react-portal";
import { isArray } from "lodash";
//import { useDocumentLocalUIContext } from "../_context/DocumentLocalUIContext";



export function PrintLabelsDialog() {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl()

  //const contextLocal = useDocumentLocalUIContext();
  const UIContext = useDocumentsUIContext();
  const UIProps = useMemo(() => ({
    documentQueryParams: {}, //contextLocal.queryParams,
    requestedDocument: UIContext.requestedDocument,
    show: UIContext.showPrintLabel,
    onHide: UIContext.closePrintLabelsDialog,
    shipper: UIContext.shipperName,
    openLabelSettingDialog: UIContext.openLabelSettingDialog,
    openPrintLabelsDialog: UIContext.openPrintLabelsDialog,
    sendEmailLabelDialog: UIContext.sendEmailLabelDialog,
    openDeleteLabelDialog: UIContext.openDeleteLabelDialog,
    documentId: UIContext.documentId,
    setShippingType: UIContext.setShippingType,
    shippingType: UIContext.shippingType,
  }),
    [
      UIContext.documentId,
      UIContext.shipperName,
      UIContext.requestedDocument,
      UIContext.showPrintLabel,
      UIContext.closePrintLabelsDialog,
      UIContext.openLabelSettingDialog,
      UIContext.openPrintLabelsDialog,
      UIContext.sendEmailLabelDialog,
      UIContext.openDeleteLabelDialog,
      UIContext.setShippingType,
      UIContext.shippingType
    ]);

  const { labels, printLoading } = useSelector(state => ({
    labels: state.documents.labels,
    printLoading: state.documents.printLoading
  }), shallowEqual);

  const [errorStacks, setErrorStacks] = useState([]);
  const [url, setUrl] = useState();

  const onHideCallBack = () => {
    UIProps.onHide();
    setUrl(undefined);
    setErrorStacks([]);
    UIProps.setShippingType(null);
    dispatch(DocumentActions.cleanStatesInStore({ labels: [] }));
  };

  useEffect(() => {

    const getBlob = async (label) => {
      if (label.includes("data:application/pdf;base64,")) {
        label = label.substr("data:application/pdf;base64,".length);
      }
      const bytes = window.atob(label);
      let length = bytes.length;
      let out = new Uint8Array(length);
      while (length--) { out[length] = bytes.charCodeAt(length) }
      const blob = new Blob([out], { type: "application/pdf" });
      return blob;
    };

    const render = async (labelArray) => {
      const promiseList = [];
      for (const label of labelArray) {
        promiseList.push(
          new Promise((resolve, reject) => {
            getBlob(label.label).then((urls) => resolve(urls)).catch((err) => reject(err));
          })
        );
      }

      const settledBlobs = await Promise.all(promiseList);
      const merger = new PDFMerger();
      await Promise.all(settledBlobs.map(async (file) => await merger.add(file)));
      const mergedPdf = await merger.saveAsBlob();
      const urls = URL.createObjectURL(mergedPdf);
      setUrl(urls);
    };

    if (isArray(labels) && labels.length) {
      const labelFilterByStatusOk = labels.filter((lbl) => !lbl.error);
      const labelFilterByStatusError = labels.filter((lbl) => lbl.error);

      if (labelFilterByStatusOk.length) {
        render(labelFilterByStatusOk).catch((err) => { console.error("error", err) });
      };

      if (labelFilterByStatusError.length) {
        setUrl(undefined);
        const errors = labelFilterByStatusError.map((err) => ({
          message: err.error,
          service: err.service,
          tracking_id: err.tracking_id || null,
          documentId: err.documentId || null,
        }));
        setErrorStacks(errors);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, labels, UIProps.requestedDocument.type, UIProps.shipper]);


  const deleteLabelHandler = () => {
    const { label, ...rest } = labels?.[0] || {};
    UIProps.openDeleteLabelDialog({ documentId: UIProps.documentId, ...rest });
  };

  const mailLabelHandler = () => {
    const returnLabel = labels?.find(shipping => shipping.type === "return");
    if (returnLabel && UIProps.documentId) {
      UIProps.sendEmailLabelDialog(UIProps.documentId, returnLabel.id);
    }
  };

  const emailReturnLabel = labels[0]?.type === "return" && labels[0]?.label;
  const valid = labels.every(label => label.documentId === labels[0]?.documentId) && !errorStacks.length;

  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={UIProps.show} onHide={onHideCallBack} backdrop="static" keyboard={false} aria-labelledby="example-modal-sizes-title-xl" size={UIProps.shippingType === 'customs' ? 'xl' : 'lg'}>

        <Modal.Header>

          <Modal.Title>
            {
              getShipperName(UIProps?.shipper)
              + (labels?.[0]?.type === "return"
                ? " " + intl({ id: "DOCUMENT.SHIPPING.TYPE_RETURN" })
                : UIProps.shippingType === 'customs'
                  ? " " + intl({ id: "DOCUMENT.SHIPPING.DHL.CUSTOMS_DECLARATION" })
                  : "")
              + (UIProps.shippingType !== 'customs' && labels.length <= 1
                ? " Label"
                : UIProps.shippingType !== 'customs' && labels.length > 1
                  ? " Labels"
                  : "")
            }
          </Modal.Title>

          <div className="d-flex">

            {emailReturnLabel &&
              <MailButton onClick={mailLabelHandler} />
            }

            {(valid && UIProps.shippingType !== 'customs') && (
              <DeleteButton className="text-muted mr-3" onClick={deleteLabelHandler} disabled={printLoading} />
            )}

            <CloseButton onClick={onHideCallBack} />

          </div>

        </Modal.Header>

        {printLoading
          ? <div className="d-flex align-items-center justify-content-center" style={{ height: '50vh' }}>
            <div className='spinner spinner-primary spinner-xl mr-16' />
          </div>
          : <>
            {url && <PdfViewer url={url} height="80vh" />}

            <Modal.Body>
              {errorStacks.length > 0 &&
                errorStacks.map((err, i) => (
                  <div key={i} className="text-center" style={{ minHeight: '90px' }}>

                    <div>
                      <span><FormattedMessage id="CONNECTIONS.SHIPPER" /> : </span>
                      <span>{getShipperName(err?.service)}</span>
                    </div>
                    <div>
                      <span><FormattedMessage id="DOCUMENT.SHIPPING.PRINT_LABEL.DOCUMENT_ID" /> : </span>
                      <span>{err?.documentId || "---"}</span>
                    </div>
                    <div className="text-danger">
                      <span>{err?.message}</span>
                    </div>
                  </div>
                ))}
            </Modal.Body>
          </>

        }

      </Modal>
    </Portal>
  );
}
