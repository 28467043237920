import React, { useMemo } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { ActionsColumnFormatter } from './ActionsColumnFormatter';
import { useCustomersUIContext } from '../../_context/CustomersUIContext';
import { getCountryList, addressType } from '_metronic/_helpers';
import { BlankMessage } from '_metronic/_partials/components';
import { useIntl, FormattedMessage } from 'react-intl';
import { useLang } from '_metronic/i18n';
import { Icon } from '_metronic/_icons';



export function AddressTable() {

  const locale = useLang();
  const { formatMessage: intl } = useIntl();

  const UIContext = useCustomersUIContext();
  const UIProps = useMemo(() => ({
    openEditAddressDialogHandler: UIContext.openEditAddressDialogHandler,
    deleteAddressHandler: UIContext.deleteAddressHandler,
    address: UIContext.address,
  }), [
    UIContext.address,
    UIContext.deleteAddressHandler,
    UIContext.openEditAddressDialogHandler,
  ]);

  const COLUMNS = [
    {
      dataField: 'type',
      classes: 'pl-9',
      headerClasses: 'pl-9',
      text: intl({ id: 'ADDRESS.TABLE.TITLE_TYPE' }),
      formatter: (cell, row) => (addressType.find(item => item.value === cell)?.label ?? <FormattedMessage id="GENERAL.ADDRESS" />)
    },
    {
      dataField: 'name',
      text: intl({ id: 'GENERAL.NAME' }),
      headerStyle: { minWidth: '120px' },
      formatter: (cell, row) => (
        <div className="d-flex flex-column">
          <div className='font-weight-bold'>{cell}</div>
          <div>{row.company}</div>
        </div>
      )
    },
    {
      dataField: 'address_line1',
      text: intl({ id: 'ADDRESS.TABLE.TITLE_STR' }),
      formatter: (cell, row) => (<>{cell} {row.address_line2 && row.address_line2}</>),
    },
    {
      dataField: 'country_code',
      text: intl({ id: 'ADDRESS.TABLE.COUNTRY' }),
      formatter: (cell, row) => (
        <div className='d-flex flex-column'>
          <span>{row.city} / {getCountryList(locale).find(item => item.value === cell)?.label}</span>
          <span>{row.post_code}</span>
        </div>
      ),
    },
    {
      dataField: 'action',
      text: intl({ id: 'GENERAL.ACTIONS' }),
      classes: 'text-right pr-9',
      headerClasses: 'text-right pr-9',
      headerStyle: { minWidth: '100px' },
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditAddressDialogHandler: UIProps.openEditAddressDialogHandler,
        deleteAddressHandler: UIProps.deleteAddressHandler,
      },
    },
  ];


  return (
    UIProps.address.length === 0
      ? <BlankMessage
        icon={<Icon.GeoAlt />}
        title={<FormattedMessage id='CUSTOMER.ADDRESS.BLANK_MESSAGE_TITLE' />}
        message={<FormattedMessage id='CUSTOMER.ADDRESS.BLANK_MESSAGE' />}
        arrow
      />
      : <BootstrapTable
        keyField="id"
        data={UIProps.address || []}
        columns={COLUMNS}
        wrapperClasses="table-responsive"
        headerClasses="bg-info-o-10"
        classes="table table-head-custom table-vertical-center overflow-hidden mb-0"
        bootstrap4
        bordered={false}
        condensed
        remote
      />
  );
}
