import React from 'react';
import { FormattedMessage } from 'react-intl';
import actionTypes from './actionTypes';
import { toast } from 'react-toastify';



export class AdminActions {

  // Get all users
  static getUsers = (dateRange,queryParams) => {
    return {
      type: actionTypes.GET_USERS,
      payload: {
        queryParams,
        dateRange
      },
    };
  };

  static getUsersSuccess = (users, count) => {
    return {
      type: actionTypes.GET_USERS_SUCCESS,
      payload: {
        users,
        count
      },
    };
  };

  static getUsersFail = (error) => {
    return {
      type: actionTypes.GET_USERS_FAIL,
      payload: {
        error
      },
    };
  };


  // Get  user
  static getUser = (id) => {
    return {
      type: actionTypes.GET_USER,
      payload: {
        id
      },
    };
  };

  static getUserSuccess = (user) => {
    return {
      type: actionTypes.GET_USER_SUCCESS,
      payload: {
        user,
      },
    };
  };

  static getUserFail = (error) => {
    return {
      type: actionTypes.GET_USER_FAIL,
      payload: {
        error
      },
    };
  };



  // Update Users
  static updateUser = (id, user) => {
    return {
      type: actionTypes.UPDATE_USER,
      payload: {
        id,
        user
      },
    };
  };

  static updateUserSuccess = (userId, updatedUser) => {
    toast.success(<FormattedMessage id="USER.REDUX.UPDATE_USER.SUCCESS" />);
    return {
      type: actionTypes.UPDATE_USER_SUCCESS,
      payload: {
        userId,
        updatedUser
      },
    };
  };

  static updateUserFail = (error) => {
    toast.error(error?.data?.error?.message);
    return {
      type: actionTypes.UPDATE_USER_FAIL,
      payload: {
        error
      },
    };
  };



  // Delete user
  static deleteUser = (id, user, queryParams) => {
    return {
      type: actionTypes.DELETE_USER,
      payload: {
        id,
        user,
        queryParams
      },
    };
  };

  static deleteUserSuccess = (user) => {
    toast.success(<FormattedMessage id='ADMIN.USER.DELETE_MESSAGE.SUCCESS' values={{ name: user.name }} />);
    return {
      type: actionTypes.DELETE_USER_SUCCESS,
      payload: {
        user
      },
    };
  };

  static deleteUserFail = (error) => {
    toast.error(error.message);
    return {
      type: actionTypes.DELETE_USER_FAIL,
      payload: {
        error
      },
    };
  };



  // Get all temp users
  static getTempUsers = (queryParams) => {
    return {
      type: actionTypes.GET_TEMP_USERS,
      payload: {
        queryParams
      },
    };
  };

  static getTempUsersSuccess = (tempUsers, count) => {
    return {
      type: actionTypes.GET_TEMP_USERS_SUCCESS,
      payload: {
        tempUsers,
        count
      },
    };
  };

  static getTempUsersFail = (error) => {
    return {
      type: actionTypes.GET_TEMP_USERS_FAIL,
      payload: {
        error
      },
    };
  };



  // Update Temp Users
  static updateTempUser = (tempUser) => {
    return {
      type: actionTypes.UPDATE_TEMP_USER,
      payload: {
        tempUser
      },
    };
  };

  static updateTempUserSuccess = () => {
    toast.success(<FormattedMessage id="USER.REDUX.UPDATE_USER.SUCCESS" />);
    return {
      type: actionTypes.UPDATE_TEMP_USER_SUCCESS,
    };
  };

  static updateTempUserFail = (error) => {
    toast.error(error.data.error.message);
    return {
      type: actionTypes.UPDATE_TEMP_USER_FAIL,
      payload: {
        error
      },
    };
  };



  // Delete TempUser
  static deleteTempUser = (ids, queryParams) => {
    return {
      type: actionTypes.DELETE_TEMP_USERS,
      payload: {
        ids,
        queryParams
      },
    };
  };

  static deleteTempUserSuccess = (ids) => {
    toast.success(<FormattedMessage id='ADMIN.REDUX.DELETE_TEMP_USER.SUCCESS' />);
    return {
      type: actionTypes.DELETE_TEMP_USERS_SUCCESS,
      payload: {
        ids,
      },
    };
  };

  static deleteTempUserFail = error => {
    return {
      type: actionTypes.DELETE_TEMP_USERS_FAIL,
      payload: {
        error: error,
      },
    };
  };

  // Get all users
  static getReportsUsers = (dateRange, queryParams) => {
    return {
      type: actionTypes.GET_REPORTS_USERS,
      payload: {
        dateRange,
        queryParams
      },
    };
  };

  static getReportsUsersSuccess = (users, count) => {
    return {
      type: actionTypes.GET_REPORTS_USERS_SUCCESS,
      payload: {
        users,
        count
      },
    };
  };

  static getReportsUsersFail = (error) => {
    return {
      type: actionTypes.GET_REPORTS_USERS_FAIL,
      payload: {
        error
      },
    };
  };

  // Get all users contact
  static getUsersContact = (queryParams) => {
    return {
      type: actionTypes.GET_USERS_CONTACT,
      payload: {
        queryParams
      },
    };
  };

  static getUsersContactSuccess = (users, count) => {
    return {
      type: actionTypes.GET_USERS_CONTACT_SUCCESS,
      payload: {
        users,
        count
      },
    };
  };

  static getUsersContactFail = (error) => {
    return {
      type: actionTypes.GET_USERS_CONTACT_FAIL,
      payload: {
        error
      },
    };
  };


  // Get user details
  static getUserCompany = (id) => {
    return {
      type: actionTypes.GET_USER_COMPANY,
      payload: {
        id
      },
    };
  };

  static getUserCompanySuccess = (data) => {
    return {
      type: actionTypes.GET_USER_COMPANY_SUCCESS,
      payload: {
        company: data.company,
        order_report: data.order_report,
        usage_report: data.usage_report,
      },
    };
  }

  static getUserCompanyFail = (error) => {
    return {
      type: actionTypes.GET_USER_COMPANY_FAIL,
      payload: {
        error
      },
    };
  }

  // Users Action Creators
  static cleanUpUserModule = () => {
    return {
      type: actionTypes.CLEAN_UP_USER_MODULE,
    };
  };

  static cleanUpUserModuleParams = (params) => {
    return {
      type: actionTypes.CLEAN_UP_USER_MODULE_PARAMS,
      payload: {
        params
      },
    };
  };

}