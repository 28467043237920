import { toast } from "react-toastify";

export const validator = {

  isValidSku: (value, row, skuArray, positions, intl) => {

    const newSkuPositions = positions.map((position) => position.sku);
    const newSkuArray = skuArray.map((item) => item.sku)
    const newSku = [...newSkuPositions, ...newSkuArray];

    let skuExists;
    if (row.sku === value) {
      skuExists = newSku.filter(item => item !== value).includes(value)
    } else {
      skuExists = newSku.includes(value);
    }


    if (row.sku === undefined || row.sku === null || skuExists) {
      return {
        valid: false,
        message: intl({ id: "PRODUCT.GENERAL.SKU_WARNING" }),
      };
    } else {
      return { valid: true };
    }
  },

  isValidPrice: (cell, row, intl) => {
    if (cell.toString().includes(',')) {
      cell = +cell.replace(',', '.');
    }
    if (isNaN(cell)) {
      return {
        valid: false,
        message: intl({ id: "DOCUMENT.POSITION.IS_VALID.PRICE" })
      };
    }

    // en fazla iki ondalık sayı olmalı
    const decimalPart = (cell.toString().split('.')[1] || '').length;
    if (decimalPart > 2 && row.total_net_price === 0) {
      return {
        valid: false,
        message: intl({ id: "DOCUMENT.POSITION.IS_VALID.DECIMALS" })
      };
    }
  },


  isValidQuantity: (cell, row, skuArray, position, intl) => {

    const product = skuArray?.find(item => item.sku === row.sku);

    const productQuantity = product?.quantity ?? 0;
    const positionQuantity = position?.find(item => item.sku === row.sku)?.quantity ?? 0;

    if (cell.includes(',')) {
      cell = cell.replace(',', '.');
    }

    if (product && (cell > productQuantity + positionQuantity) && !row.manuel_product) {

      if (product?.category.key === "digital_product") {
        toast.warning(intl({ id: "DOCUMENT.POSITION.IS_VALID.QUANTITY4" }, { quantity: productQuantity }));
        return {
          valid: false,
          message: intl({ id: "GENERAL.STOCK_WARNING" })
        };
      }
      toast.warning(intl({ id: "DOCUMENT.POSITION.IS_VALID.QUANTITY3" }, { quantity: cell - (productQuantity + positionQuantity) }));
    }

    if (isNaN(cell)) {
      return {
        valid: false,
        message: intl({ id: "DOCUMENT.POSITION.IS_VALID.QUANTITY" })
      };
    }
    if (+cell <= 0) {
      return {
        valid: false,
        message: intl({ id: "PRODUCT.GENERAL.PRICE_THAN" })
      };
    }

    if (+cell > 999999) {
      return {
        valid: false,
        message: intl({ id: "PRODUCT.VARIANT.IS_VALID.QUANTITY" })
      };
    }

    if (row.unit === 'piece' && cell % 1 !== 0) {
      return {
        valid: false,
        message: intl({ id: "PRODUCT.GENERAL.INTEGER_REQUIRED" })
      };
    }
  },

  isValidWeight: (cell, intl) => {

    if (cell.toString().includes(',')) {
      cell = +cell.replace(',', '.');
    }

    const weightRegex = /^\d+(\.\d{1,3})?$/;

    if (isNaN(cell) || !weightRegex.test(cell.toString())) {
      return {
        valid: false,
        message: intl({ id: 'DOCUMENT.SHIPPING.VALID_WEIGHT' }),
      };
    }

    if (cell < 0.001) {
      return {
        valid: false,
        message: intl({ id: 'DOCUMENT.SHIPPING.VALID_WEIGHT_MIN' }),
      };
    }

    const weightString = cell.toString();
    const decimalPlaces = (weightString.split('.')[1] || '').length;

    if (decimalPlaces > 3) {
      return {
        valid: false,
        message: intl({ id: 'DOCUMENT.SHIPPING.VALID_WEIGHT_DECIMAL' }),
      };
    }
  },

};
