
/* eslint-disable */
// export const streetRegExp = /(?<strasse>\S+.*?)[\s+\.:](?<hausnr>[a-zA-Z-]*\d+\s*.*)/;
export const streetRegExp = /^(?:(?<hausnr_start>\d+\s*[a-zA-Z-]*[\.,]?\s+)(?<strasse>[a-zA-Z\u00C0-\u017F\s-]+)|(?<strasse>[a-zA-Z\u00C0-\u017F\s-]+?)[\s\.,:]\s*(?<hausnr>[a-zA-Z-]*\d+[a-zA-Z]*))$/;

// export const ibanRegExp = /^[A-Z]{2}[0-9]{2}[-. _]?[0-9]{4}[-. _]?[0-9]{4}[-. _]?[0-9]{4}[-. _]?[0-9]{4}[-. _]?[0-9]{2}$/;
export const ibanRegExp = /^[A-Z]{2}.*$/;

export const bicRegExp = /^([A-Z]{6}[A-Z2-9][A-NP-Z1-9])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$/;

// export const nameRegExp = /^/;
export const nameRegExp = /^(?!\s*$).+/;

export const password_regex_1 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;

// export const ortRegExp = /^[a-zA-ZÄÖÜäöüß]+(?:[\s-][a-zA-ZÄÖÜäöüß]+)*(?:[\.][\s]*[a-zA-ZÄÖÜäöüß]*)*$/;
export const ortRegExp = /\b[A-ZÄÖÜß][a-zA-Zäöüß\s-]+\b/;

// Sağladığınız normal ifade kalıbı, /\b[A-ZÄÖÜß][a-zA-Zäöüß\s-]+\b/, bir büyük harfle veya Almanca Ä, Ö, Ü veya ß harfleriyle başlayan ve ardından bir dizi harf, boşluk veya kısa çizgi gelen bir kelimeyi eşleştirmek için kullanılır. İşte modelin bir dökümü:

// \b: Bir sözcük sınırını temsil eder. Eşleşmenin kelime başında başlamasını sağlar.
// [A-ZÄÖÜß]: Bir büyük harfle veya Almanca Ä, Ö, Ü veya ß harfleriyle eşleşir.
// [a-zA-Zäöüß\s-]+: Küçük harflerin, büyük harflerin, Alman harfleri Ä, Ö, Ü veya ß'nin, boşlukların veya kısa çizgilerin bir veya daha fazla tekrarını eşleştirir.
// \b: Bir sözcük sınırını temsil eder. Eşleşmenin kelime sonunda bitmesini sağlar.
// Genel olarak, bu model belirli bir metinden büyük harfle yazılmış kelimeleri veya Almanca kelimeleri bulmak ve çıkarmak için kullanılabilir.


//export const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,10}$/;
// export const phoneRegExp = /^[0-9+\(\)\s][0-9\+\-\(\)\s]*$/;
export const phoneRegExp = /^[0-9+\(\)\s./-]*$/;

export const plzRegExp = /^(?<!\d)(\w+[\-])?(\d{4,5})$/;
//export const plzRegExp = /^[0-9]*$/;

export const eanRegExp = /^[0-9]+$/;

export const barcodeRegExp = /^[0-9]{1,30}$/;

export const isbnRegExp = /^[0-9xX-]{10,17}[xX]?$/;

export const upcRegExp = /^(?:\d{12})$/;

export const vatRegExp = /^[A-Z]{1,3}[0-9]+$/;

export const taxRegExp = /^[0-9/]+$/;

export const emailRegExp = /^[A-Za-z0-9._%\-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/u;

// Minimum eight characters, at least one uppercase letter, one lowercase letter and one number
export const passwordRedExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/

export const regexCategoryPattern = /^[0-9a-zA-Z\s>]+$/;

export const regexImgPattern = /^https?:\/\/[^ ]+\/[^ ]+\.(jpeg|jpg|png|gif)(\?.*)?$/i;

export const regexFeaturePattern = /^[^:]+:[^:]+$/;

export const regexVariantPattern = /^\s*(?:\d+\.\d+|\d+|\S+)\s*(?:,\s*(?:\d+\.\d+|\d+|\S+)\s*)*$/;

// Bu regex deseni, boşluk karakterleriyle başlayan veya sadece boşluk karakterlerinden oluşan girişleri reddedecek ve en az üç karakter içeren girişleri kabul edecektir. Bu şekilde, kullanıcıya yalnızca boş olmayan ve üç veya daha fazla karakter içeren girişler yapması gerektiği hatırlatılacaktır.
export const regexEmptyPattern = /^(?!\s+$).{3,}$/;

