/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { DocumentActions } from '../../_redux/actions';
import { Button, Modal, Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import * as columnFormatters from './column-formatters/ColumnFormatters';
import { useDocumentsUIContext } from '../../_context/DocumentsUIContext';
import * as UIHelpers from '../../_context/DocumentsUIHelpers';
import { UIDates } from '_metronic/_helpers';
import { toast } from 'react-toastify';
import { Icon } from '_metronic/_icons';
import { find } from 'lodash';
import { Portal } from 'react-portal';



export function ShippingTableDialog() {

  const { formatMessage: intl } = useIntl();
  const dispatch = useDispatch();

  const UIContext = useDocumentsUIContext();
  const UIProps = useMemo(() => ({
    show: UIContext.showLabelsTable,
    onHide: UIContext.closeLabelsTable,
    documentId: UIContext.documentId,
    openPrintLabelsDialog: UIContext.openPrintLabelsDialog,
    openDeleteLabelDialog: UIContext.openDeleteLabelDialog,
    sendEmailLabelDialog: UIContext.sendEmailLabelDialog,
    openLabelSettingDialog: UIContext.openLabelSettingDialog,
  }), [UIContext]);

  const { documents } = useSelector(state => ({
    documents: state.documents.documents,
  }), shallowEqual);

  const shipping = documents.find(item => item.id === UIProps.documentId)?.shipping;

  const openPrintLabelsDialog = (row, type) => {

    const findMethod = find(UIHelpers.LABEL_OPTIONS, { value: row.service });

    if (findMethod) {
      dispatch(DocumentActions.getLabel([row.id], type));
      UIProps.openPrintLabelsDialog(findMethod.value, UIProps.documentId, '', type);

    } else {
      toast.error(<FormattedMessage id="GENERAL.FAIL" />);
    }
  };

  const mailLabelHandler = (row) => {
    if (row.type === "return" && UIProps.documentId) {
      UIProps.sendEmailLabelDialog(UIProps.documentId, row.id);
    }
  };

  useEffect(() => {
    if (UIProps.show && shipping?.length === 0) {
      UIProps.onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipping])

  const COLUMNS = [
    {
      dataField: '#',
      text: intl({ id: 'GENERAL.NO' }),
      isDummyField: true,
      headerStyle: { minWidth: '50px', width: "3%" },
      headerClasses: 'text-center pl-4',
      classes: 'text-center font-weight-bold text-dark-50 pl-4',
      editable: false,
      formatter: (cell, row, rowIndex) => (rowIndex + 1),
    },
    {
      dataField: 'label',
      text: intl({ id: 'GENERAL.LABEL' }),
      headerStyle: { minWidth: '60px', width: "25%" },
      headerClasses: 'text-left pl-2',
      classes: 'font-weight-bold text-left text-dark-75',
      editable: false,
      style: (cell, row) => ({ cursor: row.unlabeled ? 'pointer' : 'default' }),
      editorStyle: () => ({ height: "32px", padding: "0.5rem 0.75rem", textAlign: "right" }),
      formatter: columnFormatters.LabelFormatter,
      formatExtraData: { openPrintLabelsDialog }
    },
    {
      dataField: 'type',
      text: "Type",
      headerStyle: { minWidth: '100px', width: "15%" },
      headerClasses: 'text-center',
      classes: 'font-weight-bold text-center text-dark-75',
      editable: false,
      editorStyle: () => ({ height: "32px", padding: "0.5rem 0.75rem", textAlign: "center" }),
      formatter: (cell, row, rowIndex) => (cell === "shipment" ? intl({ id: 'DOCUMENT.SHIPPING.TYPE_SHIPMENT' }) : intl({ id: 'DOCUMENT.SHIPPING.TYPE_RETURN' })),
    },
    {
      dataField: 'delivery_date',
      text: intl({ id: 'GENERAL.DELIVERY_DATE' }),
      type: 'date',
      headerStyle: { minWidth: '80px', width: "20%" },
      headerClasses: 'text-center',
      classes: 'font-weight-bold text-center',
      editable: false,
      editorStyle: () => ({ height: "32px", padding: "0.5rem 0.75rem", textAlign: "center" }),
      formatter: (cell, row) => cell ? UIDates.formatDate(cell) : null
    },
    {
      dataField: 'created_at',
      text: intl({ id: 'GENERAL.CREATE_DATE' }),
      type: 'date',
      headerStyle: { minWidth: '80px', width: "20%" },
      headerClasses: 'text-center',
      classes: 'font-weight-bold text-center',
      editable: false,
      editorStyle: () => ({ height: "32px", padding: "0.5rem 0.75rem", textAlign: "center" }),
      formatter: (cell, row) => cell ? UIDates.formatDateTime(cell) : row.delivery_date ? UIDates.formatDate(row.delivery_date) : null
    },
    {
      dataField: 'custom',
      text: intl({ id: 'DOCUMENT.SHIPPING.DHL.CUSTOMS' }),
      headerStyle: { minWidth: '50px', width: "5%" },
      headerClasses: 'text-center',
      classes: 'font-weight-bold text-center',
      editable: false,
      hidden: !shipping?.some(item => item.custom),
      editorStyle: () => ({ height: "32px", padding: "0.5rem 0.75rem", textAlign: "center" }),
      formatter: (cell, row) => (row?.custom &&
        <OverlayTrigger overlay={<Tooltip id="shipping-print-tooltip"><FormattedMessage id="GENERAL.PRINT.CUSTOMS_DECLARATION" /></Tooltip>}>
          <a className="btn btn-icon btn-hover-warning btn-sm" onClick={() => openPrintLabelsDialog(row, 'customs')}>
            <span className="svg-icon svg-icon-md">
              <Icon.FileEarmarkText />
            </span>
          </a>
        </OverlayTrigger>
      )
    },
    {
      dataField: 'action',
      text: "...",
      headerStyle: { minWidth: '100px', width: "7%" },
      headerClasses: 'font-size-h3 text-right pr-9',
      classes: 'text-right pr-9',
      editable: false,
      formatter: columnFormatters.ActionFormatter,
      formatExtraData: {
        documentId: UIProps.documentId,
        mailLabelHandler,
        openPrintLabelsDialog,
        openDeleteLabelDialog: UIProps.openDeleteLabelDialog
      },
    },
  ];


  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={UIProps.show} onHide={UIProps.onHide} size="lg" backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered>

        <Modal.Header>
          <Modal.Title>
            <Icon.Supplier className="mr-3" />
            <FormattedMessage id='GENERAL.SHIPPING' />
          </Modal.Title>

          <Button variant='outline-primary' size='sm' className="svg-icon svg-icon-sm" onClick={() => { UIProps.onHide(); UIProps.openLabelSettingDialog(UIProps.documentId) }} id="btn_shipping_table">
            <Icon.Plus />
            <span className="d-none d-sm-inline font-weight-bold ml-2">
              <FormattedMessage id="DOCUMENT.SHIPPING.ADD_TITLE" />
            </span>
          </Button>
        </Modal.Header>

        <Modal.Body className='p-0'>

          <BootstrapTable
            keyField="id"
            wrapperClasses="table-responsive"
            headerClasses="bg-info-o-10"
            classes="table table-head-custom table-vertical-center"
            data={shipping?.filter(item => !item.error) || []}
            bootstrap4
            bordered={false}
            columns={COLUMNS}
            deleteRow={true}
            condensed
          />

          {shipping?.filter(item => item.error)?.map((item, i) => (
            <Alert key={i} variant='light-danger' className='alert-custom alert-notice flex-column mx-10' onClose={() => UIProps.openDeleteLabelDialog({ documentId: UIProps.documentId, ...item })} dismissible>
              <FormattedMessage id='DOCUMENT.SHIPPING.CREATE_LABEL_ERROR' />
              <span className='font-weight-bold mt-2'>
                <span className='text-danger'>  {UIHelpers.getShipperName(item?.service)} - <FormattedMessage id={item.type === 'return' ? 'DOCUMENT.SHIPPING.TYPE_RETURN' : 'DOCUMENT.SHIPPING.TYPE_SHIPMENT'} />:{" "}</span>
                {item.error}
              </span>
            </Alert>
          ))}

        </Modal.Body>

        <Modal.Footer>

          <Button variant='light' onClick={UIProps.onHide} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

        </Modal.Footer>

      </Modal>
    </Portal>
  );
}
