import React from 'react'
import { Col, Row } from 'react-bootstrap'
import EmailSettings from './EmailSettings'
import PasswordSettings from './PasswordSettings'
import EmailEdit from './email-edit/EmailEdit'
import PasswordEdit from './password-edit/PasswordEdit'
import AccountSettings from './AccountSettings'
import AccountDelete from './account-delete/AccountDelete'



export default function LoginSettings() {
  return (
    <>
      <EmailEdit />
      <PasswordEdit />
      <AccountDelete />

      <Row>
        <Col lg={6}>
          <EmailSettings />
        </Col>
        <Col lg={6}>
          <PasswordSettings />
        </Col>
        <Col lg={6}>
          <AccountSettings />
        </Col>
      </Row>

    </>
  )
}
