import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import * as columnFormatters from './column-formatters';
import { getSelectRow, sortCaret, headerSortingClasses, NoRecordsFoundMessage } from '_metronic/_helpers';
import { ListingActions } from '../../_redux/actions';
import { RemotePagination } from '_metronic/_partials/controls';
import { useProductListingsUIContext } from '../../_context/ProductListingsUIContext';
import { useProductsUIContext } from 'app/modules/products/_context/ProductsUIContext';
import { useIntl } from 'react-intl';



export function EbayListingsTable({ queryParams, setPaginationQuery, setSearchQuery, products, count, isLoading, }) {

  const { formatMessage: intl } = useIntl();
  const dispatch = useDispatch();

  const productUIContext = useProductsUIContext();
  const UIContext = useProductListingsUIContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    setIds: UIContext.setIds,
    market_id: UIContext.market_id,
    openSyncAlertDialog: UIContext.openSyncAlertDialog,
    openPrepareEbayListingEdit: UIContext.openPrepareEbayListingEdit,
    openProductDetailsDialog: productUIContext.openProductDetailsDialog,
  }),
    [
      UIContext.ids,
      UIContext.setIds,
      UIContext.market_id,
      UIContext.openSyncAlertDialog,
      UIContext.openPrepareEbayListingEdit,
      productUIContext.openProductDetailsDialog
    ]
  );

  const deleteEbayInventoryHandler = (id, market_id, inventory) => {
    const updateEbayInventory = inventory.filter((inv) => inv.market_id !== market_id);
    dispatch(ListingActions.ebayInventoryUpdateRequest(id, market_id, { inventories: updateEbayInventory }, queryParams));
  };


  const COLUMNS = [
    {
      dataField: 'name',
      text: intl({ id: 'GENERAL.NAME' }),
      sort: true,
      sortCaret,
      headerSortingClasses,
      headerStyle: { width: '40%' },
      formatter: columnFormatters.NameColumnFormatter,
      formatExtraData: {
        market_id: UIProps.market_id,
        openPrepareEbayListingEdit: UIProps.openPrepareEbayListingEdit,
        openProductDetailsDialog: UIProps.openProductDetailsDialog,
      },
    },
    {
      dataField: 'inventories',
      text: intl({ id: 'GENERAL.STATUS' }),
      //sort: true,
      sortCaret,
      headerSortingClasses,
      classes: 'text-center',
      headerClasses: 'text-center',
      headerStyle: { minWidth: '150px', width: '15%' },
      formatter: columnFormatters.StatusColumnFormatter,
      formatExtraData: {
        market_id: UIProps.market_id,
        isLoading
      },
    },
    {
      dataField: 'prepare',
      text: intl({ id: 'GENERAL.PREPARE' }),
      classes: 'text-center',
      headerClasses: 'text-center',
      headerStyle: { minWidth: '90px', width: '10%' },
      editable: false,
      formatter: columnFormatters.PrepareColumnFormatter,
      formatExtraData: {
        openSyncAlertDialog: UIProps.openSyncAlertDialog
      },
    },
    {
      dataField: 'quantity',
      text: intl({ id: 'PRODUCT.GENERAL.QUANTITY' }),
      classes: 'font-weight-bolder text-center',
      headerClasses: 'text-center',
      headerStyle: { minWidth: '50px', width: '10%' },
      formatter: (cell, row) => columnFormatters.QuantityColumnFormatter(cell, row, UIProps.market_id),
    },
    {
      dataField: 'pricing',
      text: intl({ id: 'PRODUCT.TABLE.PRICE_GROSS' }),
      classes: 'font-weight-bolder text-right text-dark-75',
      headerClasses: 'text-right',
      headerStyle: { minWidth: '110px', width: '10%' },
      formatter: (cell, row) => columnFormatters.PricingColumnFormatter(cell, row, UIProps.market_id),
    },
    {
      dataField: 'action',
      text: intl({ id: 'GENERAL.ACTIONS' }),
      classes: 'text-right',
      headerClasses: 'text-right',
      headerStyle: { minWidth: '100px', width: '15%' },
      editable: false,
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        market_id: UIProps.market_id,
        openPrepareEbayListingEdit: UIProps.openPrepareEbayListingEdit,
        deleteEbayInventoryHandler
      },
    }
  ];


  return (
    <>
      <BootstrapTable
        keyField="id"
        data={products || []}
        columns={COLUMNS}
        wrapperClasses="table-responsive"
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        bordered={false}
        striped
        condensed
        noDataIndication={<NoRecordsFoundMessage loading={isLoading} onClick={() => setSearchQuery('')} />}
        selectRow={getSelectRow({
          ids: UIProps.ids,
          setIds: UIProps.setIds,
          entities: products != null ? products : []
        })}
      />
      <RemotePagination
        queryParams={queryParams.pagination}
        setQueryParams={setPaginationQuery}
        totalCount={count}
        entitiesCount={products?.length}
        loading={isLoading}
        countForPagination={count}
        paginationSize={8}
      />
    </>
  );
}
