import { INITIAL_SHIPPING } from '../_context/ShippingsUIHelpers';
import ActionTypes from './actionTypes';



const INITIAL_STATE = {
  loading: false,
  entities: [],
  countShipping: 0,
  totalShipping: 0,
  shippingForEdit: INITIAL_SHIPPING,
  labels: [],
  error: '',
};


export function shippingsReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {

    // Get Shippings
    case ActionTypes.GET_SHIPPINGS:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.GET_SHIPPINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        entities: payload.shippings,
        countShipping: payload.count.count,
        totalShipping: payload.count.total,
      };

    case ActionTypes.GET_SHIPPINGS_FAIL:
      return {
        ...state,
        loading: false,
        error: 'GENERAL.ERROR_MESSAGE_GENERAL',
      };



    // Delete Shipping
    case ActionTypes.DELETE_SHIPPING:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.DELETE_SHIPPING_SUCCESS:
      return {
        ...state,
        loading: false,
        entities: state.entities.filter((shipping) => shipping.id !== payload.shippingId),
        countShipping: state.countShipping - 1,
        totalShipping: state.totalShipping - 1,
      };

    case ActionTypes.DELETE_SHIPPING_FAIL:
      return {
        ...state,
        loading: false,
        error: 'GENERAL.ERROR_MESSAGE_GENERAL',
      };




    //* get labels
    case ActionTypes.GET_SHIPPING:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.GET_SHIPPING_SUCCESS:
      return {
        ...state,
        loading: false,
        labels: payload?.shipping,
      };

    case ActionTypes.GET_SHIPPING_FAIL:
      return {
        ...state,
        loading: false,
        labels: [],
        error: 'GENERAL.ERROR_MESSAGE',
      };



    case ActionTypes.CLEAN_STATES_IN_STORE:
      return {
        ...state,
        ...payload.cleanedStates,
      };


    default:
      return state;
  }
}
