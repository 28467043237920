import React, { useMemo } from "react";
import { Card, CardBody } from "_metronic/_partials/controls";
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { useDashboardUIContext } from "../_context/DashboardUIContext";
import { BlankMessage } from "_metronic/_partials/components";
import { convertPriceCurrency } from "_metronic/_helpers";
import ShowHideButton from "../components/ShowHideButton";
import { UserInfoDropdown } from "_metronic/_partials";
import { useToggleDisplayCard } from "app/hooks";
import { FormattedMessage } from "react-intl";
import { Icon } from '_metronic/_icons';
import { truncate } from 'lodash';



export function TableTopProduct({ data, total_invoice_count, currency }) {

  const [toggleProperty, dispatchProperty] = useToggleDisplayCard('top_products');

  const UIContext = useDashboardUIContext();
  const UIProps = useMemo(() => ({
    openProductDetailsDialog: UIContext.openProductDetailsDialog
  }), [UIContext])


  return (
    <Card className="card-stretch gutter-b">

      <div className="d-flex justify-content-between p-8">
        <div className="d-flex align-items-start flex-column">
          <h4 className="d-flex align-items-center font-weight-bolder text-dark">
            <FormattedMessage id="DASHBOARD.TOP_PRODUCTS" />
            <UserInfoDropdown description={<FormattedMessage id='USER_GUIDE.DASHBOARD.TOP_PRODUCTS' />} />
          </h4>
          <span className="text-muted font-weight-bold font-size-sm">
            {data.count &&
              <FormattedMessage id="DASHBOARD.TOP_PRODUCTS.MORE_THAN" values={{ count: data.count > 10 ? Math.floor(data.count / 10) * 10 : data.count }} />
            }
          </span>
        </div>
        <div className="align-items-start">
          <ShowHideButton toggleProperty={toggleProperty} dispatchProperty={dispatchProperty} id={"cardTop10ProductsShow"} />
        </div>
      </div>

      <CardBody className="pb-5 pt-0">
        {!(!toggleProperty || !data?.list?.length > 0)
          ? <Table responsive className="table-head-custom table-head-bg table-vertical-center">
            <thead>
              <tr className="text-left text-uppercase">

                <th className="text-center svg-icon pl-4 pl-md-7" style={{ width: "10%" }}>
                  <OverlayTrigger overlay={<Tooltip id="quick-actions-tooltip"><FormattedMessage id="PRODUCT.GENERAL.QUANTITY" /></Tooltip>}>
                    <Icon.GraphUp />
                  </OverlayTrigger>
                </th>

                <th className="px-0 px-md-3" style={{ minWidth: "150px" }}>
                  <FormattedMessage id="GENERAL.NAME" />
                </th>

                <th className="text-right pr-4 pr-md-7" style={{ width: "20%" }}>
                  <FormattedMessage id="GENERAL.SALE" />
                </th>

              </tr>
            </thead>

            <tbody>

              {data.list?.map((data, index) => (
                <tr key={index}>

                  <td className="text-center pl-0 pl-md-7">
                    <OverlayTrigger placement="left" overlay={<Tooltip id="quick-actions-tooltip">{data?.previous_index ? `Previous index: ${data?.previous_index}` : 'New'}</Tooltip>}>
                      <div type="button" className="svg-icon text-dark-75 font-weight-bolder font-size-lg d-flex flex-column align-items-center">
                        {data?.previous_index
                          ? index < data?.previous_index
                            ? <>
                              <Icon.CaretUpFill className="text-success" />
                              {data.quantity}
                            </>
                            : <>
                              {data.quantity}
                              <Icon.CaretDownFill className="text-danger" />
                            </>
                          : <>
                            <Icon.CaretUpFill className="text-success" />
                            {data.quantity}
                          </>
                        }
                      </div>
                    </OverlayTrigger>
                  </td>

                  <td className="px-0 px-md-3 py-3">
                    <div className="d-flex align-items-start preview">

                      <span type="button" className="text-decoration-none text-nowrap" onClick={() => UIProps.openProductDetailsDialog(data.product_id)} id='btn_product_preview'>
                        <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg" title={data.title}>
                          {truncate(data.title, { length: 40, separator: ' ' })}
                        </div>
                        <div className="text-muted font-weight-bold">
                          {data.sku}
                        </div>
                      </span>

                      <span className='invisible text-dark-25 svg-icon svg-icon-sm pl-2'>
                        <Icon.FileEarmarkText />
                      </span>

                    </div>
                  </td>

                  <td className="text-right pr-2 pr-md-6">
                    <div className="text-dark-75 font-weight-bolder font-size-lg">
                      {convertPriceCurrency(+data?.total_gross?.toFixed(2), currency)}
                    </div>
                  </td>

                </tr>))}

            </tbody>
          </Table>

          : <BlankMessage
            icon={<Icon.Catalog />}
            message={toggleProperty && <FormattedMessage id={(total_invoice_count === 0) ? 'DASHBOARD.BLANK_MESSAGE' : 'DASHBOARD.BLANK_MESSAGE.NO_FILTER'} />}
          />
        }
      </CardBody>
    </Card>
  );
}
