import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Type } from 'react-bootstrap-table2-editor';
import BootstrapTable from 'react-bootstrap-table-next';
import { RemotePagination } from '_metronic/_partials/controls';
import { sortCaret, headerSortingClasses, NoRecordsFoundMessage, UIDates, convertPriceCurrency, getSelectRow } from '_metronic/_helpers';
import * as UIHelpersDocuments from 'app/modules/documents/_context/DocumentsUIHelpers';
import * as columnFormatters from './column-formatters/ColumnFormatters';
import { useShippingsUIContext } from '../_context/ShippingsUIContext';
import * as uiHelpers from '../_context/ShippingsUIHelpers';
import { validator } from './column-formatters/validator';
import { ShippingsActions } from '../_redux/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { find } from 'lodash';



export function ShippingsTable({ entities, countShipping, loading, queryParams, setPaginationQuery, setOrderQuery, setSearchQuery }) {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  // Customers UI Context
  const UIContext = useShippingsUIContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    setIds: UIContext.setIds,
    openDeleteLabelDialog: UIContext.openDeleteLabelDialog,
    openCustomerDetailsDialog: UIContext.openCustomerDetailsDialog,
    openPrintLabelsDialog: UIContext.openPrintLabelsDialog,
    sendEmailLabelDialog: UIContext.sendEmailLabelDialog
  }), [UIContext]);

  const openPrintLabelsDialog = (row) => {
    const findMethod = find(UIHelpersDocuments.LABEL_OPTIONS, { value: row.service });
    if (findMethod) {
      dispatch(ShippingsActions.getShipping([row.id]))
      UIProps.openPrintLabelsDialog(findMethod.value);
    }
  };


  const COLUMNS = [
    {
      dataField: 'label',
      text: intl({ id: 'GENERAL.LABEL' }),
      headerStyle: { minWidth: '60px', width: "15%" },
      headerClasses: 'text-left pl-6',
      classes: 'text-left font-weight-bold text-dark-75 pl-5',
      editable: false,
      editorStyle: () => ({ height: "32px", padding: "0.5rem 0.75rem", textAlign: "left", }),
      formatter: columnFormatters.LabelFormatter,
      formatExtraData: {
        openPrintLabelsDialog
      }
    },
    {
      dataField: 'type',
      text: intl({ id: 'CONNECTIONS.TYPE' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '100px', width: "15%" },
      headerClasses: 'text-center',
      classes: 'text-center font-weight-bold text-dark-75  pr-7',
      editor: { type: Type.SELECT, options: UIHelpersDocuments.getShipperOptions() },
      editable: false,
      editorStyle: () => ({ height: "32px", padding: "0.5rem 0.75rem", textAlign: "left" }),
      formatter: (cell, row) => {
        return (
          <div className='d-flex flex-column mr-1'>
            <span className={`${cell === "return" ? "text-danger" : "text-dark-50"}`} >
              <FormattedMessage id={cell === "return" ? 'DOCUMENT.SHIPPING.TYPE_RETURN' : 'DOCUMENT.SHIPPING.TYPE_SHIPMENT'} />
            </span>
          </div>
        );
      },
    },
    {
      dataField: 'customer',
      text: intl({ id: 'CUSTOMER_TITLE' }),
      headerStyle: { minWidth: '150px', width: "15%" },
      headerClasses: 'text-left',
      classes: 'text-left',
      editable: false,
      editorStyle: () => ({ height: "32px", textAlign: "center" }),
      formatter: (cell, row) => {
        return (
          <div className='d-flex flex-column mr-1'>
            <span className='font-weight-bold text-nowrap'>{row.delivery_address.name}</span>
            <span className='text-dark-50'>{row.delivery_address.email}</span>
          </div>
        );
      }
    },
    {
      dataField: 'delivery_address',
      text: intl({ id: 'ADDRESS.DELIVERY' }),
      headerStyle: { minWidth: '150px', width: "10%" },
      headerClasses: 'text-left',
      classes: 'text-left',
      editable: false,
      editorStyle: () => ({ height: "32px", textAlign: "center" }),
      formatter: (cell, row) => {
        const location = [];
        if (cell?.country_code) {
          location.push(cell?.country_code);
        }
        if (cell?.post_code) {
          location.push(cell?.post_code);
        }
        return (
          <div className='d-flex flex-column mr-1'>
            <span className='font-weight-bold text-nowrap'>{cell.city}</span>
            <span className='text-dark-50'>{location.join('-')}</span>
          </div>
        );
      },
    },
    {
      dataField: 'relation_documents',
      text: intl({ id: 'DOCUMENT.DETAILS' }),
      headerStyle: { minWidth: '160px', width: "10%" },
      headerClasses: 'text-center',
      classes: 'text-center',
      editable: false,
      editorStyle: () => ({ height: "32px", textAlign: "center" }),
      formatter: (cell, row) => {
        if (cell.length === 1) {
          const item = cell[0];
          return (
            <div className='d-flex flex-column mr-1'>
              <span className='font-weight-bold'>{item.id}</span>
              <span className='text-dark-50'>({item.type})</span>
            </div>
          );
        } else {
          const tooltip = cell.map((item, index) => (
            <div className='d-flex flex-column mr-1' key={index}>
              <span className='font-weight-bold'>{item.id}  <span className='text-dark-50'>({item.type})</span> </span>
            </div>
          ));
          return <div className='d-flex flex-column mr-1'>{tooltip}</div>;
        }
      }
    },
    {
      dataField: 'dp_product_name',
      text: intl({ id: 'GENERAL.NAME' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '60px', width: "10%" },
      headerClasses: 'text-center',
      classes: 'text-center font-weight-bold text-dark-75 pr-7',
      hidden: (cell, row) => !(row.service === 'deutsche_post'),
      editable: false,
      editorStyle: () => ({ height: "32px", textAlign: "center" }),
      formatter: (cell, row) => cell,
    },
    {
      dataField: 'delivery_date',
      text: intl({ id: 'GENERAL.DELIVERY_DATE' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      type: 'date',
      headerStyle: { minWidth: '140px', width: "15%" },
      headerClasses: 'text-center',
      classes: 'text-center pr-7',
      editor: { type: Type.DATE },
      editable: false,
      editorStyle: () => ({ height: "32px", padding: "0.5rem 0.75rem", textAlign: "center" }),
      formatter: (cell, row) => UIDates.formatDate(cell)
    },
    {
      dataField: 'weight',
      text: intl({ id: 'GENERAL.WEIGHT' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '90px', width: "5%" },
      headerClasses: 'text-center',
      classes: 'text-center font-weight-bold text-dark-75 pr-7',
      editable: false,
      editorStyle: (row) => ({ height: "32px", padding: "0.5rem 0.75rem", textAlign: "center" }),
      validator: (cell, row, column) => validator.isValidWeight(cell, row?.service, intl),
      formatter: (cell, row) => cell,
    },
    {
      dataField: 'price',
      text: intl({ id: 'DOCUMENT.SHIPPING_PRICE' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '150px', width: "10%" },
      headerClasses: 'text-right',
      classes: 'text-right font-weight-bolder text-dark-75 pr-3',
      editable: false,
      editorStyle: () => ({ height: "32px", padding: "0.5rem 0.75rem", textAlign: "right" }),
      validator: (cell, row, column) => validator.isValidPrice(cell, intl),
      formatter: (cell, row) => {
        return (
          <div className="pr-4">
            {convertPriceCurrency(+cell, "EUR")}
          </div>
        );
      }
    },
    {
      dataField: 'action',
      text: intl({ id: 'GENERAL.ACTIONS' }),
      headerStyle: { minWidth: '100px', width: "5%" },
      headerClasses: 'text-right pr-3',
      classes: 'text-right pr-3',
      editable: false,
      formatter: columnFormatters.ActionFormatter,
      formatExtraData: {
        openPrintLabelsDialog,
        openDeleteLabelDialog: UIProps.openDeleteLabelDialog
      },
    },
  ];


  return (
    <>
      <BootstrapTable
        keyField="id"
        key={UIProps.ids}
        data={entities || []}
        columns={COLUMNS}
        wrapperClasses="table-responsive"
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        bordered={false}
        striped
        condensed
        defaultSorted={uiHelpers.defaultSorted}
        noDataIndication={<NoRecordsFoundMessage loading={loading} onClick={() => setSearchQuery('')} />}
        selectRow={getSelectRow({
          ids: UIProps.ids,
          setIds: UIProps.setIds,
          entities: entities,
        })}
      />
      <RemotePagination
        queryParams={queryParams.pagination}
        setQueryParams={setPaginationQuery}
        totalCount={countShipping}
        entitiesCount={entities?.length}
        loading={loading}
      />
    </>
  );
}
