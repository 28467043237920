import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDocumentsUIContext } from 'app/modules/documents/_context/DocumentsUIContext';
import { DHL_ACCOUNT_OPTIONS } from 'app/modules/connections/_context/SettingsUIHelpers';
import * as columnFormatters from '../../../document-table/column-formatters';
import { DocumentActions } from 'app/modules/documents/_redux/actions';
import { CloseButton, UserInfoDropdown } from '_metronic/_partials';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { getConnection } from 'constants/connectionSettings';
import { validator } from '../column-formatters/validator';
import { convertPriceCurrency } from '_metronic/_helpers';
import BootstrapTable from 'react-bootstrap-table-next';
import { FormattedMessage, useIntl } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useWindowSize } from 'app/hooks';
import { toast } from 'react-toastify';
import { Icon } from '_metronic/_icons';



export function ShippingAddFormGroping() {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();
  const { windowSize } = useWindowSize();

  const [processed, setProcessed] = useState(false);
  const [serviceOptions, setServiceOptions] = useState({
    goGreen: false,
    premium: false,
    endorsement: false,
  });

  const UIContext = useDocumentsUIContext();
  const UIProps = useMemo(() => ({
    show: UIContext.showLabelGroupingDialog,
    onHide: UIContext.closeLabelGroupingDialog,
    shipperName: UIContext.shipperName,
    openPrintLabelsDialog: UIContext.openPrintLabelsDialog,
    ids: UIContext.ids,
  }),
    [
      UIContext.showLabelGroupingDialog,
      UIContext.closeLabelGroupingDialog,
      UIContext.shipperName,
      UIContext.openPrintLabelsDialog,
      UIContext.ids,
    ]);


  const { shippingGrouping, connections } = useSelector(state => ({
    shippingGrouping: state.documents.shippingGrouping,
    connections: state.connections.entities
  }), shallowEqual);

  useEffect(() => {
    dispatch(DocumentActions.getSettingsByType('shipping'))
  }, [dispatch])

  const tableRowHandler = (oldValue, newValue, row, column) => {

    if (oldValue === newValue) { return; }

    if (column.dataField === 'weight') {
      row.weight = +row.weight.replace(',', '.');
    }
    if (column.dataField === 'price') {
      row.price = +row.price.replace(',', '.');
    }

    if (column.dataField === 'dp_product_id' && row.marketplace === "") {
      const findDeutschePost = deutschePost?.deutsche_post?.product_list.find(item => item.id === newValue)
      row.price = +findDeutschePost?.price / 100 || 0
    }

    dispatch(DocumentActions.updateShippingGrouping(row, column));
  };

  const onHide = () => {
    UIProps.onHide();
    dispatch(DocumentActions.cleanStatesInStore({ shippingGrouping: [] }));
    setServiceOptions({
      goGreen: false,
      premium: false,
      endorsement: false,
    });
    setProcessed(false);
  };

  const dhlCustomerNumber = connections.find((item) => item?.name === 'dhl_new')?.dhl?.customerNumber;
  const dhlSubscriptions = connections.find((item) => item?.name === 'dhl_new')?.dhl?.subscriptions || [];
  const dhlAccountOptions = dhlSubscriptions.map(item => {
    const baseOption = DHL_ACCOUNT_OPTIONS?.find((opt) => opt.accountNumber === item.accountNumber);
    return baseOption;
  })

  const handleCheckbox = (e) => {
    const { name, checked } = e.target;
    setServiceOptions(prevState => ({
      ...prevState,
      [name]: checked
    }));

    if (name === 'goGreen') {
      const getIds = [];
      UIProps.ids.forEach(id => Object.values(id).forEach(i => getIds.push(i)));
      dispatch(DocumentActions.createAllDocuments(getIds, UIProps.shipperName, { goGreen: checked }));
    }
  }

  const getDHLAccountOptions = (country_code) => {
    return dhlAccountOptions.filter((item) => {
      return country_code === "DE"
        ? UIProps.shipperName === "dhl_new_warenpost"
          ? serviceOptions?.goGreen
            ? (item?.type === "dhl_new_warenpost" && item?.location === "domestic" && item?.goGreen)
            : (item?.type === "dhl_new_warenpost" && item?.location === "domestic" && !item?.goGreen)
          : serviceOptions?.goGreen
            ? (item?.type === "dhl_new" && item?.location === "domestic" && item?.goGreen)
            : (item?.type === "dhl_new" && item?.location === "domestic" && !item?.goGreen)
        : UIProps.shipperName === "dhl_new_warenpost"
          ? serviceOptions?.premium
            ? serviceOptions?.goGreen
              ? (item?.type === "dhl_new_warenpost" && item?.location === "abroad" && item?.goGreen && item?.premium)
              : (item?.type === "dhl_new_warenpost" && item?.location === "abroad" && !item?.goGreen && item?.premium)
            : serviceOptions?.goGreen
              ? (item?.type === "dhl_new_warenpost" && item?.location === "abroad" && item?.goGreen)
              : (item?.type === "dhl_new_warenpost" && item?.location === "abroad" && !item?.goGreen)
          : serviceOptions?.goGreen
            ? (item?.type === "dhl_new" && item?.location === "abroad" && item?.goGreen) || (item?.type === "dhl_new" && item?.location === "eu" && item?.goGreen)
            : (item?.type === "dhl_new" && item?.location === "abroad" && !item?.goGreen) || (item?.type === "dhl_new" && item?.location === "eu" && !item?.goGreen)
    }).map(item => ({ label: `${item?.accountNumber} - ${intl({ id: item?.name_short })}`, value: `${dhlCustomerNumber}${item?.accountNumber}` })) || []
  }

  const saveShipping = () => {

    if (UIProps.shipperName === "deutsche_post" && shippingGrouping.some(item => !item.dp_product_id)) {
      return toast.warning(<FormattedMessage id='DOCUMENT.SHIPPING.DEUTSCHE_POST.PRODUCT_SELECT' />);
    }

    const documents = [];

    for (const item of shippingGrouping) {

      const { order_id, order_number, dp_product_id, email, number, delivery_address, documentId, price, weight, position } = item;
      const shipping = { price, weight, type: "shipment" }

      if (UIProps.shipperName === "deutsche_post") {

        const findDeutschePost = deutschePost?.deutsche_post?.product_list.find(product => product.id === dp_product_id);
        shipping.dp_product_name = findDeutschePost?.name || '';
        shipping.dp_product_id = dp_product_id;

      } else if ((UIProps.shipperName === "dhl_new" || UIProps.shipperName === "dhl_new_warenpost") && item.billing_number) {

        shipping.billing_number = item.billing_number;
        shipping.service_options = {
          premium: serviceOptions.premium,
          endorsement: serviceOptions.endorsement
        };
      }

      documents.push({
        id: documentId,
        order_id,
        order_number,
        customer_details: { email, number, delivery_address },
        shipping,
        ...(['dhl', 'dhl_warenpost', 'dhl_new', 'dhl_new_warenpost'].includes(UIProps.shipperName) && {
          positions: position
        })
      })
    }


    if (UIProps.shipperName && documents.length) {
      dispatch(DocumentActions.createLabels(UIProps.shipperName, documents));
      UIProps.openPrintLabelsDialog(UIProps.shipperName);
      setProcessed(true);
    };

    onHide();
  };

  const deutschePost = connections?.find(item => item.name === 'deutsche_post') || null;
  const DEUTSCHE_POST = deutschePost?.deutsche_post?.product_list?.map(item => ({ label: item.name, value: item.id })) || []

  const SERVICE_OPTIONS = [
    {
      name: "premium",
      label: intl({ id: 'DOCUMENT.SHIPPING.DHL_SERVICE.PREMIUM' }),
      info: intl({ id: UIProps.shipperName === 'dhl_new' ? 'DOCUMENT.SHIPPING.DHL_SERVICE.PREMIUM_INFO' : 'DOCUMENT.SHIPPING.DHL_SERVICE.WARENPOST_PREMIUM_INFO' }),
      show: UIProps.shipperName === 'dhl_new_warenpost'
    },
    {
      name: "goGreen",
      label: intl({ id: 'DOCUMENT.SHIPPING.DHL_SERVICE.GO_GREEN' }),
      info: intl({ id: 'DOCUMENT.SHIPPING.DHL_SERVICE.GO_GREEN_INFO' }),
      show: true
    },
    {
      name: "endorsement",
      label: intl({ id: 'DOCUMENT.SHIPPING.DHL_SERVICE.ENDORSEMENT' }),
      info: intl({ id: 'DOCUMENT.SHIPPING.DHL_SERVICE.ENDORSEMENT_INFO' }),
      show: (UIProps.shipperName === 'dhl_new' && shippingGrouping.some(item => item?.delivery_address?.country_code !== "DE"))
    }
  ]

  const COLUMNS = [
    {
      dataField: 'documentId',
      text: 'Id',
      headerStyle: { width: "15%" },
      editable: false,
    },
    {
      dataField: 'position',
      isDummyField: true,
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.ARTICLE' }),
      headerStyle: { minWidth: '320px' },
      style: { fontSize: '11px' },
      editable: false,
      formatter: (cell, row, rowIndex) => <columnFormatters.ProductColumnFormatter cell={cell} row={row} rowIndex={rowIndex} />,
    },
    {
      dataField: 'delivery_address',
      text: intl({ id: 'ADDRESS.DELIVERY' }),
      headerStyle: { minWidth: '80px', width: "20%" },
      headerClasses: 'text-left',
      classes: 'text-left',
      editable: false,
      formatter: (cell, row) => {
        const location = [];
        if (cell?.country_code) {
          location.push(cell?.country_code);
        }
        if (cell?.post_code) {
          location.push(cell?.post_code);
        }
        return (
          <div className='d-flex flex-column mr-1'>
            <span className='font-weight-bold text-nowrap'>{cell.city}</span>
            <span className='text-dark-50'>{location.join('-')}</span>
          </div>
        );
      },
    },
    {
      dataField: 'marketplace',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.MARKETPLACE' }),
      headerStyle: { width: "15%" },
      headerClasses: 'text-center',
      classes: 'text-center font-weight-bold text-dark-50',
      editable: false,
      formatter: (cell, row) => {
        const icon = getConnection(cell)?.iconLong;
        return (
          <div className="text-decoration-none text-muted text-hover-primary">
            <span className='icon-lg'>{icon ? icon : cell}</span>
          </div>
        );
      },
    },
    {
      dataField: 'order_number',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.ORDER_ID' }),
      editable: false,
      headerStyle: { width: "10%" },
      headerClasses: 'text-center',
      classes: 'text-center font-weight-bold text-dark-50',
      formatter: (cell, row) => cell ? cell : row.order_id,
    },
    {
      dataField: 'dp_product_id',
      text: "Product List",
      headerStyle: { width: "20%" },
      hidden: UIProps.shipperName !== "deutsche_post",
      headerClasses: 'text-center',
      classes: 'text-center',
      editor: { type: Type.SELECT, options: DEUTSCHE_POST },
      style: (cell, row) => ({ cursor: 'pointer' }),
      editorStyle: () => ({ height: "34.7px", padding: "0.5rem 0.75rem 0.5rem 1.75rem" }),
      formatter: (cell, row) => (
        <div className={`edit-table ${!cell && "form-control form-control-sm is-invalid"}`}>
          {DEUTSCHE_POST.find(item => item.value === cell)?.label}
        </div>
      ),
    },
    {
      dataField: 'billing_number',
      text: intl({ id: "DOCUMENT.SHIPPING.DHL.BILLING_DATA" }),
      headerStyle: { width: "25%" },
      hidden: !["dhl_new", "dhl_new_warenpost"].includes(UIProps.shipperName),
      headerClasses: 'text-center',
      classes: 'text-left',
      editor: {
        type: Type.SELECT,
        getOptions: (setOptions, { row, column, cell }) => {
          return getDHLAccountOptions(row?.delivery_address?.country_code);
        }
      },
      style: (cell, row) => ({ cursor: 'pointer' }),
      editorStyle: () => ({ height: "34.7px", padding: "0.5rem 0.75rem 0.5rem 1.75rem" }),
      formatter: (cell, row) => (
        <div className={`edit-table ${!cell && "form-control form-control-sm is-invalid"}`}>
          {getDHLAccountOptions(row?.delivery_address?.country_code).find(item => item.value === cell)?.label}
        </div>
      ),
    },
    {
      dataField: 'weight',
      text: intl({ id: 'GENERAL.WEIGHT' }),
      headerStyle: { width: "10%" },
      headerClasses: 'text-center',
      classes: 'text-center',
      style: (cell, row) => ({ cursor: 'pointer' }),
      editorStyle: () => ({ height: "34.7px", textAlign: "center" }),
      validator: (cell, row, column) => validator.isValidWeight(cell, UIProps.shipperName, intl),
      formatter: (cell, row) => <div className="edit-table">{+cell}</div>,
    },
    {
      dataField: 'price',
      text: intl({ id: 'DOCUMENT.SHIPPING_PRICE2' }),
      headerStyle: { width: "15%" },
      headerClasses: 'text-center pr-15',
      classes: 'font-weight-bold text-center pr-15',
      style: (cell, row) => ({ cursor: (row?.marketplace === "" && UIProps.shipperName !== "deutsche_post") ? 'pointer' : 'default' }),
      editable: (cell, row) => row.marketplace === "" && UIProps.shipperName !== "deutsche_post",
      editorStyle: () => ({ height: "34.7px", textAlign: "center" }),
      validator: (cell, row, column) => validator.isValidPrice(cell, intl),
      formatter: (cell, row) => {
        const isEditTable = row.marketplace === "" && UIProps.shipperName !== "deutsche_post";
        return (
          <div className={` ${isEditTable ? 'edit-table' : 'pr-4'} `}>
            {convertPriceCurrency(+cell, row.currency)}
          </div>
        );
      },
    },
    {
      dataField: '#',
      text: "",
      classes: 'hover-menu',
      editable: false,
      hidden: processed || !["dhl_new", "dhl_new_warenpost"].includes(UIProps.shipperName),
      formatter: (cell, row, rowIndex) => {
        return (
          <OverlayTrigger placement="top" overlay={<Tooltip id={`fulfillment-delete-tooltip-${rowIndex}`}><FormattedMessage id="DOCUMENT.SHIPPING.DELETE" /></Tooltip>}>
            <span className="d-inline-block">
              <span className="btn btn-icon btn-sm" onClick={() => dispatch(DocumentActions.deleteShippingsList(row?.id))} id={`btn_delete_${row?.id}`}>
                <Icon.Close className="text-danger" />
              </span>
            </span>
          </OverlayTrigger>
        )
      },
    }
  ]


  return (
    <Modal show={UIProps.show} onHide={onHide} size='xl' backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered>

      <CloseButton onClick={onHide} className="position-absolute top-0 right-0 mr-10 mt-10" />

      <div className="d-flex justify-content-center align-items-center icon-4x pt-15">
        {getConnection(UIProps.shipperName)?.iconLong}
        <UserInfoDropdown description={<FormattedMessage id='DOCUMENT.SHIPPING.GROUPING_PRINT_INFO' />} />
      </div>

      <Modal.Body>

        {["dhl_new", "dhl_new_warenpost"].includes(UIProps.shipperName) &&
          <Row>
            <Col lg="12">
              <div className="separator separator-dashed my-2" />
              <label className='font-weight-bold text-capitalize text-dark-75'>
                <FormattedMessage id="DOCUMENT.SHIPPING.DHL_ADDITIONAL_SERVICE" />
              </label>
            </Col>

            <Col lg='6'>
              {SERVICE_OPTIONS?.map((item, i) => (
                item.show &&
                <label className="switch switch-sm switch-icon" key={i}>
                  <input
                    type="checkbox"
                    name={item.name}
                    id={item.name}
                    checked={serviceOptions[item.name]}
                    onChange={(e) => handleCheckbox(e)}
                  // disabled={loading}
                  />
                  <span />
                  <div className="ml-2">
                    {item.label}
                  </div>
                  <OverlayTrigger overlay={<Tooltip id="document-print-tooltip">{item.info}</Tooltip>}>
                    <div type="button" className="d-flex svg-icon svg-icon-sm text-info ml-2" id="service_info">
                      <Icon.InfoCircle />
                    </div>
                  </OverlayTrigger>
                </label>
              ))}
            </Col>
          </Row>
        }

        <PerfectScrollbar className="scroll pr-5 mr-n5" style={{ maxHeight: '500px' }}>

          <BootstrapTable
            keyField="id"
            data={shippingGrouping || []}
            columns={COLUMNS}
            wrapperClasses="table-responsive pb-5"
            classes="table table-head-custom table-vertical-center"
            headerClasses="bg-info-o-10"
            bootstrap4
            bordered={false}
            deleteRow={true}
            condensed
            cellEdit={cellEditFactory({
              mode: windowSize < 576 ? 'click' : 'dbclick',
              blurToSave: true,
              afterSaveCell: tableRowHandler,
            })}
          />

        </PerfectScrollbar>
      </Modal.Body>

      <Modal.Footer>

        <Button variant='light' onClick={onHide} id="btn_cancel_modal">
          <FormattedMessage id="GENERAL.CANCEL" />
        </Button>

        <Button variant='primary' className="ml-2" onClick={saveShipping} id="btn_save_modal">
          <FormattedMessage id="DOCUMENT.SHIPPING.CREATE_LABEL" />
        </Button>

      </Modal.Footer>

    </Modal>
  );
}
